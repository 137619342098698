import React, { ReactNode } from "react";
import Backdrop from "./Backdrop";
import styled from "styled-components";
import { FaXmark, FaZ } from "react-icons/fa6";
// styled-components ----
const Container = styled.div`
  z-index: 2001;
  width: 90%;
  max-width: 694px;
  background: white;
  padding: 1.875rem;
  border-radius: 20px;
  opacity: 1;
  margin: auto;
`;
const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const CloseButton = styled.button`
  margin: none;
  padding: none;
  background: none;
`;
const CrossIcon = styled(FaXmark)`
  height: 1.625rem;
  width: 1rem;
  opacity: 0.15;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const Heading = styled.h2`
  margin-top: 0.5625rem;
  font-size: 25px;
  line-height: 35px;
  color: ${(props) => props.theme.colors.gray[800]};
  font-weight: 700;
  padding: 0 1.875rem;
`;
// ----------------------

function Modal({
  children,
  handleClose,
  heading,
}: {
  children: ReactNode;
  handleClose: () => void;
  heading: string;
}) {
  return (
    <Backdrop>
      <Container>
        <CloseButtonContainer>
          <CloseButton onClick={handleClose}>
            <CrossIcon />
          </CloseButton>
        </CloseButtonContainer>
        <Heading>{heading}</Heading>
        {children}
      </Container>
    </Backdrop>
  );
}

export default Modal;
