import { ScheduleItemType } from "@components/AdminDashboard/SubPages/Courses/AddCourse/formInitialValues";
import { TeacherScheduleEditFormType } from "@lib/Types/API/Teacher/APITeacherScheduleEditFormType";
import { format, parseISO } from "date-fns";

export function mapScheduleItemToAPI(
  data: ScheduleItemType[]
): TeacherScheduleEditFormType[] {
  const something = data.map((item) => ({
    name: item.name ? item.name : "",
    date: item.date ? format(item.date, "yyyy-MM-dd") : "",
    "start-time": item.startTime ? `${item.startTime}:00` : "",
    "end-time": item.endTime ? `${item.endTime}:00` : "",
  }));
  return something;
}
