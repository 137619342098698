import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";
import { UserRole } from "@app/redux/slices/userSlice";
import { TeacherList } from "@lib/Types/API/FormData/FormData";

type TeacherListType =
  | {
      success: true;
      data: TeacherList;
    }
  | {
      success: false;
      data: string;
    };

export const getTeachersList = async function (
  role: UserRole
): Promise<TeacherListType> {
  let url: string;
  if (role === UserRole.ADMIN) {
    url = urlFunctions.getAdminTeachersList();
  } else if (role === UserRole.MANAGER) {
    url = urlFunctions.getManagerTeachersList();
  } else {
    throw new Error("Not Authorised");
  }
  const res = await API.sendGetRequest(url, true);
  if (res.success) {
    const data: TeacherList = res.data;
    return {
      success: true,
      data: data,
    };
  } else {
    return {
      success: false,
      data: "There was an error in getting teacher list",
    };
  }
};
