import React from "react";
import StudentProfile from "@components/StudentDashBoardSubPages/StudentProfile";

function StudentDetails() {
  return (
    <>
      <StudentProfile />
    </>
  );
}

export { StudentDetails };
