import React, { ReactNode } from "react";
import Header from "@components/CommonDashboard/Header";
import Layout from "./Layout";

type ViewProps = {
  isCheckout: boolean;
  children: ReactNode;
};

function View(props: ViewProps) {
  const { isCheckout, children } = props;

  return <Layout isCheckout={isCheckout}>{children}</Layout>;
}

export default View;
