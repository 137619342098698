import { FormEvent, FormEventHandler, useEffect, useState } from "react";
import CustomHeading from "@lib/microComponents/CustomHeading";
import {
  redirect,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
// import Cookies from "js-cookie";
import styled from "styled-components";
import FormSubmitButton from "@components/LogInPages/commonComponents/FormSubmitButton";
import SignInWithGoogleButton from "@components/LogInPages/commonComponents/SignInWithGoogleLink";
import BaseLayout from "@components/LogInPages/BaseLayout";
import FormInput from "@components/LogInPages/commonComponents/FormInput";
import { Link } from "react-router-dom";
import { loginWithPassword } from "@API/services/Auth";
import { getUserProfile } from "@API/services/Data/getUserProfile";
import { UserRole, userSlice } from "@app/redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { mapAPidatatoUserInfo } from "@lib/mapperFunctions/mapAPidatatoUserInfo";
import { ReactToast } from "@lib/toast";
import { useTranslation } from "react-i18next";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { useRedirectUri } from "@app/hooks/useRedirectUri";
import Cookies from "js-cookie";
import SignInAndGoogleDivider from "../commonComponents/SignInAndGoogleDivider";
import SignInButton from "../commonComponents/SignInButton";
// import { loginWithPassword } from "api/services/Auth";
// start of styled-components

const FormContainer = styled.div`
  max-width: 35.75rem;
  margin: auto;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  width: 100%;
`;
const SignInForm = styled.form`
  background: white;
  margin-top: 3.5rem;
  display: flex;
  flex-direction: column;
  font-weight: 600;
`;
const FormLabel = styled.label`
  color: ${(props) => props.theme.colors.gray[800]};
  font-size: 0.9rem;
  font-family: ${(props) => props.theme.font.font};
`;

const ForgotPasswordLink = styled(Link)`
  font-size: 0.8rem;
  text-decoration: underline;
  margin-top: 1.1rem;
  font-weight: 400;
  color: ${(props) => props.theme.colors.gray[700]};
  font-family: ${(props) => props.theme.font.font};
`;
const Divider = styled.div`
  margin: 1.6rem 0rem;
  height: 2px;
  background: #eaefec;
`;
const SignUpLinkContainer = styled.div`
  margin: auto;
  margin-top: 2.5rem;
  color: ${(props) => props.theme.colors.gray[800]};

  font-size: 0.82rem;
  font-weight: 700;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SignUpLink = styled(Link)`
  color: #355f44;
  font-weight: 400;
  font-family: ${(props) => props.theme.font.font};
`;

interface SignInHeadingProps {
  $type: "signin" | "checkout";
}

const SigninHeading = styled.h2<SignInHeadingProps>`
  color: ${(props) => props.theme.colors.gray[800]};
  font-weight: 700;
  font-size: 1.375rem;
  line-height: 2.0625rem;
  max-width: ${(props) => (props.$type === "checkout" ? "336px" : "100%")};
  margin: ${(props) => (props.$type === "checkout" ? "auto" : "none")};
  text-align: ${(props) => (props.$type === "checkout" ? "center" : "left")};
`;

// end of styled-components

function SignIn() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();
  const { lang } = useParams();
  const userInfo = useSelector(selectUser);
  const [redirectUri, redirectFunction] = useRedirectUri(userInfo.role);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const verification = searchParams.get("verified");
    const statusCode = searchParams.get("statusCode");
    if (statusCode === "555") {
      ReactToast.error(
        "Wrong signin method. Registered with email and password."
      );
      searchParams.delete("statusCode");
      setSearchParams(searchParams);
    }
    if (verification === "true") {
      ReactToast.success("Verfication successfull");
    } else if (verification === "false") {
      ReactToast.error("Verfication failed. Try again");
    }
  }, []);

  useEffect(() => {
    const redirect_uri = searchParams.get("redirect_uri");

    if (userInfo.loggedIn) {
      if (userInfo.role === UserRole.STUDENT && redirect_uri) {
        navigate(redirect_uri);
        return;
      }
      if (userInfo.role === UserRole.STUDENT && state && state.to) {
        navigate(`${state.to}`);
        return;
      }
      if (userInfo.role) {
        navigate(redirectFunction(userInfo.role));
        return;
      }
    }
  }, [userInfo.loggedIn]);
  const onSubmit = async function (e: FormEvent) {
    e.preventDefault();
    setLoading(true);
    const login = await loginWithPassword(email, password);
    if (login) {
      const userInfo = await getUserProfile();
      if (userInfo.success) {
        if (
          userInfo.data.role === UserRole.STUDENT &&
          userInfo.data.verified &&
          userInfo.data
        ) {
          ReactToast("Login Successfull");
          dispatch(userSlice.actions.setUserInfo(userInfo.data));
        } else if (userInfo.data.role !== UserRole.STUDENT && userInfo.data) {
          dispatch(userSlice.actions.setUserInfo(userInfo.data));
        }
        if (
          userInfo.data.role === UserRole.STUDENT &&
          !userInfo.data.verified
        ) {
          if (state && state.to) {
            navigate(`${state.to}`);
          } else {
            navigate(lang ? `/${lang}/verify-email` : `/verify-email`, {
              state: { email: userInfo.data.email },
            });
            Cookies.remove("token");
          }
        } else if (state && state.to) {
          console.log("navigating to", state.to);
          navigate(state.to);
        }
      } else {
        ReactToast("Error in getting user info");
      }
      setLoading(false);
    } else {
      ReactToast("bad credentials");
    }
    setLoading(false);
  };
  return (
    <BaseLayout>
      <FormContainer>
        <SigninHeading
          $type={state ? "checkout" : "signin"}
          style={{ marginTop: "1.5rem" }}
        >
          {state ? t("signin.checkoutWelcome") : t("signin.welcome")}
        </SigninHeading>
        <SignInForm onSubmit={onSubmit}>
          <FormLabel htmlFor="email">E-mail</FormLabel>
          <FormInput
            name="email"
            type="email"
            autoComplete="username" // autocomplete="username" is intentional
            id="email"
            value={email}
            placeholder="your@email.com"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            disabled={loading}
          />
          <FormLabel htmlFor="password" style={{ marginTop: "1.4rem" }}>
            {t("signin.password")}
          </FormLabel>
          <FormInput
            name="current- password"
            type="password"
            autoComplete="current-password"
            id="password"
            value={password}
            style={{ letterSpacing: "0.05rem" }}
            placeholder="••••••••••••••••••••"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            disabled={loading}
          />
          <ForgotPasswordLink to={`/${lang}/forgot-password`}>
            {t("signin.forgotpassword")}
          </ForgotPasswordLink>
          <FormSubmitButton loading={loading}>
            {t("signin.signin")}
          </FormSubmitButton>
        </SignInForm>
        <SignInAndGoogleDivider text="Or quickly sign in/up with:" />
        <SignInWithGoogleButton state={state} />
        <SignInAndGoogleDivider text={`${t("signin.new")}?`} />
        <SignInButton state={state} to={`/${lang}/signup`}>
          {t("signin.createaccount")}
        </SignInButton>
      </FormContainer>{" "}
    </BaseLayout>
  );
}

export default SignIn;
