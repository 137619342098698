import React from 'react'
import styled from 'styled-components'
const ErrorContainer= styled.div`
margin:2rem auto;
text-align:center;
`;
function CourseLoadingError() {
  return (
    <ErrorContainer>There was issue in loading the courses try after sometime</ErrorContainer>
  )
}

export default CourseLoadingError