import styled from "styled-components";
import { IconType } from "react-icons";
import { Link } from "react-router-dom";
import theme from "@styles/theme";

// styled-components ----
const Container = styled.div`
  background: #ffffff;
  height: 15.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
`;
const Text = styled.span`
  display: block;
  margin-bottom: 1rem;
  color: ${(props) => props.theme.colors.gray[800]};
  line-height: 1.5625rem;
  font-size: 1.125rem;
`;

// ----------------------

const SettingsMenuItem = function ({
  Icon,
  title,
  link,
}: {
  Icon: IconType;
  title: string;
  link: string;
}) {
  return (
    <Link to={link} style={{ textDecoration: "none" }}>
      <Container>
        <div style={{ textAlign: "center" }}>
          <Text>{title}</Text>
          <Icon
            style={{
              height: "2.3125rem",
              minHeight: "2.3125rem",
              color: `${theme.colors.gray[600]}`,
              width: "auto",
            }}
          />
        </div>
      </Container>
    </Link>
  );
};
export default SettingsMenuItem;
