import { colors, device } from "@styles/theme";
import React from "react";
import styled from "styled-components";
import AccordionRenderer from "./AccordionRenderer";

const Container = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 20px;
  padding: 50px;
  @media ${device.tablet} {
    gap: 20px;
    padding: 20px;
  }
`;

const Heading = styled.div`
  height: 38px;
  line-height: 1.9375rem;
  font-size: 1.375rem;
  font-weight: 700;
  border-left: 11px solid ${colors.blue[700]};
  padding-left: 20px;
  display: flex;
  align-items: center;
  color: ${colors.gray[800]};
`;

function Accordion() {
  return (
    <Container>
      <Heading>We're looking for you:</Heading>
      <AccordionRenderer />
    </Container>
  );
}

export default Accordion;
