import styled from "styled-components";
import { device } from "@styles/theme";
import {
  FaRegCircleQuestion,
  FaRegPaperPlane,
  FaWhatsapp,
} from "react-icons/fa6";
import Skeleton from "@components/CommonComponents/CourseComponent/Skeleton";

import { useTranslation } from "react-i18next";
import DateRangeSelector from "@lib/microComponents/DateRangeSelector";
import { useState } from "react";
import { DateRange } from "react-day-picker";
import { MultiSelect } from "@lib/microComponents/MultiSelect";
import { useUniversityOptions } from "@lib/hooks/useUniversityOptions";
// styled components -----

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
const Heading = styled.div`
  font-size: 3.125rem;
  line-height: 4.4375rem;
  color: ${(props) => props.theme.colors.gray[600]};
  font-weight: 700;
  margin-bottom: 3.125rem;
  @media ${device.tablet} {
    font-size: 2.1875rem;
    line-height: 3.125rem;
    margin-bottom: 1.875rem;
  }
`;
const HelpComponentContainer = styled.div`
  background: #ffffff;
  border-radius: 20px;
  padding: 1.875rem;
  width: 100%;
  max-width: 418px;
  display: inline-block;
  @media ${device.tablet} {
    padding: 1.25rem;
    border-radius: 10px;
  }
`;
const HelpHeading = styled.div`
  font-size: 1.375rem;
  line-height: 1.9375rem;
  font-weight: 700;
  border-left: 11px solid ${(props) => props.theme.colors.blue[700]};
  padding: 0.25rem 0 0.25rem 1.25rem;
  color: ${(props) => props.theme.colors.gray[800]};
  @media ${device.tablet} {
    line-height: 1.5625rem;
    font-size: 1.125rem;
    border-width: 0.5rem;
  }
`;

const InnerContainer = styled.div`
  margin-top: 1.875rem;
`;
const HelpContainer = styled.a`
  display: flex;
  align-items: center;
`;

const HelpWhatsappIcon = styled(FaWhatsapp)`
  height: 2.1875rem;
  width: 2.1875rem;
  color: ${(props) => props.theme.colors.gray[800]};
  opacity: 0.25;
`;
const EmailIcon = styled(FaRegPaperPlane)`
  height: 2.1875rem;
  width: 2.1875rem;
  color: ${(props) => props.theme.colors.gray[800]};
  opacity: 0.25;
`;
const HelpText = styled.div`
  font-size: 18px;
  line-height: 25px;
  color: ${(props) => props.theme.colors.gray[800]};
  margin-left: 30px;
`;
// -----------------------
function Support() {
  const { t } = useTranslation();
  return (
    <HelpComponentContainer>
      <HelpHeading>{t("sdashboard.support.component.heading")}</HelpHeading>
      <InnerContainer>
        <HelpContainer target="_black" href=" https://wa.me/+31202612965">
          <HelpWhatsappIcon />
          <HelpText>{t("sdashboard.support.component.whatsapp")}</HelpText>
        </HelpContainer>
        <HelpContainer
          target="_blank"
          href="mailto:info@academischkenniscentrum.nl"
          style={{ marginTop: "0.9375rem" }}
        >
          <EmailIcon />
          <HelpText>{t("sdashboard.support.component.mail")}</HelpText>
        </HelpContainer>
      </InnerContainer>
    </HelpComponentContainer>
  );
}

function StudentSupport() {
  const [show, setShow] = useState<boolean>(true);
  const [range, setRange] = useState<DateRange>({
    from: new Date("2023-10-20"),
    to: new Date(),
  });
  const universityOptions = useUniversityOptions();
  const { t } = useTranslation();
  return (
    <Container>
      <Heading>{t("sdashboard.support.heading")}</Heading>
      <Support />
    </Container>
  );
}

export default StudentSupport;
