import { AddCourseManagerListOptionType } from "@components/AdminDashboard/SubPages/Courses/AddCourse/Components/GeneralDetails/ManagerSelector";
import { AdminData } from "@components/AdminDashboard/SubPages/Settings/SubPages/AdminAndManagers/AdminAdmin";
import { AdminAdminResponse } from "@lib/Types/API/APIAdminAdmin";
import { assetsUrls } from "@styles/theme";

export const mapAdminResponseAddCourseManagerListOptionType = (
  data: AdminData
): AddCourseManagerListOptionType => {
  return {
    id: data.AdminId,
    value: data.firstName + " " + data.LastName,
    profilePicUrl: data.profilePicUrl,
  };
};
