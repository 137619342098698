import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";
import {
  createGroupDataType,
  createStudyDataType,
  createSubjectDataType,
  createTeacherDataType,
  createUniversityDataType,
  updateStudyDataType,
  updateSubjectDataType,
  updateUniversityDataType,
} from "@lib/Types/EntitiesCrud";
import { string } from "yup";

type Response =
  | {
      success: true;
      error: null;
      data: any;
    }
  | {
      success: false;
      error: string;
      data: null;
    };

export const createUniversity = async (
  name: string,
  image: string
): Promise<Response> => {
  const url = urlFunctions.createUniversity();
  const data: createUniversityDataType = {
    universityName: name,
    universityLogoUrl: image,
  };
  const res = await API.sendPostRequest(url, data, true);
  if (res.success) {
    return {
      success: true,
      error: null,
      data: res.data,
    };
  } else {
    return {
      success: false,
      error: "Could not create university",
      data: null,
    };
  }
};

export const createGroup = async (name: string): Promise<Response> => {
  const url = urlFunctions.createGroup();
  const data: createGroupDataType = {
    courseGroupName: name,
  };
  const res = await API.sendPostRequest(url, data, true);
  if (res.success) {
    return {
      success: true,
      error: null,
      data: res.data,
    };
  } else {
    return {
      success: false,
      error: "Could not create group",
      data: null,
    };
  }
};

export const createSubject = async (
  name: string,
  studyId: string
): Promise<Response> => {
  const url = urlFunctions.createSubject();
  const data: createSubjectDataType = {
    courseSubjectName: name,
    studyId: studyId,
  };
  const res = await API.sendPostRequest(url, data, true);
  if (res.success) {
    return {
      success: true,
      error: null,
      data: res.data,
    };
  } else {
    return {
      success: false,
      error: "Could not create subject",
      data: null,
    };
  }
};

export const createStudy = async (
  name: string,
  universityId: string
): Promise<Response> => {
  const url = urlFunctions.createStudy();
  const data: createStudyDataType = {
    courseStudyName: name,
    universityId: universityId,
  };
  const res = await API.sendPostRequest(url, data, true);
  if (res.success) {
    return {
      success: true,
      error: null,
      data: res.data,
    };
  } else {
    return {
      success: false,
      error: "Could not create study",
      data: null,
    };
  }
};

export const createTeacher = async (
  name: string,
  email: string,
  feature1: string,
  feature2: string
): Promise<Response> => {
  const url = urlFunctions.createTeacher();
  const data: createTeacherDataType = {
    name,
    email,
    feature1,
    feature2,
  };
  const res = await API.sendPostRequest(url, data, true);
  if (res.success) {
    return {
      success: true,
      error: null,
      data: res.data,
    };
  } else {
    return {
      success: false,
      error: "Could not create teacher",
      data: null,
    };
  }
};

export const updateUniversity = async (
  universityId: string,
  universityName: string,
  universityImage: string | undefined
) => {
  const url = urlFunctions.editUniversity(universityId);
  const data: updateUniversityDataType = {
    universityName: universityName,
    universityLogoUrl: universityImage,
  };
  const res = await API.sendPutRequest(url, data, true);
  if (res.success) {
    return {
      success: true,
      error: null,
      data: res.data,
    };
  } else {
    return {
      success: false,
      error: "Could not create university",
      data: null,
    };
  }
};

export const updateSubject = async (
  name: string,
  subjectId: string
): Promise<Response> => {
  const url = urlFunctions.editSubject(subjectId);
  const data: updateSubjectDataType = {
    courseSubjectName: name,
  };
  const res = await API.sendPutRequest(url, data, true);
  if (res.success) {
    return {
      success: true,
      error: null,
      data: res.data,
    };
  } else {
    return {
      success: false,
      error: "Could not create subject",
      data: null,
    };
  }
};

export const updateStudy = async (
  name: string,
  studyId: string
): Promise<Response> => {
  const url = urlFunctions.editStudy(studyId);
  const data: updateStudyDataType = {
    courseStudyName: name,
  };
  const res = await API.sendPutRequest(url, data, true);
  if (res.success) {
    return {
      success: true,
      error: null,
      data: res.data,
    };
  } else {
    return {
      success: false,
      error: "Could not create study",
      data: null,
    };
  }
};
