import { CourseOrderDetailsResponseData } from "@lib/Types/API/APIOrderDetails";
import { mapAdminOrdersDetails } from "@lib/mapperFunctions/mapAPIAdminOrders";
import { OrdersDetailsData } from "@components/AdminDashboard/SubPages/Orders/AdminOrders";
import { urlFunctions } from "@API/functions/createUrl";
import { API } from "@API/API";
import { number } from "yup";
export const getAdminOrdersDetails = async (
  id: string | undefined
): Promise<OrdersDetailsData> => {
  const url = urlFunctions.getAdminOrderDetails(id);
  const repsonse = await API.sendGetRequest(url, true);
  if (repsonse.success) {
    const Datas: CourseOrderDetailsResponseData = repsonse.data;
    const res: OrdersDetailsData = await mapAdminOrdersDetails(Datas);
    return res;
  }
  return {} as OrdersDetailsData;
};
