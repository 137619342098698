import { object, string, number, date, boolean, array, mixed } from "yup";
import dateFns from "date-fns";

export const minimumNumberOfSpots = 1;
export const minimumNumberOfGroups = 1;
export const minimumOriginalPrice = 0;
export const minimumSalePrice = 0;
export const MANAGER = "manager";
export const STUDY = "study";
export const SUBJECT = "subject";
export const NAME = "name";
export const UNIVERSITY = "university";
export const LANGUAGE = "language";
export const EVENT = "event";
export const GRADE_YEAR = "gradeYear";
export const SPOTS = "spots";
export const GROUPS = "groups";
export const ORIGINAL_PRICE = "originalPrice";
export const SALE_PRICE = "salePrice";
export const STUDY_GUIDE_AVAILABLE = "studyGuideAvailable";
export const STUDY_GUIDE = "studyGuide";
export const SELL_INDIVIDUALLY = "sell-individually";
export const SCHEDULE = "schedule";
export const AVAILABLE_FROM = "available-from";
export const AVAILABLE_UPTO = "available-upto";
export const GUIDE_PRICE = "guidePrice";
export const QUESTIONS = "questions";
export const FEATURES = "features";
export const HIGHLIGHTS = "highlights";
export const TEACHER = "teacher";
export const TEACHER_USP_1 = "teacher-usp-1";
export const TEACHER_USP_2 = "teacher-usp-2";

export type AddCourseFormFieldsName =
  | typeof MANAGER
  | typeof STUDY
  | typeof SUBJECT
  | typeof NAME
  | typeof UNIVERSITY
  | typeof LANGUAGE
  | typeof EVENT
  | typeof GRADE_YEAR
  | typeof SPOTS
  | typeof GROUPS
  | typeof ORIGINAL_PRICE
  | typeof SALE_PRICE
  | typeof STUDY_GUIDE_AVAILABLE
  | typeof STUDY_GUIDE
  | typeof SELL_INDIVIDUALLY
  | typeof SCHEDULE
  | typeof AVAILABLE_FROM
  | typeof AVAILABLE_UPTO
  | typeof GUIDE_PRICE
  | typeof QUESTIONS
  | typeof FEATURES
  | typeof HIGHLIGHTS
  | typeof TEACHER
  | typeof TEACHER_USP_1
  | typeof TEACHER_USP_2;

export const optionTypeSchema = object().shape({
  id: string().required(),
  value: string().required(),
});

const optionTypeSchemaValidation = (name: string) => {
  return mixed().test(name, `${name} is required`, (value) => {
    if (optionTypeSchema.isValidSync(value)) {
      return true;
      return false;
    }
  });
};
const formSchema = object({
  [MANAGER]: optionTypeSchemaValidation(MANAGER),
  [NAME]: string()
    .required("Name can not be empty")
    .min(5, "Name should be atleast 5 characters long")
    .max(50, "Name can not be more than 50 characters long"),
  [SUBJECT]: optionTypeSchemaValidation(SUBJECT),
  [UNIVERSITY]: optionTypeSchemaValidation(UNIVERSITY),
  [STUDY]: optionTypeSchemaValidation(STUDY),
  [LANGUAGE]: optionTypeSchemaValidation(LANGUAGE),
  [EVENT]: optionTypeSchemaValidation(EVENT),
  [GRADE_YEAR]: optionTypeSchemaValidation(GRADE_YEAR),
  [SPOTS]: number()
    .integer()
    .required("required")
    .min(minimumNumberOfSpots)
    .strict(),
  [GROUPS]: number().required().min(minimumNumberOfGroups),
  [ORIGINAL_PRICE]: number()
    .integer()
    .required("Original Price is a required field")
    .min(
      minimumOriginalPrice,
      "Orignial price should be greater than or equal to €0"
    ),
  [SALE_PRICE]: number()
    .integer()
    .required("Sales price is required")
    .min(minimumSalePrice, "Sale price should be greater than or equal to €0"),
  [SCHEDULE]: array()
    .of(
      object().shape({
        name: string()
          .required("Schedule name is required ")
          .min(5, "Schedule name must be at least 5 characters long")
          .max(50, "Schedule name must be at most 50 characters long"),
        date: date().required("Schedule date is required"),
        startTime: string().required("Start time is required"),
        endTime: string().required("End time is required"),
        id: string().required(),
      })
    )
    .min(1),
  [STUDY_GUIDE_AVAILABLE]: boolean().required(
    "study guide is a required field"
  ),
  [AVAILABLE_FROM]: date().when(STUDY_GUIDE_AVAILABLE, {
    is: true,
    then: () => date().required("Required"),
    otherwise: () => date().notRequired(),
  }),
  [AVAILABLE_UPTO]: date().when(STUDY_GUIDE_AVAILABLE, {
    is: true,
    then: () => date().required("Required"),
    otherwise: () => date().notRequired(),
  }),
  [GUIDE_PRICE]: number().when(STUDY_GUIDE_AVAILABLE, {
    is: true,
    then: () => number().required("Required"),
    otherwise: () => number().notRequired(),
  }),
  [STUDY_GUIDE]: string().when(STUDY_GUIDE_AVAILABLE, {
    is: true,
    then: () => string().required("file is required"),
    otherwise: () => string().notRequired(),
  }),
  [SELL_INDIVIDUALLY]: boolean().when(STUDY_GUIDE_AVAILABLE, {
    is: true,
    then: () => boolean().required(),
    otherwise: () => boolean().notRequired(),
  }),
  [QUESTIONS]: array()
    .of(
      object().shape({
        question: string()
          .required("question is a required field")
          .min(10, "question should be atleast 10 character long"),
        answer: string()
          .required("answer is a reqired field")
          .min(20, "answer should be at least 20 characters long"),
        id: string().required(),
      })
    )
    .min(1),
  [FEATURES]: array()
    .optional()
    .of(
      object().shape({
        id: string().required(),
        content: string().required("feature field is required").min(10),
      })
    ),
  [HIGHLIGHTS]: array().of(
    object().shape({
      id: string().required(),
      label: string().required(),
      content: string().required().min(10),
    })
  ),
  [TEACHER]: object().shape({
    id: string().required(),
    name: string().required(),
    description: string().required(),
    image: string().required(),
  }),
  [TEACHER_USP_1]: string().required("Teacher usp 1 is a required field"),
  [TEACHER_USP_2]: string().required("Teacher usp 2 is a required field"),
});

export const saveFormSchema = object().shape({
  [NAME]: string()
    .required("Name can not be empty")
    .min(5, "Name should be atleast 5 characters long")
    .max(50, "Name can not be more than 50 characters long"),
  [SUBJECT]: optionTypeSchemaValidation(SUBJECT),
  [UNIVERSITY]: optionTypeSchemaValidation(UNIVERSITY),
  [STUDY]: optionTypeSchemaValidation(STUDY),
});
export default formSchema;
export type formSchemaType = typeof formSchema;
