import styled from "styled-components";
import Header from "@components/CommonDashboard/Header";
import BookImageUrl from "@images/book.png";
import LaptopImage from "@images/laptop.png";
import SpiralArrowImage from "@images/spiral-arrow.png";
import { FaAngleDown, FaStar } from "react-icons/fa6";
import FormSelect from "./FormSelect";
import SmilingManImage from "@images/smiling-man-low-res.webp";
import Form from "./Form";
import { device } from "@styles/theme";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
// start of styled-components\
const Container = styled.div`
  width: 100vw;
  display: flex;
  height: 100vh;
  min-height: 100vh;
  flex-direction: column;
  background: rgb(72, 131, 196);
  background: linear-gradient(
    90deg,
    rgba(72, 131, 196, 1) 35%,
    rgba(27, 103, 188, 1) 100%
  );

  @media ${device.laptopL} {
    height: auto;
  }
  @media (max-height: 1000px) {
    height: auto;
  }
`;
const HeaderContainer = styled.div``;
const InnerContainer = styled.div`
  width: 80%;
  justify-content: center;
  margin: auto;
  flex-grow: 1;
  display: flex;
  @media ${device.desktopXS} {
    width: 90%;
  }
`;

const LeftIconsContainer = styled.div`
  margin: 5rem 0;
  margin-right: 5.625rem;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media ${device.laptopM} {
    display: none;
  }
`;

const RightIconsContainer = styled.div`
  margin: 5rem 0;
  margin-left: 4.875rem;
  width: 24.375rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media ${device.laptopM} {
    display: none;
  }
`;

const BookImageContainer = styled.div`
  display: inline-block;
  align-self: flex-end;
  position: relative;
`;
const BookImageShadow = styled.div`
  position: absolute;
  height: 4.875rem;
  width: 4.875rem;
  border-radius: 2.4375rem;
  bottom: 0;
  background: #00000040;
  transform-origin: center;
  transform: translate(-5%, 40%);
  z-index: 0;
`;
const BookImageStyled = styled.img`
  width: 5.5rem;
  height: 6.625rem;
  position: relative;
  z-index: 1;
`;

const MiddleContainer = styled.div`
  width: 52rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5rem 0;
  @media ${device.desktopXS} {
    margin: 3rem 0;
  }
`;
const LaptopImageContainer = styled.div`
  margin-top: 1.6875rem;
  display: inline-block;
  align-self: flex-start;
  position: relative;
`;
const LaptopImageShadow = styled.div`
  width: 10.625rem;
  height: 10.625rem;
  position: absolute;
  top: 0;
  left: -2rem;
  background: #00000040;
  transform-origin: center;
  border-radius: 5.3125rem;
  z-index: 0;
`;
const LaptopImageStyled = styled.img`
  position: relative;
  z-index: 1;
  width: 13.8125rem;
  height: 9.0625rem;
`;
const GirlImageContainer = styled.div`
  margin-top: 5.4375rem;
  align-self: center;
  width: 7.75rem;
  height: 7.75rem;
`;

const GirlImageStyled = styled.img`
  width: 7.75rem;
  height: 7.75rem;
`;

const HeadingsContainer = styled.div`
  text-align: center;
  @media ${device.tablet} {
    text-align: left;
  }
`;
const SpiralArrowContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 10rem;
  width: 5.5rem;
  transform-origin: top left;
  transform: translate(100%, 0);
  display: flex;
  justify-content: end;
  align-items: center;
  @media ${device.tablet} {
    height: 8rem;
    width: 4rem;
  }
  @media ${device.mobileL} {
    display: none;
  }
`;
const SpiralArrowImageStyled = styled.img`
  height: 6rem;
  @media ${device.tablet} {
    height: 3.75rem;
  }
`;

const Heading = styled.h1`
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  font-size: 2.2rem;
  line-height: 3.3125rem;
  text-align: center;
  display: inline-block;
  position: relative;
  @media ${device.tablet} {
    font-size: 1.375rem;
    line-height: 1.9375rem;
  }
`;

const HeadingBoldPart = styled.span`
  font-weight: 700;
`;
const SubHeading = styled.h2`
  font-size: 1.4rem;
  line-height: 2.1875rem;
  margin-top: 0.5rem;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  text-align: center;
  display: inline-block;
  @media ${device.tablet} {
    font-size: 1rem;
    line-height: 1.4375rem;
  }
`;
const FormContainer = styled.div`
  margin-top: 6.2rem;
  padding: 2.55rem 2.3rem;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 5px #356daa;
  position: relative;
  @media ${device.laptopL} {
    padding: 1.5rem 1.75rem;
  }
  @media ${device.desktopXS} {
    margin-top: 3.75rem;
  }
  @media ${device.tablet} {
    padding: 2.75rem 1.25rem;
  }
`;
const FormImageContainer = styled.div`
  width: 6.875rem;
  height: 6.875rem;
  background: #ffffff;
  position: absolute;
  right: 2.125rem;
  top: -2.5rem;
  display: none;
  @media ${device.tablet} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  border-radius: 50%;
`;
const FormImage = styled.img`
  width: 6.5625rem;
  height: 6.5625rem;
  object-fit: cover;
  border-radius: 50%;
  object-position: center;
`;
const RatingComponent = styled.div`
  margin-top: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${device.mobileL} {
    flex-direction: column;
  }
`;
const StarsContianer = styled.div`
  display: flex;
`;
const RatingComponentText = styled.span`
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  margin-left: 0.8rem;
  @media ${device.mobileL} {
    margin-top: 1rem;
  }
`;
const Stars = styled(FaStar)`
  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.yellow[500]};
`;

const PencilImageContainer = styled.div`
  margin-left: 66px;
  height: 4.875rem;
  width: 7.75rem;
  display: inline-block;
  position: relative;
`;

const PencilImageStyled = styled.img`
  height: 4.875rem;
  width: 7.75rem;
  position: relative;
  z-index: 1;
`;

const PencilShadow = styled.div`
  height: 4.875rem;
  width: 4.875rem;
  background: #00000040;
  position: absolute;
  border-radius: 2.4375rem;
  left: 3rem;
  transform-origin: center;
  transform: translate(-5%, 40%);
  z-index: 0;
`;

const BellImageContainer = styled.div`
  margin-top: 4.4375rem;
  margin-right: 7.6875rem;
  height: 7.1875rem;
  width: 6.6875rem;
  display: inline-block;
  position: relative;
  align-self: flex-end;
`;

const BellImageStyled = styled.img`
  height: 7.1875rem;
  width: 6.6875rem;
  position: relative;
  z-index: 1;
`;

const BoyImageContainer = styled.div`
  margin-top: 2.25rem;
  width: 10.625rem;
  height: 10.625rem;
`;

const BoyImageStyled = styled.img`
  width: 10.625rem;
  height: 10.625rem;
`;

const CapImageContainer = styled.div`
  height: 11.0625rem;
  width: 12.6875rem;
  align-self: flex-end;
  display: inline-block;
  position: relative;
`;

const CapImageStyled = styled.img`
  height: 11.0625rem;
  width: 12.6875rem;
  position: relative;
  z-index: 1;
`;

const CapImageShadow = styled.div`
  height: 7.75rem;
  width: 7.75rem;
  background: #00000040;
  position: absolute;
  border-radius: 3.875rem;
  transform-origin: center;
  z-index: 0;
  bottom: 0;
  left: 0;
`;
// end of styled-components

function Layout() {
  const { t } = useTranslation();

  return (
    <Container>
      <HeaderContainer>
        <Header />
      </HeaderContainer>
      <InnerContainer>
        <LeftIconsContainer>
          <BookImageContainer>
            <BookImageShadow />
            <BookImageStyled src="/assets/book.svg" />
          </BookImageContainer>
          <LaptopImageContainer>
            <LaptopImageShadow />
            <LaptopImageStyled src="/assets/laptop.svg" />
          </LaptopImageContainer>
          <GirlImageContainer>
            <GirlImageStyled src="/assets/girl.png" />
          </GirlImageContainer>
        </LeftIconsContainer>
        <MiddleContainer>
          <HeadingsContainer>
            <Heading>
              <HeadingBoldPart>{t("landingPage.Title.part1")}</HeadingBoldPart>
              &nbsp;
              {t("landingPage.Title.part2")}
              <SpiralArrowContainer>
                <SpiralArrowImageStyled
                  src={SpiralArrowImage}
                  alt="spiral-arrow"
                ></SpiralArrowImageStyled>
              </SpiralArrowContainer>
            </Heading>
            <br />
            <SubHeading>{t("landingPage.Subtitle")}</SubHeading>
          </HeadingsContainer>
          <FormContainer>
            <FormImageContainer>
              <FormImage src={SmilingManImage} />
            </FormImageContainer>
            <Form />
          </FormContainer>
          <RatingComponent>
            <StarsContianer>
              {new Array(5).fill(0).map((e, index) => (
                <Stars key={index} />
              ))}
            </StarsContianer>
            <RatingComponentText>
              {t("landingPage.ratingText")}
            </RatingComponentText>
          </RatingComponent>
        </MiddleContainer>
        <RightIconsContainer>
          <PencilImageContainer>
            <PencilShadow />
            <PencilImageStyled src="/assets/pencil.png" />
          </PencilImageContainer>
          <BellImageContainer>
            <BellImageStyled src="/assets/bell.png" />
          </BellImageContainer>
          <BoyImageContainer>
            <BoyImageStyled src="/assets/boyimage.png" />
          </BoyImageContainer>
          <CapImageContainer>
            <CapImageShadow />
            <CapImageStyled src="/assets/cap.png" />
          </CapImageContainer>
        </RightIconsContainer>
      </InnerContainer>
    </Container>
  );
}

export default Layout;
