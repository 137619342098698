import { formikConfig } from "@components/AdminDashboard/SubPages/Courses/AddCourse/formInitialValues";
import { APICourseResponse } from "@lib/Types/API/APICourseResponse";
import SampleTeacherImage from "@images/demo-teacher-image.png";
import { v4 as uuid } from "uuid";
import { parseISO } from "date-fns";
import { format, parse } from "date-fns";
import { CourseStatus } from "@lib/Enums/CourseStatus";
import {
  GRADE_YEAR,
  MANAGER,
  TEACHER_USP_1,
  TEACHER_USP_2,
} from "@components/AdminDashboard/SubPages/Courses/AddCourse/validationSchema";
import { Language, getLanguageInfo } from "@lib/Enums/Language";
import { assetsUrls } from "@styles/theme";

const parseTime = (time: string): string => {
  const parsedTime = parse(time, "HH:mm:ss", new Date());
  const formattedTime = format(parsedTime, "HH:mm");
  return formattedTime;
};
export const mapCourseResopnseToEditForm = function (
  response: APICourseResponse
): formikConfig["initialValues"] {
  const data = response.course;
  const material = data.courseStudyMaterials;
  const description = data.courseDescription;
  const schedule = data.courseSchedules;
  const heightlights = data.courseDescription.highlights;
  const mappedData: formikConfig["initialValues"] = {
    name: data.name ? data.name : "",
    subject: data.courseSubject
      ? {
          id: data.courseSubject.id.toString(),
          value: data.courseSubject.name,
        }
      : undefined,
    university: data.university
      ? {
          id: data.university.id.toString(),
          value: data.university.universityName,
        }
      : undefined,
    study: data.courseStudy
      ? {
          id: data.courseStudy.id.toString(),
          value: data.courseStudy.name,
        }
      : undefined,
    language: data.language
      ? {
          id: data.language,
          value: getLanguageInfo(data.language).name,
        }
      : undefined,
    event: data.type
      ? {
          id: data.type.id.toString(),
          value: data.type.courseType,
        }
      : undefined,
    spots: data.availableSpots ? data.availableSpots : "",
    [MANAGER]: data.manager
      ? {
          id: data.manager.id,
          value: data.manager.name,
          profilePicUrl: data.manager.profilePicUrl
            ? data.manager.profilePicUrl
            : assetsUrls.userPng,
        }
      : undefined,
    groups: data.numberOfGroups ? data.numberOfGroups : "",
    [GRADE_YEAR]: data.courseGradeYear
      ? {
          id: data.courseGradeYear.id.toString(),
          value: data.courseGradeYear.gradeYear,
        }
      : undefined,
    originalPrice: data.pricing
      ? data.pricing.originalPrice
        ? data.pricing.originalPrice
        : ""
      : "",
    salePrice: data.pricing
      ? data.pricing.salePrice
        ? data.pricing.salePrice
        : ""
      : "",
    teacher: data.teacher
      ? {
          id: data.teacher.id.toString(),
          name: data.teacher.name,
          image: SampleTeacherImage,
          description:
            "some description that is not important now so i am writing this in wain",
        }
      : null,
    questions: description
      ? description.questionnaires
        ? Object.keys(data.courseDescription.questionnaires).map((item) => ({
            question: item ? item : "",
            answer: description.questionnaires[item]
              ? description.questionnaires[item]
              : "",
            id: uuid(),
          }))
        : []
      : [],
    features: description
      ? description.features
        ? Object.values(data.courseDescription.features).map((item) => ({
            id: uuid(),
            content: item ? item : "",
          }))
        : []
      : [],
    schedule:
      schedule && schedule.length
        ? data.courseSchedules.map((item) => ({
            id: uuid(),
            name: item.name ? item.name : "",
            date: item.date ? parseISO(item.date) : undefined,
            startTime: item.startTime ? parseTime(item.startTime) : undefined,
            endTime: item.endTime ? parseTime(item.endTime) : undefined,
          }))
        : [
            {
              id: uuid(),
              name: "",
              date: undefined,
              startTime: undefined,
              endTime: undefined,
            },
          ],
    studyGuideAvailable: material.available ? material.available : false,
    studyGuide: material.available
      ? material.courseStudyMaterialFiles
        ? material.courseStudyMaterialFiles.length
          ? material.courseStudyMaterialFiles[0].link
          : undefined
        : undefined
      : undefined,
    guidePrice: material.available
      ? material.price
        ? material.price
        : ""
      : "",
    "available-from": material.available
      ? material.availableFrom
        ? parseISO(material.availableFrom)
        : undefined
      : undefined,
    "available-upto": material.available
      ? material.availableTill
        ? parseISO(material.availableTill)
        : undefined
      : undefined,
    "sell-individually": !!material.sellIndividually,
    highlights: description
      ? description.highlights
        ? Object.keys(data.courseDescription.highlights).map((item) => ({
            id: uuid(),
            label: item ? item : "",
            content: description.highlights[item]
              ? description.highlights[item]
              : "",
          }))
        : []
      : [],
    [TEACHER_USP_1]: data.teacherUsps
      ? data.teacherUsps.usp1
        ? data.teacherUsps.usp1
        : ""
      : "",
    [TEACHER_USP_2]: data.teacherUsps
      ? data.teacherUsps.usp2
        ? data.teacherUsps.usp2
        : ""
      : "",
  };
  return mappedData;
};
