import { CourseStatus } from "@lib/Enums/CourseStatus";
import { getLanguageInfo } from "@lib/Enums/Language";
import { AddNewCourseApi } from "@lib/Types/API/APINewCoursePost";
import { AddCourseFormSubmitDataType } from "@lib/Types/AddCourseFormSubmitType";
import { format } from "date-fns";
import { number } from "yup";
interface Schedule {
  name: string;
  date: string;
  "start-time": string;
  "end-time": string;
}
export function filterScheduleItems(schedules: Schedule[]): Schedule[] {
  return schedules.filter((item) => {
    if (!item.date || !item["start-time"] || !item["end-time"] || !item.name) {
      return false;
    } else {
      return true;
    }
  });
}

export function mapFormToAPI(
  formData: AddCourseFormSubmitDataType
): AddNewCourseApi {
  const studyGuide = formData.studyGuideAvailable;
  const data: AddNewCourseApi = {
    name: formData.name,
    universityId: Number(formData.university.id),
    studyId: Number(formData.study.id),
    typeId: Number(formData.event.id),
    gradeYearId: Number(formData.gradeYear.id),
    subjectId: Number(formData.subject.id),
    language: getLanguageInfo(formData.language.id).sign,
    "available-spot": formData.spots,
    numberOfGroups: formData.groups,
    pricing: {
      original: formData.originalPrice,
      sale: formData.salePrice,
    },
    "enrollment-start-date": "2023-07-29T10:00:00",
    "enrollment-end-date": "2023-07-29T10:00:00",
    status: CourseStatus.PUBLISH,
    schedule: filterScheduleItems(
      formData.schedule.map((item) => ({
        name: item.name,
        date: format(item.date, "yyyy-MM-dd"),
        "start-time": `${item.startTime}:00`,
        "end-time": `${item.endTime}:00`,
      }))
    ),
    "teacher-id": formData.teacher.id,
    "manager-id": formData.manager.id,
    "study-material": {
      available: formData.studyGuideAvailable,
      price: studyGuide ? formData.guidePrice : undefined,
      file: studyGuide
        ? [
            {
              type: "DOCUMENT",
              name: "Getting started with Educational Sciences",
              link: formData.studyGuide,
            },
          ]
        : undefined,
      "available-from": studyGuide
        ? format(formData["available-from"], "yyyy-MM-dd")
        : undefined,
      "available-until": studyGuide
        ? format(formData["available-upto"], "yyyy-MM-dd")
        : undefined,
      "sell-individually": studyGuide
        ? formData["sell-individually"]
        : undefined,
    },
    "smart-points": 2,
    description: {
      features: formData.features.map((item, index) => ({
        priority: index + 1,
        content: item.content,
      })),
      highlights: formData.highlights.map((item) => ({
        label: item.label,
        content: item.content,
      })),
      questionnaires: formData.questions.map((item) => ({
        question: item.question,
        answer: item.answer,
      })),
    },
    teacherUsps: {
      usp1: formData["teacher-usp-1"],
      usp2: formData["teacher-usp-2"],
    },
  };
  return data;
}
