import styled from "styled-components";
import bannerImage from "@images/profile-banner.jpg";
import ProfilePicAndChangePassword from "./ProfilePicAndChangePassword";
import StudentInfo from "./StudentInfo";
import StudyAndUniversityInfo from "./StudyAndUniversityInfo";
import { device } from "@styles/theme";
import {
  UserRole,
  initialStateOfUserProfile,
  userInfo,
} from "@app/redux/slices/userSlice";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { getUserDetails } from "@API/services/Admin/getUserDetails";
import { mapAPidatatoUserInfo } from "@lib/mapperFunctions/mapAPidatatoUserInfo";

// styled-components-----
const Container = styled.div`
  background: #ffffff;
  border-radius: 20px;
`;

const BannerImageContainer = styled.div`
  position: relative;
`;
const BannerImageStyled = styled.img`
  height: 9.375rem;
  object-fit: cover;
  width: 100%;
  object-position: center;
  border-radius: 20px 20px 0 0;
  position: relative;
  @media ${device.tablet} {
    height: 7.25rem;
  }
`;
const ImageOverlay = styled.div`
  background: transparent linear-gradient(90deg, #4883c4 0%, #4979ad 100%) 0% 0%
    no-repeat padding-box;
  opacity: 0.85;
  position: absolute;
  height: 9.375rem;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 20px 20px 0 0;
  @media ${device.tablet} {
    height: 7.25rem;
  }
`;
const ProfileDeatailsContainer = styled.div`
  padding: 3.125rem;
  padding-top: 0;
  @media ${device.tablet} {
    padding: 1.625rem;
    padding-top: 0;
  }
`;
const StudentsDetailsContainer = styled.div`
  margin: 3.125rem 0;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  @media ${device.laptopL} {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin: 1.5625rem 0;
  }
`;

export interface StudentProfileProps {
  isButtonsVisible: boolean;
}

// ----------------------

function StudentProfile() {
  const { state }: { state: userInfo | undefined } = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const [userInfo, setUserInfo] = useState<userInfo>(initialStateOfUserProfile);
  const { userId } = useParams();
  const profile = useSelector(selectUser);
  async function getAndSetUserInfo() {
    setLoading(true);
    if (userId) {
      const res = await getUserDetails(userId);
      if (res.success) {
        const mappedProfile = await mapAPidatatoUserInfo(res.data);
        setUserInfo(mappedProfile);
      }
    } else {
      setUserInfo(profile);
    }
    setLoading(true);
  }
  useEffect(() => {
    getAndSetUserInfo();
  }, [profile]);
  return (
    <Container>
      <BannerImageContainer>
        <BannerImageStyled src={bannerImage} />
        <ImageOverlay />
      </BannerImageContainer>
      <ProfileDeatailsContainer>
        <ProfilePicAndChangePassword loading={loading} userInfo={userInfo} />
        <StudentsDetailsContainer>
          <StudentInfo loading={loading} userInfo={userInfo} />
          {userInfo.role === UserRole.STUDENT && (
            <StudyAndUniversityInfo loading={loading} userInfo={userInfo} />
          )}
        </StudentsDetailsContainer>
      </ProfileDeatailsContainer>
    </Container>
  );
}

export default StudentProfile;
