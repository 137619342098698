import styled, { css } from "styled-components";
import MetricsDataComponent from "./MetricsDataComponent";
import mockSalesData from "@lib/testData/mockSalesData";
import Chart from "./Chart";
import { util } from "@lib/util";
import { OverviewDataType } from "./main";
import { useTranslation } from "react-i18next";
import { OverViewDataReturnType } from "./userOverviewData";
import { mixin } from "@styles/theme";
// start of styled-components
interface loading {
  $loading: boolean;
}
const Container = styled.div`
  height: 29.3125rem;
  margin-top: 1.875rem;
  padding: 3.375rem 4.0625rem 3.6875rem 3.125rem;
  background: #ffffff;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
`;
const ChartComponent = styled.div`
  display: flex;
  flex-direction: column;
`;
const ChartHeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;
const ChartHeading = styled.div`
  font-size: 1.125rem;
  line-height: 1.5625rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;
interface SalesPercentageChangePropType {
  $positive: boolean;
}
const SalesPercentageChange = styled.div<SalesPercentageChangePropType>`
  width: 3.25rem;
  height: 1.3125rem;
  background: ${(props) =>
    props.$positive
      ? props.theme.colors.green[600]
      : props.theme.colors.red[500]}26;
  font-size: 0.625rem;
  color: ${(props) =>
    props.$positive
      ? props.theme.colors.green[600]
      : props.theme.colors.red[500]};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  margin-left: 0.75rem;
`;
const TotalSales = styled.div<loading>`
  margin-top: 0.5rem;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 2.4375rem;
  color: ${(props) => props.theme.colors.gray[800]};
  ${(props) => props.$loading && mixin.skeletonLoading()}
`;
const LastPeriodTotalSales = styled.div`
  display: flex;
  align-items: center;
  line-height: 1.25rem;
  font-size: 0.875rem;
  margin-top: 0.125rem;
  color: ${(props) => props.theme.colors.gray[600]};
`;
const LastPeriodTotalSalesNumber = styled.div<loading>`
  ${(props) =>
    props.$loading &&
    css`
      width: 3rem;
      ${mixin.skeletonLoading()}
    `}
`;
const ChartContainer = styled.div<loading>`
  flex-grow: 1;
  display: flex;
  margin-top: 1.6875rem;
  ${(props) =>
    props.$loading &&
    css`
      ${mixin.skeletonLoading()}
    `}
`;
const MetricsComponent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 10.125rem;
  gap: 30px;
  margin-left: 2rem;
  padding: 0 2rem;
`;
const MetricsDataComponentLoading = styled.div`
  width: 100%;
  ${mixin.skeletonLoading()}
`;
// end of styled components
function DataComponent({
  overviewState,
}: {
  overviewState: OverViewDataReturnType<true | false>;
}) {
  const [loading, data] = overviewState;
  const { t } = useTranslation();
  return (
    <Container>
      <ChartComponent>
        <ChartHeadingContainer>
          <ChartHeading>{t("adminDashboard.sales")}</ChartHeading>
          <SalesPercentageChange
            $positive={!loading ? data.sales.percentageChange >= 0.0 : true}
          >
            {!loading ? (
              `${data.sales.percentageChange >= 0.0 ? "+" : ""}${
                data.sales.percentageChange
              }%`
            ) : (
              <>&nbsp;</>
            )}
          </SalesPercentageChange>
        </ChartHeadingContainer>
        <TotalSales $loading={loading}>
          {!loading ? util.convertCurrency(data.sales.thisPeriod) : <>&nbsp;</>}
        </TotalSales>
        <LastPeriodTotalSales>
          <LastPeriodTotalSalesNumber $loading={loading}>
            {!loading ? (
              util.convertCurrency(data.sales.lastPeriod)
            ) : (
              <>&nbsp;</>
            )}
          </LastPeriodTotalSalesNumber>
          &nbsp;&nbsp;last period
        </LastPeriodTotalSales>
        <ChartContainer $loading={loading}>
          {!loading && <Chart data={data.chartData} />}
        </ChartContainer>
      </ChartComponent>
      <MetricsComponent>
        {!loading
          ? data.metricsData.map((item, index) => {
              return <MetricsDataComponent {...item} key={index} />;
            })
          : Array.from({ length: 4 }).map((e) => {
              return <MetricsDataComponentLoading />;
            })}
      </MetricsComponent>
    </Container>
  );
}

export default DataComponent;
