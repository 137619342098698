import { createUniversity } from "@API/services/EntitiesCrud";
import {
  Label,
  SettingsInput,
  SettingsSubmitButton,
} from "@components/AdminDashboard/SubPages/Settings/commonComponents/FormComponents";
import Drop from "@lib/microComponents/Drop";
import ImageEdit from "@lib/microComponents/ImageEdit";
import UploadButton from "@lib/microComponents/UploadButton";
import { ReactToast } from "@lib/toast";
import { cropImage, getDefaultCropValue } from "@lib/util/cropImage";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Crop } from "react-image-crop";
import styled from "styled-components";
import { object, string } from "yup";
import { v4 as uuid } from "uuid";
// styled-components ----
const Container = styled.div`
  margin-top: 1.875rem;
  display: flex;
  flex-direction: column;
  padding: 1.875rem;
  padding-top: 0rem;
`;
const Form = styled.form``;
const IconLabel = styled(Label)`
  margin-top: 0.625rem;
`;
const NameInput = styled(SettingsInput)`
  margin-top: 0.625rem;
`;
const UniversitySubmitButton = styled(SettingsSubmitButton)`
  margin-top: 1.875rem;
`;
const UploadButtonContainer = styled.div`
  margin-top: 1.25rem;
`;
const Error = styled.div`
  font-size: 0.9rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.red[500]};
  margin-top: 0.5rem;
`;
const UrlInput = styled.input`
  height: 0;
  width: 0;
  opacity: 1;
`;

// ----------------------

const defaultCropValue: Crop = {
  x: 0,
  y: 0,
  width: 100,
  height: 100,
  unit: "px",
};

const NAME = "university-name";
const ICON = "university-icon";

function AddUniversityForm({ onCreate }: { onCreate: () => void }) {
  const formik = useFormik({
    initialValues: {
      [NAME]: "",
      [ICON]: "",
    },
    validationSchema: object({
      [NAME]: string().required("name field is required").min(5),
      [ICON]: string().required("university icon is required").min(1),
    }),
    onSubmit: async (values, actions) => {
      const res = await createUniversity(values[NAME], values[ICON]);
      if (res.success) {
        ReactToast("University created");
        actions.setFieldValue(NAME, "");
        actions.setFieldTouched(NAME, false);
        onCreate();
      } else {
        ReactToast(res.error);
      }
      actions.setSubmitting(false);
    },
  });
  const [cropValue, setCropValue] = useState<Crop>(defaultCropValue);
  const [fileBlob, setFileBlob] = useState<Blob>();
  const [url, setUrl] = useState<string>("");
  const [filename, setFilename] = useState<string>(`${uuid()}.png`);
  async function onFileBlobChange() {
    if (fileBlob) {
      setUrl(URL.createObjectURL(fileBlob));
      const crop = await getDefaultCropValue(fileBlob);
      formik.setFieldValue(ICON, undefined);

      setCropValue(crop);
    }
  }
  useEffect(() => {
    onFileBlobChange();
  }, [fileBlob]);

  return (
    <Container>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <Label>University name</Label>
        {formik.errors[NAME] && formik.touched[NAME] && (
          <Error>{formik.errors[NAME]}</Error>
        )}
        <NameInput placeholder="University" {...formik.getFieldProps(NAME)} />
        <IconLabel>University icon</IconLabel>
        {formik.errors[ICON] && formik.touched[ICON] && (
          <Error>{formik.errors[ICON]}</Error>
        )}
        <Drop
          fileSizeInMB={1}
          onFileLoad={(blob) => {
            setFileBlob(blob);
          }}
          clearFile={() => {
            setFileBlob(undefined);
            setUrl("");
            formik.setFieldValue(ICON, undefined);
          }}
          fileType="image"
        />
        {url && !formik.getFieldProps(ICON).value && (
          <ImageEdit
            src={url}
            value={cropValue}
            onChange={(crop) => {
              setCropValue(crop);
            }}
          />
        )}
        <UploadButtonContainer>
          <UploadButton
            uploaded={!!formik.getFieldProps(ICON).value}
            disabled={!fileBlob}
            onSuccess={(url) => {
              formik.setFieldValue(ICON, url);
            }}
            fileFunction={async () => {
              if (fileBlob) {
                const newFile = await cropImage(fileBlob, cropValue);
                return newFile.image;
              } else {
                return undefined;
              }
            }}
            filename={filename}
            isPublic={true}
          />
        </UploadButtonContainer>
        <UniversitySubmitButton loading={formik.isSubmitting}>
          Add University
        </UniversitySubmitButton>
      </Form>
    </Container>
  );
}

export default AddUniversityForm;
