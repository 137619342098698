import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";
import { StudentData } from "@components/CommonComponents/CourseApplications/StudentsTable";
import { APIStudentsData } from "@lib/Types/API/APIStudentsData";

import { mapAPITeacherStudentsToComponent } from "@lib/mapperFunctions/Teachers/mapAPITeacherStudentsToComponent";
type AdminCourseApplicationResponseType =
  | {
      success: true;
      data: StudentData[];
    }
  | {
      success: false;
      data: string;
    };
export const getAdminCourseApplicationsList = async function (
  id: string
): Promise<AdminCourseApplicationResponseType> {
  const url = urlFunctions.getAdminCourseApplications(id);
  const res = await API.sendGetRequest(url, true);
  console.log(url, "get admin course application");
  if (res.success) {
    const data: APIStudentsData[] = res.data;
    const mappedData = mapAPITeacherStudentsToComponent(data);
    return {
      success: true,
      data: mappedData,
    };
  } else {
    return {
      success: false,
      data: "Could not get applications list",
    };
  }
};
