import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { UserRole } from "@app/redux/slices/userSlice";
import { OverviewDataType } from "@components/AdminDashboard/SubPages/AdminOverview/main";
import { AdminOverviewDataType } from "@lib/Types/AdminData/AdminOverviewData";
import { mapAdminOverviewData } from "@lib/mapperFunctions/mapAdminOverviewData";
import { format } from "date-fns";
import { useSelector } from "react-redux";

type AdminCourseOverviewType =
  | {
      success: true;
      data: AdminOverviewDataType;
    }
  | {
      success: false;
      data: string;
    };

const getDaysValue = (daysId: string) => {
  if (daysId === "0") {
    return "7days";
  } else if (daysId === "1") {
    return "30days";
  } else {
    return "3months";
  }
};

export const getAdminOverview = async function (
  daysId: string | undefined,
  role: UserRole | undefined,
  from?: Date,
  to?: Date
): Promise<AdminCourseOverviewType> {
  let url = undefined;
  if (!role) {
    throw new Error("Not authorized");
  }
  if (role === UserRole.ADMIN) {
    url = urlFunctions.getAdminOverView(
      daysId ? getDaysValue(daysId) : "",
      from ? format(from, "yyyy-MM-dd") : undefined,
      to ? format(to, "yyyy-MM-dd") : undefined
    );
  } else if (role === UserRole.MANAGER) {
    url = urlFunctions.getManagerOverView(
      daysId ? getDaysValue(daysId) : "",
      from ? format(from, "yyyy-MM-dd") : undefined,
      to ? format(to, "yyyy-MM-dd") : undefined
    );
  }
  if (!url) {
    throw new Error("Couldn't create url");
  }
  const res = await API.sendGetRequest(url, true);
  if (res.success) {
    const data: AdminOverviewDataType = res.data;
    return {
      success: true,
      data: data,
    };
  } else {
    return {
      success: false,
      data: "There was an error in getting course list",
    };
  }
};
