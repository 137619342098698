import React, { useState } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { OverViewDataReturnType } from "../userOverviewData";
import { mixin } from "@styles/theme";
import { useSelector } from "react-redux";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { UserRole } from "@app/redux/slices/userSlice";
// start of styled-components
interface loading {
  $loading: boolean;
}
const Container = styled.div`
  background: #ffffff;
  padding: 1.875rem;
  border-radius: 20px;
`;
const TableStyled = styled.table`
  width: 100%;
`;
const TableHeader = styled.thead``;
const TableHeading = styled.th`
  border-bottom: 0.125rem solid ${(props) => props.theme.colors.gray[500]};
  padding-bottom: 1.875rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.4375rem;
  color: ${(props) => props.theme.colors.gray[600]};
`;
const UniversityImage = styled.img<loading>`
  height: 1.4375rem;
  width: 1.4375rem;
  object-fit: cover;
  margin-right: 1.25rem;
  ${(props) => props.$loading && mixin.skeletonLoading()}
`;
const TableHeadingRow = styled.tr``;
const TableDataRow = styled.tr`
  border-bottom: 0.125rem solid ${(props) => props.theme.colors.gray[500]};
`;
const TableBody = styled.tbody``;
const TableDataCell = styled.td`
  padding: 1.125rem 0;
  font-size: 1rem;
  line-height: 1.4375rem;
  color: ${(props) => props.theme.colors.gray[800]};
  margin-right: 5rem;
`;
const TableCellDataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 6.0625rem;
  max-width: 12.5rem;
`;
const SalesCellDataContainer = styled(TableCellDataContainer)`
  padding-right: 2.5625rem;
`;
const UniversityTableDataCell = styled(TableDataCell)`
  display: flex;
  align-items: center;
`;
const TableDataText = styled.span<loading>`
  display: inline-block;
  ${(props) =>
    props.$loading &&
    css`
      width: 5rem;
      ${mixin.skeletonLoading()}
    `}
`;
interface PercentageChangePropType {
  $positive: boolean;
}
const PercentageIncrease = styled.div<PercentageChangePropType>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 1.3125rem;
  background: ${(props) =>
    props.$positive
      ? props.theme.colors.green[600]
      : props.theme.colors.red[500]}26;
  color: ${(props) =>
    props.$positive
      ? props.theme.colors.green[600]
      : props.theme.colors.red[500]};
  border-radius: 10px;
  font-size: 0.625rem;
  line-height: 0.9375rem;
  font-weight: 700;
`;

const tableHeaders = [
  // "adminDashboard.universities",
  "adminDashboard.orders",
  "adminDashboard.promoClick",
  "adminDashboard.courses",
  "adminDashboard.students",
  "adminDashboard.sales",
];
export interface OverviewtableData {
  id: string;
  UniversityName: string;
  Orders: number;
  ordersPercentage: number;
  PromoClicks: number;
  PromoClicksPercentage: number;
  Courses: number;
  CoursesPercentage: number;
  Students: number;
  StudentsPercentage: number;
  Sales: number;
  SalesPercentage: number;
  image: string;
}

const ToggleButtonContainer = styled.div`
  display: inline-block;
  background: ${(props) => props.theme.colors.gray[500]};
  border-radius: 1.0625rem;
`;

const ToggleButtonForManager = styled.div``;

interface ToggleButtonsStylePropType {
  $active: boolean;
}
const UniversityButton = styled.button<ToggleButtonsStylePropType>`
  font-size: 1rem;
  line-height: 1.4375rem;
  background: ${(props) =>
    props.$active
      ? props.theme.colors.gray[600]
      : props.theme.colors.gray[500]};
  color: ${(props) =>
    props.$active ? "#ffffff" : props.theme.colors.gray[600]};
  font-weight: ${(props) => (props.$active ? 700 : 500)};
  padding: 0 1.25rem;
  height: 2.125rem;
  border-radius: ${(props) =>
    props.$active ? "1.0625rem" : "1.0625rem 0 0 1.0625rem"};
`;

const ManagerButton = styled(UniversityButton)`
  border-radius: ${(props) =>
    props.$active ? "1.0625rem" : "0 1.0625rem 1.0625rem 0"};
`;
const CoursesButton = styled(UniversityButton)`
  border-radius: ${(props) =>
    props.$active ? "1.0625rem" : "0 1.0625rem 1.0625rem 0"};
`;
// end of sytled-components

function Table({
  overviewState,
}: {
  overviewState: OverViewDataReturnType<true | false>;
}) {
  const { t } = useTranslation();
  const [loading, overviewData] = overviewState;
  const userInfo = useSelector(selectUser);

  const [activeButton, setActiveButton] = useState<number>(
    userInfo.role === UserRole.ADMIN ? 0 : 2
  );
  function getTableData(state: number): OverviewtableData[] {
    if (loading) return [];
    if (state === 0) {
      const universityTableData = overviewData.universityTableData;
      if (universityTableData) {
        return universityTableData;
      }
    } else if (state === 1) {
      const managerTableData = overviewData.managersTableData;
      if (managerTableData) {
        return managerTableData;
      }
    } else if (state === 2) {
      const courseTableData = overviewData.coursesTableData;
      if (courseTableData) {
        return courseTableData;
      }
    } else {
      return [];
    }
    return [];
  }
  const tableData = !loading
    ? activeButton
      ? overviewData.managersTableData
      : overviewData.universityTableData
    : [];
  return (
    <Container>
      <TableStyled>
        <TableHeader>
          <TableHeadingRow>
            <TableHeading>
              {userInfo.role === UserRole.ADMIN ? (
                <ToggleButtonContainer>
                  <UniversityButton
                    $active={activeButton === 0}
                    onClick={() => {
                      setActiveButton(0);
                    }}
                  >
                    University
                  </UniversityButton>
                  <ManagerButton
                    onClick={() => {
                      setActiveButton(1);
                    }}
                    $active={activeButton === 1}
                  >
                    Managers
                  </ManagerButton>
                  <CoursesButton
                    onClick={() => {
                      setActiveButton(2);
                    }}
                    $active={activeButton === 2}
                  >
                    Courses
                  </CoursesButton>
                </ToggleButtonContainer>
              ) : (
                <ToggleButtonForManager>Courses</ToggleButtonForManager>
              )}
            </TableHeading>
            {tableHeaders.map((heading, index) => {
              if (activeButton === 2 && index === 2) return;
              return <TableHeading>{t(heading)}</TableHeading>;
            })}
          </TableHeadingRow>
        </TableHeader>
        <TableBody>
          {!loading
            ? getTableData(activeButton).map((e) => {
                return (
                  <TableDataRow key={e.id}>
                    <UniversityTableDataCell>
                      <UniversityImage $loading={loading} src={e.image} />
                      <TableDataText $loading={loading}>
                        {e.UniversityName}
                      </TableDataText>
                    </UniversityTableDataCell>
                    <TableDataCell>
                      <TableCellDataContainer>
                        <TableDataText $loading={loading}>
                          {e.Orders}
                        </TableDataText>
                        <PercentageIncrease
                          $positive={e.ordersPercentage >= 0.0}
                        >
                          {`${e.ordersPercentage >= 0 ? "+" : ""}${Math.trunc(
                            e.ordersPercentage
                          )}%`}
                        </PercentageIncrease>
                      </TableCellDataContainer>
                    </TableDataCell>
                    <TableDataCell>
                      <TableCellDataContainer>
                        <TableDataText $loading={loading}>
                          {e.PromoClicks}
                        </TableDataText>
                        <PercentageIncrease
                          $positive={e.PromoClicksPercentage >= 0.0}
                        >
                          {`${
                            e.PromoClicksPercentage >= 0 ? "+" : ""
                          }${Math.trunc(e.PromoClicksPercentage)}%`}
                        </PercentageIncrease>
                      </TableCellDataContainer>
                    </TableDataCell>
                    {activeButton !== 2 && (
                      <TableDataCell>
                        <TableCellDataContainer>
                          <TableDataText $loading={loading}>
                            {e.Courses}
                          </TableDataText>
                          <PercentageIncrease
                            $positive={e.CoursesPercentage >= 0.0}
                          >
                            {`${
                              e.CoursesPercentage >= 0 ? "+" : ""
                            }${Math.trunc(e.CoursesPercentage)}%`}
                          </PercentageIncrease>
                        </TableCellDataContainer>
                      </TableDataCell>
                    )}

                    <TableDataCell>
                      <TableCellDataContainer>
                        <TableDataText $loading={loading}>
                          {e.Students}
                        </TableDataText>
                        <PercentageIncrease
                          $positive={e.StudentsPercentage >= 0.0}
                        >
                          {`${e.StudentsPercentage >= 0 ? "+" : ""}${Math.trunc(
                            e.StudentsPercentage
                          )}%`}
                        </PercentageIncrease>
                      </TableCellDataContainer>
                    </TableDataCell>
                    <TableDataCell>
                      <SalesCellDataContainer>
                        <TableDataText $loading={loading}>
                          € {e.Sales}
                        </TableDataText>
                        <PercentageIncrease
                          $positive={e.SalesPercentage >= 0.0}
                        >
                          {`${e.SalesPercentage >= 0 ? "+" : ""}${Math.trunc(
                            e.SalesPercentage
                          )}%`}
                        </PercentageIncrease>
                      </SalesCellDataContainer>
                    </TableDataCell>
                  </TableDataRow>
                );
              })
            : Array.from({ length: 10 }).map((e, index) => {
                return (
                  <TableDataRow key={index}>
                    <UniversityTableDataCell>
                      <UniversityImage $loading={loading} />
                      <TableDataText $loading={loading}>&nbsp;</TableDataText>
                    </UniversityTableDataCell>
                    {Array.from({ length: 5 }).map((e) => {
                      return (
                        <TableDataCell>
                          <TableCellDataContainer>
                            <TableDataText $loading={loading}>
                              &nbsp;
                            </TableDataText>
                          </TableCellDataContainer>
                        </TableDataCell>
                      );
                    })}
                  </TableDataRow>
                );
              })}
        </TableBody>
      </TableStyled>
    </Container>
  );
}

export default Table;
