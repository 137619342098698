import React, { useEffect, useState } from "react";
import { useCombobox } from "downshift";
// import sam
import SampleImage from "@images/sample-university.png";
import { mapStudentUniversityAndStudyToAPI } from "@lib/mapperFunctions/mapStudentUniversityAndStudyToAPI";
import { useNavigate } from "react-router-dom";
import { getCoursesForSearch } from "@lib/hooks/userCourseSearch";
import CourseComponent from "@components/CommonComponents/CourseComponent";
import styled, { useTheme } from "styled-components";
import { NonEnrolledCourseDataType } from "@components/CommonComponents/CourseComponent/NonEnrolledCourse";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { device } from "@styles/theme";
import { useTranslation } from "react-i18next";
import pageRouteNames from "@/constants/pageRouteNames";
//styled-components---
const Container = styled.div`
  position: relative;
  margin-left: 3.375rem;
  display: flex;
  height: 3.8125rem;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  @media ${device.laptopM} {
    margin-left: 2.5rem;
  }
  @media ${device.laptop} {
    margin-left: 1.875rem;
  }
  @media ${device.tablet} {
    margin-left: 0;
    width: 100%;
    height: auto;
    padding: 0.625rem 1.25rem;
  }
`;
const InputContainer = styled.div`
  padding: 0.3rem;
  border-radius: 0.75rem;
  width: 100%;
  background: ${(props) => props.theme.colors.gray[500]};
  //   left:0%;
  border: none;
  display: flex;
  flex-direction: row;
  column-gap: 0.3rem;
  justify-content: flex-start;
  align-items: center;
  @media ${device.tablet} {
    background: #ffffff;
  }
`;
const SearchInput = styled.input`
  font-size: 1.2rem;
  width: 80%;
  color: ${(props) => props.theme.colors.gray[800]};
  background: ${(props) => props.theme.colors.gray[500]};
  &::placeholder {
    color: ${(props) => props.theme.colors.gray[600]};
    font-size: 1.125rem;
  }
  @media ${device.tablet} {
    background: #ffffff;
  }
`;
interface OptionsListPropsType {
  $isOpen: boolean;
}
const ItemDiv = styled.ul<OptionsListPropsType>`
  padding: 0rem 0.5rem;
  position: absolute;
  min-width: 35rem;
  width: 100%;
  top: 4.4375rem;
  // left: 3.5%;
  max-height: 18rem;
  overflow-y: scroll;

  border-radius: 0rem 0rem 1rem 1rem;
  z-index: 1200;
  box-shadow: 0px 3.5px 5px 0px #00000040;
  flex-direction: column;
  justify-content: flex-start;
  display: ${(props) => (props.$isOpen ? "flex" : "none")};
  background: white;
  align-items: flex-start;
  &::-webkit-scrollbar {
    // Width of vertical scroll bar
    width: 0.6rem;
    // Height of horizontal scroll bar
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    width: 1.5rem;
    background: #c2c9d2;
  }
  @media ${device.tablet} {
    width: 100%;
    max-width: 100%;
    top: 3.8125rem;
    max-height: calc(100vh - 5.0625rem - 3.8125rem);
    height: calc(100vh - 5.0625rem - 3.8125rem);
    min-width: 0;
    border-radius: 0rem 0rem 0rem 0rem;
    left: 0;
  }
`;

const IconContainer = styled.div`
  //   margin-left: 3.3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0rem 0.875rem 0rem 1.25rem;
  border-radius: 12px 0 0 12px;
  background: ${(props) => props.theme.colors.gray[500]};
  @media ${device.tablet} {
    background: #ffffff;
  }
`;
const Division = styled.div`
  width: 100%;
  height: 0.125rem;
  background: ${(props) => props.theme.colors.gray[500]};
`;
const ItemDataContainer = styled.div`
  padding: 5px 0;
  padding-top: 7px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: flex-start;
  // align-items: cente;
`;
const ItemsContainer = styled.div`
  width: 100%;
`;
const ItemsFilter = (
  inputValue: string
): ((item: NonEnrolledCourseDataType) => boolean) => {
  const LowerCaseInputValue: string = inputValue.toLowerCase();
  return function CourseFilter(item: NonEnrolledCourseDataType): boolean {
    return (
      !inputValue ||
      item.major.toLowerCase().includes(LowerCaseInputValue) ||
      item.university.universityName
        .toLowerCase()
        .includes(LowerCaseInputValue) ||
      item.study.toLowerCase().includes(LowerCaseInputValue) ||
      item.type.toLowerCase().includes(LowerCaseInputValue)
    );
  };
};

function SearchBar() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [Courseitems, SetCourseItems] = useState<NonEnrolledCourseDataType[]>(
    []
  );
  const navigate = useNavigate();
  const [items, SetItems] = useState<NonEnrolledCourseDataType[]>([]);
  const DataFunc = async () => {
    const res = await getCoursesForSearch();
    if (res) {
      SetCourseItems(res);
    }
  };
  useEffect(() => {
    DataFunc();
  }, []);
  let { getMenuProps, isOpen, getInputProps, getItemProps, closeMenu } =
    useCombobox({
      onInputValueChange({ inputValue }) {
        if (inputValue === undefined || inputValue === "") {
          SetItems([]);
          return;
        }
        SetItems(Courseitems.filter(ItemsFilter(inputValue)));
      },
      onSelectedItemChange(e) {
        if (!e.selectedItem) return;
        navigate(`/${e.selectedItem.id}/${pageRouteNames.COURSE}`);
      },
      items,
    });
  return (
    <Container>
      <InputContainer>
        <IconContainer>
          <FaMagnifyingGlass
            style={{ height: "1rem", color: `${theme.colors.gray[600]}` }}
          />
        </IconContainer>
        <SearchInput
          placeholder={t("Header.search.searchPlaceholder")}
          style={{ border: "none" }}
          {...getInputProps({
            onBlur: () => {
              closeMenu();
            },
          })}
        />
      </InputContainer>

      <ItemDiv
        $isOpen={isOpen}
        style={{ padding: items.length > 0 ? "0.5rem" : "0rem 0.5rem" }}
        {...getMenuProps()}
      >
        {isOpen &&
          items.map((item, index) => {
            return (
              <ItemsContainer>
                <ItemDataContainer
                  key={`${item.id}`}
                  {...getItemProps({ item, index })}
                >
                  <CourseComponent data={item} />
                </ItemDataContainer>
                {items.length !== index + 1 && <Division />}
              </ItemsContainer>
            );
          })}
      </ItemDiv>
    </Container>
  );
}

export default SearchBar;
