import { API } from "@API/API";
import { AdminStudentResponse } from "@lib/Types/API/APIAdminStudents";
import { urlFunctions } from "@API/functions/createUrl";
import { UserRole } from "@app/redux/slices/userSlice";
import { optionType } from "@components/LandingPage/FormSelect";
export const getAdminStudents = async (
  role: UserRole | undefined,
  offset: number,
  size: number,
  search: string,
  university?: optionType,
  study?: optionType
): Promise<AdminStudentResponse | undefined> => {
  let url: string;
  if (role === UserRole.ADMIN) {
    url = urlFunctions.getAdminStudent({
      offset,
      size,
      search,
      university: university ? university.id : undefined,
      study: study ? study.id : undefined,
    });
  } else if (role === UserRole.MANAGER) {
    url = urlFunctions.getManagerStudent({
      offset,
      size,
      search,
      university: university ? university.id : undefined,
      study: study ? study.id : undefined,
    });
  } else {
    throw new Error("Not Authorised");
  }
  const res = await API.sendGetRequest(url, true);
  if (res.success) {
    const data: AdminStudentResponse = res.data;
    return data;
  }
  return undefined;
};
