import { API, APIResponse } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";
import { UserRole, userSlice } from "@app/redux/slices/userSlice";
import { StudnetProfileFormType } from "@components/StudentDashBoardSubPages/StudentProfile/StudentInfo";
import { mapStudentProfileToAPI } from "@lib/mapperFunctions/mapStudentProflieToAPI";

type updateProfileResponseType =
  | {
      success: true;
      data: any;
    }
  | {
      success: false;
      data: string;
    };

export const updateProfile = async function (
  data: StudnetProfileFormType,
  role: UserRole | undefined
): Promise<updateProfileResponseType> {
  const formattedData = mapStudentProfileToAPI(data);
  let url: string;
  let res: APIResponse;
  if (UserRole.STUDENT === role) {
    url = urlFunctions.updateStudentProfile();
    res = await API.sendPutRequest(url, formattedData, true);
  } else {
    url = urlFunctions.updateProfile();
    res = await API.sendPostRequest(url, formattedData, true);
  }
  if (res.success) {
    return {
      success: true,
      data: null,
    };
  } else {
    return {
      success: false,
      data: "Could not update profile try again",
    };
  }
};

export const updateProfilePic = async function (
  profilePicUrl: string,
  role: UserRole | undefined
) {
  const data = {
    profilePicUrl,
  };
  let url: string;
  let res: APIResponse;
  if (UserRole.STUDENT === role) {
    url = urlFunctions.updateStudentProfile();
    res = await API.sendPutRequest(url, data, true);
  } else {
    url = urlFunctions.updateProfile();
    res = await API.sendPostRequest(url, data, true);
  }

  if (res.success) {
    return {
      success: true,
      data: null,
    };
  } else {
    return {
      success: false,
      data: "Could not update profile picture try again",
    };
  }
};
