import { getAdminUniversityList } from "@API/services/Admin/adminUniversityList";
import { getUniversityList } from "@API/services/CourseCRUD/getUniversityList";
import { optionType } from "@components/AdminDashboard/SubPages/Courses/AddCourse/Components/FormSelect";
import { AdminUniversityView } from "@components/AdminDashboard/SubPages/Settings/SubPages/UniversitiesStudiesAndSubjects/SubPages/Universities/main";
import { mapAdminUniversityListToComponent } from "@lib/mapperFunctions/admin/mapAdminUniversityListToComponent";
import { mapUniversityListToOptions } from "@lib/mapperFunctions/mapUniversityListToOptions";
import { ReactToast } from "@lib/toast";
import { useEffect, useState } from "react";

export const useAdminUniversityOptions = function (): [
  boolean,
  AdminUniversityView[],
  () => void
] {
  const [universityOptons, setUniversityOptions] = useState<
    AdminUniversityView[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    getAdminUniversityOptions();
  }, []);
  function reset() {
    getAdminUniversityOptions();
  }
  async function getAdminUniversityOptions() {
    setLoading(true);
    const data = await getAdminUniversityList();
    if (data.success) {
      const mappedList = mapAdminUniversityListToComponent(data.data);
      setUniversityOptions(mappedList);
    } else {
      ReactToast(data.data);
    }
    setLoading(false);
  }

  return [loading, universityOptons, reset];
};
