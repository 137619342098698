import { useEffect, useState } from "react";
import { CourseDetailsType } from "../../src/components/View/CourseView/CourseDetailsType";
import { getCourseDetails } from "@API/services/Data/getCourseDetails";
import { mapAPICourseDetailsToComponent } from "@lib/mapperFunctions/mapAPICourseDetailsToComponent";

export type returnType<Loading extends boolean> = Loading extends true
  ? [true, undefined]
  : [false, CourseDetailsType];

export function useCourseDetails(courseId: string): returnType<true | false> {
  const [loading, setLoading] = useState<boolean>(true);
  const [courseDetails, setCourseDetails] = useState<CourseDetailsType>();
  async function setCourseDetailsAsync() {
    setLoading(true);
    const data = await getCourseDetails(courseId);
    if (data.success) {
      const formattedData = mapAPICourseDetailsToComponent(data.data);
      setCourseDetails(formattedData);
    }
    setLoading(false);
  }
  useEffect(() => {
    setCourseDetailsAsync();
  }, [courseId]);
  return [loading, courseDetails] as returnType<true | false>;
}
