import { IconType } from "react-icons";
import {
  FaTableColumns,
  FaGraduationCap,
  FaBasketShopping,
  FaUser,
  FaGear,
} from "react-icons/fa6";
const DashBoardNavigationArray = [
  {
    id: 0,
    Icon: FaGraduationCap,
    key: "courses",
    name: "Courses",
    link: "/teacher-dashboard/courses",
  },
  {
    id: 1,
    Icon: FaUser,
    name: "Teacher profile",
    key: "teacher_profile",
    link: "/teacher-dashboard/profile",
  },
];
export default DashBoardNavigationArray;
