import { useCombobox, useSelect } from "downshift";
import { IconType } from "react-icons";
import {
  FaAngleDown,
  FaAngleUp,
  FaBan,
  FaCircleNotch,
  FaPlus,
  FaRegCalendarDays,
} from "react-icons/fa6";
import styled, { keyframes } from "styled-components";
import { useEffect, useRef, useState } from "react";
import { Formik } from "../main";
import Text from "@lib/microComponents/Text";
import FormError from "./FormError";
import { AddCourseFormFieldsName } from "../validationSchema";

// start of styled-components
const SelectContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
`;
interface InnerContainerPropType {
  $active: boolean;
}
const InnerContainer = styled.div<InnerContainerPropType>`
  border-radius: 10px;
  box-shadow: ${(props) => (props.$active ? "0px 0px 10px #00000029" : "none")};
`;
const SelectButtonContainer = styled.div`
  width: 100%;
`;

interface SelectPropType {
  $open: boolean;
}

const Select = styled.div<SelectPropType>`
  margin-top: 0.625rem;
  width: 100%;
  height: 2.9375rem;
  display: flex;
  align-items: center;
  box-shadow: ${(props) => (props.$open ? "0px 0px 10px #00000029" : "none")};
  border-radius: 10px;
`;
const ArrowDown = styled(FaAngleDown)`
  width: 1.125rem;
  height: 1.125rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const ArrowUp = styled(FaAngleUp)`
  width: 1.125rem;
  height: 1.125rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;

interface SelectorInputPropType {
  $open: boolean;
}

const SelectorInput = styled.input<SelectorInputPropType>`
  min-width: 0px;
  flex-grow: 1;
  height: 100%;
  padding: 0.6875rem 1.875rem;
  padding-right: 0rem;
  background: ${(props) => props.theme.colors.gray[500]};
  border-radius: ${(props) => (props.$open ? "10px 0 0 0px" : "10px 0 0 10px")};
  color: ${(props) => props.theme.colors.gray[800]};
  &::placeholder {
    color: ${(props) => props.theme.colors.gray[600]};
    line-height: 1.5625rem;
    font-size: 1.125rem;
  }
`;

interface SelectorButtonPropType {
  $open: boolean;
}

const SelectorButton = styled.button<SelectorButtonPropType>`
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
  border-radius: ${(props) => (props.$open ? "0 10px 0px 0" : "0 10px 10px 0")};
  background: ${(props) => props.theme.colors.gray[500]};
`;
const SelectLabel = styled.label``;

interface SelectButtonPropTypes {
  $isActive: boolean;
  $selected: boolean;
  $disabled: boolean;
}

const SelectButton = styled.button<SelectButtonPropTypes>`
  margin-top: 0.625rem;
  width: 100%;
  padding: 0.6563rem 1.25rem;
  background: ${(props) => props.theme.colors.gray[500]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${(props) => (props.$isActive ? "10px 10px 0 0" : "10px")};
  outline: none;
  border: none;
  cursor: pointer;
  color: ${(props) =>
    props.$selected
      ? props.theme.colors.gray[800]
      : props.theme.colors.gray[600]};
`;
const SelectButtonText = styled.span`
  line-height: 1.5625rem;
  font-size: 1.125rem;
  white-space: nowrap;
  overflow: hidden;
`;
const SelectButtonIconContainer = styled.div`
  width: 1.125rem;
  height: 1.125rem;
`;
const SelectButtonDownIcon = styled(FaAngleDown)`
  width: 1.125rem;
  height: 1.125rem;
  margin-left: 2rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const SelectButtonUpIcon = styled(FaAngleUp)`
  width: 1.125rem;
  height: 1.125rem;
  margin-left: 2rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;
interface OptionsListPropsTyps {
  $open: boolean;
}
const OptionsList = styled.ul<OptionsListPropsTyps>`
  width: 100%;
  position: absolute;
  right: 0;
  background: #ffffff;
  display: ${(props) => (props.$open ? "block" : "none")};
  border-radius: 0 0 15px 15px;
  /* padding: 0.5rem 0rem; */
  /* margin-top: 0.3125rem; */
  max-height: 20rem;
  overflow-y: scroll;
  z-index: 1000;
  box-shadow: 0px 10px 10px #00000029;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
`;

const OptionsListOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #00000080;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const LoadingSpinner = styled.div`
  border-radius: 1rem;
  border: 2px solid white;
  border-left: none;
  height: 2rem;
  width: 2rem;
  animation: ${rotateAnimation} 0.2s linear infinite;
`;
// border-top: ${(props) => (props.index ? "1px solid green" : "none")};
const ListItem = styled.li`
  padding: 1.4375rem 0.3125rem;
  margin: 0px 1.5625rem;
  cursor: pointer;
  text-align: left;
  color: ${(props) => props.theme.colors.gray[800]};
  transition: background 0.2s;
  transition: color 0.2s;
  line-height: 1.5625rem;
  font-size: 1.125rem;
  overflow-wrap: break-word;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray[500]};

  /* &:last-child {
    border-bottom: none;
  } */
  &:hover {
    background: ${(props) => props.theme.colors.gray[600]}cc;
    color: #ffffff;
  }
`;

const AddOptionListItem = styled(ListItem)`
  display: flex;
  align-items: center;
  color: ${(props) => `${props.theme.colors.gray[800]}80`};

  &:hover {
    background: #ffffff;
    color: ${(props) => `${props.theme.colors.gray[800]}80`};
  }
`;
const AddOptionPlusIcon = styled(FaPlus)`
  margin-right: 18px;
  color: ${(props) => `${props.theme.colors.gray[800]}80`};
`;
const DisabledIcon = styled(FaBan)`
  position: relative;
  top: 0.2rem;
  margin-right: 0.4rem;
`;

const AddNewOptionBold = styled.div`
  color: ${(props) => `${props.theme.colors.gray[800]}80`};
  font-weight: 700;
`;

// end of styled-components
export interface optionType {
  id: string;
  value: string;
}
function itemToString(item: any) {
  return item ? item.value : "";
}
function getOptionsFilter(inputValue: string): (option: optionType) => boolean {
  const lowerCasedInputValue = inputValue.toLowerCase().trim();
  return function booksFilter(option: optionType): boolean {
    return (
      !inputValue ||
      option.value.toLowerCase().trim().includes(lowerCasedInputValue)
    );
  };
}
function NewFormSelect({
  text,
  options,
  formik,
  value,
  name,
  id,
  label,
  disabled,
  createEntity,
  ...props
}: {
  text: string;
  options: optionType[];
  formik: Formik;
  value?: optionType | undefined;
  name: AddCourseFormFieldsName;
  id: string;
  label: string;
  createEntity: (input: string) => Promise<optionType | undefined>;
  disabled?: boolean;
}) {
  const [items, setItems] = useState<optionType[]>([]);
  const [selectedItem, setSelectedItem] = useState<optionType | null>(null);

  function shouldShowAddItem(input: string) {
    return inputValue && !searchItemByValue(input);
  }
  const {
    isOpen,
    getToggleButtonProps,
    selectItem,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    inputValue,
  } = useCombobox({
    defaultSelectedItem: value ? value : undefined,
    onInputValueChange({ inputValue }) {
      if (inputValue === undefined) return;
      setItems(options.filter(getOptionsFilter(inputValue)));
    },
    onSelectedItemChange(changes) {
      let item: optionType | undefined;
      if (!changes.selectedItem) {
        item = undefined;
      } else {
        item = changes.selectedItem;
      }
      formik.setFieldValue(name, item);
    },
    items: items,
    itemToString(item) {
      return item ? item.value : "";
    },
  });
  const [addingEntity, setAddingEntity] = useState<boolean>(false);
  //---------------------------
  function searchItem(id: string) {
    for (let key in options) {
      if (options[key].id === id) {
        return options[key];
      }
    }
    return null;
  }
  function searchItemByValue(input: string) {
    for (let key in options) {
      if (
        options[key].value.toLowerCase().trim() === input.toLowerCase().trim()
      ) {
        return options[key];
      }
    }
    return undefined;
  }
  async function addEntity() {
    setAddingEntity(true);
    const entity = await createEntity(inputValue);
    if (entity) {
      // selectItem(entity);
      formik.setFieldValue(name, undefined);
    }
    setAddingEntity(false);
  }
  // the below useEffect is needed because we are storing options locally
  // in a useState hook because we need to filter on input
  useEffect(() => {
    setItems(options);
  }, [options]);
  useEffect(() => {
    if (value) {
      selectItem(value);
    } else {
      selectItem(null);
    }
  }, [value]);
  // useEffect(() => {
  //   if (value) {
  //     selectItem(value);
  //   } else {
  //     selectItem(null);
  //   }
  // }, [value]);
  // useEffect(() => {
  //   const initiallySelecteditem = formik.getFieldProps(name).value;
  //   const findOption = (id: string) => {
  //     for (let item of options) {
  //       if (item.id === id) {
  //         return item;
  //       }
  //     }
  //   };
  //   if (initiallySelecteditem) {
  //     const option = findOption(initiallySelecteditem);
  //     if (option) {
  //       selectItem(option);
  //     }
  //   }
  // }, [formik, options]);
  return (
    <SelectContainer {...props}>
      <SelectLabel {...getLabelProps()}>
        <Text>{label}</Text>
      </SelectLabel>
      <SelectButtonContainer>
        <Select $open={isOpen}>
          <SelectorInput
            $open={isOpen}
            placeholder={text}
            {...getInputProps()}
            disabled={disabled}
            onBlur={(e) => {
              const onBlur = getInputProps().onBlur;
              if (onBlur) {
                onBlur(e);
              }
              formik.setFieldTouched(name, true);
            }}
          />
          <SelectorButton
            $open={isOpen}
            aria-label="toggle menu"
            type="button"
            {...getToggleButtonProps()}
          >
            {isOpen ? <ArrowUp /> : <ArrowDown />}
          </SelectorButton>
        </Select>
        <FormError name={name} formik={formik} />
      </SelectButtonContainer>
      <OptionsList $open={isOpen} {...getMenuProps()}>
        {isOpen &&
          items.map((item, index) => (
            <ListItem {...getItemProps({ item, index })}>
              <span>{item.value}</span>
            </ListItem>
          ))}
        {shouldShowAddItem(inputValue) && (
          <AddOptionListItem
            onClick={() => {
              addEntity();
            }}
          >
            <AddOptionPlusIcon />
            <AddNewOptionBold>Add new: &nbsp;{}</AddNewOptionBold>
            {inputValue}
          </AddOptionListItem>
        )}
        {addingEntity && (
          <OptionsListOverlay>
            <LoadingSpinner />
          </OptionsListOverlay>
        )}
      </OptionsList>
    </SelectContainer>
  );
}

export default NewFormSelect;
