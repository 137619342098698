import React from "react";
import CourseDetails from "./CourseDetails";
import styled from "styled-components";
import CourseFeedbackGreetings from "@components/CommonComponents/CourseFeedbackGreetings";
import RatingComponents from "./RatingComponents";
import FailedTestComponents from "./FailedTestComponents";

// styled.components ----------
const Container = styled.div``;
const GreetingsContainer = styled.div`
  margin-top: 1.875rem;
`;
const RatingAndFailedTestComponent = styled.div`
  margin-top: 1.875rem;
  display: grid;
  grid-template-columns: 1fr 25.0625rem;
  gap: 1.875rem;
`;
const RatingContainer = styled.div`
  padding: 1.875rem;
  background: #ffffff;
  border-radius: 20px;
`;
const RatingAndFailedTestComponentHeading = styled.h3`
  line-height: 1.9375rem;
  font-size: 1.375rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const Divider = styled.div`
  height: 2px;
  background: ${(props) => props.theme.colors.gray[500]};
  margin-top: 1.25rem;
`;
const ComponentsContainer = styled.div`
  margin-top: 30px;
`;
// ----------------------------
function CourseFeedbackComponent() {
  return (
    <Container>
      <CourseDetails />
      <GreetingsContainer>
        <CourseFeedbackGreetings />
      </GreetingsContainer>
      <RatingAndFailedTestComponent>
        <RatingContainer>
          <RatingAndFailedTestComponentHeading>
            Let us know about your experience and earn points!
          </RatingAndFailedTestComponentHeading>
          <Divider />
          <ComponentsContainer>
            <RatingComponents />
          </ComponentsContainer>
        </RatingContainer>
        <FailedTestComponents></FailedTestComponents>
      </RatingAndFailedTestComponent>
    </Container>
  );
}

export default CourseFeedbackComponent;
