import Search from "@lib/microComponents/Search";
import React, { ReactNode } from "react";
import { FaAngleLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
// styled-components ----
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const BackButtonAndHeadingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.875rem;
`;

const Heading = styled.h2`
  font-size: 1.375rem;
  line-height: 1.9375rem;
  color: ${(props) => props.theme.colors.gray[800]};
  font-weight: 700;
`;
const BackButton = styled.button`
  height: 2.6875rem;
  width: 2.6875rem;
  border-radius: 10px;
  background: ${(props) => props.theme.colors.gray[600]};
`;
const BackButtonIcon = styled(FaAngleLeft)`
  color: #ffffff;
`;
const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
`;
// ----------------------

function Header({
  text,
  searchText,
  children,
  ...props
}: {
  text: string;
  searchText: string;
  children: ReactNode;
}) {
  const navigate = useNavigate();
  function goBack() {
    navigate(-1);
  }
  return (
    <Container>
      <BackButtonAndHeadingContainer>
        <BackButton onClick={goBack}>
          <BackButtonIcon />
        </BackButton>
        <Heading>{text}</Heading>
      </BackButtonAndHeadingContainer>
      <ButtonsContainer>
        <Search onChange={(value) => {}} placeholder={searchText} />
        {children}
      </ButtonsContainer>
    </Container>
  );
}

export default Header;
