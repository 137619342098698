import { returnType } from "@lib/hooks/useCourseDetails";
import { device } from "@styles/theme";
import React, { useSyncExternalStore } from "react";
import { useTranslation } from "react-i18next";
import {
  FaRegCircleQuestion,
  FaRegPaperPlane,
  FaWhatsapp,
} from "react-icons/fa6";
import styled from "styled-components";

// styled-components----
const Container = styled.div``;
const HelpHeading = styled.div`
  margin-top: 4.375rem;
  font-size: 1.375rem;
  line-height: 1.9375rem;
  font-weight: 700;
  border-left: 11px solid ${(props) => props.theme.colors.blue[700]};
  padding: 0.25rem 0 0.25rem 1.25rem;
  color: ${(props) => props.theme.colors.gray[800]};
  @media ${device.tablet} {
    line-height: 1.5625rem;
    font-size: 1.125rem;
    border-width: 0.5rem;
  }
`;

const InnerContainer = styled.div`
  margin-top: 1.875rem;
`;
const HelpContainer = styled.a`
  display: flex;
  align-items: center;
`;
const HelpWhatsappIcon = styled(FaWhatsapp)`
  height: 2.1875rem;
  width: 2.1875rem;
  color: ${(props) => props.theme.colors.gray[800]};
  opacity: 0.25;
`;
const EmailIcon = styled(FaRegPaperPlane)`
  height: 2.1875rem;
  width: 2.1875rem;
  color: ${(props) => props.theme.colors.gray[800]};
  opacity: 0.25;
`;
const HelpText = styled.div`
  font-size: 18px;
  line-height: 25px;
  color: ${(props) => props.theme.colors.gray[800]};
  margin-left: 30px;
`;

// ---------------------

function CourseHelp() {
  const { t } = useTranslation();
  return (
    <Container>
      <HelpHeading>{t("sdashboard.support.component.heading")}</HelpHeading>
      <InnerContainer>
        <HelpContainer target="_black" href=" https://wa.me/+31202612965">
          <HelpWhatsappIcon />
          <HelpText>{t("sdashboard.support.component.whatsapp")}</HelpText>
        </HelpContainer>
        <HelpContainer
          target="_blank"
          href="mailto:info@academischkenniscentrum.nl"
          style={{ marginTop: "0.9375rem" }}
        >
          <EmailIcon />
          <HelpText>{t("sdashboard.support.component.mail")}</HelpText>
        </HelpContainer>
      </InnerContainer>
    </Container>
  );
}

export default CourseHelp;
