import {
  Language,
  LanguageArray,
  getLanguageFromLocaleString,
  getLanguageInfo,
} from "@lib/Enums/Language";
import { LanguageStrings } from "@lib/intl/locales/Routes/en/translation";
import { Fragment, ReactNode, useEffect } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Redirect } from "./AppRoutes";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { settingsSlice } from "@app/redux/slices/settingsSlice";

interface Props {
  children: ReactNode;
  RouterComponent: any;
  languages: { [k: number]: string };
  appStrings: { [prop: string]: LanguageStrings };
  defaultLanguage?: Language;
}

export const LocalizedRouter: React.FC<Props> = ({
  children,
  RouterComponent,
  languages,
  appStrings,
  defaultLanguage,
}) => {
  return (
    <RouterComponent>
      <Routes>
        <Route
          path="/:lang?/*"
          element={
            <RouteInner
              defaultLanguage={defaultLanguage}
              appStrings={appStrings}
              languages={languages}
            >
              {children}
            </RouteInner>
          }
        />
      </Routes>
    </RouterComponent>
  );
};
function getParams(paramsString: URLSearchParams) {
  const searchParams = paramsString;
  let params = "";
  for (const [key, value] of searchParams) {
    params = params + `${key}=${value}&`;
  }
  if (params) {
    params = "?" + params;
  }
  return params;
}
function RouteInner({
  children,
  defaultLanguage,
  languages,
  appStrings,
}: {
  children: ReactNode;
  defaultLanguage?: Language;
  languages: { [k: number]: string };
  appStrings: { [prop: string]: LanguageStrings };
}) {
  const location = useLocation();
  const params = useParams();
  const [searchParams, _] = useSearchParams();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const navigate = useNavigate();
  const { lang = language || getLanguageInfo(Language.ENGLISH_US).locale } =
    params;
  const { pathname } = location;
  const currentURL = window.location.href.replace(/^https?:\/\//, "");
  if (pathname.includes("/p/")) {
    window.location.href = `${process.env.AKC_API_HOST}${pathname}`;
    return;
  }
  useEffect(() => {
    if (
      /^[A-Za-z]{2}$/.test(lang.split("-")[0]) &&
      // split is used because somethins the language is not of 2 characters
      // but a region is also included like en-US
      pathname.includes(`/${lang}/`)
    ) {
      if (LanguageArray.includes(lang)) {
        i18n.changeLanguage(lang);
        dispatch(
          settingsSlice.actions.setLanguage(getLanguageFromLocaleString(lang))
        );
      }
    }
  }, []);

  if (!/^[A-Za-z]{2}$/.test(lang.split("-")[0])) {
    return (
      <Redirect to={`/${language}${pathname}${getParams(searchParams)}`} />
    );
  } else if (!pathname.includes(`/${lang}/`)) {
    return <Redirect to={`/${lang}/`} />;
  } else if (!LanguageArray.includes(lang)) {
    navigate(
      `/en${pathname.replace(`/${lang}`, "")}${getParams(searchParams)}`
    );
  }

  return <Fragment>{children}</Fragment>;
}
