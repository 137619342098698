import { getGradeYearList } from "@API/services/CourseCRUD/getGradeYearList";
import { optionType } from "@components/AdminDashboard/SubPages/Courses/AddCourse/Components/FormSelect";
import { mapGradeYearListToOptions } from "@lib/mapperFunctions/mapGradeYearListToOptions";
import { ReactToast } from "@lib/toast";
import { useEffect, useState } from "react";

export const useGradeYearOptions = function () {
  const [gradeYearOptions, setGradeYearOptions] = useState<optionType[]>([]);
  useEffect(() => {
    setGradeYearList();
  }, []);
  async function setGradeYearList() {
    const data = await getGradeYearList();
    if (data.success) {
      const mappedList = mapGradeYearListToOptions(data.data);
      setGradeYearOptions(mappedList);
    } else {
      ReactToast(data.data);
    }
  }
  return gradeYearOptions;
};
