import { getSubjectList } from "@API/services/CourseCRUD/getSubjectList";
import { optionType } from "@components/AdminDashboard/SubPages/Courses/AddCourse/Components/FormSelect";
import { mapSubjectListToOptions } from "@lib/mapperFunctions/mapSubjectToOptions";
import { ReactToast } from "@lib/toast";
import { useEffect, useState } from "react";

export const useSubjectOptions = async function (id: string) {
  if (!id) {
    throw new Error("Provide an id to get subject list");
  }
  const data = await getSubjectList(id);
  if (data.success) {
    const mappedList = mapSubjectListToOptions(data.data);
    return mappedList;
  } else {
    ReactToast(data.data);
    return [];
  }
};
