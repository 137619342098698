import { optionType } from "@components/LandingPage/FormSelect";
import { extensions } from "@lib/Enums/FileType";
import mime from "mime";
export const convertCurrency = (num: number) => {
  return new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  }).format(num);
};

export function searchOption(id: string, options: optionType[]) {
  for (let keys in options) {
    if (options[keys].id === id) {
      return {
        id: options[keys].id,
        value: options[keys].value,
      };
    }
  }
  return undefined;
}

export function getOnlineStatus() {
  return navigator.onLine;
}

export function checkFileType(
  file: string,
  type: "image" | "document" | "both" | "video" | "all"
): string | false {
  const mimeType = mime.getType(file);
  if (!mimeType) return false;
  const ext = mime.getExtension(mimeType);
  if (!ext) return false;
  switch (type) {
    case "image":
      if (extensions.image.includes(ext)) {
        return ext;
      } else {
        return false;
      }
    case "document":
      if (extensions.document.includes(ext)) {
        return ext;
      } else {
        return false;
      }
    case "both":
      if (extensions.both.includes(ext)) {
        return ext;
      } else {
        return false;
      }
    case "all":
      if (extensions.all.includes(ext)) {
        return ext;
      } else {
        return false;
      }
    default:
      return false;
  }
}

export function formatDuration(timeInMs: number) {
  let hours = Math.floor(timeInMs / (1000 * 60 * 60));
  let minutes = Math.floor((timeInMs % (1000 * 60 * 60)) / (1000 * 60));
  return hours + " hours";
}
