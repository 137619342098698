import { UniversityAndStudyFormType } from "@components/StudentDashBoardSubPages/StudentProfile/StudyAndUniversityInfo";
import { APIStudentAndUniversityUpdate } from "@lib/Types/API/APIStudentAndUniversityUpdate";

export function mapStudentUniversityAndStudyToAPI(
  data: UniversityAndStudyFormType
): APIStudentAndUniversityUpdate {
  return {
    universityId: data.university ? Number(data.university.id) : undefined,
    studyId: data.study ? Number(data.study.id) : undefined,
    gradeYearId: data.grade ? Number(data.grade.id) : undefined,
  };
}
