import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";

export type publicKeyResponseType =
  | {
      success: true;
      data: string;
    }
  | {
      success: false;
      data: string;
    };
export const getPublicKey = async function (): Promise<publicKeyResponseType> {
  const url = urlFunctions.getPublicKey();
  const res = await API.sendGetRequest(url, true);
  if (res.success) {
    const data: string = res.data;
    return {
      success: true,
      data: data,
    };
  } else {
    return {
      success: false,
      data: "There was an error in processing payment",
    };
  }
};
