import { OverviewDataType } from "@components/AdminDashboard/SubPages/AdminOverview/main";
import { AdminOverviewDataType } from "@lib/Types/AdminData/AdminOverviewData";
import { v4 as uuid } from "uuid";
export const mapAdminOverviewData = (
  data: AdminOverviewDataType
): OverviewDataType => {
  const byUniversity = data.aggregatedByUniversity;
  const byGroup = data.groupByUniversity;
  const byManager = data.groupByManager;
  const byCourse = data.groupByCourses;
  return {
    sales: {
      thisPeriod: Number(byUniversity.sales.thisPeriodValue),
      lastPeriod: Number(byUniversity.sales.lastPeriodValue),
      percentageChange: Number(
        byUniversity.sales.changeSinceLastPeriodPercentage
      ),
    },
    metricsData: [
      {
        heading: "adminDashboard.orders",
        percentIncrease: Number(
          byUniversity.orders.changeSinceLastPeriodPercentage
        ),
        value: Number(byUniversity.orders.thisPeriodValue),
        pastPeriodValue: Number(byUniversity.orders.lastPeriodValue),
      },
      {
        heading: "adminDashboard.promoClick",
        percentIncrease: Number(byUniversity.promoClicks.changeSinceLastPeriod),
        value: Number(byUniversity.promoClicks.thisPeriodValue),
        pastPeriodValue: Number(byUniversity.promoClicks.lastPeriodValue),
      },
      {
        heading: "adminDashboard.courses",
        percentIncrease: Number(
          byUniversity.courses.changeSinceLastPeriodPercentage
        ),
        value: Number(byUniversity.courses.thisPeriodValue),
        pastPeriodValue: Number(byUniversity.courses.lastPeriodValue),
      },
      {
        heading: "adminDashboard.students",
        percentIncrease: Number(byUniversity.students.changeSinceLastPeriod),
        value: Number(byUniversity.students.thisPeriodValue),
        pastPeriodValue: Number(byUniversity.students.lastPeriodValue),
      },
    ],
    chartData: [
      {
        sales: byUniversity.sales.thisPeriodTimeSeriesData
          ? byUniversity.sales.thisPeriodTimeSeriesData.map((item) => {
              return item.value;
            })
          : [],
      },
      {
        sales: byUniversity.sales.lastPeriodTimeSeriesData
          ? byUniversity.sales.lastPeriodTimeSeriesData.map((item) => {
              return item.value;
            })
          : [],
      },
      {
        startDate: byUniversity.sales.thisPeriodTimeSeriesData
          ? byUniversity.sales.thisPeriodTimeSeriesData[0].date
          : "Start Date",
        endDate: byUniversity.sales.thisPeriodTimeSeriesData
          ? byUniversity.sales.thisPeriodTimeSeriesData.slice(-1)[0].date
          : "End date",
      },
    ],
    universityTableData: byGroup
      ? byGroup.map((item) => ({
          id: item.groupBy.id.toString(),
          UniversityName: item.groupBy.universityName,
          Sales: Number(item.sales.thisPeriodValue),
          SalesPercentage: Number(item.sales.changeSinceLastPeriodPercentage),
          Orders: Number(item.orders.thisPeriodValue),
          ordersPercentage: Number(item.orders.changeSinceLastPeriodPercentage),
          PromoClicks: Number(item.promoClicks.thisPeriodValue),
          PromoClicksPercentage: Number(
            item.promoClicks.changeSinceLastPeriodPercentage
          ),
          Courses: Number(item.courses.thisPeriodValue),
          CoursesPercentage: Number(
            item.courses.changeSinceLastPeriodPercentage
          ),
          Students: Number(item.students.thisPeriodValue),
          StudentsPercentage: Number(
            item.students.changeSinceLastPeriodPercentage
          ),
          image: item.groupBy.universityLogoUrl
            ? item.groupBy.universityLogoUrl
            : "/assets/defaultuniversityicon.png",
        }))
      : [],
    managersTableData: byManager
      ? byManager.map((item) => ({
          id: item.groupBy.id.toString(),
          UniversityName: item.groupBy.name,
          Sales: Number(item.sales.thisPeriodValue),
          SalesPercentage: Number(item.sales.changeSinceLastPeriodPercentage),
          Orders: Number(item.orders.thisPeriodValue),
          ordersPercentage: Number(item.orders.changeSinceLastPeriodPercentage),
          PromoClicks: Number(item.promoClicks.thisPeriodValue),
          PromoClicksPercentage: Number(
            item.promoClicks.changeSinceLastPeriodPercentage
          ),
          Courses: Number(item.courses.thisPeriodValue),
          CoursesPercentage: Number(
            item.courses.changeSinceLastPeriodPercentage
          ),
          Students: Number(item.students.thisPeriodValue),
          StudentsPercentage: Number(
            item.students.changeSinceLastPeriodPercentage
          ),
          image: item.groupBy.profilePicUrl
            ? item.groupBy.profilePicUrl
            : "/assets/defaultuniversityicon.png",
        }))
      : [],

    coursesTableData: byCourse
      ? byCourse.map((item) => ({
          id: uuid(),
          UniversityName: item.groupBy,
          Sales: Number(item.sales.thisPeriodValue),
          SalesPercentage: Number(item.sales.changeSinceLastPeriodPercentage),
          Orders: Number(item.orders.thisPeriodValue),
          ordersPercentage: Number(item.orders.changeSinceLastPeriodPercentage),
          PromoClicks: Number(item.promoClicks.thisPeriodValue),
          PromoClicksPercentage: Number(
            item.promoClicks.changeSinceLastPeriodPercentage
          ),
          Courses: 1,
          CoursesPercentage: 0,
          Students: Number(item.students.thisPeriodValue),
          StudentsPercentage: Number(
            item.students.changeSinceLastPeriodPercentage
          ),
          image: "/assets/defaultuniversityicon.png",
        }))
      : [],
  };
};
