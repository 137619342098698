import styled from "styled-components";
import { StudentData } from "./StudentTable";
import { getAdminStudents } from "@API/services/Admin/adminStudentList";
import Select, { optionType } from "@lib/microComponents/Select";
import Search from "@lib/microComponents/Search";
import StudentTable from "./StudentTable";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { AdminStudentResponse, Student } from "@lib/Types/API/APIAdminStudents";
import { useAdminUniversityOptions } from "@lib/hooks/useAdminUniversityOptions";
import useInfiniteScrolling from "@lib/hooks/useInfiniteScrolling";
import { useEffect, useState } from "react";
import { mapResponseToAdminStudents } from "@lib/mapperFunctions/mapAdminStudents";
// styled-components----
const Container = styled.div``;
const StudentsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StudentHeading = styled.h2`
  line-height: 1.9375rem;
  font-size: 1.375rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.gray[800]};
  align-self: last baseline;
`;
const FilterContainer = styled.div`
  display: flex;
  align-items: center;
`;
const StyledSelect = styled(Select)`
  margin-left: 1.25rem;
`;
const StyledSearch = styled(Search)`
  margin-left: 1.25rem;
`;

const StudentsTableContainer = styled.div`
  margin-top: 1.875rem;
`;

// ---------------------

function StudentsView() {
  const [students, setStudents] = useState<StudentData[]>([]);
  const [rawStudentsData, setRawStudentsData] = useState<
    AdminStudentResponse[]
  >([]);
  const userInfo = useSelector(selectUser);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [loadingUniversity, universityOptions] = useAdminUniversityOptions();
  const [studyOptions, setStudyOptions] = useState<optionType[]>([]);
  const [university, setUniversity] = useState<optionType | null>();
  const [study, setStudy] = useState<optionType | null>();
  const [search, setSearch] = useState<string>("");
  const [loading, studentItems, lasElementref, reset] =
    useInfiniteScrolling<StudentData>({
      key: `${university ? university.id : ""}.${
        study ? study.id : ""
      }.${search}`,
      fetcher: async (pageNo) => {
        console.log(university?.value);

        const res = await getAdminStudents(
          userInfo.role,
          pageNo,
          10,
          search,
          university ? university : undefined,
          study ? study : undefined
        );
        if (res) {
          return {
            totalCount: res.count,
            items: mapResponseToAdminStudents(res.data),
          };
        } else {
          throw new Error("Error in getting data");
        }
      },
    });

  function onFilterDataChange({
    universitySelected,
    studySelected,
    searchEntered,
  }: {
    universitySelected?: optionType;
    studySelected?: optionType;
    searchEntered?: string;
  }) {}

  const { t } = useTranslation();
  return (
    <Container>
      <StudentsHeader>
        <StudentHeading>{t("adminStudents.students")}</StudentHeading>
        <FilterContainer>
          <StyledSelect
            text={t("adminCourses.selectAUniversity")}
            onChange={(e) => {
              setUniversity(e);
              setStudy(undefined);
              onFilterDataChange({ universitySelected: e ? e : undefined });
            }}
            value={university}
            options={universityOptions}
          />
          <StyledSelect
            text={t("adminCourses.selectAStudy")}
            onChange={(e) => {
              setStudy(e);
              onFilterDataChange({ studySelected: e ? e : undefined });
            }}
            disabled={!university}
            value={study}
            options={studyOptions}
          />
          <StyledSearch
            onChange={(value) => {
              setSearch(value);
              onFilterDataChange({ searchEntered: value });
            }}
            placeholder={t("adminStudents.searchInStudents")}
          />
        </FilterContainer>
      </StudentsHeader>
      <StudentsTableContainer>
        <StudentTable
          items={studentItems}
          loading={loading}
          lastElementref={lasElementref}
        ></StudentTable>
      </StudentsTableContainer>
    </Container>
  );
}

export default StudentsView;
