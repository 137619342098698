import React, { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import { FaCircleCheck } from "react-icons/fa6";
import { CourseDetailsType } from "./CourseDetailsType";
import { device, mixin } from "@styles/theme";
import { returnType } from "../../../../lib/hooks/useCourseDetails";

// styled-components----
const Container = styled.div`
  margin-top: 2.375rem;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  @media ${device.laptop} {
    grid-template-columns: minmax(0, 1fr);
  }
`;
interface LeftContainerPropType {
  $showDivider: boolean;
}

const LeftContainer = styled.div<LeftContainerPropType>`
  padding: 0.625rem 0;
  padding-right: 10px;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 0.875rem;
  border-right: ${(props) =>
    props.$showDivider
      ? `2px solid ${props.theme.colors.gray[600]}40`
      : "none"};

  color: ${(props) => props.theme.colors.gray[800]};
`;
const RightContainer = styled.div`
  padding: 0.625rem 0;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 0.875rem;
  color: ${(props) => props.theme.colors.gray[800]};
  padding-left: 2.5313rem;
`;
const VerticalDivider = styled.div`
  background: ${(props) => props.theme.colors.gray[600]};
  opacity: 0.25;
  width: 0.125rem;
`;
const CheckIcon = styled(FaCircleCheck)`
  min-width: 1.125rem;
  line-height: 1.5625rem;
  min-height: 1.125rem;
  width: 1.125rem;
  height: 1.125rem;
  color: ${(props) => props.theme.colors.blue[700]};
  margin-top: 4px;
  @media ${device.tablet} {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
  }
`;
const FeaturesItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  word-wrap: break-word;
`;
interface loading {
  $loading: boolean;
}
const FeaturesItemText = styled.span<loading>`
  flex-grow: 1;
  margin-left: 1.75rem;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  display: inline-block;
  word-wrap: break-word;
  ${(props) => props.$loading && mixin.skeletonLoading()}
  @media ${device.tablet} {
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin-left: 1.375rem;
  }
`;
const Divider = styled.div`
  height: 2px;
  background: ${(props) => props.theme.colors.gray[600]};
  opacity: 0.25;
`;
// ---------------------

function FeaturesItem({
  children,
  loading,
}: {
  children: ReactNode;
  loading: boolean;
}) {
  return (
    <FeaturesItemContainer>
      <CheckIcon />
      <FeaturesItemText $loading={loading}>{children}</FeaturesItemText>
    </FeaturesItemContainer>
  );
}

function CourseFeatures({ details }: { details: returnType<true | false> }) {
  const [loading, courseDetails] = details;
  const [[firstArray, secondArray], setArrays] = useState<string[][]>(
    makeTwoArraysFromItems()
  );

  useEffect(() => {
    function rearrangeArray() {
      setArrays(makeTwoArraysFromItems());
    }
    window.addEventListener("resize", rearrangeArray);
    return () => {
      window.removeEventListener("resize", rearrangeArray);
    };
  }, [loading]);
  useEffect(() => {
    setArrays(makeTwoArraysFromItems());
  }, [loading]);
  function makeTwoArraysFromItems() {
    const firstArray = [];
    const secondArray = [];
    if (loading) {
      if (window.innerWidth > 1024) {
        return [
          ["", "", ""],
          ["", "", ""],
        ];
      } else {
        return [["", "", "", "", "", ""], []];
      }
    }
    const values = Object.values(courseDetails.features);
    const length = Object.values(courseDetails).length;
    if (window.innerWidth > 1024) {
      for (let i = 0; i < values.length; i++) {
        if (i % 2 === 0) {
          firstArray.push(values[i]);
        } else {
          secondArray.push(values[i]);
        }
      }
    } else {
      for (let i = 0; i < values.length; i++) {
        firstArray.push(values[i]);
      }
    }

    return [firstArray, secondArray];
  }
  if (loading || Object.values(courseDetails.features).length) {
    return (
      <>
        <Container>
          <LeftContainer $showDivider={!!secondArray.length}>
            {firstArray.map((e, index) => (
              <FeaturesItem loading={loading} key={`${e}.${index}`}>
                {!loading ? e : <>&nbsp;</>}
              </FeaturesItem>
            ))}
          </LeftContainer>

          <RightContainer>
            {secondArray.map((e, index) => (
              <FeaturesItem loading={loading} key={`${e}.${index}`}>
                {!loading ? e : <>&nbsp;</>}
              </FeaturesItem>
            ))}
          </RightContainer>
        </Container>
        <Divider style={{ marginTop: " 2.0938rem" }} />
      </>
    );
  }
  return <></>;
}

export default CourseFeatures;
