import Modal from "@components/CommonComponents/Modal";
import React from "react";
import styled from "styled-components";
const Container = styled.div`
  padding: 0 1.875rem;
`;
const Heading = styled.div`
  font-size: 1.5625rem;
  line-height: 1.9375rem;
  margin-top: 1rem;
  font-weight: 700;
  text-align: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 1.875rem;
  justify-content: center;
  gap: 1.25rem;
`;

const ConfirmationButton = styled.button`
  background: ${(props) => props.theme.colors.green[600]};
  height: 2.0625rem;
  width: 7rem;
  font-weight: 700;
  color: #ffffff;
  border-radius: 10px;
`;

const CancelButton = styled.button`
  background: ${(props) => props.theme.colors.red[500]};
  height: 2.0625rem;
  width: 7rem;
  font-weight: 700;
  color: #ffffff;
  border-radius: 10px;
`;

function ConfirmationPopup({
  handleClose,
  onConfirmation,
  text,
}: {
  handleClose: () => void;
  onConfirmation: () => void;
  text: string;
}) {
  return (
    <Modal heading={text} handleClose={handleClose}>
      <Container>
        <ButtonsContainer>
          <ConfirmationButton
            onClick={() => {
              onConfirmation();
              handleClose();
            }}
          >
            Confirm
          </ConfirmationButton>
          <CancelButton
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </CancelButton>
        </ButtonsContainer>
      </Container>
    </Modal>
  );
}

export default ConfirmationPopup;
