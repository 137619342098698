import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { RouteProps } from "react-router-dom";

export const LocalizedSwitch = ({
  children,
}: {
  children: ReactNode[] | ReactNode;
}) => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;

  return <>{TransformChildren({ children: children })}</>;

  function localizeRoutePath(path?: string) {
    switch (typeof path) {
      case "undefined":
        return undefined;

      default:
        const isFallbackRoute = path === "*";
        // return isFallbackRoute ? path : `/${locale}` + t(path) ? t(path) : path;
        return isFallbackRoute ? path : path;
    }
  }
  function TransformChildren({
    children,
  }: {
    children: ReactNode | ReactNode[];
  }): ReactNode | ReactNode[] {
    if (Array.isArray(children)) {
      return children.map((child) => {
        return TransformChildren({ children: child });
      });
    }
    //@ts-ignore
    if (!children.props.children) {
      return React.isValidElement<RouteProps>(children)
        ? React.cloneElement(children, {
            ...children.props,
            path: localizeRoutePath(children.props.path),
          })
        : children;
    } else {
      const newChild = React.isValidElement<RouteProps>(children)
        ? React.cloneElement(children, {
            ...children.props,
            path: localizeRoutePath(children.props.path),
            children: TransformChildren({ children: children.props.children }),
          })
        : children;
      return newChild;
    }
  }
};
