import { DOCUMENT_UPLOAD_LIMIT } from "@/constants/fileSizeLimit";
import { uploadStudyMaterialByTeacher } from "@API/services/Teacher/uploadStudyMaterialByTeacher";
import { SettingsInput } from "@components/AdminDashboard/SubPages/Settings/commonComponents/FormComponents";
import DatePicker from "@lib/microComponents/DatePicker";
import Drop from "@lib/microComponents/Drop";
import UploadButton from "@lib/microComponents/UploadButton";
import { ReactToast } from "@lib/toast";
import { format, getDate } from "date-fns";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { FaCalendarDays } from "react-icons/fa6";
import styled, { css } from "styled-components";
import { v4 as uuid } from "uuid";
import { date, object, string } from "yup";
// styled.components ----
const Label = styled.label`
  color: ${(props) => props.theme.colors.gray[800]};
`;
const Container = styled.div`
  padding: 1.875rem;
  padding-top: 0rem;
  margin-top: 1.875rem;
`;
const DatePickerIcon = styled(FaCalendarDays)`
  height: 1.125rem;
  width: 1rem;
  color: ${(props) => props.theme.colors.gray[800]};
  margin-right: 1.125rem;
`;
const UploadButtonContainer = styled.div`
  margin-top: 1.25rem;
`;
const FileNameInput = styled(SettingsInput)`
  margin-top: 1rem;
`;
const ExpirationLabel = styled(Label)`
  margin-top: 1rem;
  display: block;
`;
const FileLabel = styled(Label)`
  display: block;
  margin-top: 1rem;
`;
const DatePickerContainer = styled.div`
  background: ${(props) => props.theme.colors.gray[500]};
  border-radius: 0.625rem;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  width: 100%;
  margin-top: 1rem;
  display: flex;
`;

const DatePickerButton = styled.button`
  padding: 1.125rem 1.875rem;
  flex-grow: 1;
  background: none;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const DatePickerStyled = styled(DatePicker)`
  position: absolute;
  z-index: 1000;
`;
interface DoneButtonProps {
  $disabled: boolean;
}
const DoneButton = styled.button<DoneButtonProps>`
  height: 3.8125rem;
  width: 100%;
  border-radius: 10px;
  margin-top: 1.25rem;
  background: ${(props) => props.theme.colors.gray[500]};
  color: #ffffff;
  font-weight: 700;
  position: relative;
  background: ${(props) => props.theme.colors.green[600]};
  ${(props) =>
    props.$disabled &&
    css`
      background: ${(props) => props.theme.colors.gray[500]};
      &::after {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #ffffff;
        opacity: 0.5;
      }
    `};
`;
// ----------------------
const GUIDE_NAME = "guide-name";
const EXPIRATION_DATE = "expiration-date";
const FILE = "file";
function UploadStudyMaterials({
  courseId,
  closeModal,
  onFileUploadSuccess,
}: {
  courseId: string;
  closeModal: () => void;
  onFileUploadSuccess: () => void;
}) {
  const [fileBlob, setFileBlob] = useState<Blob>();
  const [url, setUrl] = useState("");
  const [filename, setFilenname] = useState("");
  const [showDate, setShowDate] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: {
      [GUIDE_NAME]: "",
      [EXPIRATION_DATE]: "",
      [FILE]: "",
    },
    validationSchema: object({
      [GUIDE_NAME]: string().required("name field is required").min(5),
      [EXPIRATION_DATE]: date().required("expiration date is required").min(1),
      [FILE]: string().required("File is required"),
    }),
    onSubmit: async (values, actions) => {
      const res = await uploadStudyMaterialByTeacher(
        courseId,
        values[GUIDE_NAME],
        values[FILE],
        values[EXPIRATION_DATE]
      );
      if (res.success) {
        ReactToast.success("Study material uploaded");
        actions.resetForm();
        setUrl("");
        setFilenname("");
        setFileBlob(undefined);
        onFileUploadSuccess();
      } else {
        ReactToast.error(res.data);
      }
      actions.setSubmitting(false);
    },
  });
  useEffect(() => {
    if (!fileBlob) return;
    setUrl(URL.createObjectURL(fileBlob));
    formik.setFieldValue(FILE, undefined);
  }, [fileBlob]);
  function getDate() {
    const date = formik.getFieldProps(EXPIRATION_DATE).value;
    try {
      return format(date, "yyyy-MM-dd");
    } catch (e) {
      return "";
    }
  }
  return (
    <Container>
      <Label>Name file</Label>
      <FileNameInput {...formik.getFieldProps(GUIDE_NAME)}></FileNameInput>
      <ExpirationLabel>Expiration date</ExpirationLabel>
      {/* <FileNameInput {...formik.getFieldProps(EXPIRATION_DATE)}></FileNameInput> */}
      <DatePickerContainer>
        <DatePickerButton
          onClick={() => {
            setShowDate(!showDate);
            formik.setFieldTouched(EXPIRATION_DATE, true);
          }}
          type="button"
        >
          <DatePickerIcon />
          {getDate() ? getDate() : "Select a Date"}
        </DatePickerButton>
        {showDate && (
          <DatePickerStyled
            setShow={setShowDate}
            value={formik.getFieldProps(EXPIRATION_DATE).value}
            onChange={(date) => {
              formik.setFieldValue(EXPIRATION_DATE, date);
            }}
          />
        )}
      </DatePickerContainer>
      <FileLabel>Upload file</FileLabel>
      <Drop
        fileSizeInMB={DOCUMENT_UPLOAD_LIMIT}
        onFileLoad={(blob, ext) => {
          setFileBlob(blob);
          setFilenname(`studyMaterial-${uuid()}.${ext}`);
        }}
        clearFile={() => {
          setFileBlob(undefined);
          setUrl("");
          setFilenname("");
        }}
        fileType="document"
      />
      <UploadButtonContainer>
        <UploadButton
          onSuccess={(url) => {
            formik.setFieldValue(FILE, url);
          }}
          fileFunction={async () => {
            return fileBlob;
          }}
          isPublic={false}
          filename={filename}
          uploaded={!!formik.getFieldProps(FILE).value}
          disabled={!url}
        />
      </UploadButtonContainer>
      <DoneButton
        $disabled={!url}
        disabled={!url}
        type="button"
        onClick={() => {
          formik.handleSubmit();
        }}
      >
        Done
      </DoneButton>
    </Container>
  );
}

export default UploadStudyMaterials;
