import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { EnrolledCourseDetailsData } from "./EnrolledCourseDetails";
import { getCourseDetails } from "@API/services/CourseCRUD/getCourseDetails";
import { Lession } from "@components/CommonComponents/CourseComponent/EnrolledCourse";
import CourseComponent from "@components/CommonComponents/CourseComponent";
import { device } from "@styles/theme";
import { useTranslation } from "react-i18next";
import GroupChatView from "./GroupChatView";
import StudyMaterialFiles from "./StudyMaterialFiles";
import PersonalHelp from "./PersonalHelp";
import LoadingScreenNew from "@components/LoadingScreenNew";
import { TeacherCourseDetailsType } from "@components/TeachersDashboard/TeacherComponents/TeachersCourseDetails/TeachersCourseDetails";
import { UserRole } from "@app/redux/slices/userSlice";
import { getTeachersCourseDetails } from "@API/services/Teacher/getTeacherCourseDetails";
import TeachersScheduleEditModal from "@components/TeachersDashboard/TeacherComponents/TeacherScheduleEditModal.tsx";
import { parse } from "date-fns";
import NoEnrollmentIndicator from "./NoEnrollmentIndicator";
//styled-component
const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;
const CourseDetailContainer = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 20px;
  width: 100%;
  div:nth-child(3) {
    display: none;
  }
  @media ${device.tablet} {
    padding: 1.25rem;
    border-radius: 10px;
  }
`;

const CourseDetailsAndApplicationContainer = styled.div`
  display: flex;
  gap: 1.875rem;
`;

const DetailsMainContainer = styled.div`
  margin-top: 1.875rem;
  height: fit-content;
  position: relative;
  width: 100%;
  display: flex;
  /* // border-radius:0.625rem; */
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 3.125rem;
  // background:white;
  align-items: flex-start;
  justify-content: space-between;
`;
const ApplicationsContainer = styled.div`
  width: 18.875rem;
  border-radius: 20px;
  padding: 1.625rem 1.875rem;
  background: #ffffff;
`;
const ApplicationHeading = styled.div`
  display: flex;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const ViewListButton = styled.button`
  font-size: 0.625rem;
  line-height: 0.9375rem;
  background: ${(props) => props.theme.colors.gray[600]}26;
  width: 5rem;
  height: 1.3125rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ApplicationNumberContainer = styled.div`
  margin-top: 0.1875rem;
  font-size: 1.75rem;
  line-height: 2.4375rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const LessionsDetails = styled.div`
  display: flex;
  padding: 1.875rem;
  border-radius: 1.25rem;
  flex-direction: column;
  row-gap: 1.25rem;
  background: white;
  align-items: flex-start;
  width: 100%;
  justify-content: flex-start;
  @media ${device.tablet} {
    padding: 1.25rem;
    border-radius: 10px;
    row-gap: 0.9375rem;
  }
`;
const Container2 = styled.div`
  // height:100%;
  // flex:1;
  width: 30%;
  display: flex;
  min-height: 15rem;
  flex-direction: column;
  flex-direction: flex-start;
  align-items: flex-start;
  row-gap: 1.5rem;
  padding: 1rem;
  border-radius: 1.25rem;
  min-width: 18rem;
  background: white;
`;
const DivisionNameContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const ScheduleEditButton = styled.button`
  background: none;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-decoration: underline;
  color: ${(props) => props.theme.colors.gray[600]};
`;

const DivisionName = styled.div`
  font-size: 1.375rem;
  line-height: 1.9375rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.gray[800]};
  @media ${device.tablet} {
    font-size: 1.125rem;
    line-height: 1.5625rem;
  }
`;
const DivisionName2 = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-direction: flex-start;
  align-items: flex-start;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const OtherDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  row-gap: 2rem;
  column-gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
`;
const ChatContainer = styled.div`
  width: 100%;
  padding: 1.875rem;
  min-width: 30rem;
  display: flex;
  border-radius: 20px;
  flex-direction: column;
  row-gap: 2rem;
  align-items: flex-start;
  justify-content: center;
  background: white;
`;
const ButtonsContainer = styled.button`
  border-radius: 0.625rem;
  // flex:1;/
  width: 8rem;
  background: #4883c4;
  cursor: pointer;
  padding: 0.4rem;
  font-size: 1.1rem;
  color: white;
`;

const Separation = styled.div`
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.gray[500]};
`;
const Avilable = styled.div`
  color: ${(props) => props.theme.colors.gray[700]};
  font-weight: 200;
`;
const CourseExtraDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin-top: 1.875rem;
  gap: 1.875rem;
  grid-template-rows: repeat(auto-fill, 19.625rem);
  @media ${device.desktopXS} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media ${device.laptop} {
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto;
  }
`;

//styled-component
function CourseDetails({
  user,
}: {
  user: UserRole.TEAHCER | UserRole.STUDENT;
}) {
  const param = useParams();
  const [CourseData, SetCourseData] = useState<
    EnrolledCourseDetailsData | TeacherCourseDetailsType
  >();
  const applications = CourseData ? CourseData.applications : 0;
  const navigate = useNavigate();
  const [showEditSchedulePopup, setShowEditSchedulePopup] =
    useState<boolean>(false);

  const [CoursesLoading, SetCourseLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  let id: string;
  if (param.id) {
    id = param.id;
  }
  async function getCourseDetail(id: string) {
    SetCourseLoading(true);
    let CourseData:
      | EnrolledCourseDetailsData
      | TeacherCourseDetailsType
      | undefined;
    if (user === UserRole.STUDENT) {
      CourseData = await getCourseDetails({ id: id });
    } else if (user === UserRole.TEAHCER) {
      const res = await getTeachersCourseDetails(id);
      if (res.success) {
        CourseData = res.data;
      }
    }
    if (CourseData) {
      SetCourseData(CourseData);
    } else {
    }
    SetCourseLoading(false);
  }
  useEffect(() => {
    getCourseDetail(id);
  }, []);
  return (
    <Container>
      {CoursesLoading && <LoadingScreenNew />}
      {CourseData && (
        <CourseDetailsAndApplicationContainer>
          <CourseDetailContainer>
            <CourseComponent data={CourseData} />
          </CourseDetailContainer>
          {user === UserRole.TEAHCER && (
            <ApplicationsContainer>
              <ApplicationHeading>
                Applications&nbsp;&nbsp;
                <ViewListButton
                  onClick={() => {
                    navigate("applications");
                  }}
                >
                  View list
                </ViewListButton>
              </ApplicationHeading>
              <ApplicationNumberContainer>
                {CourseData.applications}
              </ApplicationNumberContainer>
            </ApplicationsContainer>
          )}
        </CourseDetailsAndApplicationContainer>
      )}
      {showEditSchedulePopup && CourseData && (
        <TeachersScheduleEditModal
          courseId={CourseData.id}
          scheduleItems={CourseData.lessions.map((lession) => ({
            name: lession.title,
            date: parse(lession.date, "yyyy-MM-dd", new Date()),
            startTime: lession.startTime,
            endTime: lession.endTime,
            id: lession.id.toString(),
          }))}
          handleClose={() => {
            setShowEditSchedulePopup(false);
          }}
          heading="Edit schedule"
        ></TeachersScheduleEditModal>
      )}

      {CourseData && (
        <>
          <DetailsMainContainer>
            <LessionsDetails>
              <DivisionNameContainer>
                <DivisionName>Schedule</DivisionName>
                {user === UserRole.TEAHCER && (
                  <ScheduleEditButton
                    onClick={() => {
                      setShowEditSchedulePopup(true);
                    }}
                  >
                    Edit schedule
                  </ScheduleEditButton>
                )}
              </DivisionNameContainer>
              <Separation />

              {CourseData.enrolledForGuideOnly ? (
                <NoEnrollmentIndicator height="3rem">
                  Enroll in the course to see course schedule
                </NoEnrollmentIndicator>
              ) : (
                CourseData.lessions.map((e) => (
                  <Lession
                    key={`${e.title}.${e.date}`}
                    name={e.title}
                    date={e.date}
                    startTime={e.startTime}
                    endTime={e.endTime}
                    started={true}
                    linkToJoin={e.linkToJoin}
                  />
                ))
              )}
            </LessionsDetails>
          </DetailsMainContainer>

          <CourseExtraDetailsContainer>
            <StudyMaterialFiles data={CourseData} />
            <GroupChatView data={CourseData} />
            {user === UserRole.STUDENT && <PersonalHelp />}
          </CourseExtraDetailsContainer>
        </>
      )}
    </Container>
  );
}

export default CourseDetails;
