import {
  EnrolledCourses,
  EnrolledCoursesList,
  lessions,
} from "@components/CommonComponents/CourseComponent/SampleCourseData";
import { v4 as uuid } from "uuid";
import {
  EnrolledCourseSearch,
  EnrolledCourseSearchList,
} from "@lib/Types/API/APIEnrolledCourseSearchResponse";
import SampleUniversityImage from "@images/sample-university.png";
import { assetsUrls } from "@styles/theme";
export const mapCoursetoEnrolled = function (
  EnrolledCoursesList: EnrolledCourseSearchList
): EnrolledCoursesList {
  return EnrolledCoursesList.map((item) => ({
    id: item["course-id"],
    // image: item.university,
    // university: item.university,
    university: {
      id: item.university.id,
      universityName: item.university.universityName,
      universityLogoUrl: item.enrollmentForStudyMaterialOnly
        ? assetsUrls.guide
        : item.university.universityLogoUrl,
    },
    study: item.study,
    subject: item.subject,
    group: item.group,
    status: item.status === "ACTIVE",
    language: item.language,
    type: item["course-type"],
    enrolledForStudyGuideOnly: item.enrollmentForStudyMaterialOnly,
    lessions: item.enrollmentForStudyMaterialOnly
      ? []
      : item.schedule.map((lession) => ({
          date: lession.date,
          startTime: lession["start-time"],
          endTime: lession["end-time"],
          linkToJoin: lession["link-to-join"],
          title: lession.name,
          id: lession.id,
        })),
  }));
};
