import { ManagerData } from "@components/AdminDashboard/SubPages/Settings/SubPages/AdminAndManagers/AdminManagers";
import { AdminManagerResponseArray } from "@lib/Types/API/APIAdminManager";
import { assetsUrls } from "@styles/theme";

export const mapResponseToAdminManagers = (
  item: AdminManagerResponseArray
): ManagerData[] => {
  return item.map((e) => ({
    managerId: e.id,
    firstName: e.name.split(" ")[0],
    LastName: e.name.split(" ")[1],
    role: e.role,
    accessTo: e.userProfile.accessibleUniversities,
    email: e.email,
    accepted: e.acceptedInvite,
    profilePicUrl: e.profilePicUrl ? e.profilePicUrl : assetsUrls.userPng,
  }));
};
