import React from "react";
import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import { ManagerData } from "../AdminManagers";
import { Link, useNavigate } from "react-router-dom";
import { AdminData } from "../AdminAdmin";
import { mapAdminsTableDataToProfileComponent } from "@lib/mapperFunctions/admin/mapAdminsTableDataToProfileComponent";
import { FaCircleCheck, FaHourglassHalf } from "react-icons/fa6";
// start of styled-components
const Container = styled.div`
  background: #ffffff;
  padding: 1.875rem;
  padding-bottom: 10rem;
  border-radius: 20px;
`;
const TableStyled = styled.table`
  width: 100%;
`;
const TableHeader = styled.thead``;
const TableHeading = styled.th`
  border-bottom: 0.125rem solid ${(props) => props.theme.colors.gray[500]};
  padding-bottom: 1.875rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.4375rem;
  color: ${(props) => props.theme.colors.gray[600]};
`;
const TableHeadingRow = styled.tr``;
const TableDataRow = styled.tr`
  border-bottom: 0.125rem solid ${(props) => props.theme.colors.gray[500]};
`;
const TableBody = styled.tbody``;
const TableDataCell = styled.td`
  padding: 1.125rem 0;
  font-size: 1rem;
  line-height: 1.4375rem;
  color: ${(props) => props.theme.colors.gray[800]};
  margin-right: 5rem;
`;
const TableCellDataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 6.0625rem;
`;
const TableCellDetailsContainer = styled.div`
  display: flex;
  padding-right: 6.0625rem;
  justify-content: flex-end;
  align-items: center;
`;
const TableDataText = styled.span`
  display: inline-block;
`;
const Boldtext = styled.span``;
const PendingInvitationIcon = styled(FaHourglassHalf)`
  width: 0.875rem;
  height: 1.125rem;
  color: ${(props) => props.theme.colors.yellow[600]};
`;
const AcceptedInvitaionIcon = styled(FaCircleCheck)`
  width: 1.125rem;
  height: 1.125rem;
  color: ${(props) => props.theme.colors.green[600]};
`;
const Details = styled.button`
  padding: 0.375rem 2.875rem;
  font-size: 1rem;
  line-height: 1.4375rem;
  color: ${(props) => props.theme.colors.gray[800]};
  background: ${(props) => props.theme.colors.gray[500]};
  border-radius: 10px;
  margin-left: 1.875rem;
`;
const AccessToTableCell = styled(TableDataCell)``;
const tableHeaders = [
  "adminSettings.adminsAndManagersPage.firstName",
  "adminSettings.adminsAndManagersPage.lastName",
  "adminSettings.adminsAndManagersPage.role",
  "adminSettings.adminsAndManagersPage.accessTo",
  "",
];
const skeletonLoading = keyframes`
  0% {
    background: hsl(200,20%,70%);
  }
  100% {
    background: hsl(200,20%,95%)
  }
`;
const LoadingComponent = styled.div`
  width: 100%;
  height: 2.1875rem;
  animation: ${skeletonLoading} 1s linear infinite alternate;
`;

function TableSkeleton() {
  return (
    <TableDataRow>
      <TableDataCell>
        <LoadingComponent />
      </TableDataCell>
      <TableDataCell>
        <LoadingComponent />
      </TableDataCell>
      <TableDataCell>
        <LoadingComponent />
      </TableDataCell>
      <TableDataCell>
        <LoadingComponent />
      </TableDataCell>
      <TableDataCell>
        <LoadingComponent />
      </TableDataCell>
    </TableDataRow>
  );
}
// end of sytled-components

function Table({
  managerData,
  adminData,
  loadingA,
  loadingM,
}: {
  managerData: ManagerData[];
  adminData: AdminData[];
  loadingA: boolean;
  loadingM: boolean;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Container>
      <TableStyled>
        <TableHeader>
          <TableHeadingRow>
            {tableHeaders.map((heading, index) => {
              return <TableHeading>{t(heading)}</TableHeading>;
            })}
          </TableHeadingRow>
        </TableHeader>
        <TableBody>
          {loadingA || loadingM
            ? Array.from({ length: 5 }).map((e) => <TableSkeleton />)
            : adminData.map((e) => {
                return (
                  <TableDataRow key={e.AdminId}>
                    <TableDataCell>
                      <TableDataText>{e.firstName}</TableDataText>
                    </TableDataCell>
                    <TableDataCell>
                      <TableCellDataContainer>
                        <TableDataText>{e.LastName}</TableDataText>
                      </TableCellDataContainer>
                    </TableDataCell>
                    <TableDataCell>
                      <TableCellDataContainer>
                        <TableDataText>{e.role}</TableDataText>
                      </TableCellDataContainer>
                    </TableDataCell>
                    <AccessToTableCell>
                      <TableCellDataContainer>
                        <TableDataText>{e.accessTo}</TableDataText>
                      </TableCellDataContainer>
                    </AccessToTableCell>
                    <TableDataCell>
                      <TableCellDetailsContainer>
                        {e.accepted ? (
                          <AcceptedInvitaionIcon />
                        ) : (
                          <PendingInvitationIcon />
                        )}
                        <Details
                          onClick={() => {
                            navigate(`${e.AdminId}`, {
                              state: mapAdminsTableDataToProfileComponent(e),
                            });
                          }}
                        >
                          {t("adminSettings.adminsAndManagersPage.details")}
                        </Details>
                      </TableCellDetailsContainer>
                    </TableDataCell>
                  </TableDataRow>
                );
              })}
          {managerData.map((e) => {
            return (
              <TableDataRow key={e.managerId}>
                <TableDataCell>
                  <TableDataText>{e.firstName}</TableDataText>
                </TableDataCell>
                <TableDataCell>
                  <TableCellDataContainer>
                    <TableDataText>{e.LastName}</TableDataText>
                  </TableCellDataContainer>
                </TableDataCell>
                <TableDataCell>
                  <TableCellDataContainer>
                    <TableDataText>{e.role}</TableDataText>
                  </TableCellDataContainer>
                </TableDataCell>
                <AccessToTableCell>
                  <TableCellDataContainer>
                    <TableDataText>
                      {e.accessTo.map((university) => (
                        <>
                          <span key={university.id}>
                            {university.universityName}
                          </span>
                          <br />
                        </>
                      ))}
                    </TableDataText>
                  </TableCellDataContainer>
                </AccessToTableCell>
                <TableDataCell>
                  <TableCellDetailsContainer>
                    {e.accepted ? (
                      <AcceptedInvitaionIcon />
                    ) : (
                      <PendingInvitationIcon />
                    )}
                    <Details
                      onClick={() => {
                        navigate(`${e.managerId}`, {
                          state: mapAdminsTableDataToProfileComponent(e),
                        });
                      }}
                    >
                      {t("adminSettings.adminsAndManagersPage.details")}
                    </Details>
                  </TableCellDetailsContainer>
                </TableDataCell>
              </TableDataRow>
            );
          })}
        </TableBody>
      </TableStyled>
    </Container>
  );
}

export default Table;
