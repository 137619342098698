import React from "react";
import styled, { css } from "styled-components";
import { parseISO, format } from "date-fns";
import { CourseDetailsType } from "./CourseDetailsType";
import { device, mixin } from "@styles/theme";
import { returnType } from "../../../../lib/hooks/useCourseDetails";

// styled-components----
interface loading {
  $loading: boolean;
}
const Container = styled.div`
  margin-top: 3.125rem;
`;
const ScheduleHeading = styled.div`
  font-size: 1.375rem;
  line-height: 1.9375rem;
  font-weight: 700;
  border-left: 11px solid ${(props) => props.theme.colors.blue[700]};
  padding-left: 1.25rem;
  color: ${(props) => props.theme.colors.gray[800]};
  @media ${device.tablet} {
    line-height: 1.5625rem;
    font-size: 1.125rem;
    border-width: 0.5rem;
  }
`;

const ScheduleContainer = styled.div`
  display: flex;
  grid-template-columns: repeat(auto-fill, minmax(17.75rem, 100%));
  gap: 1.25rem;
  margin-top: 1.875rem;
  border-radius: 10px;
  overflow-x: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
`;
const ScheduleItem = styled.div<loading>`
  width: 17.75rem;
  overflow-wrap: break-word;
  min-width: 17.75rem;
  padding: 1.875rem 2.1875rem;
  background: ${(props) => props.theme.colors.gray[400]};
  border-radius: 10px;
  font-size: 1.125rem;
  color: ${(props) => props.theme.colors.gray[800]};
  line-height: 1.5625rem;
  ${(props) =>
    props.$loading &&
    css`
      height: 9.6875rem;
      ${mixin.skeletonLoading()}
    `}
`;
const ScheduleItemName = styled.div`
  font-weight: 700;
`;
const ScheduleItemDate = styled.div`
  margin-top: 10px;
`;
const ScheduleItemTime = styled.div`
  margin-top: 10px;
`;
// ---------------------
function CourseSchedule({ details }: { details: returnType<true | false> }) {
  const [loading, courseDetails] = details;
  const parseTime = (time: string) => {
    const parsedTime = parseISO(`1970-01-01T${time}`);
    const formattedTime = format(parsedTime, "HH:mm");
    return formattedTime;
  };

  return (
    <Container>
      <ScheduleHeading>Course schedule</ScheduleHeading>
      <ScheduleContainer>
        {!loading ? (
          courseDetails.courseSchedules.map((e) => (
            <ScheduleItem $loading={loading} key={e.id}>
              <ScheduleItemName>{e.name}</ScheduleItemName>
              <ScheduleItemDate>
                {format(parseISO(e.date), "d MMM yyyy")}
              </ScheduleItemDate>
              <ScheduleItemTime>
                {parseTime(e.startTime)}&nbsp;-&nbsp;{parseTime(e.endTime)}
              </ScheduleItemTime>
            </ScheduleItem>
          ))
        ) : (
          <>
            <ScheduleItem $loading={loading}></ScheduleItem>
            <ScheduleItem $loading={loading}></ScheduleItem>
            <ScheduleItem $loading={loading}></ScheduleItem>
            <ScheduleItem $loading={loading}></ScheduleItem>
            <ScheduleItem $loading={loading}></ScheduleItem>
            <ScheduleItem $loading={loading}></ScheduleItem>
            <ScheduleItem $loading={loading}></ScheduleItem>
          </>
        )}
      </ScheduleContainer>
    </Container>
  );
}

export default CourseSchedule;
