import { AdminData } from "@components/AdminDashboard/SubPages/Settings/SubPages/AdminAndManagers/AdminAdmin";
import { AdminAdminResponseArray } from "@lib/Types/API/APIAdminAdmin";
import { assetsUrls } from "@styles/theme";

export const mapResponseToAdminAdmins = (
  item: AdminAdminResponseArray
): AdminData[] => {
  return item.map((e) => ({
    AdminId: e.id,
    firstName: e.name.split(" ")[0],
    LastName: e.name.split(" ")[1],
    role: e.role,
    accessTo: "ALL",
    email: e.email,
    accepted: e.acceptedInvite,
    profilePicUrl: e.profilePicUrl ? e.profilePicUrl : assetsUrls.userPng,
  }));
};
