import {
  ordersData,
  OrdersDetailsData,
} from "@components/AdminDashboard/SubPages/Orders/AdminOrders";
import { convertCurrency } from "@lib/util/main";
import {
  courseOrderResponse,
  CourseOrderDetailsResponseData,
} from "@lib/Types/API/APIOrderDetails";

export const mapAdminOrders = (item: courseOrderResponse[]): ordersData[] => {
  return item.map((items) => ({
    akcOrderId: items.akcOrderId,
    OrderId: items["order-id"],
    PaymentId: items["payment-id"],
    subject: items.subject,
    study: items.study,
    language: items.language,
    university: items.unviersity,
    Status: items.status,
    Date: items.date,
    amount: convertCurrency(items.amount),
    name: items["student-name"] ? items["student-name"] : undefined,
  }));
};

export const mapAdminOrdersDetails = async (
  item: CourseOrderDetailsResponseData
): Promise<OrdersDetailsData> => {
  return {
    orderId: item.orderId,
    orderTime: item.orderTime,
    amount: item.amount,
    akcOrderId: item.akcOrderId,
    orderStatus: item.orderStatus,
    studentName:item.studentName,
    productName:item.productName,
    stripePaymentIntentId:item.stripePaymentIntentId,
    payment: {
      id: item.payment.id,
      paymentTime: item.payment.paymentTime,
      amount: item.payment.amount,
      akcPaymentId: item.payment.akcPaymentId,
      stripePaymentIntentId: item.payment.stripePaymentIntentId,
      stripePaymentChargeId: item.payment.stripePaymentChargeId,
      status: item.payment.status,
    },
    hibernateLazyInitializer: {},
  };
};


