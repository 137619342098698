import GreetingsComponent from "@API/CallToSignIn/main";
import CourseComponent from "@components/CommonComponents/CourseComponent";
import NonEnrolledCourse from "@components/CommonComponents/CourseComponent/NonEnrolledCourse";
import { CourseAction } from "@lib/Enums/CourseAction";
import { CourseStatus } from "@lib/Enums/CourseStatus";
import { Language } from "@lib/Enums/Language";
import { mapCourseToUnenrolled } from "@lib/mapperFunctions/mapCourseToUnenrolled";
import styled from "styled-components";

// styled.components---------
const Container = styled.div`
  background: #ffffff;
  padding: 30px;
  border-radius: 20px;
`;
// --------------------------

const item = mapCourseToUnenrolled({
  courseId: "0f008b4e-1260-49a0-8824-3ae8947d7e60",
  university: {
    id: 4,
    universityName: "Leiden University",
    universityLogoUrl: "/logo/university/leiden.png",
  },
  courseType: {
    id: 2,
    courseType: "DIGITAL EXAM TRAINNING",
  },
  courseStudy: {
    id: 6,
    name: "Law",
    universityId: 4,
  },
  courseSubject: {
    id: 14,
    name: "Computer Science",
    studyId: 6,
  },
  courseGroup: {
    id: 3,
    name: "Web Develpment",
  },
  language: Language.ENGLISH_US,
  courseStatus: CourseStatus.DRAFTED,
  actionForCurrentLoggedInUser: CourseAction.EDIT_COURSE,
});

function CourseDetails() {
  return (
    <Container>
      <CourseComponent data={item}></CourseComponent>
    </Container>
  );
}

export default CourseDetails;
