import React, { ReactNode } from "react";
import {
  FaC,
  FaClock,
  FaLocationDot,
  FaCircleCheck,
  FaBook,
} from "react-icons/fa6";
import styled, { css } from "styled-components";
import CourseFeatures from "./CourseFeatures";
import CourseQuestionnare from "./CourseQuestionnare";
import CourseHighlights from "./CourseHighlights";
import CourseTeacher from "./CourseTeacher";
import CourseSchedule from "./CourseSchedule";
import { IconType } from "react-icons/lib";
import { CourseDetailsType } from "./CourseDetailsType";
import { device, mixin } from "@styles/theme";
import { returnType } from "../../../../lib/hooks/useCourseDetails";
import CourseStudyGuide from "./CourseStudyGuide";
// styled-components----
const Container = styled.div``;
const CourseMeta = styled.div`
  line-height: 1.5625rem;
  font-size: 1.125rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.gray[600]};
  display: flex;
  gap: 4.375rem;
  @media ${device.tablet} {
    flex-direction: column;
    gap: 1.25rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;
const IconContainer = styled.div`
  height: 2.1875rem;
  @media ${device.tablet} {
    height: 1.5625rem;
  }
  svg {
    height: 2.1875rem;
    width: auto;
    opacity: 0.25;
    margin-right: 1.25rem;
    @media ${device.tablet} {
      height: 1.5625rem;
      margin-right: 0.9375rem;
    }
  }
`;
const CourseMetaIcon = ({ Icon }: { Icon: IconType }) => {
  return (
    <IconContainer>
      <Icon />
    </IconContainer>
  );
};
const CourseMetaLoading = styled.div`
  ${css`
    width: 5rem;
    ${mixin.skeletonLoading()}
  `}
  height: 1.5625rem;
  @media ${device.laptop} {
    width: 7rem;
  }
  @media ${device.tablet} {
    height: 1.25rem;
  }
`;
const CourseDurationContainer = styled.div`
  display: flex;
  align-items: center;
`;
const CourseFormatContainer = styled.div`
  display: flex;
  align-items: center;
`;
const CourseKnowledgeGuidInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;
const Divider = styled.div`
  height: 2px;
  background: ${(props) => props.theme.colors.gray[600]};
  opacity: 0.25;
`;
const FeaturesContainer = styled.div``;
const QuestionnairesContainer = styled.div``;
const HightlightsContainer = styled.div``;
const TeachersContainer = styled.div``;
const CourseStudyGuideContainer = styled.div`
  margin-top: 3.125rem;
`;

const CourseScheduleContainer = styled.div``;
// ---------------------

function CourseDetails({ details }: { details: returnType<true | false> }) {
  const [loading, courseDetails] = details;

  return (
    <Container>
      <CourseMeta>
        <CourseDurationContainer>
          <CourseMetaIcon Icon={FaClock} />
          {!loading ? (
            `Total time: ${courseDetails.courseDuration}`
          ) : (
            <>
              &nbsp;&nbsp;
              <CourseMetaLoading />
            </>
          )}
        </CourseDurationContainer>
        <CourseFormatContainer>
          <CourseMetaIcon Icon={FaLocationDot} />
          {!loading ? (
            courseDetails.courseFormat
          ) : (
            <>
              &nbsp;&nbsp;
              <CourseMetaLoading />
            </>
          )}
        </CourseFormatContainer>
        {!loading ? (
          courseDetails.guide.knowledgeGuideAvailable ? (
            <CourseKnowledgeGuidInfoContainer>
              <CourseMetaIcon Icon={FaBook} />
              Knowledge guide available
            </CourseKnowledgeGuidInfoContainer>
          ) : (
            ""
          )
        ) : (
          <CourseKnowledgeGuidInfoContainer>
            <CourseMetaIcon Icon={FaBook} />
            &nbsp;&nbsp;
            <CourseMetaLoading />
          </CourseKnowledgeGuidInfoContainer>
        )}
      </CourseMeta>
      <Divider style={{ marginTop: "1.75rem" }} />
      <FeaturesContainer>
        <CourseFeatures details={details} />
      </FeaturesContainer>
      <QuestionnairesContainer>
        <CourseQuestionnare details={details} />
      </QuestionnairesContainer>
      <HightlightsContainer>
        <CourseHighlights details={details} />
      </HightlightsContainer>
      <TeachersContainer>
        <CourseTeacher details={details} />
      </TeachersContainer>
      <Divider style={{ marginTop: "3.125rem" }} />
      <CourseScheduleContainer>
        <CourseSchedule details={details} />
      </CourseScheduleContainer>
      <Divider style={{ marginTop: "3.125rem" }} />
      {!loading &&
        courseDetails.guide.knowledgeGuideAvailable &&
        courseDetails.guide.sellIndividually && (
          <CourseStudyGuideContainer>
            <CourseStudyGuide details={details}></CourseStudyGuide>
          </CourseStudyGuideContainer>
        )}
    </Container>
  );
}

export default CourseDetails;
