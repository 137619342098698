import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";

import { AdminUniversity } from "@lib/Types/AdminData/AdminEntitiyList";

type AdminUniversityListType =
  | {
      success: true;
      data: AdminUniversity[];
    }
  | {
      success: false;
      data: string;
    };

export const getAdminUniversityList =
  async function (): Promise<AdminUniversityListType> {
    const url = urlFunctions.getAdminUniversityList();
    const res = await API.sendGetRequest(url);
    if (res.success) {
      const data: AdminUniversity[] = res.data;
      return {
        success: true,
        data: data,
      };
    } else {
      return {
        success: false,
        data: "There was an error in getting user info",
      };
    }
  };
