import Drop from "@lib/microComponents/Drop";
import UploadButton from "@lib/microComponents/UploadButton";
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Formik } from "../../useFormikInstance";
import { STUDY_GUIDE } from "../../validationSchema";
import { v4 as uuid } from "uuid";
import { DOCUMENT_UPLOAD_LIMIT } from "@/constants/fileSizeLimit";
// styled.components ----
const Label = styled.label`
  color: ${(props) => props.theme.colors.gray[800]};
`;
const Container = styled.div`
  padding: 1.875rem;
  padding-top: 0rem;
  margin-top: 1.875rem;
`;
const UploadButtonContainer = styled.div`
  margin-top: 1.25rem;
`;
interface DoneButtonProps {
  $disabled: boolean;
}
const DoneButton = styled.button<DoneButtonProps>`
  height: 3.8125rem;
  width: 100%;
  border-radius: 10px;
  margin-top: 1.25rem;
  background: ${(props) => props.theme.colors.gray[500]};
  color: #ffffff;
  font-weight: 700;
  position: relative;
  background: ${(props) => props.theme.colors.green[600]};
  ${(props) =>
    props.$disabled &&
    css`
      background: ${(props) => props.theme.colors.gray[500]};
      &::after {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #ffffff;
        opacity: 0.5;
      }
    `};
`;
// ----------------------
function FileSelectorModal({
  onFileUploadSuccess,
  closeModal,
  formik,
}: {
  onFileUploadSuccess: (url: string) => void;
  closeModal: () => void;
  formik: Formik;
}) {
  const [fileBlob, setFileBlob] = useState<Blob>();
  const [url, setUrl] = useState("");
  const [filename, setFilenname] = useState("");
  useEffect(() => {
    if (!fileBlob) return;
    setUrl(URL.createObjectURL(fileBlob));
    formik.setFieldValue(STUDY_GUIDE, undefined);
  }, [fileBlob]);
  return (
    <Container>
      <Label>Upload document</Label>
      <Drop
        fileSizeInMB={DOCUMENT_UPLOAD_LIMIT}
        onFileLoad={(blob, ext) => {
          setFileBlob(blob);
          setFilenname(`studyMaterial-${uuid()}.${ext}`);
        }}
        clearFile={() => {
          setFileBlob(undefined);
          setUrl("");
          setFilenname("");
        }}
        fileType="both"
      />
      <UploadButtonContainer>
        <UploadButton
          onSuccess={(url) => {
            formik.setFieldValue(STUDY_GUIDE, url);
          }}
          fileFunction={async () => {
            return fileBlob;
          }}
          isPublic={false}
          filename={filename}
          uploaded={!!formik.getFieldProps(STUDY_GUIDE).value}
          disabled={!url}
        />
      </UploadButtonContainer>
      <DoneButton
        $disabled={!url}
        disabled={!url}
        type="button"
        onClick={() => {
          closeModal();
        }}
      >
        Done
      </DoneButton>
    </Container>
  );
}

export default FileSelectorModal;
