import { API } from "@API/API";
import { mapResponseToAdminManagers } from "@lib/mapperFunctions/mapAdminManager";
import { ManagerData } from "@components/AdminDashboard/SubPages/Settings/SubPages/AdminAndManagers/AdminManagers";
import { AdminManagerResponseArray } from "@lib/Types/API/APIAdminManager";
import { urlFunctions } from "@API/functions/createUrl";

export const getAdminManagers = async():Promise<ManagerData[]|undefined> => {
    const url = urlFunctions.getAdminManager();
    const res = await API.sendGetRequest(url,true);
    if(res.success)
    {
        const data:AdminManagerResponseArray = res.data;
        const mappedData = mapResponseToAdminManagers(data);
        return mappedData;
    }
    return undefined;
}