import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import CourseRenderer, { AdminCourseView } from "./CourseRenderer";
import Skeleton from "@components/CommonComponents/CourseComponent/Skeleton";

// styled-components----
const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 1.25rem;
`;
//----------------------
function CourseView({
  items,
  totalPages,
  pageNumber,
  setPageNumber,
  loading,
}: {
  items: AdminCourseView[];
  totalPages: number;
  pageNumber: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
  loading: boolean;
}) {
  const observer = useRef<IntersectionObserver>();
  const lastElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (loading) return;
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (pageNumber < totalPages - 1) {
            setPageNumber((value) => value + 1);
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, setPageNumber]
  );
  const [active, setActive] = useState<number>();

  return (
    <>
      <Container>
        {items.map((item, index) => {
          if (index === items.length - 1) {
            return (
              <CourseRenderer
                ref={lastElementRef}
                id={index}
                key={index}
                onClick={() => {
                  setActive(index);
                }}
                handleActive={(id) => {
                  if (id === active) {
                    setActive(undefined);
                  } else {
                    setActive(id);
                  }
                }}
                active={index === active}
                item={item}
              />
            );
          } else {
            return (
              <CourseRenderer
                id={index}
                key={index}
                onClick={() => {
                  setActive(index);
                }}
                handleActive={(id) => {
                  if (id === active) {
                    setActive(undefined);
                  } else {
                    setActive(id);
                  }
                }}
                active={index === active}
                item={item}
              />
            );
          }
        })}
        {loading && Array.from({ length: 10 }).map((e) => <Skeleton />)}
      </Container>
    </>
  );
}

export default CourseView;
