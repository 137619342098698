import { API } from "@API/API";
import { mapResponseToAdminTeachers } from "@lib/mapperFunctions/mapAdminTeachers";
import { TeacherData } from "@components/AdminDashboard/SubPages/Settings/SubPages/Teachers/AdminTeachers";
import { AdminTeacherResponseArray } from "@lib/Types/API/APIAdminTeachers";
import { urlFunctions } from "@API/functions/createUrl";
import { UserRole } from "@app/redux/slices/userSlice";

export const getAdminTeachers = async (
  role: UserRole | undefined
): Promise<TeacherData[] | undefined> => {
  let url: string;
  if (role === UserRole.ADMIN) {
    url = urlFunctions.getAdminTeachersList();
  } else if (role === UserRole.MANAGER) {
    url = urlFunctions.getManagerTeachersList();
  } else {
    throw new Error("Not authorized");
  }
  const res = await API.sendGetRequest(url, true);
  if (res.success) {
    const data: AdminTeacherResponseArray = res.data;
    const mappedData = mapResponseToAdminTeachers(data);
    return mappedData;
  }
  return undefined;
};
