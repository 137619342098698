import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";

type Response =
  | {
      success: true;
      error: null;
      data: any;
    }
  | {
      success: false;
      error: string;
      data: null;
    };

export const deleteAUniversityService = async (
  universityId: string
): Promise<Response> => {
  const url = urlFunctions.deleteAUniversity(universityId);
  const res = await API.sendDeleteRequest(url, undefined, true);
  if (res.success) {
    return {
      success: true,
      error: null,
      data: res.data,
    };
  } else {
    return {
      success: false,
      error: "Could not create university",
      data: null,
    };
  }
};

export const deleteAStudyService = async (
  studyId: string
): Promise<Response> => {
  const url = urlFunctions.deleteAStudy(studyId);
  const res = await API.sendDeleteRequest(url, undefined, true);
  if (res.success) {
    return {
      success: true,
      error: null,
      data: res.data,
    };
  } else {
    return {
      success: false,
      error: "Could not delete study",
      data: null,
    };
  }
};

export const deleteASubjectService = async (
  subjectId: string
): Promise<Response> => {
  const url = urlFunctions.deleteASubject(subjectId);
  const res = await API.sendDeleteRequest(url, undefined, true);
  if (res.success) {
    return {
      success: true,
      error: null,
      data: res.data,
    };
  } else {
    return {
      success: false,
      error: "Could not delete subject",
      data: null,
    };
  }
};
