import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";
import { CourseSearchList } from "@lib/Types/API/APICourseSearchList";
import EnrolledCourse from "@components/CommonComponents/CourseComponent/EnrolledCourse";
import { EnrolledCourseSearchList } from "@lib/Types/API/APIEnrolledCourseSearchResponse";
import { EnrolledCoursesList } from "@components/CommonComponents/CourseComponent/SampleCourseData";
import { mapCoursetoEnrolled } from "@lib/mapperFunctions/mapCourseToEnrolled";
type EnrolledCourseData = {
  success: boolean;
  EnrolledCourses: any;
};

export const getEnrolledCourse = async function (): Promise<
  EnrolledCoursesList 
> {
  const url = urlFunctions.getenrolledCourse();
  const res = await API.sendGetRequest(url, true);
  if (res.success) {
    const data: EnrolledCourseSearchList = res.data;
    const mapData: EnrolledCoursesList = await mapCoursetoEnrolled(data);
    return mapData;
  }
  return [];
};
