import React, { useRef, useState } from "react";
import { format, getYear } from "date-fns";
import { DayPicker } from "react-day-picker";
import "./CalenderStyles.css";
import styled from "styled-components";
import { useOnClickOutside } from "usehooks-ts";

// styled-components----
const Container = styled.div`
  margin-top: 0.2rem;
  width: 100%;
`;
//----------------------
function DatePicker({
  onChange,
  value,
  setShow,
  showYear,
  ...props
}: {
  value: Date;
  onChange: (e: Date | undefined) => void;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  showYear?: boolean;
}) {
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    setShow(false);
  });
  return (
    <Container {...props} ref={ref}>
      <DayPicker
        defaultMonth={value}
        mode="single"
        selected={value}
        captionLayout={showYear ? "dropdown" : "buttons"}
        onSelect={(e) => {
          onChange(e);
          setShow(false);
        }}
        fromYear={showYear ? 1920 : undefined}
        toYear={showYear ? getYear(new Date()) : undefined}
      ></DayPicker>
    </Container>
  );
}

export default DatePicker;
