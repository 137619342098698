import styled from "styled-components";
import SampleTeacherImage from "@images/demo-teacher-image.png";
import { FaStar } from "react-icons/fa6";

// styled-components----------
const Container = styled.div`
  width: 100%;
  border: 2px solid ${(props) => props.theme.colors.gray[500]};
  border-radius: 1.25rem;
  padding: 1.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`;
const Vector = styled.img`
  height: 8.3125rem;
  width: 14.5rem;
`;
const RatingButton = styled.button`
  margin-top: 1rem;
  width: 100%;
  border-radius: 10px;
  background: ${(props) => props.theme.colors.green[600]};
  padding: 0.5625rem 0;
  line-height: 1.5625rem;
  font-size: 1.125rem;
  font-weight: 700;
  color: #ffffff;
`;
const AkcRatingContainer = styled.div`
  margin-top: 1.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StarContainer = styled.div`
  display: flex;
`;
const StarImage = styled(FaStar)`
  color: ${(props) => props.theme.colors.yellow[500]};
  height: 1.375rem;
`;

const RatingText = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${(props) => props.theme.colors.gray[800]};
  margin-top: 0.375rem;
`;
const BoldText = styled.span`
  font-weight: 700;
`;
// ---------------------------

function AkcRatingComponent() {
  return (
    <Container>
      <Vector src="/assets/m_rating_vector.png" />
      <RatingButton>Rate AKC</RatingButton>
      <AkcRatingContainer>
        <StarContainer>
          {Array.from({ length: 5 }).map(() => {
            return <StarImage />;
          })}
        </StarContainer>
        <RatingText>
          <BoldText>AKC</BoldText>&nbsp;is currently rated with a&nbsp;
          <BoldText>9.4</BoldText>
        </RatingText>
      </AkcRatingContainer>
    </Container>
  );
}

export default AkcRatingComponent;
