import { useSelect } from "downshift";
import { IconType } from "react-icons";
import {
  FaAngleDown,
  FaAngleUp,
  FaBan,
  FaRegCalendarDays,
  FaSort,
} from "react-icons/fa6";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { mixin } from "@styles/theme";

// start of styled-components
const SelectContainer = styled.div`
  position: relative;
`;
const SelectButtonContainer = styled.div``;
interface SelectButtonPropTypes {
  $selected: boolean;
  $disabled: boolean;
}

const SelectButton = styled.button<SelectButtonPropTypes>`
  width: 15rem;
  padding: 0.6563rem 1.25rem;
  background: ${(props) => props.theme.colors.gray[500]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
  color: ${(props) =>
    props.$selected
      ? props.theme.colors.gray[800]
      : props.theme.colors.gray[600]};
`;
const SelectButtonText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  color: ${(props) => props.theme.colors.gray[700]};
`;
const SelectButtonIconContainer = styled.div`
  width: 1.125rem;
  height: 1.125rem;
`;
const SelectButtonDownIcon = styled(FaAngleDown)`
  width: 1.125rem;
  height: 1.125rem;
  margin-left: 2rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const SelectButtonUpIcon = styled(FaAngleUp)`
  width: 1.125rem;
  height: 1.125rem;
  margin-left: 2rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const SortIcon = styled(FaSort)`
  width: 1.125rem;
  height: 1.125rem;
  margin-left: 2rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;
interface OptionsListPropsTyps {
  open: boolean;
}
const OptionsList = styled.ul<OptionsListPropsTyps>`
  width: 15rem;
  z-index: 1000;
  position: absolute;
  left: 0;
  padding: 0.5rem 0.5rem;
  background: #ffffff;
  display: ${(props) => (props.open ? "block" : "none")};
  border-radius: 15px;
  margin-top: 0.3125rem;
  max-height: 20rem;
  overflow-y: scroll;
  box-shadow: 0px 0px 25px #00000040;
  ${mixin.hideScroll}
`;

const ListItem = styled.li`
  cursor: pointer;
  text-align: left;
  padding: 0.2rem 0.5rem;
  color: ${(props) => props.theme.colors.gray[700]};
  transition: background 0.2s;
  transition: color 0.2s;
  border-radius: 0.2rem;
  overflow-wrap: break-word;
  &:hover {
    background: ${(props) => props.theme.colors.gray[600]};
    color: #ffffff;
  }
`;
const DisabledIcon = styled(FaBan)`
  position: relative;
  top: 0.2rem;
  margin-right: 0.4rem;
`;
// end of styled-components
export interface optionType {
  id: string;
  value: string;
}
function itemToString(item: any) {
  return item ? item.value : "";
}
function EditSelect({
  text,
  onChange,
  options,
  reset,
  value,
  disabled,
  sort = false,
  removeHyphen = false,
  ...props
}: {
  text: string;
  onChange: (item: optionType | null | undefined) => void;
  reset?: boolean;
  value: optionType | undefined | null;
  sort?: boolean;
  disabled?: boolean;
  removeHyphen?: boolean;
  options: optionType[];
}) {
  const [selectedItem, setSelectedItem] = useState<
    optionType | null | undefined
  >();
  const {
    selectItem,
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    onSelectedItemChange: (e) => {
      if (e.selectedItem && e.selectedItem.id === "-1") {
        onChange(undefined);
        setSelectedItem(undefined);
        return;
      }
      onChange(e.selectedItem);
      setSelectedItem(e.selectedItem);
    },
    items: [{ id: "-1", value: "" }, ...options],
    itemToString,
  });
  useEffect(() => {
    if (value) {
      selectItem(value);
    }
  }, [value]);

  return (
    <SelectContainer {...props}>
      <SelectButtonContainer>
        <SelectButton
          type="button"
          disabled={!!disabled}
          $disabled={!!disabled}
          $selected={!!selectedItem}
          {...getToggleButtonProps()}
        >
          <SelectButtonText>
            {selectedItem ? (
              selectedItem.value
            ) : disabled ? (
              <>
                <DisabledIcon /> {text}
              </>
            ) : (
              <>—&nbsp;{text}</>
            )}
          </SelectButtonText>

          {!sort &&
            (isOpen ? <SelectButtonUpIcon /> : <SelectButtonDownIcon />)}
          {sort && <SortIcon />}
        </SelectButton>
      </SelectButtonContainer>
      <OptionsList open={isOpen} {...getMenuProps()}>
        {isOpen &&
          [{ id: "-1", value: `— ${text}` }, ...options].map((item, index) => (
            <ListItem
              key={`${item.id}.${index}`}
              {...getItemProps({ item, index })}
            >
              <span>{item.value}</span>
            </ListItem>
          ))}
      </OptionsList>
    </SelectContainer>
  );
}

export default EditSelect;
