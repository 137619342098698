import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";
import { Message } from "@components/CommonComponents/Chat/ChatsComponent";
import { IncomingMessage } from "@lib/Types/Chat/IncomingMessage";
import { mapMessageToComponent } from "@lib/mapperFunctions/Chat/mapMessageToComponent";

type ChatHistoryResponseType =
  | {
      success: true;
      data: Message[];
    }
  | {
      success: false;
      error: string;
    };

export const getChatHistory = async function (
  courseId: string
): Promise<ChatHistoryResponseType> {
  const url = urlFunctions.chatHistory(courseId);
  const res = await API.sendGetRequest(url);
  if (res.success) {
    const data: IncomingMessage[] = res.data;
    const mappedMessage = data
      .map((item) => {
        return mapMessageToComponent(item);
      })
      .reverse();
    return {
      success: true,
      data: mappedMessage,
    };
  } else {
    return {
      success: false,
      error: "There was an error in getting messages list",
    };
  }
};
