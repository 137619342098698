import { createSlice } from "@reduxjs/toolkit";
import { optionType } from "@components/LandingPage/FormSelect";

interface SearchTerms {
  university: optionType | undefined;
  study: optionType | undefined;
  subject: optionType | undefined;
}

const initialState: SearchTerms = {
  university: undefined,
  study: undefined,
  subject: undefined,
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setUniversity: (state, action) => {
      state.university = action.payload;
    },
    setStudy: (state, action) => {
      state.study = action.payload;
    },
    setSubject: (state, action) => {
      state.subject = action.payload;
    },
  },
});
