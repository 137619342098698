import { getAdminSubjectList } from "@API/services/Admin/adminSubjectList";
import { getSubjectList } from "@API/services/CourseCRUD/getSubjectList";
import { optionType } from "@components/AdminDashboard/SubPages/Courses/AddCourse/Components/FormSelect";
import { AdminSubjectView } from "@components/AdminDashboard/SubPages/Settings/SubPages/UniversitiesStudiesAndSubjects/SubPages/Subjects/main";
import { AdminSubject } from "@lib/Types/AdminData/AdminEntitiyList";
import { mapAdminSubjectListToComponents } from "@lib/mapperFunctions/admin/mapAdminSubjectListToComponents";
import { mapSubjectListToOptions } from "@lib/mapperFunctions/mapSubjectToOptions";
import { ReactToast } from "@lib/toast";
import { useEffect, useState } from "react";

export const useAdminSubjectOptions = async function (
  id: string
): Promise<AdminSubjectView[]> {
  if (!id) {
    throw new Error("Provide an id to get subject list");
  }
  const data = await getAdminSubjectList(id);
  if (data.success) {
    const mappedList = mapAdminSubjectListToComponents(data.data);
    return mappedList;
  } else {
    ReactToast(data.data);
    return [];
  }
};
