import { BASEURI, FRONTEND_URI } from "../CONSTANTS";

interface adminRequestQueryParamsType {
  offset: number;
  size: number;
  search: string;
  university?: string;
  study?: string;
}

export const urlFunctions = {
  loginUrl: () => `${BASEURI}/auth/login`,
  signupUrl: (checkout?: boolean) => {
    if (checkout) {
      return `${BASEURI}/auth/signup?checkout=${checkout}`;
    } else {
      return `${BASEURI}/auth/signup`;
    }
  },
  authenticateWithGoogle: (redirect_uri?: string) => {
    if (redirect_uri) {
      return `${BASEURI}/oauth2/authorize/google?redirect_uri=${FRONTEND_URI}/oauth/google?redirect_uri=${redirect_uri}`;
    } else {
      return `${BASEURI}/oauth2/authorize/google?redirect_uri=${FRONTEND_URI}/oauth/google`;
    }
  },
  createCourse: () => `${BASEURI}/admin/courses/new`,
  editCourse: (id: string) => `${BASEURI}/admin/courses/${id}/edit`,
  getUniversityList: () => `${BASEURI}/data/public/university/list`,
  getStudyList: (universityId: string) =>
    `${BASEURI}/data/public/${universityId}/study/list`,
  getGroupList: () => `${BASEURI}/data/public/group/list`,
  getAdminTeachersList: () => `${BASEURI}/admin/teacher/list`,
  getManagerTeachersList: () => `${BASEURI}/manager/teacher/list`,
  getSubjectList: (studyId: string) =>
    `${BASEURI}/data/public/${studyId}/subject/list`,
  getGradeYearList: () => `${BASEURI}/data/public/grade/list`,
  getEventType: () => `${BASEURI}/data/public/type/list`,
  getUserInfo: () => `${BASEURI}/user/me`,
  verifyToken: (token: string) => `${BASEURI}/user/verify?token=${token}`,
  getAdminCourseList: (params: adminRequestQueryParamsType) => {
    let url = `${BASEURI}/admin/courses/list?offset=${params.offset}&size=${params.size}`;
    if (params.university) {
      url = url + `&universityId=${params.university}`;
    }
    if (params.study) {
      url = url + `&studyId=${params.study}`;
    }
    if (params.search) {
      url = url + `&search=${params.search}`;
    }
    return url;
  },

  getManagerCourseList: (params: adminRequestQueryParamsType) => {
    let url = `${BASEURI}/manager/courses/list?offset=${params.offset}&size=${params.size}`;
    if (params.university) {
      url = url + `&universityId=${params.university}`;
    }
    if (params.study) {
      url = url + `&studyId=${params.study}`;
    }
    if (params.search) {
      url = url + `&search=${params.search}`;
    }
    return url;
  },
  getAdminOverView: (period: string, from?: string, to?: string) => {
    if (from && to) {
      return `${BASEURI}/admin/overview?from=${from}&to=${to}`;
    } else {
      return `${BASEURI}/admin/overview?period=${period}`;
    }
  },
  getManagerOverView: (period: string, from?: string, to?: string) => {
    if (from && to) {
      return `${BASEURI}/manager/overview?from=${from}&to=${to}`;
    } else {
      return `${BASEURI}/manager/overview?period=${period}`;
    }
  },
  getSearchResult: (
    universityId?: string,
    studyId?: string,
    gradeId?: string
  ) => {
    const queries = [];
    if (universityId) {
      queries.push(["universityId", universityId]);
    }
    if (studyId) {
      queries.push(["studyId", studyId]);
    }
    if (gradeId) {
      queries.push(["gradeId", gradeId]);
    }
    let queryString = "";
    queries.map((item) => {
      queryString = queryString + `${item[0]}=${item[1]}&`;
    });
    const url = `${BASEURI}/search/course?size=10000&offset=0&${queryString}`;

    return url;
  },
  getCourseDetails: (id: string) => `${BASEURI}/public/courses/${id}`,
  getStudentCourseDetails: (id: string) =>
    `${BASEURI}/student/courses/enrolled/${id}/details`,
  createUniversity: () => `${BASEURI}/data/university`,
  createGroup: () => `${BASEURI}/data/group`,
  createSubject: () => `${BASEURI}/data/subject`,
  createStudy: () => `${BASEURI}/data/study`,
  createTeacher: () => `${BASEURI}/admin/teacher`,
  getenrolledCourse: () =>
    `${BASEURI}/student/courses/enrolled?offset=0&size=10`,
  updateStudentProfile: () => `${BASEURI}/student/profile/update/personalInfo`,
  updateStudentUniversityAndStudy: () =>
    `${BASEURI}/student/profile/update/universityAndStudy`,
  updateProfile: () => `${BASEURI}/user/updateProfile`,
  getSuggestionCourse: () =>
    `${BASEURI}/student/courses/suggestion?offset=0&size=10`,

  getAdminOrderItems: (params: adminRequestQueryParamsType) => {
    let url = `${BASEURI}/admin/orders/list?offset=${params.offset}&size=${params.size}`;
    if (params.university) {
      url = url + `&universityId=${params.university}`;
    }
    if (params.study) {
      url = url + `&studyId=${params.study}`;
    }
    if (params.search) {
      url = url + `&search=${params.search}`;
    }
    return url;
  },
  getAdminOrderItemsCount: () => `${BASEURI}/admin/orders/count`,
  getManagerOrderItemsCount: () => `${BASEURI}/manager/orders/count`,
  getManagerOrderItems: (params: adminRequestQueryParamsType) => {
    let url = `${BASEURI}/manager/orders/list?offset=${params.offset}&size=${params.size}`;
    if (params.university) {
      url = url + `&universityId=${params.university}`;
    }
    if (params.study) {
      url = url + `&studyId=${params.study}`;
    }
    if (params.search) {
      url = url + `&search=${params.search}`;
    }
    return url;
  },
  getAdminOrderDetails: (id: string | undefined) => `${BASEURI}/orders/${id}`,
  getRefund: (id: string | undefined) =>
    `${BASEURI}/admin/stripe/refund/order/${id}`,

  getStudentOrderItems: () => `${BASEURI}/student/order/list`,
  getAdminCourseOrderItems: (courseId: string) =>
    `${BASEURI}/orders/course/${courseId}`,
  getAdminUniversityList: () => `${BASEURI}/data/university/list`,
  getAdminStudyList: (universityId: string) =>
    `${BASEURI}/data/${universityId}/study/list`,
  getAdminSubjectList: (studyId: string) =>
    `${BASEURI}/data/${studyId}/subject/list`,
  getAdminStudent: (params: adminRequestQueryParamsType) => {
    let url = `${BASEURI}/admin/students/list?offset=${params.offset}&size=${params.size}`;
    if (params.university) {
      url = url + `&universityId=${params.university}`;
    }
    if (params.study) {
      url = url + `&studyId=${params.study}`;
    }
    if (params.search) {
      url = url + `&search=${params.search}`;
    }
    return url;
  },
  getAdminTeacher: () => `${BASEURI}/admin/teacher/list`,
  getAdminManager: () => `${BASEURI}/admin/managers/list`,
  getAdminAdmin: () => `${BASEURI}/admin/admin/list`,
  getManagerStudent: (params: adminRequestQueryParamsType) => {
    let url = `${BASEURI}/manager/students/list?offset=${params.offset}&size=${params.size}`;
    if (params.university) {
      url = url + `&universityId=${params.university}`;
    }
    if (params.study) {
      url = url + `&studyId=${params.study}`;
    }
    if (params.search) {
      url = url + `&search=${params.search}`;
    }
    return url;
  },
  inviteTeamMember: () => `${BASEURI}/admin/send/invite`,
  getTeacherCourses: () =>
    `${BASEURI}/teacher/courses/instructed?offset=0&size=10`,
  getTeachersCourseDetails: (courseId: string) =>
    `${BASEURI}/teacher/courses/instructed/${courseId}/details`,
  getTeacherCourseApplications: (courseId: string) =>
    `${BASEURI}/teacher/courses/${courseId}/students`,
  getAdminCourseApplications: (courseId: string) =>
    `${BASEURI}/admin/courses/${courseId}/students`,
  uploadStudyMaterialByTeacher: (courseId: string) =>
    `${BASEURI}/teacher/courses/${courseId}/uploadStudyGuide`,
  // changePassword: () => `${BASEURI}/admin/change-password`,
  changePassword: () => `${BASEURI}/user/changePassword`,
  changePasswordFromToken: () => `${BASEURI}/user/forgot/changePassword`,
  forgotPassword: (email: string) =>
    `${BASEURI}/user/forgotPassword?email=${email}`,
  editScheduleByTeacher: (courseId: string) =>
    `${BASEURI}/teacher/courses/${courseId}/edit`,
  getPaymentIntent: () => `${BASEURI}/student/stripe/paymentIntent`,
  getPublicKey: () => `${BASEURI}/student/stripe/publicKey`,
  uploadUrl: (fileName: string, isPublic: boolean) =>
    `${BASEURI}/file/upload?fileKey=${fileName}&isPublic=${isPublic}`,
  downloadUrl: (filename: string) =>
    `${BASEURI}/file/download?filename=${filename}`,
  getPromoUrl: (courseId: string) => `${BASEURI}/course/${courseId}/promoUrl`,
  chat: () => `${BASEURI}/chat`,
  inComingChat: () => `/topic/messages`,
  outGoingChat: () => `/app/chat`,
  chatHistory: (courseId: string) =>
    `${BASEURI}/chat/course/${courseId}/history?pageNumber=0&pageSize=1000`,
  reactOnMessage: (messageId: string, unicode: string) =>
    `${BASEURI}/chat/react/${messageId}/message?emoji=${unicode}`,
  getUserDetails: (userid: string) => `${BASEURI}/user/${userid}`,
  editUniversity: (universityId: string) =>
    `${BASEURI}/data/university/${universityId}`,
  editStudy: (studyId: string) => `${BASEURI}/data/study/${studyId}`,
  editSubject: (subjectId: string) => `${BASEURI}/data/subject/${subjectId}`,
  deleteAUniversity: (universityId: string) =>
    `${BASEURI}/data/university/${universityId}`,
  deleteAStudy: (studyId: string) => `${BASEURI}/data/study/${studyId}`,
  deleteASubject: (subjectId: string) => `${BASEURI}/data/subject/${subjectId}`,
  enrollInMasterClass: (courseId: string) =>
    `${BASEURI}/student/enroll/materClass/${courseId}`,
};
