import { colors } from "@/styles/theme";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface IProps {
  children: ReactNode;
  to: string;
  state?: any;
}

const Button = styled(Link)`
  border: none;
  height: 3.8125rem;
  background: ${colors.blue[700]};
  border-radius: 16px;
  font-family: ${(props) => props.theme.font.font};
  font-size: 1.125rem;
  font-weight: 700;
  position: relative;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SignInButton: React.FC<IProps> = (props) => {
  return (
    <Button to={props.to} state={props.state}>
      {props.children}
    </Button>
  );
};

export default SignInButton;
