import styled from "styled-components";

// styled.components ------
const Container = styled.div`
  background: #ffffff;
  padding: 1.875rem;
  border-radius: 20px;
`;
const Heading = styled.div`
  font-size: 1.375rem;
  line-height: 1.9375rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const Divider = styled.div`
  height: 2px;
  background: ${(props) => props.theme.colors.gray[500]};
  margin-top: 1.25rem;
`;
const ButtonsContainer = styled.div`
  margin-top: 1.25rem;
  display: flex;
  gap: 0.625rem;
  flex-direction: column;
`;
const ApplicationButton = styled.button`
  width: 100%;
  background: ${(props) => props.theme.colors.gray[600]};
  font-weight: 700;
  color: #ffffff;
  padding: 0.5625rem 0;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  border-radius: 10px;
`;

const SupportButton = styled.button`
  width: 100%;
  background: ${(props) => props.theme.colors.gray[600]};
  font-weight: 700;
  color: #ffffff;
  padding: 0.5625rem 0;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  border-radius: 10px;
`;
// ------------------------

function FailedTestComponents() {
  return (
    <Container>
      <Heading>Failed the test?</Heading>
      <Divider />
      <ButtonsContainer>
        <ApplicationButton>Guarantee application</ApplicationButton>
        <SupportButton>Get support</SupportButton>
      </ButtonsContainer>
    </Container>
  );
}

export default FailedTestComponents;
