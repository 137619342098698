import { enrollInMasterClassService } from "@API/services/Payment/enrolInMasterClassService";
import { ReactToast } from "@lib/toast";
import { assetsUrls, colors, device } from "@styles/theme";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled, { css } from "styled-components";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Heading = styled.h2`
  color: ${colors.gray[800]};
  font-size: 1.5625rem;
  line-height: 2.375rem;
  font-weight: 700;
  margin-top: 1.3125rem;
`;

const VectorImage = styled.img`
  width: 100%;
  max-width: 416px;
  height: auto;
`;

interface ReserveButtonPropType {
  $available: boolean;
  $isWorking: boolean;
}

const EnrollmentButton = styled.button<ReserveButtonPropType>`
  margin-top: 1.3125rem;
  width: 100%;
  max-width: 32.6875rem;
  height: 3.5rem;
  font-weight: 700;

  background: ${(props) =>
    props.$available
      ? props.theme.colors.green[600]
      : props.theme.colors.red[500]};
  text-align: center;
  color: #ffffff;
  border-radius: 10px;
  position: relative;
  @media ${device.tablet} {
    display: block;
    height: 3.1875rem;
    width: 100%;
    max-width: 100%;
  }
  ${(props) =>
    props.$isWorking &&
    css`
      &::after {
        position: absolute;
        content: "";
        background: #ffffff80;
        height: 100%;
        width: 100%;
        border-radius: 10px;
        top: 0;
        left: 0;
      }
    `}
`;

interface IProps {
  courseId: string;
}

const MasterClassInrollmentComponent: React.FC<IProps> = (props) => {
  const [enrolling, setEnrolling] = useState<boolean>(false);
  const { courseId } = props;
  const { lang } = useParams();
  const navigate = useNavigate();
  async function enrollInMasterClass() {
    setEnrolling(true);
    const res = await enrollInMasterClassService(props.courseId);
    if (res) {
      ReactToast.success("Enrollment successfull");
      navigate(`/${lang}/dashboard/overview?payment=true`);
    } else {
      ReactToast.error("Enrllment in course failed");
    }
    setEnrolling(false);
  }

  return (
    <Container>
      <VectorImage src={assetsUrls.masterclass_checkout} />
      <Heading>Ready to join this masterclass?</Heading>
      <EnrollmentButton
        onClick={enrollInMasterClass}
        $available={true}
        $isWorking={enrolling}
      >
        Yes, enroll for free!
      </EnrollmentButton>
    </Container>
  );
};

export default MasterClassInrollmentComponent;
