import { ReactNode } from "react";
import { FaArrowRight, FaBuildingColumns } from "react-icons/fa6";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { mixin } from "@styles/theme";

// styled-components----
const Container = styled.div`
  padding: 1.875rem;
  background: #ffffff;
  margin-top: 1.25rem;
  border-radius: 1.25rem;
  padding-right: 7.0625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;
const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;
const RightContainer = styled.div`
  display: flex;
  align-items: center;
`;
const EditButton = styled.button`
  width: 9.125rem;
  height: 2.1875rem;
  border-radius: 10px;
  ${mixin.skeletonLoading()}
`;
const UniversityImageContainer = styled.div`
  height: 3.625rem;
  width: 3.625rem;
  ${mixin.skeletonLoading()}
`;

const NameAndIconContainer = styled.div`
  height: 2.25rem;
  margin-left: 2rem;
  display: flex;
  width: 10rem;
  ${mixin.skeletonLoading()}
`;

// ---------------------

export default function LoadingComponent() {
  return (
    <Container>
      <LeftContainer>
        <UniversityImageContainer></UniversityImageContainer>
        <NameAndIconContainer>&nbsp;</NameAndIconContainer>
      </LeftContainer>
      <RightContainer>
        <EditButton>&nbsp;</EditButton>
      </RightContainer>
    </Container>
  );
}
