import React from "react";
import styled from "styled-components";
import { FaBuildingColumns, FaLocationDot } from "react-icons/fa6";
import { device } from "@styles/theme";
import { returnType } from "@lib/hooks/useCourseDetails";
import { LoadingComponent } from "@components/AdminDashboard/SubPages/Orders/OrderDetails";

const CourseDetailsMainContainer = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  display: flex;
  padding: 25px;
  @media screen and (max-width: 1350px) {
    padding: 15px;
  }
  @media screen and (max-width: 810px) {
    padding: 23px;
    border-radius: 10px;
  }
  @media screen and (max-width: 545px) {
    padding: 10px;
  }
`;

const UniversityImageContainer = styled.div`
  margin: auto 0;
  margin-right: 50px;
  @media screen and (max-width: 1350px) {
    margin-right: 20px;
  }
`;

const CourseDetailsRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  gap: 15px;
  @media screen and (max-width: 545px) {
    gap: 0px;
  }
`;

const UniversityImage = styled.img`
  width: 50px;
  height: 50px;
  @media ${device.mobileL} {
    width: 40px;
    height: 40px;
  }
`;

const DetailsPartOne = styled.div`
  display: flex;
  gap: 15px;
  @media screen and (max-width: 545px) {
    margin-bottom: 5px;
    gap: 10px;
  }
`;

const DetailsPartTwo = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  @media screen and (max-width: 545px) {
    gap: 10px;
  }
`;

const DetailsPartOneText = styled.p`
  font-size: 0.825rem;
  color: #a8bace;
  font-weight: normal;
  display: flex;
  align-items: center;
  @media ${device.mobileL} {
    font-size: 0.625rem;
  }
`;

const DetailsPartTwoTextOne = styled.p`
  font-size: 0.825rem;
  color: #707070;
  font-weight: bold;
  display: flex;
  align-items: center;
  @media ${device.mobileL} {
    font-size: 0.625rem;
  }
`;

const DetailsPartTwoTextTwo = styled(DetailsPartTwoTextOne)`
  font-weight: normal;
`;

const BuildingIcon = styled(FaBuildingColumns)`
  margin-right: 5px;
`;

const LocationIcon = styled(FaLocationDot)`
  margin-right: 5px;
`;

const SmallSquare = styled.div`
  width: 7px;
  height: 7px;
  background: #e6ebf0 0% 0% no-repeat padding-box;
`;

export const CourseDetailsSkeleton = () => {
  return (
    <LoadingComponent
      style={{
        background: "#ffffff 0% 0% no-repeat padding-box",
        borderRadius: "20px",
        display: "flex",
        padding: "25px",
        height: "100px",
        marginBottom: "30px",
      }}
    />
  );
};

const CourseDetails = ({ details }: { details: returnType<true | false> }) => {
  const [loading, courseDetails] = details;

  return (
    <div>
      {!loading && (
        <CourseDetailsMainContainer>
          <UniversityImageContainer>
            <UniversityImage src={courseDetails.universityImage} />
          </UniversityImageContainer>
          <CourseDetailsRightContainer>
            <DetailsPartOne>
              <DetailsPartOneText>
                <BuildingIcon />
                {courseDetails.university}
              </DetailsPartOneText>
              <DetailsPartOneText>
                <LocationIcon />
                {courseDetails.type}
              </DetailsPartOneText>
            </DetailsPartOne>

            <DetailsPartTwo>
              <DetailsPartTwoTextOne>
                {courseDetails.major}
              </DetailsPartTwoTextOne>
              <SmallSquare />
              <DetailsPartTwoTextTwo>
                {courseDetails.subject}
              </DetailsPartTwoTextTwo>
              <SmallSquare />
              <DetailsPartTwoTextTwo>
                {courseDetails.language}
              </DetailsPartTwoTextTwo>
            </DetailsPartTwo>
          </CourseDetailsRightContainer>
        </CourseDetailsMainContainer>
      )}
    </div>
  );
};

export default CourseDetails;
