import { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { v4 as uuid } from "uuid";
import "./ChatEditorStyles.css";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Editor as EditorType } from "tinymce";
import {
  FaBold,
  FaCirclePlus,
  FaItalic,
  FaLink,
  FaList,
  FaListOl,
  FaPaperPlane,
  FaRegFaceSmile,
  FaUnderline,
} from "react-icons/fa6";
import { renderToStaticMarkup } from "react-dom/server";
import { colors, device } from "@styles/theme";
import styled from "styled-components";
import { IconType } from "react-icons";
import { urlFunctions } from "@API/functions/createUrl";
import axios from "axios";
import Cookies from "js-cookie";
import { useMediaQuery } from "usehooks-ts";

// styled.components -----
const Container = styled.div`
  width: 100%;
`;

// -----------------------

const IconStyle = styled.span`
  svg {
    height: 18px;
    min-height: 18px;
    width: 18px;
    min-width: 18px;
  }
`;
function IconStyled({ Icon }: { Icon: IconType }) {
  return (
    <IconStyle>
      <Icon />
    </IconStyle>
  );
}

interface APIResponse {
  code: number;
  message: string;
  data: string;
}

const BoldIcon = renderToStaticMarkup(<IconStyled Icon={FaBold} />);
const ItalicIcon = renderToStaticMarkup(<IconStyled Icon={FaItalic} />);
const UnderlineIcon = renderToStaticMarkup(<IconStyled Icon={FaUnderline} />);
const LinkIcon = renderToStaticMarkup(<IconStyled Icon={FaLink} />);
const OrderedListIcon = renderToStaticMarkup(<IconStyled Icon={FaListOl} />);
const UnOrderedListIcon = renderToStaticMarkup(<IconStyled Icon={FaList} />);
const UploadImageButton = renderToStaticMarkup(
  <IconStyled Icon={FaCirclePlus} />
);
const AddEmojiImageButton = renderToStaticMarkup(
  <IconStyled Icon={FaRegFaceSmile} />
);
const PaperPlaneIcon = styled(FaPaperPlane)`
  fill: ${colors.green[600]} !important;
`;
const SendMessageIcon = renderToStaticMarkup(<PaperPlaneIcon />);
let sum = 0;
export default function ChatEditor({
  onSubmit,
  connected,
}: {
  onSubmit: (message: string) => void;
  connected: boolean;
}) {
  const matchesTablet = useMediaQuery(device.tablet);
  const editorRef = useRef<EditorType | null>(null);
  const send = () => {
    if (editorRef.current) {
      const editor = editorRef.current;
      if (editor) {
        const content = editor.getContent();
        if (content) {
          onSubmit(editor.getContent());
          editor.setContent("");
        }
      }
    }
  };
  return (
    <Container>
      <Editor
        tinymceScriptSrc="/tinymce/tinymce.min.js"
        onInit={(evt, editor) => {
          editorRef.current = editor;
        }}
        initialValue=""
        init={{
          placeholder: "Your reply",
          height: matchesTablet ? 104 : 150,
          branding: false,
          menubar: false,
          elementpath: false,
          content_css: "/tinymce_content.css",
          resize: false,
          statusbar: false,
          plugins: [
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "code",
            "insertdatetime",
            "table",
            "emoticons",
          ],
          toolbar: !matchesTablet
            ? "emoticons image | bold italic underline | link | numlist bullist | send"
            : "emoticons | send",
          toolbar_location: "bottom",
          // images_upload_url: urlFunctions.uploadUrl("chat"),
          images_upload_credentials: true,
          images_upload_handler: async function (blobInfo, progress) {
            const token = Cookies.get("token");
            const formData = new FormData();
            const file = blobInfo.blob();
            formData.append("file", file, `${uuid()}.png`);
            const res = await axios<APIResponse>({
              method: "post",
              url: urlFunctions.uploadUrl(`files_${uuid()}.png`, true),
              data: formData,
              maxRedirects: 0,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              onUploadProgress: (progressEvent) => {
                if (progressEvent.total) {
                  var percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  // setUploadingPercentage(percentCompleted);
                }
              },
            });
            if (res.status === 200) {
              return res.data.data;
            }

            return "something";
          },
          // images_upload_handler: async function (blobInfo, success, failure) {
          //   const token = "your_bearer_token";

          //   const formData = new FormData();
          //   formData.append("file", blobInfo.blob(), blobInfo.filename());

          //   axios
          //     .post("/your-upload-endpoint", formData, {
          //       headers: {
          //         Authorization: `Bearer ${token}`,
          //         "Content-Type": "multipart/form-data",
          //       },
          //       withCredentials: true,
          //     })
          //     .then((response) => {
          //       // Assuming the response contains the image URL
          //       success(response.data.location);
          //     })
          //     .catch((error) => {
          //       failure("Image upload failed");
          //     });
          //   return "something";
          // },
          // content_style: something,
          setup: (editor) => {
            editor.ui.registry.addIcon("bold", BoldIcon);
            editor.ui.registry.addIcon("italic", ItalicIcon);
            editor.ui.registry.addIcon("underline", UnderlineIcon);
            editor.ui.registry.addIcon("ordered-list", OrderedListIcon);
            editor.ui.registry.addIcon("unordered-list", UnOrderedListIcon);
            editor.ui.registry.addIcon("image", UploadImageButton);
            editor.ui.registry.addIcon("emoticons", AddEmojiImageButton);
            editor.ui.registry.addIcon("send", SendMessageIcon);
            editor.ui.registry.addIcon("link", LinkIcon);
            editor.ui.registry.addButton("send", {
              text: "send",

              onAction: () => {
                send();
              },
            });
          },
        }}
      />
      {/* <button type="button" onClick={log}>
        Log editor content
      </button> */}
    </Container>
  );
}
