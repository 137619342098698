import { APICourseResponse } from "@lib/Types/API/APICourseResponse";
import { useEffect, useState } from "react";
import { FaStar, FaStarHalf } from "react-icons/fa6";
import styled, { css } from "styled-components";
import { CourseDetailsType } from "../CourseDetailsType";
import { useTranslation } from "react-i18next";
import { returnType } from "../../../../../lib/hooks/useCourseDetails";
import { mixin } from "@styles/theme";

// styled-components----

interface loading {
  $loading: boolean;
}

const Container = styled.div<loading>`
  display: flex;
  ${(props) =>
    props.$loading &&
    css`
      width: 100%;
      height: 1.375rem;
      ${mixin.skeletonLoading()}
    `}
`;
const StarsContainer = styled.div`
  font-size: 1.375rem;
  color: ${(props) => props.theme.colors.yellow[500]};
`;
const StarIcon = styled(FaStar)``;
const HalfStarIcon = styled(FaStarHalf)``;
const RatingText = styled.div`
  font-size: 1rem;
  line-height: 1.4375rem;
  margin-left: 0.75rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;
//----------------------

function CourseRatingComponent({
  details,
}: {
  details: returnType<true | false>;
}) {
  const [star, setStar] = useState<number>(0);
  const [halfStar, setHalfStar] = useState<boolean>(false);
  const { t } = useTranslation();
  const [loading, courseDetails] = details;
  useEffect(() => {
    if (!loading) {
      const rounded = Math.floor(courseDetails.rating);
      setStar(rounded);
      setHalfStar(courseDetails.rating - rounded > 0);
    }
  }, [loading]);
  return (
    <Container $loading={loading}>
      {!loading && (
        <>
          <StarsContainer>
            {Array.from({ length: star }).map((e, index) => (
              <StarIcon key={index} />
            ))}
            {halfStar && <HalfStarIcon />}
          </StarsContainer>
          <RatingText>
            {t("course.rating.part1")} {courseDetails.rating}{" "}
            {t("course.rating.part2")} 5.0
          </RatingText>
        </>
      )}
    </Container>
  );
}

export default CourseRatingComponent;
