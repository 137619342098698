import { API } from "@/API/API";
import { urlFunctions } from "@/API/functions/createUrl";
import { getCourseSearchList } from "@/API/services/Search/getCourseSearchList";
import { selectUser } from "@/app/redux/publicSelectors/userSelector";
import { UserRole } from "@/app/redux/slices/userSlice";
import { CourseSearchList } from "@lib/Types/API/APICourseSearchList";
import { useSelector } from "react-redux";
import useSWR, { Fetcher } from "swr";

interface IParams {
  universityId: string | undefined;
  studyId: string | undefined;
  groupId: string | undefined;
}

export function useSearchData(
  params: IParams
): [CourseSearchList | undefined, string, boolean] {
  const { universityId, studyId, groupId } = params;
  const userInfo = useSelector(selectUser);
  const fetcher: Fetcher<
    CourseSearchList,
    [string, IParams, UserRole | undefined]
  > = async ([url, params]) => {
    const res = await API.sendGetRequest(url);
    if (res.success) {
      const data: CourseSearchList = res.data;
      return data;
    } else {
      throw new Error("Coulnd not get search data");
    }
  };

  const { data, error, isLoading } = useSWR(
    [
      urlFunctions.getSearchResult(universityId, studyId, groupId),
      params,
      userInfo.role,
    ],
    fetcher
  );
  return [data, error, isLoading];
}
