import { courseOrderResponse } from "@lib/Types/API/APIOrderDetails";
import { mapAdminOrders } from "@lib/mapperFunctions/mapAPIAdminOrders";
import { ordersData } from "@components/AdminDashboard/SubPages/Orders/AdminOrders";
import { urlFunctions } from "@API/functions/createUrl";
import { API } from "@API/API";
export const getStudentOrders = async (): Promise<ordersData[]> => {
  const url = urlFunctions.getStudentOrderItems();
  const repsonse = await API.sendGetRequest(url, true);
  if (repsonse.success) {
    const Datas: courseOrderResponse[] = repsonse.data;
    const res: ordersData[] = await mapAdminOrders(Datas);
    return res;
  }
  return [];
};
