import { LoadingComponent } from "@/components/AdminDashboard/SubPages/Orders/OrderDetails";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5625rem;
`;

export function StripeLoadingSkeleton() {
  return (
    <Container>
      <LoadingComponent style={{ height: "20px", width: "40%" }} />
      <LoadingComponent style={{ borderRadius: "10px", height: "40px" }} />
      <LoadingComponent style={{ borderRadius: "10px", height: "40px" }} />
      <LoadingComponent style={{ borderRadius: "10px", height: "40px" }} />
      <LoadingComponent style={{ height: "40px", width: "40%" }} />
    </Container>
  );
}
