import { selectSettings } from "@app/redux/publicSelectors/settingsSelector";
import { settingsSlice } from "@app/redux/slices/settingsSlice";
import { Language } from "@lib/Enums/Language";
import { device } from "@styles/theme";
import { GB, NL } from "country-flag-icons/react/3x2";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { useOnClickOutside } from "usehooks-ts";

// styled.components

interface LanguageIndicatorListPropType {
  $language: Language;
  $languageMenuOpen: boolean;
}
const LanguageIndicatorListCloseStyle = css`
  & > :nth-child(2) {
    display: none;
  }
`;
const LanguageIndicatorList = styled.ul<LanguageIndicatorListPropType>`
  display: flex;
  flex-direction: column;
  width: 5rem;
  box-shadow: ${(props) =>
    props.$languageMenuOpen ? "0px 0px 10px #00000029" : "none"};
  position: absolute;
  left: 0;
  top: 0;
  background: #ffffff;
  border-radius: 10px;
  ${(props) => !props.$languageMenuOpen && LanguageIndicatorListCloseStyle}
  z-index: 1000;
`;
const LanguageIndicatorButton = styled.button`
  width: 5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  background: none;
  align-items: center;
`;
const LanguageIndicatorText = styled.span`
  color: ${(props) => props.theme.colors.gray[800]};
  font-weight: 700;
  font-family: ${(props) => props.theme.font.font};
  display: inline-block;
  margin-left: 0.5rem;
  font-size: 0.8rem;
`;

const FlagIconContainer = styled.div`
  border-radius: 50%;
  overflow: hidden;
  height: 1rem;
  width: 1rem;
  text-align: center;
  position: relative;
`;

// --------------------

function LanguageSelector() {
  const [languageMenuOpen, setLanguageMenuOpen] = useState<boolean>(false);
  const settings = useSelector(selectSettings);
  const { t, i18n } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const containerRef = useRef<HTMLUListElement>(null);
  const language = i18n.language;
  const { lang } = useParams();
  const handleOutsideClick = () => {
    setLanguageMenuOpen(false);
  };
  useOnClickOutside(containerRef, handleOutsideClick);
  function changeLanguage(lang: "en" | "nl") {
    const pathname = location.pathname;
    const languageRoute = pathname.slice(0, 3);
    const pathWithoutLanguage = pathname.replace(languageRoute, "");
    window.location.replace(`/${lang}${pathWithoutLanguage}`);
  }
  return (
    <LanguageIndicatorList
      ref={containerRef}
      $languageMenuOpen={languageMenuOpen}
      $language={settings.language ? settings.language : Language.ENGLISH_US}
    >
      {!(lang === "nl") ? (
        <>
          <LanguageIndicatorButton
            onClick={() => {
              if (languageMenuOpen) {
                changeLanguage("en");
              }
              setLanguageMenuOpen((value) => !value);
            }}
          >
            <FlagIconContainer>
              <GB
                style={{
                  height: "100%",
                  display: "inline-block",
                  position: "absolute",
                  transform: "translate(-50%,-50%)",
                  top: "50%",
                  left: "50%",
                }}
              />
            </FlagIconContainer>
            <div>
              <LanguageIndicatorText>EN</LanguageIndicatorText>
            </div>
          </LanguageIndicatorButton>
          <LanguageIndicatorButton
            onClick={() => {
              if (languageMenuOpen) {
                // i18n.changeLanguage("nl");
                // dispatch(settingsSlice.actions.setLanguage(Language.DUTCH));
                changeLanguage("nl");
              }
              setLanguageMenuOpen((value) => !value);
            }}
          >
            <FlagIconContainer>
              <NL
                style={{
                  height: "100%",
                  display: "inline-block",
                  position: "absolute",
                  transform: "translate(-50%,-50%)",
                  top: "50%",
                  left: "50%",
                }}
              />
            </FlagIconContainer>
            <div>
              <LanguageIndicatorText>NL</LanguageIndicatorText>
            </div>
          </LanguageIndicatorButton>
        </>
      ) : (
        <>
          <LanguageIndicatorButton
            onClick={() => {
              if (languageMenuOpen) {
                // i18n.changeLanguage("nl");
                // dispatch(settingsSlice.actions.setLanguage(Language.DUTCH));
                changeLanguage("nl");
              }
              setLanguageMenuOpen((value) => !value);
            }}
          >
            <FlagIconContainer>
              <NL
                style={{
                  height: "100%",
                  display: "inline-block",
                  position: "absolute",
                  transform: "translate(-50%,-50%)",
                  top: "50%",
                  left: "50%",
                }}
              />
            </FlagIconContainer>
            <div>
              <LanguageIndicatorText>NL</LanguageIndicatorText>
            </div>
          </LanguageIndicatorButton>
          <LanguageIndicatorButton
            onClick={() => {
              if (languageMenuOpen) {
                // i18n.changeLanguage("en");
                // dispatch(
                //   settingsSlice.actions.setLanguage(Language.ENGLISH_US)
                // );
                changeLanguage("en");
              }
              setLanguageMenuOpen((value) => !value);
            }}
          >
            <FlagIconContainer>
              <GB
                style={{
                  height: "100%",
                  display: "inline-block",
                  position: "absolute",
                  transform: "translate(-50%,-50%)",
                  top: "50%",
                  left: "50%",
                }}
              />
            </FlagIconContainer>
            <div>
              <LanguageIndicatorText>EN</LanguageIndicatorText>
            </div>
          </LanguageIndicatorButton>
        </>
      )}
    </LanguageIndicatorList>
  );
}
export default LanguageSelector;
