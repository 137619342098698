import { useCombobox } from "downshift";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import styled from "styled-components";
import { useEffect, useState } from "react";

import { mixin } from "@styles/theme";
// start of styled-components
const SelectContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
`;

const SelectButtonContainer = styled.div`
  width: 100%;
`;

interface SelectPropType {
  $open: boolean;
}

const Select = styled.div<SelectPropType>`
  width: 100%;
  display: flex;
  align-items: center;
  /* background: ${(props) => props.theme.colors.gray[500]}; */
  border: 3px solid ${(props) => props.theme.colors.gray[500]};
  border-radius: ${(props) => (props.$open ? "5px 5px 0 0" : "5px")};
  border-bottom: none;
  height: 35px;
`;
const ArrowDown = styled(FaAngleDown)`
  width: 1.125rem;
  height: 1.125rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const ArrowUp = styled(FaAngleUp)`
  width: 1.125rem;
  height: 1.125rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;

interface SelectorInputPropType {
  $open: boolean;
}

const SelectorInput = styled.input<SelectorInputPropType>`
  min-width: 0px;
  flex-grow: 1;
  padding: 0.3125rem 1rem;
  padding-right: 0rem;
  background: ${(props) => props.theme.colors.gray[500]};
  border-radius: 3px 0 0 3px;
  color: ${(props) => props.theme.colors.gray[800]};
  height: 100%;
  &::placeholder {
    color: ${(props) => props.theme.colors.gray[700]};
  }
`;

interface SelectorButtonPropType {
  $open: boolean;
}

const SelectorButton = styled.button<SelectorButtonPropType>`
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  border-radius: 0 3px 3px 0;
  background: ${(props) => props.theme.colors.gray[500]};
  height: 100%;
  display: block;
`;
const SelectLabel = styled.label``;

interface SelectButtonPropTypes {
  $isActive: boolean;
  $selected: boolean;
  $disabled: boolean;
}

const SelectButton = styled.button<SelectButtonPropTypes>`
  width: 100%;
  padding: 0.6563rem 1.25rem;
  background: ${(props) => props.theme.colors.gray[500]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${(props) => (props.$isActive ? "10px 10px 0 0" : "10px")};
  outline: none;
  border: none;
  cursor: pointer;
  color: ${(props) =>
    props.$selected
      ? props.theme.colors.gray[800]
      : props.theme.colors.gray[600]};
`;

interface OptionsListPropsTyps {
  $open: boolean;
}
const OptionsList = styled.ul<OptionsListPropsTyps>`
  width: 100%;
  position: absolute;
  right: 0;
  background: #ffffff;
  display: ${(props) => (props.$open ? "block" : "none")};
  border-radius: 0 0 10px 10px;
  border-left: 3px solid ${(props) => props.theme.colors.gray[500]};
  border-bottom: 3px solid ${(props) => props.theme.colors.gray[500]};
  border-right: 3px solid ${(props) => props.theme.colors.gray[500]};
  /* padding: 0.5rem 0rem; */
  /* margin-top: 0.3125rem; */
  max-height: 20rem;
  overflow-y: scroll;
  z-index: 1000;
  ${mixin.customScroll()}
`;

// border-top: ${(props) => (props.index ? "1px solid green" : "none")};
const ListItemContainer = styled.li`
  color: ${(props) => props.theme.colors.gray[800]};
  &:hover {
    background: ${(props) => props.theme.colors.gray[500]};
  }
`;
const ListItem = styled.div`
  margin: 0px 1.5625rem;
  cursor: pointer;
  text-align: left;
  padding: 0.3rem 0;
  transition: background 0.2s;
  transition: color 0.2s;
  line-height: 1.5625rem;
  font-size: 1.125rem;
  overflow-wrap: break-word;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray[500]};
  &:last-child {
    border-bottom: none;
  }
`;
// end of styled-components
export interface optionType {
  id: string;
  value: string;
}
function itemToString(item: any) {
  return item ? item.value : "";
}
function getOptionsFilter(inputValue: string): (option: optionType) => boolean {
  const lowerCasedInputValue = inputValue.toLowerCase().trim();
  return function booksFilter(option: optionType): boolean {
    return (
      !inputValue ||
      option.value.toLowerCase().trim().includes(lowerCasedInputValue)
    );
  };
}
function NewFormSelect({
  text,
  onChange,
  options,
  value,
  disabled,
  style,
  ...props
}: {
  text: string;
  onChange: (item: optionType | null | undefined) => void;
  options: optionType[];
  value: optionType | undefined | null;
  style?: any;
  disabled?: boolean;
}) {
  const [items, setItems] = useState<optionType[]>([]);
  const [selectedItem, setSelectedItem] = useState<
    optionType | null | undefined
  >();
  const {
    isOpen,
    getToggleButtonProps,
    selectItem,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    inputValue,
  } = useCombobox({
    onInputValueChange({ inputValue }) {
      if (inputValue === undefined) return;
      setItems(options.filter(getOptionsFilter(inputValue)));
    },
    onSelectedItemChange(changes) {
      setSelectedItem(changes.selectedItem);
      selectItem(changes.selectedItem ? changes.selectedItem : null);
    },
    defaultSelectedItem: value ? value : undefined,
    items: items,
    itemToString(item) {
      return item ? item.value : "";
    },
  });
  //---------------------------
  useEffect(() => {
    onChange(selectedItem);
  }, [selectedItem]);
  useEffect(() => {
    setItems(options);
  }, [options]);
  useEffect(() => {
    if (value) {
      selectItem(value);
    } else {
      selectItem(null);
    }
  }, [value]);
  return (
    <SelectContainer style={style} {...props}>
      <SelectButtonContainer>
        <Select $open={isOpen}>
          <SelectorInput
            $open={isOpen}
            placeholder={selectedItem ? selectedItem.value : text}
            {...getInputProps()}
            disabled={disabled}
          />
          <SelectorButton
            $open={isOpen}
            aria-label="toggle menu"
            type="button"
            {...getToggleButtonProps()}
          >
            {isOpen ? <ArrowUp /> : <ArrowDown />}
          </SelectorButton>
        </Select>
      </SelectButtonContainer>
      <OptionsList $open={isOpen} {...getMenuProps()}>
        {isOpen &&
          items.map((item, index) => (
            <ListItemContainer>
              <ListItem key={item.id} {...getItemProps({ item, index })}>
                <span>{item.value}</span>
              </ListItem>
            </ListItemContainer>
          ))}
      </OptionsList>
    </SelectContainer>
  );
}

export default NewFormSelect;
