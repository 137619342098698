import { colors } from "@/styles/theme";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  margin-top: 1.0625rem;
  margin-bottom: 1.0625rem;
  justify-content: space-between;
  align-items: center;
`;

const Divider = styled.div`
  height: 2px;
  background: #eaefec;
  flex-grow: 1;
`;

const Text = styled.p`
  font-size: 0.9rem;
  color: ${colors.gray[800]};
  margin: 0 1.5rem;
`;

interface IProps {
  text: string;
}

const SignInAndGoogleDivider: React.FC<IProps> = (props) => {
  return (
    <Container>
      <Divider />
      <Text>{props.text}</Text>
      <Divider />
    </Container>
  );
};

export default SignInAndGoogleDivider;
