import { colors, device } from "@styles/theme";
import { ReactNode } from "react";
import styled from "styled-components";

const Container = styled.div`
  border-radius: 22px;
  border: 2px solid ${colors.gray[500]};
  @media ${device.tablet} {
    border-radius: 12px;
  }
`;

const AccordionItemHeading = styled.div`
  width: 100%;
  background: ${colors.gray[500]};
  padding: 1.375rem 3.125rem;
  border-radius: 20px;
  color: ${colors.gray[800]};
  font-size: 22px;
  line-height: 31px;
  font-weight: 700;
  @media ${device.tablet} {
    padding: 20px;
    border-radius: 10px;
    font-size: 18px;
    line-height: 20px;
  }
`;
const AccordionContent = styled.div`
  padding: 21px 50px 42px;
  @media ${device.tablet} {
    padding: 1.375rem 1.25rem;
  }
`;

export default function AccordionItem({
  isOpen,
  heading,
  children,
  onClick,
}: {
  isOpen: boolean;
  heading: string;
  children: ReactNode;
  onClick: () => void;
}) {
  return (
    <Container
      onClick={() => {
        onClick();
      }}
    >
      <AccordionItemHeading role="button">{heading}</AccordionItemHeading>
      {isOpen && <AccordionContent>{children}</AccordionContent>}
    </Container>
  );
}
