import { returnType } from "@lib/hooks/useCourseDetails";
import { device } from "@styles/theme";
import { useTranslation } from "react-i18next";
import { FaBullseye, FaAngleDown } from "react-icons/fa6";
import styled from "styled-components";

// styled-components----
const Container = styled.div`
  display: flex;
  padding: 1.875rem;
  background: transparent linear-gradient(90deg, #4883c4 0%, #1c69bd 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 20px;
  color: #ffffff;
  min-height: 0;
  grid-area: b;
  align-self: flex-start;
  @media ${device.laptopM} {
    grid-area: a;
  }
  @media ${device.tablet} {
    border-radius: 10px;
    padding: 1.25rem 0.9375rem;
  }
`;
const SmartPointsIconContainer = styled.div`
  width: 3.125rem;
  @media ${device.tablet} {
    height: 1.875rem;
    width: 1.875rem;
  }
`;
const SmartPointsIcon = styled(FaBullseye)`
  height: 3.125rem;
  width: 3.125rem;
  @media ${device.tablet} {
    height: 1.875rem;
    width: 1.875rem;
  }
`;
const SmartPointsHeadingAndInfoContainer = styled.div`
  margin-left: 1.25rem;
  flex-grow: 1;
  align-items: flex-end;
`;
const SmartPointsHeading = styled.div`
  font-size: 1.125rem;
  line-height: 1.5625rem;
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  @media ${device.tablet} {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;
const SmartPointsInfo = styled.div`
  font-size: 1rem;
  line-height: 1.4375rem;
  margin-top: 2.5px;
  @media ${device.tablet} {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
`;
// ---------------------

function SmartPoints({ details }: { details: returnType<true | false> }) {
  const { t } = useTranslation();
  const [loading, courseDeatilas] = details;
  if (loading) return <></>;

  const smart_points = courseDeatilas.type === "Masterclass" ? 25 : 150;

  return (
    <Container>
      <SmartPointsIconContainer>
        <SmartPointsIcon />
      </SmartPointsIconContainer>
      <SmartPointsHeadingAndInfoContainer>
        <SmartPointsHeading>
          {t("course.smart.heading", { smart_points })}
        </SmartPointsHeading>
        <SmartPointsInfo>{t("course.smart.subheading")}</SmartPointsInfo>
      </SmartPointsHeadingAndInfoContainer>
    </Container>
  );
}
export default SmartPoints;
