import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";
import { CourseSearchList } from "@lib/Types/API/APICourseSearchList";
import { APIPaymentIntent } from "@lib/Types/API/Payment/PaymentIntent";
import { PaymentIntent } from "@stripe/stripe-js";
export const enrollInMasterClassService = async function (
  courseId: string
): Promise<boolean> {
  const url = urlFunctions.enrollInMasterClass(courseId);
  const res = await API.sendGetRequest(url, true);
  if (res.success) {
    const data: boolean = res.data;
    return data;
  } else {
    return false;
  }
};
