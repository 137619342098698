import { UserRole } from "@app/redux/slices/userSlice";
import styled from "styled-components";
import hash from "hash-sum";
import { mixin } from "@styles/theme";
import FromChat from "./FromChat";
import { useSelect } from "downshift";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { useSelector } from "react-redux";
import ToChat from "./ToChat";
import { useEffect, useRef, useState } from "react";
import { useShouldScrollChat } from "@lib/hooks/useShouldScrollChat";
// styled.components ------
const Container = styled.div`
  padding-top: 1rem;
  flex-grow: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
  width: 100%;
  overflow-y: scroll;
  ${mixin.hideScroll()}
`;

// ------------------------
export type Message = {
  id: string;
  user: {
    id: string;
    name: string;
    role: UserRole;
    profilePicUrl: string;
  };
  text: string;
  files: {
    type: string;
    url: string;
  }[];
  timeStamp: Date;
  reactions: Reaction[];
};
interface Reaction {
  unicode: string;
  count: number;
}

function ChatsComponent({ chats }: { chats: Message[] }) {
  const user = useSelector(selectUser);
  const containerRef = useRef<HTMLDivElement>(null);
  const shouldScroll = useShouldScrollChat(chats);
  const [emojiActive, setEmojiActive] = useState<string | null>(null); // controles which message is currently has active emoji selector

  useEffect(() => {
    const element = containerRef.current;
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }, [containerRef.current, shouldScroll]);

  return (
    <Container ref={containerRef}>
      {chats.map((item, index) => {
        if (item.user.id === user.userId) {
          return (
            <ToChat
              emojiActive={emojiActive}
              setEmojiNumber={setEmojiActive}
              key={`${item.text}.${index}`}
              message={item}
            />
          );
        } else {
          return (
            <FromChat
              key={`${item.id}${item.text}.${index}`}
              message={item}
              setEmojiNumber={setEmojiActive}
              emojiActive={emojiActive}
            />
          );
        }
      })}
    </Container>
  );
}

export default ChatsComponent;
