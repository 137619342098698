import React from "react";
import { Popup } from "./Popup";
import confetti from "@images/confetti.jpg";

interface IProps {
  onClick: () => void;
}

const CheckoutThankyouPopup: React.FC<IProps> = (props) => {
  const { onClick } = props;
  return (
    <Popup
      popupName="CheckoutThankyouPopup"
      isOpacityContainer={true}
      isPopupImage={false}
      popupImage=""
      isBackgroundImage={true}
      backgroundImage={confetti}
      headingColor="#707070"
      buttonColor="#FFFFFF"
      buttonBackgroundColor="#36CC1C"
      headingWidth=""
      isPopupButtonImage={false}
      popupButtonImage=""
      onButtonClick={onClick}
    />
  );
};

export { CheckoutThankyouPopup };
