import React, { ReactNode } from "react";
import styled from "styled-components";

// styled-components ----
const Button = styled.button`
  padding: 11px 36px;
  color: #ffffff;
  background: ${(props) => props.theme.colors.gray[600]};
  font-size: 1.125rem;
  line-height: 1.5625rem;
  font-weight: 700;
  border-radius: 10px;
`;
// ----------------------

function AddButton({ ...props }) {
  return <Button {...props}>{props.children}</Button>;
}

export default AddButton;
