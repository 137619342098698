import styled from "styled-components";
import SampleTeacherImage from "@images/demo-teacher-image.png";

// styled-components----------
const Container = styled.div`
  width: 100%;
  border: 2px solid ${(props) => props.theme.colors.gray[500]};
  border-radius: 1.25rem;
  padding: 1.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`;
const Vector = styled.img`
  height: 8.3125rem;
  width: 14.5rem;
`;
const RatingButton = styled.button`
  margin-top: 1rem;
  width: 100%;
  border-radius: 10px;
  background: ${(props) => props.theme.colors.green[600]};
  padding: 0.5625rem 0;
  line-height: 1.5625rem;
  font-size: 1.125rem;
  font-weight: 700;
  color: #ffffff;
`;
const TeacherInfoContainer = styled.div`
  margin-top: 1.625rem;
  display: flex;
  align-items: center;
`;
const TeacherImage = styled.img`
  margin-right: 1.375rem;
  width: 3.0625rem;
  height: 3.0625rem;
`;
const TeacherRatingContainer = styled.div`
  width: 15.6875rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const BoldText = styled.span`
  font-weight: 700;
`;
// ---------------------------

function TeachersRatingComponent() {
  return (
    <Container>
      <Vector src="/assets/t_rating_vector.png" />
      <RatingButton>Rate your teacher Brigith</RatingButton>
      <TeacherInfoContainer>
        <TeacherImage src={SampleTeacherImage} />
        <TeacherRatingContainer>
          <BoldText>Brigith</BoldText> is currently rated with a&nbsp;
          <BoldText>9.4 out of 3 reviews</BoldText>
        </TeacherRatingContainer>
      </TeacherInfoContainer>
    </Container>
  );
}

export default TeachersRatingComponent;
