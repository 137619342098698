import { GB, NL } from "country-flag-icons/react/3x2";
export enum Language {
  ENGLISH_US = "ENGLISH_US",
  DUTCH = "DUTCH_NL",
}

export const getLanguageInfo = (languageEnum?: Language) => {
  switch (languageEnum) {
    case Language.ENGLISH_US:
      return {
        notation: "EN",
        icon: GB,
        name: "English",
        sign: "en-US",
        enum: Language.ENGLISH_US,
        locale: "en",
      };
    case Language.DUTCH:
      return {
        notation: "NL",
        icon: NL,
        name: "Dutch",
        sign: "dt-NL",
        enum: Language.DUTCH,
        locale: "nl",
      };
    default:
      return {
        notation: "EN",
        icon: GB,
        name: "English",
        sign: "en-US",
        enum: Language.ENGLISH_US,
        locale: "en",
      };
  }
};

export const LanguageArray = ["en", "nl"];

export const getLanguageFromLocaleString = (locale: string): Language => {
  switch (locale) {
    case "en":
      return Language.ENGLISH_US;
    case "nl":
      return Language.DUTCH;
    default:
      return Language.ENGLISH_US;
  }
};

export const getLanguageLocale = (locale: string): string => {
  switch (locale) {
    case "en":
      return "en-US";
    case "en-US":
      return "en-US";
    case "nl":
      return "nl-NL";
    default:
      return "en-US";
  }
};
