import { FormEvent, useEffect, useState } from "react";
import CustomHeading from "@lib/microComponents/CustomHeading";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
// import Cookies from "js-cookie";
import styled from "styled-components";
import FormSubmitButton from "@components/LogInPages/commonComponents/FormSubmitButton";
import SignInWithGoogleButton from "@components/LogInPages/commonComponents/SignInWithGoogleLink";
import BaseLayout from "@components/LogInPages/BaseLayout";
import FormInput from "@components/LogInPages/commonComponents/FormInput";
import { Link } from "react-router-dom";
import { signupWithPassword } from "@API/services/Auth";
import { ReactToast } from "@lib/toast";
import { getUserProfile } from "@API/services/Data/getUserProfile";
import { mapAPidatatoUserInfo } from "@lib/mapperFunctions/mapAPidatatoUserInfo";
import { useDispatch, useSelector } from "react-redux";
import { UserRole, userSlice } from "@app/redux/slices/userSlice";
import { useTranslation } from "react-i18next";
import { device } from "@styles/theme";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import Cookies from "js-cookie";
import { useRedirectUri } from "@app/hooks/useRedirectUri";
import { ISignupResponseCode } from "@/API/services/Auth/types";
import SignInAndGoogleDivider from "../commonComponents/SignInAndGoogleDivider";
import SignInButton from "../commonComponents/SignInButton";
// import { signUpWithPassword } from "api/services/Auth";

// start of styled-components
const FormContainer = styled.div`
  max-width: 35.75rem;
  margin: auto;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  width: 100%;
`;
const SignUpForm = styled.form`
  width: 100%;
  background: #ffffff;
  margin-top: 3.5rem;
  display: flex;
  flex-direction: column;
  font-weight: 600;
`;
const NameContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1.25rem;
  @media ${device.tabletS} {
    grid-template-columns: minmax(0, 1fr);
  }
`;
const FirstNameContainer = styled.div`
  @media ${device.tabletS} {
    width: 100%;
  }
`;
const SecondNameContainer = styled.div`
  @media ${device.tabletS} {
    width: 100%;
  }
`;
const FormLabel = styled.label`
  color: #355f44;
  font-size: 0.9rem;
`;
const Divider = styled.div`
  margin: 1.6rem 0rem;
  height: 2px;
  background: #eaefec;
`;
const SignUpLinkContainer = styled.div`
  margin: auto;
  margin-top: 2.5rem;
  color: ${(props) => props.theme.colors.gray[800]};
  font-size: 0.82rem;
  font-weight: 700;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SignUpLink = styled(Link)`
  color: #355f44;
  font-weight: 400;
`;
// end of styled-components

function SignUp() {
  const [name, setName] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [fieldsDisabled, setFieldsDisabled] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, _] = useSearchParams();
  const { lang } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = searchParams.get("token") || undefined;
  const nameFromQueryString = searchParams.get("n");
  const emailFromQueryString = searchParams.get("e");
  const { state } = useLocation();
  const userInfo = useSelector(selectUser);
  const [redirectUri, redirectFunction] = useRedirectUri(userInfo.role);
  useEffect(() => {
    if (nameFromQueryString && emailFromQueryString) {
      setFieldsDisabled(true);
      const nameDecoded = window.atob(nameFromQueryString);
      const firstNameDecoded = nameDecoded.split(" ")[0];
      const lastNameDecoded = nameDecoded.split(" ")[1];
      setFirstName(firstNameDecoded);
      setLastName(lastNameDecoded);
      const emailDecoded = window.atob(emailFromQueryString);
      setEmail(emailDecoded);
    }
    if (userInfo.loggedIn) {
      if (token) {
        Cookies.remove("token");
      } else {
        navigate(redirectUri);
      }
    }
  }, []);
  const onSubmit = async function (e: FormEvent) {
    e.preventDefault();
    setLoading(true);

    const signup = await signupWithPassword(
      `${firstName.trim()} ${lastName}`,
      email,
      password,
      state && state.to ? true : false,
      token
    );

    if (signup) {
      const statusCode = signup.statusCode;
      const token = signup.data?.token;
      if (statusCode === ISignupResponseCode.FAILURE) {
        ReactToast("Unexpected error occured");
        setLoading(false);
        return;
      } else if (statusCode === ISignupResponseCode.EMAIL_ALREADY_EXIST) {
        ReactToast("Email already exist");
        setLoading(false);
        return;
      } else if (statusCode === ISignupResponseCode.SUCCESS) {
        ReactToast("Sign Up Successfull");
        Cookies.set("token", token);
      }

      const userInfo = await getUserProfile();
      if (userInfo.success) {
        if (
          userInfo.data.role === UserRole.STUDENT &&
          userInfo.data.verified &&
          userInfo.data
        ) {
          dispatch(userSlice.actions.setUserInfo(userInfo.data));
        } else if (userInfo.data.role !== UserRole.STUDENT && userInfo.data) {
          ReactToast("Signup Successfull");
          dispatch(userSlice.actions.setUserInfo(userInfo.data));
        }
        if (
          userInfo.data.role === UserRole.STUDENT &&
          !userInfo.data.verified
        ) {
          if (state && state.to) {
            navigate(`${state.to}`);
          } else {
            Cookies.remove("token");
            navigate(lang ? `/${lang}/verify-email` : `/verify-email`, {
              state: { email: userInfo.data.email },
            });
          }
        } else if (state && state.to) {
          navigate(state.to);
        } else {
          if (userInfo.data.role) {
            navigate(redirectFunction(userInfo.data.role));
          }
        }
      } else {
        ReactToast("Error in getting user info");
      }
      setLoading(false);
    } else {
      ReactToast("SignUp Failed");
    }
    setLoading(false);
  };
  return (
    <BaseLayout>
      <FormContainer>
        <CustomHeading sx={{ marginTop: "1.5rem" }}>
          {t("signup.heading")}
        </CustomHeading>
        <SignUpForm onSubmit={onSubmit}>
          <NameContainer>
            <FirstNameContainer>
              <FormLabel htmlFor="first-name">
                {t("signup.firstname")}
              </FormLabel>
              <FormInput
                name="first-name"
                type="text"
                autoComplete="given-name"
                disabled={fieldsDisabled}
                id="first-name"
                value={firstName}
                placeholder="John"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </FirstNameContainer>
            <SecondNameContainer>
              <FormLabel htmlFor="last-name">{t("signup.lastname")}</FormLabel>
              <FormInput
                name="last-name"
                type="text"
                autoComplete="family-name"
                id="last-name"
                disabled={fieldsDisabled}
                value={lastName}
                placeholder="Doe"
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </SecondNameContainer>
          </NameContainer>
          <FormLabel htmlFor="email" style={{ marginTop: "1.4rem" }}>
            E-mail
          </FormLabel>
          <FormInput
            name="email"
            type="email"
            autoComplete="username" // autocomplete="username" is intentional
            id="email"
            disabled={fieldsDisabled}
            value={email}
            placeholder="your@email.com"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <FormLabel htmlFor="password" style={{ marginTop: "1.4rem" }}>
            {t("signup.password")}
          </FormLabel>
          <FormInput
            name="new-password"
            type="password"
            autoComplete="new-password"
            id="password"
            value={password}
            style={{ letterSpacing: "0.05rem" }}
            placeholder="••••••••••••••••••••"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />

          <FormSubmitButton loading={loading}>
            {t("signup.button")}
          </FormSubmitButton>
        </SignUpForm>
        {!token && (
          <>
            <SignInAndGoogleDivider text="Or quickly sign in/up with:" />
            <SignInWithGoogleButton state={state} />
            <SignInAndGoogleDivider text={t("signup.already")} />
            <SignInButton state={state} to={`/${lang}/signin`}>
              {t("signup.loginlink")}
            </SignInButton>
          </>
        )}
      </FormContainer>
    </BaseLayout>
  );
}

export default SignUp;
