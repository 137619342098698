import { getTeacherCourseApplications } from "@API/services/Teacher/getTeacherCourseApplications";
import { UserRole } from "@app/redux/slices/userSlice";
import { useEffect, useState } from "react";
import { FaAngleLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import StudentTable, { StudentData } from "./StudentsTable";
import { getAdminCourseApplicationsList } from "@API/services/Admin/getAdminCourseApplicationsList";
// styled-components ----
const Container = styled.div``;
const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;
const BackLink = styled.button`
  height: 2.6875rem;
  width: 2.6875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors.gray[600]};
  border-radius: 10px;
  color: #ffffff;
`;
const Heading = styled.div`
  line-height: 1.9375rem;
  font-size: 1.375rem;
  color: ${(props) => props.theme.colors.gray[800]};
  font-weight: 700;
  margin-left: 1.25rem;
`;
const TableContainer = styled.div`
  margin-top: 1.5rem;
`;

// ----------------------

function CourseApplications({
  user,
}: {
  user: UserRole.TEAHCER | UserRole.ADMIN | UserRole.MANAGER;
}) {
  const { id } = useParams();
  const [data, setData] = useState<StudentData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  async function getAndSetData() {
    setLoading(true);
    if (!id) return;
    if (user === UserRole.TEAHCER) {
      const res = await getTeacherCourseApplications(id);
      if (res.success) {
        setData(res.data);
      }
    } else {
      const res = await getAdminCourseApplicationsList(id);
      if (res.success) {
        setData(res.data);
      }
    }

    setLoading(false);
  }
  useEffect(() => {
    getAndSetData();
  }, []);
  return (
    <Container>
      <HeadingContainer>
        <BackLink
          onClick={() => {
            navigate(-1);
          }}
        >
          <FaAngleLeft />
        </BackLink>
        <Heading>Applications</Heading>
      </HeadingContainer>
      <TableContainer>
        <StudentTable items={data} loading={loading}></StudentTable>
      </TableContainer>
    </Container>
  );
}

export default CourseApplications;
