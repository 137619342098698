import { urlFunctions } from "@API/functions/createUrl";
import { v4 as uuid } from "uuid";
import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";

interface APIResponse {
  code: number;
  message: string;
  data: string;
}

type uploadFunctionReturnType =
  | {
      success: true;
      data: string;
    }
  | {
      success: false;
      message: string;
    };
function createReturnObject(
  success: boolean,
  message?: string,
  data?: string
): uploadFunctionReturnType {
  if (success) {
    if (!data) {
      throw new Error("data is required when success is true");
    } else {
      return {
        success,
        data,
      };
    }
  } else {
    if (!message) {
      throw new Error("message is required when success is true");
    } else {
      return {
        success,
        message,
      };
    }
  }
}
function useUpload({
  fileFunction,
  filename,
  isPublic,
}: {
  fileFunction: () => Promise<Blob | undefined>;
  filename: string;
  isPublic: boolean;
}): [boolean, number, () => Promise<uploadFunctionReturnType>] {
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadingPercentage, setUploadingPercentage] = useState<number>(0);
  const [status, setStatus] = useState<string>();
  const token = Cookies.get("token");
  async function uploadFile(): Promise<uploadFunctionReturnType> {
    const formData = new FormData();
    const file = await fileFunction();
    if (!file) return createReturnObject(false, "no file present");
    setLoading(true);
    setUploadingPercentage(0);
    formData.append("file", file, `${uuid()}.png`);
    if (!token) {
      setLoading(false);
      setUploadingPercentage(0);
      throw new Error("Not authorized");
    }
    try {
      const res = await axios<APIResponse>({
        method: "post",
        url: urlFunctions.uploadUrl(filename, isPublic),
        data: formData,
        maxRedirects: 0,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total) {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadingPercentage(percentCompleted);
          }
        },
      });
      if (res.data.code === 200) {
        setLoading(false);
        setUploadingPercentage(0);

        return createReturnObject(true, undefined, res.data.data);
      }
    } catch (e) {
      setLoading(false);
      setUploadingPercentage(0);
      return createReturnObject(false, "upload failed");
    }
    return createReturnObject(false, "Something went wrong");
  }

  return [loading, uploadingPercentage, uploadFile];
}

export default useUpload;
