import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";
import { LoignSuccessful } from "@lib/Types/API/Auth";
import Cookies from "js-cookie";
import { ISignupResponse } from "./types";

export async function signupWithPassword(
  name: string,
  email: string,
  password: string,
  checkout: boolean,
  token?: string
): Promise<ISignupResponse | undefined> {
  const url = urlFunctions.signupUrl(checkout);
  const res = await API.sendPostRequest(url, {
    name,
    email,
    password,
    token,
  });

  if (res.success) {
    const data: ISignupResponse = res.data;
    return data;
  }
  return undefined;
}

export async function loginWithPassword(
  email: string,
  password: string
): Promise<boolean> {
  const url = urlFunctions.loginUrl();
  const res = await API.sendPostRequest(url, {
    email,
    password,
  });
  if (res.success) {
    const data: LoignSuccessful = res.data;
    Cookies.set("token", data.accessToken);
    return true;
  }
  return false;
}
export async function verifyToken(): Promise<boolean> {
  const token = Cookies.get("token");
  if (!token) {
    return false;
  } else {
    const url = urlFunctions.verifyToken(token);
    const res = await API.sendGetRequest(url);
    if (res.success) {
      const success: boolean = await res.data;
      return success;
    }
    return false;
  }
}

interface changePasswordAPIformat {
  oldPassword: string;
  newPassword: string;
}

export async function changePassword({
  oldpassword,
  newpassword,
}: {
  oldpassword: string;
  newpassword: string;
}): Promise<boolean> {
  const token = Cookies.get("token");
  if (!token) {
    return false;
  } else {
    const url = urlFunctions.changePassword();
    const data: changePasswordAPIformat = {
      oldPassword: oldpassword,
      newPassword: newpassword,
    };
    const res = await API.sendPutRequest(url, data, true);
    if (res.success) {
      const success: boolean = await res.data;
      return success;
    }
    return false;
  }
}

type forgotPasswordResponseType =
  | {
      success: true;
      data: boolean;
    }
  | {
      success: false;
      data: string;
    };

export async function forgotPassword(
  email: string
): Promise<forgotPasswordResponseType> {
  const url = urlFunctions.forgotPassword(email);
  const res = await API.sendGetRequest(url);
  const data: boolean = res.data;
  if (res.success) {
    return {
      success: true,
      data: data,
    };
  } else {
    return {
      success: false,
      data: "There was an error in getting your account",
    };
  }
}

interface changePasswordFromTokenAPIFormat {
  newPassword: string;
  token: string;
}

export async function changePasswordFromToken(
  password: string,
  token: string
): Promise<boolean> {
  const data: changePasswordFromTokenAPIFormat = {
    newPassword: password,
    token: token,
  };
  const url = urlFunctions.changePasswordFromToken();
  const res = await API.sendPostRequest(url, data);
  if (res.success) {
    return true;
  } else {
    return false;
  }
}
