import { Message } from "@components/CommonComponents/Chat/ChatsComponent";

const ChatFunctions = {
  updateMessage(
    messages: Message[],
    newmessage: Message
  ): [boolean, Message[]] {
    let messageFound: boolean = false;
    const newMessages = messages.map((message) => {
      if (message.id === newmessage.id) {
        messageFound = true;
        return newmessage;
      } else {
        return message;
      }
    });
    if (messageFound) {
      return [false, newMessages];
    } else {
      return [true, [...newMessages, newmessage]];
    }
  },
};

export default ChatFunctions;
