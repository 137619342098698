import { AdminStudyView } from "@components/AdminDashboard/SubPages/Settings/SubPages/UniversitiesStudiesAndSubjects/SubPages/Studies/main";
import { AdminStudy } from "@lib/Types/AdminData/AdminEntitiyList";

export function mapAdminStudyListToComponent(
  data: AdminStudy[]
): AdminStudyView[] {
  return data.map((item) => ({
    id: item.studyId.toString(),
    value: item.studyName,
    numberOfSubjects: item.numberOfSubjects,
    logo: item.universityLogoUrl,
  }));
}
