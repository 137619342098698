import { ReactNode, useState } from "react";
import { FaAngleDown, FaAngleUp, FaBullseye } from "react-icons/fa6";
import styled from "styled-components";
import { FaCircleCheck } from "react-icons/fa6";
import { device } from "@styles/theme";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUser } from "@app/redux/publicSelectors/userSelector";

// styled-components----
interface ContainerProps {
  $active: boolean;
}
const Container = styled.div<ContainerProps>`
  background: ${(props) => props.theme.colors.green[600]};
  border-radius: 20px;
  @media ${device.tablet} {
    border-radius: 10px;
  }
  cursor: ${(props) => (props.$active ? "auto" : "pointer")};
  @media ${device.laptopL} {
    width: 50%;
    align-self: flex-start;
  }
  @media ${device.laptopS} {
    align-self: stretch;
    width: 100%;
  }
`;
const ProfileStatusContainer = styled.div`
  padding: 30px;
  color: #ffffff;
  width: 100%;
  @media ${device.tablet} {
    padding: 1.5625rem 1.25rem;
  }
`;
const ProfileStatusHeader = styled.div`
  display: flex;
`;
const ProfileStatusImageContainer = styled.div`
  height: 3.125rem;
  width: 3.125rem;
  @media ${device.tablet} {
    height: 2.5rem;
    width: 2.5rem;
  }
`;
const ProfileStatusImage = styled(FaBullseye)`
  height: 3.125rem;
  width: 3.125rem;
  min-height: 3.125rem;
  min-width: 3.125rem;
  @media ${device.tablet} {
    height: 2.5rem;
    width: 2.5rem;
    min-height: 2.5rem;
    min-width: 2.5rem;
  }
`;
const ProfileStatusHeaderTextContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-left: 1.25rem;
`;
const ProfileStatusHeaderHeadingAndToggleButton = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  @media ${device.tablet} {
    font-size: 1rem;
    line-height: 1.4375rem;
  }
`;
const ToggleButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  color: #ffffff;
  cursor: pointer;
`;
const ProfileStatusHeaderPercentageIndicator = styled.div`
  font-size: 1rem;
  line-height: 1.4375rem;
  margin-top: 2.5px;
  @media ${device.tablet} {
    font-size: 0.75rem;
    line-height: 1.0625rem;
  }
`;
const Divider = styled.div`
  height: 2px;
  background: ${(props) => props.theme.colors.gray[500]};
  opacity: 0.33;
  margin: 0 1.25rem;
`;

const SmartPointsStatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1.25rem;
  margin-top: 0.75rem;
  gap: 1.375rem;
  color: #ffffff;
`;

const SmartPointsStatus = styled.div`
  flex-grow: 1;
`;
const SmartPointsVector = styled.img`
  height: 6.5rem;
  width: 6.5rem;
`;
const SmartPointsNumberContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SmartPointsNumberHeading = styled.div`
  font-size: 1.125rem;
  line-height: 1.5625rem;
`;

const SmartPointsNumber = styled.div`
  font-size: 1.125rem;
  line-height: 1.5625rem;
  font-weight: 700;
`;

const StatusBar = styled.div`
  margin-top: 0.8125rem;
  height: 1.125rem;
  border-radius: 0.5rem;
  background: #ffffff;
  width: 100%;
`;
interface StatusbarCompletedPropsType {
  $percentage: number;
}

const StatusBarCompleted = styled.div<StatusbarCompletedPropsType>`
  width: ${(props) => props.$percentage}%;
  height: 100%;
  background: ${(props) => props.theme.colors.blue[700]};
  border-radius: 0.5rem;
`;

const SmartPointsLeftToFreeCourse = styled.div`
  margin-top: 0.8125rem;
  font-weight: 700;
  line-height: 1.25rem;
  font-size: 0.875rem;
`;
const SmartPointsDescription = styled.div`
  padding: 20px;
  background: #00000026;
  border-radius: 0.625rem;
  font-size: 0.75rem;
  line-height: 1rem;
  margin: 1.25rem;
  color: #ffffff;
`;
// ---------------------

function SmartPointsInfo() {
  const [active, setActive] = useState<boolean>(false);
  const { t } = useTranslation();
  const userInfo = useSelector(selectUser);
  const smartPoints = userInfo.smartPoints ? userInfo.smartPoints : 0;
  const percentage = (smartPoints * 100) / 1000;
  return (
    <Container
      $active={active}
      onClick={() => {
        setActive(true);
      }}
      role="button"
      aria-label="expand by clicking"
    >
      <ProfileStatusContainer>
        <ProfileStatusHeader>
          <ProfileStatusImageContainer>
            <ProfileStatusImage />
          </ProfileStatusImageContainer>
          <ProfileStatusHeaderTextContainer>
            <ProfileStatusHeaderHeadingAndToggleButton>
              {t("sdashboard.overview.extra.points.heading")}
              <ToggleButton
                onClick={(e) => {
                  e.stopPropagation();
                  setActive((value) => !value);
                }}
              >
                {" "}
                {active ? <FaAngleUp /> : <FaAngleDown />}
              </ToggleButton>
            </ProfileStatusHeaderHeadingAndToggleButton>
            <ProfileStatusHeaderPercentageIndicator>
              {smartPoints}/1000&nbsp;
              {t("sdashboard.overview.extra.points.points")}
            </ProfileStatusHeaderPercentageIndicator>
          </ProfileStatusHeaderTextContainer>
        </ProfileStatusHeader>
      </ProfileStatusContainer>
      {active && (
        <>
          <Divider />
          <SmartPointsStatusContainer>
            <SmartPointsStatus>
              <SmartPointsNumberContainer>
                <SmartPointsNumberHeading>
                  Your smart points:
                </SmartPointsNumberHeading>
                <SmartPointsNumber>{smartPoints}</SmartPointsNumber>
              </SmartPointsNumberContainer>
              <StatusBar>
                <StatusBarCompleted $percentage={percentage} />
              </StatusBar>
              <SmartPointsLeftToFreeCourse>
                {1000 - smartPoints} left for a free course…
              </SmartPointsLeftToFreeCourse>
            </SmartPointsStatus>
            <SmartPointsVector src="/assets/goal_vector.png" />
          </SmartPointsStatusContainer>
          <SmartPointsDescription>
            Earn smart points when purchasing trainings and doing other
            activities on our platform! You can redeem them for free trainings
            and other fun stuff.
          </SmartPointsDescription>
        </>
      )}
    </Container>
  );
}

export default SmartPointsInfo;
