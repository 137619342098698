import React from "react";
import { FaCircleCheck } from "react-icons/fa6";
import styled from "styled-components";
import { CourseDetailsType } from "./CourseDetailsType";
import { device, mixin } from "@styles/theme";
import { returnType } from "../../../../lib/hooks/useCourseDetails";

// styled-components----
const Container = styled.div`
  margin-top: 1.875rem;
  padding: 1.875rem;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  background: ${(props) => props.theme.colors.gray[400]};
  border-radius: 10px;
  gap: 2.5313rem;
  @media ${device.laptop} {
    grid-template-columns: minmax(0, 1fr);
  }
  @media ${device.tablet} {
    padding: 1.375rem;
    gap: 1.375rem;
  }
`;
const FacultyBioContainer = styled.div`
  display: flex;
  align-items: center;
  border-right: 2px solid ${(props) => props.theme.colors.gray[600]}40;
  @media ${device.laptop} {
    padding-bottom: 0.9375rem;
    border-right: none;
    border-bottom: 2px solid ${(props) => props.theme.colors.gray[600]}40;
  }
`;

interface loading {
  $loading: boolean;
}

const FacultyImageContainer = styled.div<loading>`
  height: 5.3125rem;
  width: 5.3125rem;
  border-radius: 2.6563rem;
  overflow: hidden;
  @media ${device.laptop} {
    height: 3.75rem;
    width: 3.75rem;
  }
  ${(props) => props.$loading && mixin.skeletonLoading()}
`;
const FacultyImage = styled.img`
  height: 5.3125rem;
  width: 5.3125rem;
  @media ${device.laptop} {
    height: 3.75rem;
    width: 3.75rem;
  }
`;
const FacultyBio = styled.div`
  margin-left: 1.875rem;
`;
const FacultyBioHeading = styled.div`
  font-size: 18px;
  line-height: 25px;
  color: ${(props) => props.theme.colors.gray[600]};
  @media ${device.laptop} {
    line-height: 1.0625rem;
    font-size: 0.75rem;
  }
`;

const FacultyBioName = styled.div<loading>`
  margin-top: 0.1875rem;
  color: ${(props) => props.theme.colors.gray[800]};
  font-size: 1.5rem;
  line-height: 2.0625rem;
  font-weight: 700;
  @media ${device.laptop} {
    line-height: 1.4375rem;
    font-size: 1rem;
  }
  ${(props) => props.$loading && mixin.skeletonLoading()}
`;
const VerticalDivider = styled.div`
  height: 100%;
  width: 2px;
  background: ${(props) => props.theme.colors.gray[600]};
  opacity: 0.25;
`;
const HorizontalDivider = styled.div`
  height: 100%;
  width: 2px;
  background: ${(props) => props.theme.colors.gray[600]};
  opacity: 0.25;
`;
const FacultyProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
interface FacultyProfileItemPropType {
  $top: boolean;
}
const FacultyProfileItem = styled.div<FacultyProfileItemPropType>`
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 25px;
  color: ${(props) => props.theme.colors.gray[800]};
  margin-top: ${(props) => (!props.$top ? "14px" : "0px")};
  @media ${device.laptop} {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;
const FacultyProfileItemText = styled.div<loading>`
  flex-grow: 1;
  ${(props) => props.$loading && mixin.skeletonLoading()}
`;
const FacultyProfileItemIcon = styled(FaCircleCheck)`
  width: 1.125rem;
  height: 1.125rem;
  min-width: 1.125rem;
  min-height: 1.125rem;
  margin-right: 1.5625rem;
  color: ${(props) => props.theme.colors.blue[700]};
  @media ${device.laptop} {
    width: 1rem;
    height: 1rem;
    min-width: 1rem;
    min-height: 1rem;
  }
`;
// ---------------------

function CourseTeacher({ details }: { details: returnType<true | false> }) {
  const [loading, courseDetails] = details;
  return (
    <Container>
      <FacultyBioContainer>
        <FacultyImageContainer $loading={loading}>
          {!loading && <FacultyImage src={courseDetails.teacher.image} />}
        </FacultyImageContainer>
        <FacultyBio>
          <FacultyBioHeading>This training is given by:</FacultyBioHeading>

          <FacultyBioName $loading={loading}>
            {!loading ? courseDetails.teacher.name : <>&nbsp;</>}
          </FacultyBioName>
        </FacultyBio>
      </FacultyBioContainer>
      <FacultyProfileContainer>
        {!loading ? (
          Object.values(courseDetails.teacher.profile).map((e, index) => (
            <FacultyProfileItem key={e} $top={index === 0}>
              <FacultyProfileItemIcon />
              <FacultyProfileItemText $loading={loading}>
                {!loading ? e : <>&nbsp;</>}
              </FacultyProfileItemText>
            </FacultyProfileItem>
          ))
        ) : (
          <>
            <FacultyProfileItem $top={true}>
              <FacultyProfileItemIcon />
              <FacultyProfileItemText $loading={loading}>
                &nbsp;
              </FacultyProfileItemText>
            </FacultyProfileItem>
            <FacultyProfileItem $top={false}>
              <FacultyProfileItemIcon />
              <FacultyProfileItemText $loading={loading}>
                &nbsp;
              </FacultyProfileItemText>
            </FacultyProfileItem>
          </>
        )}
      </FacultyProfileContainer>
    </Container>
  );
}

export default CourseTeacher;
