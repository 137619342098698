import styled, { css } from "styled-components";

const FormInput = styled.input`
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  min-width: 0;
  margin-top: 1rem;
  border-radius: 10px;
  height: 3.5rem;
  outline: none;
  padding: 0rem 1rem;
  font-size: 1.1rem;
  border-radius: 0.75rem;
  background: ${(props) => props.theme.colors.gray[500]};
  font-family: ${(props) => props.theme.font.font};
  color: ${(props) => props.theme.colors.gray[800]};
  &::placeholder {
    font-family: ${(props) => props.theme.font.font};
    color: ${(props) => props.theme.colors.gray[600]};
  }
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.6;
    `}
`;
export default FormInput;
