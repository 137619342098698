import { EnrolledCourseDetailsData } from "@components/StudentDashBoardSubPages/StudentCourseOverview/EnrolledCourseDetails";
import UniversityImage from "@images/sample-university.png";
import { CourseDetailsResponse } from "@lib/Types/API/APICourseDetails";
import { assetsUrls } from "@styles/theme";
import { format, parseISO } from "date-fns";
export const mapResponseToCourseDetails = async ({
  item,
}: {
  item: CourseDetailsResponse;
}): Promise<EnrolledCourseDetailsData> => {
  return {
    id: item.courseId,
    // university: item.courseUniversity.universityName,
    // image: UniversityImage,
    enrolledForGuideOnly: item.enrollmentForStudyMaterialOnly
      ? item.enrollmentForStudyMaterialOnly
      : false,
    university: {
      id: item.courseUniversity.id.toString(),
      universityName: item.courseUniversity.universityName,
      universityLogoUrl: item.enrollmentForStudyMaterialOnly
        ? assetsUrls.guide
        : item.courseUniversity.universityLogoUrl,
    },

    language: item.courseLanguage,
    study: item.courseStudy,
    major: item.courseSubject,
    subject: item.courseSubject,
    type: item.courseType,
    lessions: item.enrollmentForStudyMaterialOnly
      ? []
      : item.schedule.map((items) => ({
          id: items.id,
          date: items.date,
          endTime: items["end-time"],
          startTime: items["start-time"],
          linkToJoin: items["link-to-join"],
          title: items.name,
        })),
    StudyMaterials: await MapStudyMaterials(item["studyMaterials"]),
    applications: 0,
  };
};
const ChangeDate = (dates: string): string => {
  const Dates = new Date(dates);
  const date = Dates.getDate();
  const Month = Dates.getMonth();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return date + " " + monthNames[Month];
};
const MapStudyMaterials = async (
  item: CourseDetailsResponse["studyMaterials"]
): Promise<EnrolledCourseDetailsData["StudyMaterials"]> => {
  return {
    available: item.available,
    price: item.price,
    file: item.file
      ? item.file.length !== 0
        ? item.file.map((items) => ({
            type: items.type,
            name: items.name,
            link: items.link,
          }))
        : null
      : null,
    AvailableFrom: item["available-from"]
      ? format(parseISO(item["available-from"]), "dd MMMM")
      : null,
    AvailableUntil: item["available-until"]
      ? format(parseISO(item["available-until"]), "dd MMMM")
      : null,
    SellIndividually: item["sell-individually"]
      ? item["sell-individually"]
      : false,
  };
};
