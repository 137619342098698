import useUpload from "@API/services/useUpload";
import { ReactToast } from "@lib/toast";
import { ReactNode } from "react";
import { FaCircleCheck } from "react-icons/fa6";
import styled, { css } from "styled-components";

interface ButtonProps {
  $loading: boolean;
  $uploading: boolean;
  $loadingPercentage: number;
  $disabled: boolean;
}

const Button = styled.button<ButtonProps>`
  background: ${(props) => props.theme.colors.blue[700]};
  font-size: 1.125rem;
  line-height: 1.5625rem;
  width: 100%;
  height: 3.8125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border-radius: 10px;
  color: #ffffff;
  position: relative;
  &:focus {
    border: 3px solid ${(props) => props.theme.colors.blue[700]};
  }
  ${(props) =>
    props.$loading &&
    css`
      background: #ffffff;
      color: ${(props) => props.theme.colors.blue[700]};
      border: 3px solid ${(props) => props.theme.colors.blue[700]};
      outline: none;
      z-index: 2;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: ${props.$loadingPercentage}%;
        height: 100%;
        background: ${(props) => props.theme.colors.blue[700]};
        transition: width 1s;
      }
    `}
  ${(props) =>
    props.$disabled &&
    css`
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #ffffff;
        opacity: 0.85;
      }
    `}
`;

interface ButtonTextPropType {
  $uploading: boolean;
}

const ButtonText = styled.div<ButtonTextPropType>`
  color: #ffffff;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  position: relative;
  z-index: 3;
  ${(props) =>
    props.$uploading &&
    css`
      color: ${(props) => props.theme.colors.blue[700]};
      mix-blend-mode: multiply;
    `}
`;

function UploadButton({
  fileFunction,
  filename,
  isPublic,
  onSuccess,
  disabled,
  uploaded,
  ...props
}: {
  fileFunction: () => Promise<Blob | undefined>;
  filename: string;
  isPublic: boolean;
  onSuccess: (url: string) => void;
  disabled: boolean;
  uploaded: boolean;
}) {
  const [uploading, percentage, upload] = useUpload({
    fileFunction,
    filename,
    isPublic,
  });
  const updating = false;
  async function handleUpload() {
    const uploadRes = await upload();
    if (!uploadRes.success) {
      ReactToast.error(uploadRes.message);
      return;
    } else {
      onSuccess(uploadRes.data);
      ReactToast.success("file uploaded");
    }
  }
  return (
    <Button
      $disabled={disabled}
      $loading={uploading}
      $uploading={true}
      $loadingPercentage={percentage}
      {...props}
      type="button"
      onClick={handleUpload}
      disabled={disabled || uploaded}
    >
      <ButtonText $uploading={uploading}>
        {uploading ? `${percentage}%` : uploaded ? <FaCircleCheck /> : "Upload"}
      </ButtonText>
    </Button>
  );
}

export default UploadButton;
