import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";
export type promoLinkResType =
  | {
      success: true;
      data: {
        promoLink: string;
      };
    }
  | {
      success: false;
      data: string;
    };
export const getPromoUrl = async function (
  courseId: string
): Promise<promoLinkResType> {
  const url = urlFunctions.getPromoUrl(courseId);
  const res = await API.sendGetRequest(url);
  if (res.success) {
    const data: {
      promoLink: string;
    } = res.data;
    return {
      success: true,
      data: data,
    };
  } else {
    return {
      success: false,
      data: "There was an error in getting promo link",
    };
  }
};
