import { Label } from "@components/AdminDashboard/SubPages/Settings/commonComponents/FormComponents";
import styled, { css } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FaCircleXmark, FaFileImage } from "react-icons/fa6";
import { checkFileType } from "@lib/util/main";
import ImageEdit from "@lib/microComponents/ImageEdit";
import mime from "mime";
import { cropImage, getDefaultCropValue } from "@lib/util/cropImage";
import { Crop } from "react-image-crop";
import UploadButton from "@lib/microComponents/UploadButton";
import Drop from "@lib/microComponents/Drop";
import { updateProfilePic } from "@API/services/StudentCrud/updateProfile";
import { ReactToast } from "@lib/toast";
import { getUserProfile } from "@API/services/Data/getUserProfile";
import { mapAPidatatoUserInfo } from "@lib/mapperFunctions/mapAPidatatoUserInfo";
import { userSlice } from "@app/redux/slices/userSlice";

// styled-components ----
const Container = styled.div`
  margin-top: 1.875rem;
  display: flex;
  flex-direction: column;
  padding: 1.875rem;
  padding-top: 0rem;
`;
const Form = styled.form``;

const UploadButtonStyled = styled(UploadButton)`
  margin-top: 1.875rem;
`;

const Error = styled.div`
  font-size: 0.9rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.red[500]};
  margin-top: 0.5rem;
`;

const ImageEditorContainer = styled.div`
  margin-top: 1rem;
`;

interface ButtonProps {
  $loading: boolean;
}
const UpdateButton = styled.button<ButtonProps>`
  background: ${(props) => props.theme.colors.green[600]};
  font-size: 1.125rem;
  line-height: 1.5625rem;
  width: 100%;
  height: 3.8125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border-radius: 10px;
  color: #ffffff;
  position: relative;
  ${(props) =>
    props.$loading &&
    css`
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background: #ffffff;
        opacity: 0.85;
      }
    `}
`;
// ----------------------

const defaultCropValue: Crop = {
  x: 0,
  y: 0,
  width: 100,
  height: 100,
  unit: "px",
};

function EditPictureForm({ handleClose }: { handleClose: () => void }) {
  const { t } = useTranslation();
  const userInfo = useSelector(selectUser);
  const [fileBlob, setFileBlob] = useState<Blob>();
  const [url, setUrl] = useState<string>("");
  const [uploadedUrl, setUploadUrl] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [cropValue, setCropValue] = useState<Crop>(defaultCropValue);
  const [updating, setUpadating] = useState<boolean>(false);
  const [filename, setFilename] = useState(
    `${userInfo.name}-${userInfo.userId}.png`
  );
  const dispatch = useDispatch();
  async function updateProfilePicAsync() {
    setUpadating(true);
    const res = await updateProfilePic(uploadedUrl, userInfo.role);
    if (res.success) {
      ReactToast.success("Profile pic updated successfully");
      const userInfo = await getUserProfile();
      if (userInfo.success) {
        dispatch(userSlice.actions.setUserInfo(userInfo.data));
      }
      handleClose();
    } else {
      ReactToast.error("Could not update profile picture");
    }
    setUpadating(false);
  }
  async function onFileBlobChange() {
    if (fileBlob) {
      setUrl(URL.createObjectURL(fileBlob));
      const crop = await getDefaultCropValue(fileBlob);
      setCropValue(crop);
    }
  }
  useEffect(() => {
    onFileBlobChange();
  }, [fileBlob]);

  return (
    <Container>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Label>{t("sdashboard.profile.editpic.modal.format")}</Label>
        {error && <Error>{error}</Error>}
        <Drop
          fileSizeInMB={100}
          onFileLoad={(blob) => {
            setFileBlob(blob);
          }}
          clearFile={() => {
            setFileBlob(undefined);
            setUrl("");
            setUploadUrl("");
          }}
          fileType="image"
        />
        {fileBlob && (
          <ImageEditorContainer>
            <ImageEdit
              src={url}
              value={cropValue}
              onChange={(crop) => {
                setCropValue(crop);
              }}
            />
          </ImageEditorContainer>
        )}
        <UploadButtonStyled
          onSuccess={(url) => {
            setUploadUrl(url);
          }}
          fileFunction={async () => {
            if (fileBlob) {
              const newFile = await cropImage(fileBlob, cropValue);
              setFileBlob(newFile.image);
              return newFile.image;
            } else {
              return undefined;
            }
          }}
          filename={filename}
          isPublic={true}
          uploaded={!!uploadedUrl}
          disabled={!url}
        ></UploadButtonStyled>
        <UpdateButton
          style={{ marginTop: "20px" }}
          $loading={updating}
          disabled={updating}
          onClick={updateProfilePicAsync}
        >
          Update
        </UpdateButton>
      </Form>
    </Container>
  );
}

export default EditPictureForm;
