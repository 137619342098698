import { getAdminAdmins } from "@API/services/Admin/adminAdminList";
import { getAdminManagers } from "@API/services/Admin/adminManagerList";
import { getPaymentIntent } from "@API/services/Payment/getPaymentIntent";
import { getPublicKey } from "@API/services/Payment/getPublicKey";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { UserRole } from "@app/redux/slices/userSlice";
import { AddCourseManagerListOptionType } from "@components/AdminDashboard/SubPages/Courses/AddCourse/Components/GeneralDetails/ManagerSelector";
import { mapAdminResponseAddCourseManagerListOptionType } from "@lib/mapperFunctions/admin/settings/AdminAndManagers/mapAdminResponseAddCourseManagerListOptionType";
import { mapManagerResponseAddCourseManagerListOptionType } from "@lib/mapperFunctions/admin/settings/AdminAndManagers/mapManagerResponseAddCourseManagerListOptionType";
import { sessionS } from "@lib/util/sessionDB";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export type returnType<Loading extends boolean> = Loading extends true
  ? [true, [], string]
  : [false, AddCourseManagerListOptionType[], undefined];

export function useAdminAndManagerOptions(): returnType<true | false> {
  const userInfo = useSelector(selectUser);
  if (userInfo.role !== UserRole.ADMIN) {
    return [false, [], undefined];
  }
  const [loading, setLoading] = useState<boolean>(true);
  const [allOptions, setAllOptions] = useState<
    AddCourseManagerListOptionType[]
  >([]);
  const [error, setError] = useState<string>();
  async function getOptions() {
    const res = await getAdminAdmins();
    let options: AddCourseManagerListOptionType[] = [];
    if (res) {
      const mappedOptions = res.map((item) =>
        mapAdminResponseAddCourseManagerListOptionType(item)
      );
      options = mappedOptions;
    } else {
      setError("Error in getting admin list");
      setLoading(false);
    }
    const managrRes = await getAdminManagers();
    if (managrRes) {
      const mappedOptions = managrRes.map((item) =>
        mapManagerResponseAddCourseManagerListOptionType(item)
      );
      options = [...options, ...mappedOptions];
    } else {
      setError("Error in getting manager list");
      setLoading(false);
    }
    console.log(options, "managerOptions");
    setLoading(false);
    setAllOptions(options);
  }
  useEffect(() => {
    getOptions();
  }, []);
  return [loading, allOptions, error] as returnType<true | false>;
}
