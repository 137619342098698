import { selectUser } from "@app/redux/publicSelectors/userSelector";
import Select from "@components/LandingPage/FormSelect";
import { useUniversityOptions } from "@lib/hooks/useUniversityOptions";
import { searchOption } from "@lib/util/main";
import { FormEventHandler, useEffect, useState } from "react";
import { FaPenToSquare } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import EditSelect, { optionType } from "./EditSelect";
import { useStudyOption } from "@lib/hooks/useStudyOptions";
import { useGradeYearOptions } from "@lib/hooks/useGradeYearOptions";
import { updateProfile } from "@API/services/StudentCrud/updateProfile";
import { updateStudentUniversityAndStudy } from "@API/services/StudentCrud/updateStudentUniversityAndStudy";
import { device } from "@styles/theme";
import { ReactToast } from "@lib/toast";
import { getUserProfile } from "@API/services/Data/getUserProfile";
import { mapAPidatatoUserInfo } from "@lib/mapperFunctions/mapAPidatatoUserInfo";
import { userInfo, userSlice } from "@app/redux/slices/userSlice";
import { useTranslation } from "react-i18next";
import { RootState } from "@app/redux/store";

// styled-components----
const Container = styled.div`
  padding-left: 3.125rem;
  @media ${device.laptopL} {
    padding: 0;
    margin-top: 3.125rem;
  }
  @media ${device.tablet} {
    margin-top: 1.5625rem;
  }
`;
const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 3.125rem;
  @media ${device.tablet} {
    padding-right: 0;
  }
  @media ${device.tabletS} {
    flex-direction: column;
    gap: 0.625rem;
  }
`;
const Heading = styled.div`
  line-height: 1.9375rem;
  font-size: 1.375rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.gray[800]};
  @media ${device.tablet} {
    font-size: 1.125rem;
    line-height: 1.5625rem;
  }
`;
const EditButton = styled.button`
  height: 1.625rem;
  background: ${(props) => props.theme.colors.gray[500]};
  border-radius: 10px;
  color: ${(props) => props.theme.colors.gray[800]};
  padding: 0 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${device.tablet} {
    width: 4.5rem;
    font-size: 0.875rem;
    padding: 0 0.625rem;
  }
`;
const EditButtonIcon = styled(FaPenToSquare)`
  width: 0.75rem;
  height: 0.8125rem;
  min-width: 0.75rem;
  min-height: 0.8125rem;
  margin-left: 0.5rem;
  position: relative;
  top: -0.0625rem;
`;
const DetailsContainer = styled.div`
  margin-top: 3.125rem;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  width: 100%;
  row-gap: 1.875rem;
  @media ${device.tablet} {
    margin-top: 1.375rem;
  }
  @media ${device.tabletS} {
    gap: 1.4375rem;
  }
`;
const KeyValueContainer = styled.div`
  display: grid;
  grid-template-columns: 4fr 9fr;
  gap: 1rem;
  width: 100%;
  font-size: 1rem;
  line-height: 1.4375rem;
  color: ${(props) => props.theme.colors.gray[800]};
  @media ${device.tablet} {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  @media ${device.tabletS} {
    grid-template-columns: minmax(0, 1fr);
    gap: 0.625rem;
  }
`;
const Keys = styled.div`
  font-weight: 700;
`;
const Value = styled.div``;
const ActionButtonsContianer = styled.div``;
const SaveButton = styled.button`
  padding: 0.4rem 0.8rem;
  border-radius: 5px;
  font-weight: 700;
  color: #ffffff;
  background: ${(props) => props.theme.colors.green[600]};
  @media ${device.tablet} {
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
  }
`;
const CancelButton = styled.button`
  margin-left: 1rem;
  border-radius: 5px;
  font-weight: 700;
  padding: 0.4rem 0.8rem;
  color: #ffffff;
  background: ${(props) => props.theme.colors.red[500]};
  @media ${device.tablet} {
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
    margin-left: 0.625rem;
  }
`;
// ---------------------
export interface UniversityAndStudyFormType {
  university: optionType | undefined | null;
  study: optionType | undefined | null;
  grade: optionType | undefined | null;
}

function StudyAndUniversityInfo({
  loading,
  userInfo,
}: {
  loading: boolean;
  userInfo: userInfo;
}) {
  const userRole = useSelector((state: RootState) => state.userInfo.role);

  const [editActive, setEditActive] = useState<boolean>(false);
  const [university, setUniversity] = useState<optionType | null | undefined>();
  const [study, setStudy] = useState<optionType | null | undefined>();
  const [grade, setGrade] = useState<optionType | null | undefined>();
  const [studyOptions, setStudyOptions] = useState<optionType[]>([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const gradeOptions = useGradeYearOptions();
  const profile = useSelector(selectUser);
  const universityOptions = useUniversityOptions();
  async function getStudyOption(universityId: string) {
    const studyOptions = await useStudyOption(universityId);
    setStudyOptions(studyOptions);
  }
  useEffect(() => {
    if (userInfo.university) {
      setUniversity(userInfo.university);
    }
    if (userInfo.study) {
      setStudy(userInfo.study);
    }
    if (userInfo.grade) {
      setGrade(userInfo.grade);
    }
  }, [userInfo.university, userInfo.study, userInfo.grade]);
  useEffect(() => {
    if (university) {
      getStudyOption(university.id);
    }
    setStudy(undefined);
  }, [university]);
  async function handleSubmit(e: any) {
    e.preventDefault();
    const res = await updateStudentUniversityAndStudy({
      university: university,
      study: study,
      grade: grade,
    });
    if (res.success) {
      const userInfo = await getUserProfile();
      if (userInfo.success) {
        dispatch(userSlice.actions.setUserInfo(userInfo.data));
      }
      ReactToast("Updated Successfully");
    } else {
      ReactToast("Could not update");
    }
    setEditActive(false);
  }
  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <HeadingContainer>
          <Heading>{t("sdashboard.profile.university.heading")}</Heading>
          {userInfo.userId === profile.userId &&
            (!editActive ? (
              <EditButton
                type="button"
                onClick={() => {
                  setEditActive((active) => !active);
                }}
              >
                {t("sdashboard.profile.edit")}
                <EditButtonIcon />
              </EditButton>
            ) : (
              <ActionButtonsContianer>
                <SaveButton type="submit">
                  {t("sdashboard.profile.save")}
                </SaveButton>
                <CancelButton
                  type="button"
                  onClick={() => {
                    setEditActive(false);
                  }}
                >
                  {t("sdashboard.profile.cancel")}
                </CancelButton>
              </ActionButtonsContianer>
            ))}
        </HeadingContainer>
        <DetailsContainer>
          <KeyValueContainer>
            <Keys>{t("sdashboard.profile.university.university")}</Keys>
            {!editActive ? (
              <Value>
                {userInfo.university
                  ? userInfo.university.value
                  : t("sdashboard.profile.not_provided")}
              </Value>
            ) : (
              <EditSelect
                text={t(
                  "sdashboard.profile.university.form.university_placeholder"
                )}
                onChange={(selectedItem) => {
                  setUniversity(selectedItem);
                }}
                value={university}
                options={universityOptions}
              />
            )}
          </KeyValueContainer>
          <KeyValueContainer>
            <Keys>{t("sdashboard.profile.university.study")}</Keys>
            {!editActive ? (
              <Value>
                {userInfo.study
                  ? userInfo.study.value
                  : t("sdashboard.profile.not_provided")}
              </Value>
            ) : (
              <EditSelect
                disabled={!university}
                text={t("sdashboard.profile.university.form.study_placeholder")}
                onChange={(selectedItem) => {
                  setStudy(selectedItem);
                }}
                value={study}
                options={studyOptions}
              />
            )}
          </KeyValueContainer>

          <KeyValueContainer>
            <Keys>{t("sdashboard.profile.university.grade")}</Keys>
            {!editActive ? (
              <Value>
                {userInfo.grade
                  ? userInfo.grade.value
                  : t("sdashboard.profile.not_provided")}
              </Value>
            ) : (
              <EditSelect
                text={t("sdashboard.profile.university.form.grade_placeholder")}
                onChange={(selectedItem) => {
                  setGrade(selectedItem);
                }}
                value={grade}
                options={gradeOptions}
              />
            )}
          </KeyValueContainer>
        </DetailsContainer>
      </form>
    </Container>
  );
}

export default StudyAndUniversityInfo;
