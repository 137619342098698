import { useEffect, useState } from "react";
import CustomHeading from "@lib/microComponents/CustomHeading";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// import Cookies from "js-cookie";
import styled from "styled-components";
import FormSubmitButton from "@components/LogInPages/commonComponents/FormSubmitButton";
import BaseLayout from "@components/LogInPages/BaseLayout";
import FormInput from "@components/LogInPages/commonComponents/FormInput";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { object, ref, string } from "yup";
import { ReactToast } from "@lib/toast";
import { changePasswordFromToken } from "@API/services/Auth/main";
// import { loginWithPassword } from "api/services/Auth";
// start of styled-components

const FormContainer = styled.div`
  max-width: 35.75rem;
  margin: auto;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  width: 100%;
`;
const SignInForm = styled.form`
  background: white;
  margin-top: 3.5rem;
  display: flex;
  flex-direction: column;
  font-weight: 600;
`;
const FormLabel = styled.label`
  color: ${(props) => props.theme.colors.gray[800]};
  font-size: 0.9rem;
  font-family: ${(props) => props.theme.font.font};
`;

const ForgotPasswordLink = styled(Link)`
  font-size: 0.8rem;
  text-decoration: underline;
  margin-top: 1.1rem;
  font-weight: 400;
  color: ${(props) => props.theme.colors.gray[700]};
  font-family: ${(props) => props.theme.font.font};
`;
const Divider = styled.div`
  margin: 1.6rem 0rem;
  height: 2px;
  background: #eaefec;
`;
const SignUpLinkContainer = styled.div`
  margin: auto;
  margin-top: 2.5rem;
  color: ${(props) => props.theme.colors.gray[800]};

  font-size: 0.82rem;
  font-weight: 700;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SignUpLink = styled(Link)`
  color: #355f44;
  font-weight: 400;
  font-family: ${(props) => props.theme.font.font};
`;

// end of styled-components

const NEW_PASSWORD = "new password";
const CONFIRM_PASSWORD = "confirm password";
interface initialvaluesInterface {
  [NEW_PASSWORD]: string;
  [CONFIRM_PASSWORD]: string;
}
const initialValues: initialvaluesInterface = {
  [NEW_PASSWORD]: "",
  [CONFIRM_PASSWORD]: "",
};
function ChangePassword() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { lang } = useParams();
  const [searchParams, _] = useSearchParams();
  const token = searchParams.get("token");
  useEffect(() => {
    if (!token) {
      if (lang) {
        ReactToast("There was an error. redirecting to sign in page");
        navigate(`/${lang}/signin`);
      }
    }
  }, [lang]);
  const formik = useFormik({
    initialValues,
    validationSchema: object({
      [NEW_PASSWORD]: string()
        .required()
        .min(6, "password should be atleast 6 characters"),
      [CONFIRM_PASSWORD]: string()
        .oneOf([ref(NEW_PASSWORD)], "Password must match")
        .required("confirm password is required"),
    }),
    onSubmit: async (values, actions) => {
      if (!token) return;
      setLoading(true);
      const res = await changePasswordFromToken(values[NEW_PASSWORD], token);
      if (res) {
        ReactToast("Password changed");
        actions.resetForm();
        navigate(`/${lang}/signin`);
      } else {
        ReactToast("Couldn't change password");
      }
      actions.setSubmitting(false);
      setLoading(false);
    },
  });

  return (
    <BaseLayout>
      <FormContainer>
        <CustomHeading sx={{ marginTop: "1.5rem" }}>
          Choose new password
        </CustomHeading>
        <SignInForm onSubmit={formik.handleSubmit}>
          <FormLabel htmlFor="new-password">New password</FormLabel>
          <FormInput
            type="password"
            autoComplete="off"
            id="new-password"
            style={{ letterSpacing: "0.05rem" }}
            placeholder="••••••••••••••••••••"
            {...formik.getFieldProps(NEW_PASSWORD)}
            disabled={loading}
          />
          <FormLabel htmlFor="confirm-password" style={{ marginTop: "1.4rem" }}>
            Confirm new password
          </FormLabel>
          <FormInput
            type="password"
            autoComplete="off"
            id="confirm-password"
            style={{ letterSpacing: "0.05rem" }}
            placeholder="••••••••••••••••••••"
            {...formik.getFieldProps(CONFIRM_PASSWORD)}
            disabled={loading}
          />
          <FormSubmitButton loading={loading}>Change password</FormSubmitButton>
        </SignInForm>
      </FormContainer>
    </BaseLayout>
  );
}

export default ChangePassword;
