import Header from "./Header";
import SideBar from "./SideBar";
import styled from "styled-components";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { DashBoardNavigationArrayItemType } from "./Types";
import { device, mixin } from "@styles/theme";
import SearchBar from "./Header/SearchBar";

const Container = styled.div`
  height: 100dvh;
  width: 100vw;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div``;
const SideBarAndBodyContainer = styled.div`
  display: flex;
  background: ${(props) => props.theme.colors.gray[500]};
  flex-grow: 1;
  height: calc(100dvh - 5.1875rem);

  @media ${device.tablet} {
    height: calc(100dvh - 5.1875rem - 3.8125rem);
    flex-direction: column-reverse;
  }
`;
const SideBarContainer = styled.div`
  width: 20.1875rem;
  min-width: 20.1875rem;
  background: #ffffff;
  @media ${device.laptopL} {
    width: 17rem;
    min-width: 17rem;
  }
  @media ${device.laptopM} {
    min-width: 6.625rem;
    width: 6.625rem;
  }
  @media ${device.tablet} {
    width: 100%;
  }
`;
interface SearchBarContainerProps {
  $hidden: boolean;
}
const SearchInputContainer2 = styled.div<SearchBarContainerProps>`
  display: none;
  flex-grow: 1;
  background: ${(props) => props.theme.colors.gray[500]};
  @media ${device.tablet} {
    display: ${(props) => (props.$hidden ? "none" : "flex")};
  }
`;
const BodyContainer = styled.div`
  flex-grow: 1;
  min-height: 0;
  display: flex;
  height: 100%;
  flex-direction: column;
  ${mixin.hideScroll()}
  overflow-y: scroll;
`;
const BodyInnerContainer = styled.div`
  flex-grow: 1;
  min-height: 0;
  padding: 3.125rem;
  overflow-y: scroll;
  ${mixin.hideScroll()}
  @media ${device.laptopL} {
    padding: 1.25rem;
  }
`;

function Layout({
  DashBoardNavigationArray,
}: {
  DashBoardNavigationArray: DashBoardNavigationArrayItemType[];
}) {
  const { pathname } = useLocation();
  const arrayOfRoutesForHidingSearchBar = ["details/chat"];
  function searchBarIsHidden() {
    let isHidden = false;
    for (let i = 0; i < arrayOfRoutesForHidingSearchBar.length; i++) {
      if (pathname.includes(arrayOfRoutesForHidingSearchBar[i])) {
        return true;
      }
    }
    return false;
  }
  return (
    <Container>
      <HeaderContainer>
        <Header />
      </HeaderContainer>
      <SideBarAndBodyContainer>
        <SideBarContainer>
          <SideBar DashboardNavigationArray={DashBoardNavigationArray} />
        </SideBarContainer>
        <BodyContainer>
          <SearchInputContainer2 $hidden={searchBarIsHidden()}>
            <SearchBar />
          </SearchInputContainer2>
          <BodyInnerContainer>
            <Outlet />
          </BodyInnerContainer>
        </BodyContainer>
      </SideBarAndBodyContainer>
    </Container>
  );
}

export default Layout;
