import React, { useEffect, useState } from "react";
import StudentTable from "@components/AdminDashboard/SubPages/Orders/StudentTable";
import { getStudentOrders } from "@API/services/Order/getStudentOrders";
import { ordersData } from "@components/AdminDashboard/SubPages/Orders/AdminOrders";
import styled from "styled-components";
import Select from "@lib/microComponents/Select";
import Search from "@lib/microComponents/Search";
import { device, mixin } from "@styles/theme";
import { getLanguageInfo } from "@lib/Enums/Language";
import { convertCurrency } from "@lib/util/main";
import { useTranslation } from "react-i18next";
import OrdersTable from "./StudentOrdersTable";
// styled.components ----
const Container = styled.div``;
const Heading = styled.h2``;
const DesktopViewComponent = styled.div`
  overflow-x: scroll;
  ${mixin.hideScroll()}
  @media ${device.tablet} {
    display: none;
  }
`;
const MobileViewComponent = styled.div`
  display: none;
  background: #ffffff;
  border-radius: 10px;
  min-height: 100%;
  padding: 1.5rem 1.25rem;
  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }
  :last-child {
    border-bottom: none;
  }
`;
const OrdersHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 1.875rem;
  @media ${device.laptopL} {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    margin-bottom: 0.9375rem;
  }
`;
const OrdersHeading = styled.h2`
  line-height: 1.9375rem;
  font-size: 1.375rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.gray[800]};
  align-self: last baseline;
`;
const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
  @media ${device.laptop} {
    display: none;
  }
`;
const StudentTableStyled = styled(StudentTable)`
  min-width: 1130px;
`;
const StyledSelect = styled(Select)``;
const StyledSearch = styled(Search)`
  max-width: 15rem;
`;
const MobileViewComponentContainer = styled.div`
  padding-bottom: 1.25rem;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray[500]};
`;
const DateContainer = styled.div`
  color: ${(props) => props.theme.colors.gray[600]};
  background: ${device.tablet};
`;
const CourseDetailsContainer = styled.div`
  margin-top: 0.125rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const TagsContainer = styled.div`
  margin-top: 0.5rem;
  display: flex;
  gap: 0.3125rem;
  font-size: 0.75rem;
  line-height: 1.0625rem;
  flex-wrap: wrap;
`;
const StatusTag = styled.div`
  padding: 0.1875rem 1.6875rem;
  background: ${(props) => props.theme.colors.green[600]}40;
  border-radius: 10px;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const PriceTag = styled.div`
  padding: 0.1875rem 1.0625rem;
  border-radius: 10px;
  color: ${(props) => props.theme.colors.gray[800]};
  background: ${(props) => props.theme.colors.gray[500]};
`;
const OrderNumberTag = styled(PriceTag)`
  padding: 0.1875rem 0.8125rem;
`;
// ----------------------
function StudentOrders() {
  const [StudentOrderData, SetStudentOrdersData] = useState<ordersData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const getOrderDataFunction = async () => {
    setLoading(true);
    const res = await getStudentOrders();
    if (res) {
      SetStudentOrdersData(res);
    }
    setLoading(false);
  };
  useEffect(() => {
    getOrderDataFunction();
  }, []);
  return (
    <Container>
      <OrdersHeader>
        <OrdersHeading>{t("sdashboard.orders.heading")}</OrdersHeading>
        {/* <FilterContainer>
          <StyledSelect
            text="Select a university"
            onChange={(e) => {}}
            value={undefined}
            options={[]}
          />
          <StyledSelect
            text="Select a study"
            onChange={(e) => {}}
            value={undefined}
            options={[]}
          />
          <StyledSearch onChange={() => {}} placeholder="Search in orders" />
        </FilterContainer> */}
      </OrdersHeader>
      <DesktopViewComponent>
        <OrdersTable loading={loading} items={StudentOrderData}></OrdersTable>
      </DesktopViewComponent>
      <MobileViewComponent>
        {StudentOrderData.map((item) => {
          return (
            <MobileViewComponentContainer>
              <DateContainer>{item.Date}</DateContainer>
              <CourseDetailsContainer>
                {item.study}-{item.subject}-
                {getLanguageInfo(item.language).name}
              </CourseDetailsContainer>
              <TagsContainer>
                <StatusTag>{item.Status}</StatusTag>
                <PriceTag>{item.amount}</PriceTag>
                <OrderNumberTag>{item.akcOrderId}</OrderNumberTag>
              </TagsContainer>
            </MobileViewComponentContainer>
          );
        })}
      </MobileViewComponent>
    </Container>
  );
}

export default StudentOrders;
