import Header from "@components/CommonDashboard/Header";
import React, { ReactNode } from "react";
import styled from "styled-components";
import { device } from "@styles/theme";
import SearchBar from "@components/CommonDashboard/Header/SearchBar";

// styled-components----
const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.gray[500]};
`;
const HeaderContainer = styled.div``;
const PageContainer = styled.div`
  position: relative;
  height: calc(100dvh - 5.1875rem);
  @media ${device.tablet} {
    height: calc(100dvh - 5.1875rem);
  }
`;

const LineBreak = styled.div`
  height: 2px;
  background: ${(props) => props.theme.colors.gray[500]};
`;
// ---------------------

function Layout({
  children,
  isCheckout,
}: {
  children: ReactNode;
  isCheckout: boolean;
}) {
  return (
    <Container>
      {!isCheckout && (
        <div>
          <HeaderContainer>
            <Header />
          </HeaderContainer>
          <LineBreak />
        </div>
      )}
      <PageContainer>{children}</PageContainer>
    </Container>
  );
}

export default Layout;
