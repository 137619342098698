import { AdminOrdersDataResponseType } from "@lib/Types/API/APIOrderDetails";
import { mapAdminOrders } from "@lib/mapperFunctions/mapAPIAdminOrders";
import { ordersData } from "@components/AdminDashboard/SubPages/Orders/AdminOrders";
import { urlFunctions } from "@API/functions/createUrl";
import { API } from "@API/API";
import { UserRole } from "@app/redux/slices/userSlice";
import { optionType } from "@components/LandingPage/FormSelect";

type response =
  | {
      success: true;
      data: AdminOrdersDataResponseType;
    }
  | {
      success: false;
      data: null;
    };

export const getAdminOrders = async (
  role: UserRole | undefined,
  offset: number,
  size: number,
  search: string,
  university?: optionType,
  study?: optionType
): Promise<response> => {
  const sizeValue: number = 10;
  let url: string;
  if (role === UserRole.ADMIN) {
    url = urlFunctions.getAdminOrderItems({
      offset,
      size,
      university: university ? university.id : undefined,
      study: study ? study.id : undefined,
      search: search,
    });
  } else if (role === UserRole.MANAGER) {
    url = urlFunctions.getManagerOrderItems({
      offset,
      size,
      university: university ? university.id : undefined,
      study: study ? study.id : undefined,
      search: search,
    });
  } else {
    throw new Error("Not Authorised");
  }
  const repsonse = await API.sendGetRequest(url, true);
  if (repsonse.success) {
    const data: AdminOrdersDataResponseType = repsonse.data;
    return {
      success: true,
      data: data,
    };
  } else {
    return {
      success: false,
      data: null,
    };
  }
};
