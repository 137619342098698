import { useCallback, useEffect, useReducer, useRef, useState } from "react";

interface IParams<T> {
  key: string;
  fetcher: (pageNo: number) => Promise<{
    totalCount: number;
    items: T[];
  }>;
}

interface InitialState {
  loading: boolean;
  totalPages: number;
  pageNumber: number;
}

const initialState: InitialState = {
  loading: false,
  totalPages: 0,
  pageNumber: 0,
};

type ACTIONTYPE =
  | { type: "incrementPageNumber" }
  | { type: "setTotalPages"; payload: number }
  | { type: "changeLoadingStatus"; payload: boolean }
  | { type: "setPageNumber"; payload: number };

function reducer(state: InitialState, action: ACTIONTYPE): InitialState {
  switch (action.type) {
    case "incrementPageNumber":
      // return { ...state, pageNumber: state.pageNumber + 1 };
      // console.log("incrementing", state.pageNumber);
      console.log("this runs");
      const newPageNumber = state.pageNumber + 1;
      state.pageNumber = newPageNumber;
      // console.log("incremented", state.pageNumber);
      return state;
    case "setPageNumber":
      state.pageNumber = action.payload;
      return state;
    case "setTotalPages":
      // return { ...state, totalPages: action.payload };
      state.totalPages = action.payload;
      return state;
    case "changeLoadingStatus":
      // return { ...state, loading: action.payload };
      state.loading = action.payload;
      return state;
    default:
      return state;
  }
}

function useInfiniteScrolling<K>(
  params: IParams<K>
): [boolean, K[], (element: HTMLElement | null) => void, () => void] {
  const [error, setError] = useState<string>("");
  const observer = useRef<IntersectionObserver>();
  const [itemsCache, setItemsCache] = useState<K[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [state, dispatch] = useReducer(reducer, initialState);
  const { fetcher, key } = params;

  async function fetchAdditionalItems() {
    if (state.loading) return;
    const hasMore = state.pageNumber < state.totalPages - 1;
    if (!hasMore) return;
    setLoading(true);
    dispatch({ type: "changeLoadingStatus", payload: true });
    try {
      const { items } = await fetcher(state.pageNumber + 1);
      setItemsCache((oldItems) => {
        return [...oldItems, ...items];
      });
      setLoading(false);
      dispatch({ type: "incrementPageNumber" });
      dispatch({ type: "changeLoadingStatus", payload: false });
    } catch (e) {
      console.error(e);
      setLoading(false);
      dispatch({ type: "changeLoadingStatus", payload: false });
      setError("Error in getting data");
    }
  }

  async function fetchInitialItems() {
    dispatch({ type: "changeLoadingStatus", payload: true });
    setLoading(true);
    try {
      const { totalCount, items } = await fetcher(0);
      dispatch({ type: "setTotalPages", payload: Math.ceil(totalCount / 10) });
      setItemsCache(items);
      setLoading(false);
      dispatch({ type: "changeLoadingStatus", payload: false });
    } catch (e) {
      setLoading(false);
      dispatch({ type: "changeLoadingStatus", payload: false });
      setError("Error in getting data");
    }
  }

  function reset() {
    setLoading(false);
    dispatch({ type: "changeLoadingStatus", payload: false });
    setError("");
    dispatch({ type: "setTotalPages", payload: 0 });
    dispatch({ type: "setPageNumber", payload: 0 });
    observer.current = undefined;
    setItemsCache([]);
    fetchInitialItems();
  }
  useEffect(() => {
    reset();
  }, [key]);

  // console.log("states outside", loading, pageNumber, totalPages);

  useEffect(() => {
    fetchInitialItems();
  }, []);

  const lastElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (node) {
        if (observer.current) {
          observer.current.disconnect();
        }
        observer.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting) {
            fetchAdditionalItems();
          }
        });
        observer.current.observe(node);
      }
    },
    [key]
  );

  console.log([loading, itemsCache, lastElementRef, reset]);

  return [loading, itemsCache, lastElementRef, reset];
}

export default useInfiniteScrolling;
