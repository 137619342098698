import { getCourseDetails } from "@API/services/Data/getCourseDetails";
import { mapCourseResopnseToEditForm } from "@lib/mapperFunctions/mapCourseResopnseToEditForm";
import { ReactToast } from "@lib/toast";
import { FormikConfig, FormikProps, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { initialValues } from "./formInitialValues";
import { formikConfig } from "./formInitialValues";
import validationSchema, { MANAGER } from "./validationSchema";

export type Formik = FormikProps<formikConfig["initialValues"]>;

import { useEffect, useState } from "react";
import { editCourse } from "@API/services/CourseCRUD/editCourse";
import { createCourse } from "@API/services/CourseCRUD/createCourse";
import { CourseStatus } from "@lib/Enums/CourseStatus";
import { useSelector } from "react-redux";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { AddCourseFormSubmitDataType } from "@lib/Types/AddCourseFormSubmitType";
import { AddCourseManagerListOptionType } from "./Components/GeneralDetails/ManagerSelector";
import { assetsUrls } from "@styles/theme";

export function useFormikInstance(
  courseId: string | undefined
): [boolean, Formik, boolean | undefined] {
  const navigate = useNavigate();
  const formik = useFormik(getFormikConfig(initialValues));
  const [loading, setLoading] = useState<boolean>(true);
  const userInfo = useSelector(selectUser);
  const [alreadyPublished, setAlreadyPublished] = useState<boolean | undefined>(
    undefined
  );
  useEffect(() => {
    setInitialValues();
  }, []);
  function getFormikConfig(initialValue: formikConfig["initialValues"]) {
    const formikConfig: FormikConfig<formikConfig["initialValues"]> = {
      initialValues: initialValue,

      onSubmit: async (data) => {
        if (!courseId) {
          //@ts-ignore
          const res = await createCourse(data, CourseStatus.PUBLISH);
          if (res.success) {
            ReactToast("Course created Successfully");
            navigate("/admin-dashboard/courses");
          } else {
            ReactToast(res.data);
          }
        } else {
          //@ts-ignore
          const res = await editCourse(data, courseId, CourseStatus.PUBLISH);
          if (res.success) {
            ReactToast("Course Updated Successfully");
            navigate("/admin-dashboard/courses");
          } else {
            ReactToast(res.data);
          }
        }
      },
      validationSchema,
    };
    return formikConfig;
  }
  async function setInitialValues() {
    if (!userInfo.loggedIn || !userInfo.userId || !userInfo.name) {
      throw new Error("Unauthorized");
    }
    setLoading(true);
    if (courseId) {
      const res = await getCourseDetails(courseId);
      if (res.success) {
        if (res.data.course.status === CourseStatus.PUBLISH) {
          setAlreadyPublished(true);
        } else {
          setAlreadyPublished(false);
        }
        const formattedData = mapCourseResopnseToEditForm(res.data);
        formik.setValues(formattedData);
      } else {
        ReactToast("Could not get course data");
      }
    } else {
      const managerObject: AddCourseManagerListOptionType = {
        id: userInfo.userId,
        value: userInfo.name,
        profilePicUrl: userInfo.profileUrl
          ? userInfo.profileUrl
          : assetsUrls.userPng,
      };
      formik.setFieldValue(MANAGER, managerObject);
    }
    setLoading(false);
  }

  return [loading, formik, alreadyPublished];
}
