import {
  toast,
  ToastContainer,
  ToastOptions,
  ToastPosition,
} from "react-toastify";

export const ReactToast = (message: string, options?: ToastOptions) => {
  return toast(message);
};
ReactToast.error = (message: string, options?: ToastOptions) => {
  return toast.error(message, options);
};
ReactToast.success = (message: string, options?: ToastOptions) => {
  return toast.success(message, options);
};
export const ReactToastContainer = ToastContainer;
