import { assetsUrls } from "@styles/theme";
import BaseLayout from "../BaseLayout";
import styled from "styled-components";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const Image = styled.img`
  width: 15.25rem;
  height: 14.75rem;
`;
const Heading = styled.div`
  margin-top: 2.5rem;
  font-size: 1.5625rem;
  line-height: 2.1875rem;
  font-weight: 700;
  text-align: center;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const SubHeading = styled.div`
  font-size: 1.125rem;
  line-height: 1.5625rem;
  color: ${(props) => props.theme.colors.gray[800]};
  max-width: 30.5625rem;
  text-align: center;
  margin-top: 0.875rem;
`;
function VerifyAccount() {
  const { state } = useLocation();
  const email = state ? state.email : "";
  const navigate = useNavigate();
  if (!email) {
    navigate("/signin");
  }
  return (
    <BaseLayout>
      <Container>
        <Image src={assetsUrls.goal}></Image>
        <Heading>Please verify your e-mail..</Heading>
        <SubHeading>
          We have sent an e-mail to {email}. Please click the link in this mail
          to verify your account.
        </SubHeading>
      </Container>
    </BaseLayout>
  );
}

export default VerifyAccount;
