import { UserRole, userInfo } from "@app/redux/slices/userSlice";
import { StudentData } from "@components/AdminDashboard/SubPages/Students/StudentTable";
import { AdminStudentResponse } from "@lib/Types/API/APIAdminStudents";
import { assetsUrls } from "@styles/theme";
import { v4 as uuid } from "uuid";
export function mapAdminStudentsListToProfileComponent(
  data: StudentData
): userInfo {
  const item = data;
  const mappedData: userInfo = {
    key: new Date().getTime(),
    loggedIn: false,
    role: UserRole.STUDENT,
    userId: item.studentId,
    name: item.firstName + " " + item.lastName,
    univversityLogo: undefined,
    profileUrl: assetsUrls.userPng2,
    email: item.email,
    phone: undefined,
    address: undefined,
    country: undefined,
    city: undefined,
    zipcode: undefined,
    dateOfBirth: undefined,
    language: undefined,
    university: { id: uuid(), value: item.university },
    study: undefined,
    grade: { id: uuid(), value: item.grade },
    smartPoints: undefined,
    universityStudyAddedForTheFirstTime: undefined,
    infoAddedForTheFirstTime: undefined,
    courseOrderedForTheFirstTime: undefined,
    courseJoinedFortTheFirstTime: undefined,
    verified: undefined,
  };
  return mappedData;
}
