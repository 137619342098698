import { LegacyRef, ReactNode, forwardRef } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

// styled-components----
const Container = styled.tr`
  width: 100%;
`;

// ---------------------

const TableRow = forwardRef(function (
  {
    children,
    ...props
  }: {
    children: ReactNode;
  },
  ref: LegacyRef<HTMLTableRowElement>
) {
  return (
    <Container ref={ref} {...props}>
      {children}
    </Container>
  );
});
export default TableRow;
