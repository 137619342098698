import { createUniversity } from "@API/services/EntitiesCrud";
import { UserRole } from "@app/redux/slices/userSlice";
import {
  Label,
  SettingsInput,
  SettingsSubmitButton,
} from "@components/AdminDashboard/SubPages/Settings/commonComponents/FormComponents";
import Select from "@lib/microComponents/Select";
import { ReactToast } from "@lib/toast";
import { useFormik } from "formik";
import { useState } from "react";
import styled from "styled-components";
import { object, string } from "yup";
import SettingsSelect from "../../commonComponents/SettingsSelect";
import { useUniversityOptions } from "@lib/hooks/useUniversityOptions";
import { searchOption } from "@lib/util/main";
import { inviteTeamMember } from "@API/services/Admin/inviteTeamMember";
import { optionType } from "@components/LandingPage/FormSelect";
import { device } from "@styles/theme";

// styled-components ----
const Container = styled.div`
  margin-top: 1.875rem;
  display: flex;
  flex-direction: column;
  padding: 1.875rem;
  padding-top: 0rem;
  @media ${device.tablet} {
    padding-left: 0.2rem;
  }
`;
const Form = styled.form``;
const IconLabel = styled(Label)`
  margin-top: 1.25rem;
`;
const RoleLabel = styled(Label)`
  display: block;
  margin-top: 1.25rem;
`;
const UniversitySubmitButton = styled(SettingsSubmitButton)`
  margin-top: 5rem;
`;
const RoleSelect = styled(SettingsSelect)`
  margin-top: 0.625rem;
`;
const EmailInput = styled(SettingsInput)`
  margin-top: 0.625rem;
`;
const AccessToLabel = styled(Label)`
  margin-top: 1.875rem;
  display: block;
`;
const AccessToSelect = styled(SettingsSelect)`
  margin-top: 0.625rem;
`;
const InputContainer = styled.div``;
const Error = styled.div`
  font-size: 0.9rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.red[500]};
  margin-top: 0.5rem;
`;
const EmailLabel = styled(Label)`
  margin-top: 1.25rem;
`;
// ----------------------

const roleOptions = [{ id: UserRole.TEAHCER, value: "Teacher" }];

const FIRST_NAME = "first-name";
const LAST_NAME = "last-name";
const EMAIL = "email";
const ROLE = "role";
const ACCESS = "access";
interface initialvaluesInterface {
  [FIRST_NAME]: string;
  [LAST_NAME]: string;
  [EMAIL]: string;
}
const initialValues: initialvaluesInterface = {
  [FIRST_NAME]: "",
  [LAST_NAME]: "",
  [EMAIL]: "",
};
function AddMembersForm() {
  const universityOptions = useUniversityOptions();
  const formik = useFormik({
    initialValues,
    validationSchema: object({
      [FIRST_NAME]: string().required("First name field is required"),
      [LAST_NAME]: string().required("Last name field is required"),
      [EMAIL]: string()
        .email("must be a valid email")
        .required("email field is required"),
    }),
    onSubmit: async (values, actions) => {
      if (!values[EMAIL]) {
        ReactToast("There is some error on client side");
        return;
      }
      const res = await inviteTeamMember(
        `${values[FIRST_NAME]} ${values[LAST_NAME]}`,
        values[EMAIL],
        [0],
        UserRole.TEAHCER
      );
      if (res) {
        ReactToast.success("Invite sent");
        actions.resetForm();
      } else {
        ReactToast("Couldn't send invite");
      }
      actions.setSubmitting(false);
    },
  });

  return (
    <Container>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <Label>First name</Label>
        <InputContainer>
          <EmailInput
            placeholder="First name"
            {...formik.getFieldProps(FIRST_NAME)}
          />
          {formik.touched[FIRST_NAME] && formik.errors[FIRST_NAME] && (
            <Error>{formik.errors[LAST_NAME]}</Error>
          )}
        </InputContainer>
        <EmailLabel>Last name</EmailLabel>
        <InputContainer>
          <EmailInput
            placeholder="Last name"
            {...formik.getFieldProps(LAST_NAME)}
          />
          {formik.touched[LAST_NAME] && formik.errors[LAST_NAME] && (
            <Error>{formik.errors[LAST_NAME]}</Error>
          )}
        </InputContainer>
        <EmailLabel>Email</EmailLabel>
        <InputContainer>
          <EmailInput placeholder="Email" {...formik.getFieldProps(EMAIL)} />
          {formik.touched[EMAIL] && formik.errors[EMAIL] && (
            <Error>{formik.errors[EMAIL]}</Error>
          )}
        </InputContainer>

        <UniversitySubmitButton loading={formik.isSubmitting}>
          Invite the teacher
        </UniversitySubmitButton>
      </Form>
    </Container>
  );
}

export default AddMembersForm;
