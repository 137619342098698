import React from "react";
import styled from "styled-components";
import SmartPoints from "./SmartPoints";
import CourseHelp from "./CourseHelp";
import CourseRatingComponent from "./CourseRatingComponent";
import QaComponent from "./qa";
import { CourseDetailsType } from "../CourseDetailsType";
import { device } from "@styles/theme";
import { returnType } from "../../../../../lib/hooks/useCourseDetails";

// styled-components----
const Container = styled.div``;
const EmptyContainer = styled.div`
  height: 21.3125rem;
  background: transparent linear-gradient(180deg, #a8bace 0%, #8fa7c1 100%) 0%
    0% no-repeat padding-box;
  border-radius: 20px;
`;
const Divider = styled.div`
  height: 0.125rem;
  background: ${(props) => props.theme.colors.gray[600]};
  opacity: 0.35;
  margin: 2rem 0;
`;
const CourseHelpAndRatingComponent = styled.div`
  padding: 0 1.875rem;
  @media ${device.desktopXS} {
    padding: 0;
    margin-top: 2.6875rem;
  }
`;

const FlexContainer = styled.div`
  grid-area: c;
  display: flex;
  flex-direction: column;
`;
// ---------------------

function CourseExtraInfo({ details }: { details: returnType<true | false> }) {
  return (
    <>
      <SmartPoints details={details} />
      <FlexContainer>
        <QaComponent />
        <CourseHelpAndRatingComponent>
          <CourseHelp />
          <Divider />
          <CourseRatingComponent details={details} />
        </CourseHelpAndRatingComponent>
      </FlexContainer>
    </>
  );
}

export default CourseExtraInfo;
