import {
  Table,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableDataCell,
} from "@lib/microComponents/Table";
import { StudentDataType } from "@lib/testData/mockStudentsData";
import { ordersData } from "@components/AdminDashboard/SubPages/Orders/AdminOrders";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Dispatch, SetStateAction, useCallback, useRef } from "react";

// styled-components----
const Container = styled.div`
  background: #ffffff;
  border-radius: 20px;
  padding: 1.875rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const ProsuctTableCell = styled(TableDataCell)`
  font-weight: 700;
`;
const NameTableCell = styled(TableDataCell)`
  font-weight: 700;
`;
interface OrderStatus {
  $name: string;
}
const UniversityTableCell = styled(TableDataCell)``;
const StudyTableCell = styled(TableDataCell)``;
const DetailsTableCell = styled(TableDataCell)``;
export const DetailsLink = styled.div<OrderStatus>`
  height: 2.1875rem;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.gray[800]};
  border-radius: 10px;
  background: ${(props) =>
    props.$name == "COMPLETED" ? "#36CC1C26" : props.theme.colors.gray[500]};
`;
const skeletonLoading = keyframes`
    0% {
      background: hsl(200,20%,70%);
    }
    100% {
      background: hsl(200,20%,95%)
    }
  `;
const LoadingComponent = styled.div`
  width: 100%;
  height: 2.1875rem;
  animation: ${skeletonLoading} 1s linear infinite alternate;
`;
// ---------------------

function TableSkeleton() {
  return (
    <TableRow>
      <ProsuctTableCell>
        <LoadingComponent>&nbsp;</LoadingComponent>
      </ProsuctTableCell>
      <NameTableCell>
        <LoadingComponent>&nbsp;</LoadingComponent>
      </NameTableCell>
      <UniversityTableCell>
        <LoadingComponent>&nbsp;</LoadingComponent>
      </UniversityTableCell>
      <StudyTableCell>
        <LoadingComponent>&nbsp;</LoadingComponent>
      </StudyTableCell>
      <DetailsTableCell>
        <LoadingComponent>&nbsp;</LoadingComponent>
      </DetailsTableCell>
    </TableRow>
  );
}

function StudentOrdersTable({
  items,
  loading,
  ...props
}: {
  items: ordersData[];

  loading: boolean;
}) {
  const { t } = useTranslation();
  const { lang } = useParams();

  return (
    <Container {...props}>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>
              {t("sdashboard.orders.table.product")}
            </TableHeaderCell>
            {items.length > 0 && items[0].name && (
              <TableHeaderCell> {t("adminOrders.name")}</TableHeaderCell>
            )}

            <TableHeaderCell>
              {t("sdashboard.orders.table.number")}
            </TableHeaderCell>
            <TableHeaderCell>
              {t("sdashboard.orders.table.date")}
            </TableHeaderCell>
            <TableHeaderCell>
              {t("sdashboard.orders.table.amount")}
            </TableHeaderCell>
            <TableHeaderCell>
              {t("sdashboard.orders.table.status")}
            </TableHeaderCell>
            <TableHeaderCell>
              <div></div>
            </TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {loading
            ? Array.from({ length: 10 }).map((e) => <TableSkeleton />)
            : items.map((e, index) => (
                <TableRow>
                  <ProsuctTableCell>
                    {e.subject} - {e.study} -{e.language}
                  </ProsuctTableCell>
                  {e.name && <NameTableCell>{e.name}</NameTableCell>}
                  <UniversityTableCell>{e.akcOrderId}</UniversityTableCell>
                  <StudyTableCell>{e.Date}</StudyTableCell>
                  <StudyTableCell>{e.amount}</StudyTableCell>
                  <DetailsTableCell>
                    <DetailsLink $name={e.Status}>{e.Status}</DetailsLink>
                  </DetailsTableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </Container>
  );
}
export default StudentOrdersTable;
