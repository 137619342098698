import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";
import { Message } from "@components/CommonComponents/Chat/ChatsComponent";
import { IncomingMessage } from "@lib/Types/Chat/IncomingMessage";
import { mapMessageToComponent } from "@lib/mapperFunctions/Chat/mapMessageToComponent";

type AdminStudyListType =
  | {
      success: true;
    }
  | {
      success: false;
      error: string;
    };

export const reactOnMessage = async function (
  messageId: string,
  unicode: string
): Promise<AdminStudyListType> {
  const url = urlFunctions.reactOnMessage(messageId, unicode);
  const res = await API.sendGetRequest(url);
  if (res.success) {
    const data: IncomingMessage[] = res.data;
    return {
      success: true,
    };
  } else {
    return {
      success: false,
      error: "There was an error in reacting on a message",
    };
  }
};
