// export const BASEURI = "http://localhost:8080";
// export const FRONTEND_URI = "http://localhost:3000";
// export const BASEURI = "https://akc-be-api-9b1da8cbae99.herokuapp.com";
// export const FRONTEND_URI = "https://akc.pages.dev";

let BASEURI: string;
let FRONTEND_URI: string;
if (process.env.NODE_ENV === "production") {
  BASEURI = process.env.AKC_API_HOST ? process.env.AKC_API_HOST : "";
  FRONTEND_URI = process.env.FRONTEND_HOST ? process.env.FRONTEND_HOST : "";
} else {
  BASEURI = process.env.AKC_API_HOST ? process.env.AKC_API_HOST : "";
  FRONTEND_URI = "http://localhost:3000";
}
export { BASEURI, FRONTEND_URI };
