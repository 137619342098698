import Chat from "@components/CommonComponents/Chat";
import { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

// styled.component -----
const Container = styled.div`
  height: 100%;
`;
// ----------------------

function StudentChat() {
  const { id } = useParams();
  const [error, setError] = useState<string>("");
  if (!id) {
    setError("There was an error");
  }
  return (
    <Container>
      {!error && id ? <Chat courseId={id} /> : <div>{error}</div>}
    </Container>
  );
}

export default StudentChat;
