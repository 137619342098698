import { device } from "@styles/theme";
import { Head } from "react-day-picker";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

// styled.components ----
const Container = styled.div`
  padding: 1.875rem;
  background: #ffffff;
  border-radius: 20px;
  @media ${device.tablet} {
    padding: 1.25rem;
    border-radius: 10px;
  }
`;
const HeadingContainer = styled.div``;
const Heading = styled.div`
  font-weight: 700;
  font-size: 1.375rem;
  line-height: 1.9375rem;
  color: ${(props) => props.theme.colors.gray[800]};
  @media ${device.tablet} {
    font-size: 1.125rem;
    line-height: 1.5625rem;
  }
`;
const Divider = styled.div`
  background: ${(props) => props.theme.colors.gray[500]};
  height: 2px;
  margin-top: 1.25rem;
  @media ${device.tablet} {
    margin-top: 1.25rem;
  }
`;
const SupportLink = styled.button`
  margin-top: 1.875rem;
  width: 100%;
  height: 2.6875rem;
  border-radius: 10px;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  font-weight: 700;
  color: #ffffff;
  background: ${(props) => props.theme.colors.gray[600]};
  @media ${device.tablet} {
    margin-top: 0.9375rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-radius: 5px;
    height: 2.0625rem;
  }
`;
// ----------------------

function PersonalHelp() {
  const navigate = useNavigate();
  return (
    <Container>
      <div>
        <HeadingContainer>
          <Heading>Get support</Heading>
        </HeadingContainer>
        <Divider />
      </div>
      <SupportLink
        onClick={() => {
          navigate("/dashboard/support");
        }}
      >
        Support
      </SupportLink>
    </Container>
  );
}

export default PersonalHelp;
