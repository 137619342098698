import { formikConfig } from "@components/AdminDashboard/SubPages/Courses/AddCourse/formInitialValues";
import { CourseStatus } from "@lib/Enums/CourseStatus";
import { getLanguageInfo } from "@lib/Enums/Language";
import { AddNewCourseApi } from "@lib/Types/API/APINewCoursePost";
import { SaveNewCourseApi } from "@lib/Types/API/APISaveNewCoursePost";
import { AddCourseFormSubmitDataType } from "@lib/Types/AddCourseFormSubmitType";
import { format } from "date-fns";
import { number } from "yup";

interface Schedule {
  name: string;
  date: string | null;
  "start-time": string | null;
  "end-time": string | null;
}

export function filterScheduleItems(schedules: Schedule[]): Schedule[] {
  return schedules.filter((item) => {
    if (!item.date || !item["start-time"] || !item["end-time"] || !item.name) {
      return false;
    } else {
      return true;
    }
  });
}

export function mapFormToAPIDrafted(
  formData: formikConfig["initialValues"]
): SaveNewCourseApi {
  const studyGuide = formData.studyGuideAvailable;
  return {
    name: formData.name,
    universityId: formData.university ? Number(formData.university.id) : null,
    studyId: formData.study ? Number(formData.study.id) : null,
    typeId: formData.event ? Number(formData.event.id) : null,
    gradeYearId: formData.gradeYear ? Number(formData.gradeYear.id) : null,
    subjectId: formData.subject ? Number(formData.subject.id) : null,
    language: formData.language
      ? getLanguageInfo(formData.language.id).sign
      : null,
    "available-spot":
      typeof formData.spots === "number" ? formData.spots : null,
    numberOfGroups:
      typeof formData.groups === "number" ? formData.groups : null,
    pricing: {
      original:
        typeof formData.originalPrice === "number"
          ? formData.originalPrice
          : null,
      sale: typeof formData.salePrice === "number" ? formData.salePrice : null,
    },
    "enrollment-start-date": "2023-07-29T10:00:00",
    "enrollment-end-date": "2023-07-29T10:00:00",
    status: CourseStatus.DRAFTED,
    schedule: filterScheduleItems(
      formData.schedule.map((item) => ({
        name: item.name,
        date: item.date ? format(item.date, "yyyy-MM-dd") : null,
        "start-time": item.startTime ? `${item.startTime}:00` : null,
        "end-time": item.endTime ? `${item.endTime}:00` : null,
      }))
    ),
    "teacher-id": formData.teacher ? formData.teacher.id : null,
    "manager-id": formData.manager ? formData.manager.id : null,
    "study-material": {
      available: formData.studyGuideAvailable,
      price: studyGuide
        ? typeof formData.guidePrice === "number"
          ? formData.guidePrice
          : null
        : null,
      file: studyGuide
        ? [
            {
              type: "DOCUMENT",
              name: "Getting started with Educational Sciences",
              link: formData.studyGuide ? formData.studyGuide : "",
            },
          ]
        : null,
      "available-from": studyGuide
        ? formData["available-from"]
          ? format(formData["available-from"], "yyyy-MM-dd")
          : null
        : null,
      "available-until": studyGuide
        ? formData["available-upto"]
          ? format(formData["available-upto"], "yyyy-MM-dd")
          : null
        : null,
      "sell-individually": studyGuide ? formData["sell-individually"] : null,
    },
    "smart-points": 2,
    description: {
      features: formData.features.map((item, index) => ({
        priority: index + 1,
        content: item.content,
      })),
      highlights: formData.highlights.map((item) => ({
        label: item.label,
        content: item.content,
      })),
      questionnaires: formData.questions.map((item) => ({
        question: item.question,
        answer: item.answer,
      })),
    },
    teacherUsps: {
      usp1: formData["teacher-usp-1"],
      usp2: formData["teacher-usp-2"],
    },
  };
}
