import { typeOfFile } from "@lib/Enums/FileType";
import { checkFileType } from "@lib/util/main";
import mime from "mime";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { IconType } from "react-icons";
import {
  FaCircleXmark,
  FaFileArrowUp,
  FaFileImage,
  FaFilePdf,
  FaFileVideo,
} from "react-icons/fa6";
import styled from "styled-components";

// styled.components
const Container = styled.div``;

const DropContainer = styled.div`
  margin-top: 0.625rem;
  height: 10rem;
  width: 100%;
  background: ${(props) => props.theme.colors.gray[400]};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
`;

const FileName = styled.div`
  font-size: 1.125rem;
  line-height: 1.5625rem;
  word-break: break-all;
  color: ${(props) => props.theme.colors.gray[700]};
`;

const FileInput = styled.input``;

const FileNameContainer = styled.div`
  border: 2px solid ${(props) => props.theme.colors.gray[500]};
  border-radius: 10px;
  padding: 10px;
  padding-right: 15px;
  margin-top: 0.625rem;
  display: flex;
  justify-content: space-between;
  background: ${(props) => props.theme.colors.gray[400]};
`;

const DropContainerText = styled.div`
  text-align: center;
  max-width: 15rem;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;

const Error = styled.div`
  font-size: 0.9rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.red[500]};
  margin-top: 0.5rem;
`;

const ImageUploadIcon = styled(FaFileImage)`
  width: 1.3rem;
  height: 2rem;
`;
const DocumentUploadIcon = styled(FaFilePdf)`
  width: 1.4rem;
  height: 2rem;
`;
const VideoUploadIcon = styled(FaFileVideo)`
  width: 1.3rem;
  height: 2rem;
`;

const AllFilesUploadIcon = styled(FaFileArrowUp)`
  width: 1.3rem;
  height: 2rem;
`;

const RemoveFileButton = styled.button`
  margin-left: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  background: none;
`;

const RemoveFileButtonIcon = styled(FaCircleXmark)`
  height: 1.5rem;
  width: 1.5rem;
  color: ${(props) => props.theme.colors.gray[500]};
`;
// -----------------

const IMAGE: typeOfFile = "image";

const DOCUMENT: typeOfFile = "document";

const VIDEO: typeOfFile = "video";

const BOTH: typeOfFile = "both";

const ALL: typeOfFile = "all";

const accepFileObjects: { [key in typeOfFile]: { [key: string]: string[] } } = {
  [IMAGE]: {
    "image/*": [".png", ".jpeg", ".jpg", ".svg"],
  },
  [DOCUMENT]: {
    "application/pdf": [".pdf"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
      ".docx",
    ],
  },
  [VIDEO]: {
    "video/mp4": [".mp4"],
    "video/mpeg": [".mpeg"],
  },
  [BOTH]: {
    "image/*": [".png", ".jpeg", ".jpg", ".svg"],
    "application/pdf": [".pdf"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
      ".docx",
    ],
  },
  [ALL]: {
    "video/mp4": [".mp4"],
    "video/mpeg": [".mpeg"],
    "image/*": [".png", ".jpeg", ".jpg", ".svg"],
    "application/pdf": [".pdf"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
      ".docx",
    ],
  },
};

const uploadIcon: { [key in typeOfFile]: IconType } = {
  [IMAGE]: ImageUploadIcon,
  [DOCUMENT]: DocumentUploadIcon,
  [BOTH]: AllFilesUploadIcon,
  [VIDEO]: VideoUploadIcon,
  [ALL]: AllFilesUploadIcon,
};
/// TO-DO
// setting defualt crop value

function Drop({
  fileType,
  onFileLoad,
  clearFile,
  fileSizeInMB,
}: {
  fileType: typeOfFile;
  onFileLoad: (blob: Blob, fileext: string) => void;
  clearFile: () => void;
  fileSizeInMB: number;
}) {
  const { t } = useTranslation();
  const [error, setError] = useState<string>("");
  const [file, setFile] = useState<File>();
  const Icon = uploadIcon[fileType];
  const fileSizeInBytes = fileSizeInMB * 1024 * 1024;
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (!acceptedFiles.length) return;
    const extension = checkFileType(acceptedFiles[0].name, fileType);
    if (!extension) {
      setError("wrong file type");
      return;
    }
    const size = acceptedFiles[0].size;
    if (size > fileSizeInBytes) {
      setError(`file size should be less than ${fileSizeInMB}MB`);
      return;
    }
    setFile(acceptedFiles[0]);
    acceptedFiles.forEach((file) => {
      const reader: FileReader = new FileReader();
      reader.onabort = () => setError("file reading was aborted");
      reader.onerror = () => setError("file reading has failed");
      reader.onload = async (event) => {
        if (!event.target) {
          setError("There was some error in reading files");
          return;
        }
        const buffer = event.target.result;
        const mimeType = mime.getType(acceptedFiles[0].name);
        if (!mimeType) {
          setError("could not get mime type");
          return;
        }
        if (buffer && typeof buffer !== "string") {
          const fileBlob = new Blob([buffer], {
            type: mimeType,
          });
          onFileLoad(fileBlob, extension ? extension : "");
        }
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
      accept: accepFileObjects[fileType],
      multiple: false,
    });
  return (
    <Container>
      <Error>{error}</Error>
      {!file && (
        <DropContainer {...getRootProps()}>
          <FileInput {...getInputProps()} />
          {isDragActive ? (
            isDragReject ? (
              <Error>wrong file type</Error>
            ) : (
              <DropContainerText>Drop the file here ...</DropContainerText>
            )
          ) : (
            <DropContainerText>
              <Icon />
              <br />
              {t("sdashboard.profile.editpic.modal.dndheading.part1")}
              <br />
              {t("sdashboard.profile.editpic.modal.dndheading.part2")}
            </DropContainerText>
          )}
        </DropContainer>
      )}

      {file && (
        <FileNameContainer>
          <FileName>{file.name}</FileName>
          <RemoveFileButton
            onClick={() => {
              setFile(undefined);
              clearFile();
            }}
          >
            <RemoveFileButtonIcon />
          </RemoveFileButton>
        </FileNameContainer>
      )}
    </Container>
  );
}

export default Drop;
