import { getPaymentIntent } from "@API/services/Payment/getPaymentIntent";
import { getPublicKey } from "@API/services/Payment/getPublicKey";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { IntentErrorType } from "@lib/Types/API/Payment/PaymentIntent";
import { sessionS } from "@lib/util/sessionDB";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

type PaymentIntentReturnType =
  | {
      success: true;
      data: string;
      promise: any;
    }
  | {
      success: false;
      data: undefined;
      promise: undefined;
    };

export type returnType<Loading extends boolean> = Loading extends true
  ? [true, undefined, undefined]
  : [false, PaymentIntentReturnType, IntentErrorType | undefined];

export function usePaymentIntent(
  courseId: string,
  guide: boolean
): returnType<true | false> {
  const userInfo = useSelector(selectUser);
  const [loading, setLoading] = useState<boolean>(true);
  const [paymentIntent, setPaymentIntent] = useState<PaymentIntentReturnType>();
  const [statusCode, setStatusCode] = useState<IntentErrorType>();
  async function setPaymentIntentAsync() {
    setLoading(true);
    const pKres = await getPublicKey();
    if (!pKres.success) {
      setPaymentIntent({
        success: false,
        data: undefined,
        promise: undefined,
      });
      setLoading(false);

      return;
    }
    const promise = await loadStripe(pKres.data);
    const intent = sessionS.getItem(
      `${guide ? "guideIntent" : "courseIntent"}.${courseId}`
    );
    if (intent.success) {
      setLoading(false);
      setPaymentIntent({
        success: true,
        data: intent.data.intent,
        promise: promise,
      });
      return;
    }
    const intentRes = await getPaymentIntent(courseId, guide);
    if (!intentRes.success) {
      setPaymentIntent({
        success: false,
        data: undefined,
        promise: undefined,
      });

      setLoading(false);

      return;
    }
    setStatusCode(intentRes.data.statusCode);
    if (intentRes.data.statusCode !== IntentErrorType.SUCCESS) {
      return;
    }
    const intentName = `${guide ? "guideIntent" : "courseIntent"}.${courseId}`;
    sessionS.setItem(intentName, { intent: intentRes.data.clientSecret }, 3600);
    setPaymentIntent({
      success: true,
      data: intentRes.data.clientSecret,
      promise,
    });
    setLoading(false);
  }
  useEffect(() => {
    setPaymentIntentAsync();
  }, [courseId]);
  return [loading, paymentIntent, statusCode] as returnType<true | false>;
}
