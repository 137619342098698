import styled from "styled-components";
import { getAdminOrders } from "@API/services/Order/getOrders";
import Select, { optionType } from "@lib/microComponents/Select";
import Search from "@lib/microComponents/Search";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { StudentDataType } from "@lib/testData/mockStudentsData";
import mockStudentsData from "@lib/testData/mockStudentsData";
import StudentTable from "./StudentTable";
import { ordersData } from "./AdminOrders";
import { useTranslation } from "react-i18next";
import { UserRole } from "@app/redux/slices/userSlice";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { useSelector } from "react-redux";
import { mapAdminOrders } from "@lib/mapperFunctions/mapAPIAdminOrders";
import { useUniversityOptions } from "@lib/hooks/useUniversityDetails";
import { useStudyOption } from "@lib/hooks/useStudyOptions";
import { useAdminUniversityOptions } from "@lib/hooks/useAdminUniversityOptions";
// styled-components----
const Container = styled.div``;
const OrdersHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const OrdersHeading = styled.h2`
  line-height: 1.9375rem;
  font-size: 1.375rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.gray[800]};
  align-self: last baseline;
`;
const FilterContainer = styled.div`
  display: flex;
  align-items: center;
`;
const StyledSelect = styled(Select)`
  margin-left: 1.25rem;
`;
const StyledSearch = styled(Search)`
  margin-left: 1.25rem;
`;

const StudentsTableContainer = styled.div`
  margin-top: 1.875rem;
`;

const PaginationContainer = styled.div`
  margin-top: 1.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NextButton = styled.button`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  padding: 0.5rem;
  background-color: ${(props) => props.theme.colors.gray[800]};
  border-radius: 0.25rem;
  text-decoration: none;
  color: #000;
  padding: 0.625rem 0.9375rem;
  float: left;
  line-height: 1.9375rem;
  font-size: 1.375rem;
  font-weight: 500;
  margin-left: 0.5rem;
`;

const PrevButton = styled.button`
  padding: 0.5rem;
  background-color: ${(props) => props.theme.colors.gray[800]};
  border-radius: 0.25rem;
  text-decoration: none;
  color: #000;
  padding: 10px 15px;
  float: left;
  line-height: 1.9375rem;
  font-size: 1.375rem;
  font-weight: 500;
  margin-right: 0.5rem;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

const PageText = styled.text`
  line-height: 1.9375rem;
  font-size: 1.375rem;
  font-weight: 500;
  color: #000000;
  align-self: last baseline;
`;

const PaginationBorder = styled.ul`
  margin: 0 auto;
  padding: 0;
  text-align: center;
`;
const PaginationComponent = styled.li`
  display: block;
  text-decoration: none;
  color: #000;
  padding: 10px 15px;
  border: 1px solid #000000;
  float: left;
  cursor: pointer;
  line-height: 1.9375rem;
  font-size: 1.375rem;
  font-weight: 500;
`;

const SelectedPaginationComponent = styled.li`
  display: block;
  text-decoration: none;
  color: #000;
  padding: 10px 15px;
  border: 1px solid #000000;
  float: left;
  background-color: ${(props) => props.theme.colors.gray[800]};
  color: #fff;
  line-height: 1.9375rem;
  font-size: 1.375rem;
  font-weight: 500;
`;

// ---------------------

function StudentsView() {
  const [OrdersData, setOrdersData] = useState<ordersData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const userInfo = useSelector(selectUser);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [pageNo, setPageNo] = useState<number>(0);
  const [loadingUniversity, universityOptions] = useAdminUniversityOptions();
  const [studyOptions, setStudyOptions] = useState<optionType[]>([]);
  const [university, setUniversity] = useState<optionType | null>();
  const [study, setStudy] = useState<optionType | null>();
  const [search, setSearch] = useState<string>("");

  async function getAndSetStudyOptions() {
    if (university) {
      const studyOptions = await useStudyOption(university.id);
      setStudyOptions(studyOptions);
    }
  }
  useEffect(() => {
    getAndSetStudyOptions();
  }, [university]);

  function onFilterDataChange({
    universitySelected,
    studySelected,
    searchEntered,
  }: {
    universitySelected?: optionType;
    studySelected?: optionType;
    searchEntered?: string;
  }) {
    setTotalPages(0);
    setPageNo(0);
    setOrdersData([]);
    getOrderDataFunction(
      0,
      true,
      universitySelected,
      studySelected,
      searchEntered
    );
  }
  useEffect(() => {
    getOrderDataFunction(
      pageNo,
      false,
      university ? university : undefined,
      study ? study : undefined,
      search
    );
  }, [pageNo]);

  async function getOrderDataFunction(
    pageNo: number,
    reset: boolean,
    university: optionType | undefined,
    study: optionType | undefined,
    search: string | undefined
  ) {
    if (reset) {
      setOrdersData([]);
    }
    setLoading(true);
    const res = await getAdminOrders(
      userInfo.role,
      pageNo,
      10,
      search ? search : "",
      university ? university : undefined,
      study ? study : undefined
    );
    if (res.success) {
      const totalPages = Math.ceil(res.data.count / 10);
      setTotalPages(totalPages);
      if (res.success) {
        const mappedData = mapAdminOrders(res.data.data);
        if (reset) {
          setOrdersData((data) => [...mappedData]);
        } else {
          setOrdersData((data) => [...data, ...mappedData]);
        }
      }
    }

    setLoading(false);
  }

  const { t } = useTranslation();
  return (
    <Container>
      <OrdersHeader>
        <OrdersHeading>Orders</OrdersHeading>
        <FilterContainer>
          <StyledSelect
            text={t("adminCourses.selectAUniversity")}
            onChange={(e) => {
              setUniversity(e);
              setStudy(undefined);
              onFilterDataChange({ universitySelected: e ? e : undefined });
            }}
            value={university}
            options={universityOptions}
          />
          <StyledSelect
            text={t("adminCourses.selectAStudy")}
            onChange={(e) => {
              onFilterDataChange({ studySelected: e ? e : undefined });
              setStudy(e);
            }}
            disabled={!university}
            value={study}
            options={studyOptions}
          />
          <StyledSearch
            onChange={(value) => {
              setSearch(value);
              onFilterDataChange({ searchEntered: value });
            }}
            placeholder={t("adminOrders.searchInOrders")}
          />
        </FilterContainer>
      </OrdersHeader>
      <StudentsTableContainer>
        <StudentTable
          totalPages={totalPages}
          pageNumber={pageNo}
          setPageNumber={setPageNo}
          loading={loading}
          items={OrdersData}
        ></StudentTable>
      </StudentsTableContainer>
    </Container>
  );
}

export default StudentsView;
