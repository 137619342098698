import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CourseHeader from "./CourseHeader";
import CourseDetails from "./CourseDetails";
import CourseExtraInfo from "./CourseExtraInfo";
import CallToSignIn from "@API/CallToSignIn";
import { useNavigate, useParams } from "react-router-dom";
import { device, mixin } from "@styles/theme";
import { useCourseDetails } from "../../../../lib/hooks/useCourseDetails";
import SearchBar from "@components/CommonDashboard/Header/SearchBar";
import Header from "@components/CommonDashboard/Header";
// styled-components----
const Container = styled.div`
  overflow-y: scroll;
  height: calc(100dvh);
  background: ${(props) => props.theme.colors.gray[500]};
  padding-bottom: 10rem;
  @media ${device.tablet} {
    height: calc(100dvh);
  }
  ${mixin.hideScroll()}
`;
const CourseDetailsAndExtraInfoContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
  grid-template-areas: "a b" "a c";
  grid-auto-rows: minmax(0, auto) auto;
  padding: 3.125rem;
  gap: 3.125rem;
  @media ${device.laptopM} {
    grid-template-columns: minmax(0, 1fr);
    grid-template-areas: "a" "b" "c";
    grid-template-rows: auto auto auto;
    padding: 1.25rem;
    gap: 1.25rem;
  }
`;
const CourseDetailsContainer = styled.div`
  background: #ffffff;
  border-radius: 20px;
  padding: 1.875rem;
  padding-bottom: 3.125rem;
  grid-area: a;
  @media ${device.laptopM} {
    grid-area: b;
  }
  @media ${device.laptop} {
    padding: 1.25rem;
  }
  @media ${device.tablet} {
    border-radius: 10px;
  }
`;
const SearchInputContainer2 = styled.div`
  display: none;
  flex-grow: 1;
  background: ${(props) => props.theme.colors.gray[500]};
  @media ${device.tablet} {
    display: flex;
  }
`;
const CourseExtraInfoContainer = styled.div`
  border-radius: 20px;
  height: 50rem;
  max-width: 41rem;
  min-width: 30rem;
`;
const CallToSignInContainer = styled.div`
  padding: 0 50px 70px 50px;
  @media ${device.desktopXS} {
    padding: 0 20px 70px 20px;
  }
`;
// ---------------------

function CourseView() {
  const navigate = useNavigate();
  const { id } = useParams();
  if (!id) {
    navigate("/error");
    return;
  }
  const details = useCourseDetails(id);
  return (
    <Container>
      <Header />
      <SearchInputContainer2>
        <SearchBar />
      </SearchInputContainer2>
      <CourseHeader details={details} />
      <CourseDetailsAndExtraInfoContainer>
        <CourseDetailsContainer>
          <CourseDetails details={details} />
        </CourseDetailsContainer>
        <CourseExtraInfo details={details} />
      </CourseDetailsAndExtraInfoContainer>
      <CallToSignInContainer>
        <CallToSignIn />
      </CallToSignInContainer>
    </Container>
  );
}

export default CourseView;
