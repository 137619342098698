import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";
import { userInfo } from "@app/redux/slices/userSlice";
import { APIUserInfo } from "@lib/Types/API/APIuserinfo";
import { mapAPidatatoUserInfo } from "@lib/mapperFunctions/mapAPidatatoUserInfo";

type UserInfoType =
  | {
      success: true;
      data: userInfo;
    }
  | {
      success: false;
      data: string;
    };

export const getUserProfile = async function (): Promise<UserInfoType> {
  const url = urlFunctions.getUserInfo();

  const res = await API.sendGetRequest(url, true);
  if (res.success) {
    const data: APIUserInfo = res.data;
    const mappedData = await mapAPidatatoUserInfo(data);
    return {
      success: true,
      data: mappedData,
    };
  } else {
    return {
      success: false,
      data: "There was an error in getting search list",
    };
  }
};
