import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import closeIcon from "@images/closeIcon.png";
import { useNavigate, useParams } from "react-router-dom";
import { OrdersDetailsData } from "./AdminOrders";
import { getAdminOrdersDetails } from "@API/services/Order/getOrderDetailsData";
import { getRefund } from "@API/services/Order/getRefund";
import { useTranslation } from "react-i18next";
import loadingSvg from "@images/loading.svg";
import {
  OpacityContainer,
  PopupContainer,
  PopupHeading,
} from "@components/popups/studentPopups/Popup";
import ConfirmationPopup from "@components/CommonComponents/ConfirmationPopup";

const Container = styled.div`
  background: #ffffff;
  border-radius: 20px;
  padding: 1.875rem 1.875rem 15.875rem 1.875rem;
  color: ${(props) => props.theme.colors.gray[800]};
  display: flex;
  flex-direction: column;
`;

const CloseImage = styled.img`
  width: 1.875rem;
  height: 1.875rem;
  margin: 0 0 10px auto;
`;

const Order = styled.div``;

const OrderMain = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OrderDetail = styled.div`
  display: flex;
  flex-direction: column;
`;

const OrderMainTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const OrderDetailTextContainer = styled.div`
  display: flex;
`;

const Orderkeys = styled.h4`
  font-size: 1rem;
  color: #a8bace;
  font-weight: bold;
  min-width: 13.75rem;
  margin-right: 5rem;
`;

const OrderHeading = styled.h2`
  font-size: 1.563rem;
  margin: 1.25rem 0;
`;

const OrderValues = styled.h4`
  font-size: 1rem;
  font-weight: normal;
  color: #707070;
`;

const OrderButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
  padding: 0 1.875rem;
`;

const LineSeperator = styled.div`
  width: 100%;
  height: 0px;
  border-top: 2px solid #e6ebf0;
  margin: 1.875rem 0;
`;
interface OrderStatus {
  $name: string;
}
export const DetailsLink = styled.button<OrderStatus>`
  height: 2.1875rem;
  padding: 0 3.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.gray[800]};
  border-radius: 10px;
  background: ${(props) =>
    props.$name == "COMPLETED" ? "#36CC1C26" : props.theme.colors.gray[500]};
`;
interface OrderStatusPropsType {
  $name: string;
}
const OrderStatus = styled.div<OrderStatusPropsType>`
  height: 2.1875rem;
  padding: 0 3.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.gray[800]};
  border-radius: 10px;
  background: ${(props) =>
    props.$name == "COMPLETED" ? "#36CC1C26" : props.theme.colors.gray[500]};
`;
export const skeletonLoading = keyframes`
  0% {
    background: hsl(200,20%,70%);
  }
  100% {
    background: hsl(200,20%,95%)
  }
`;
export const LoadingComponent = styled.div`
  width: 100%;
  height: 2.1875rem;
  animation: ${skeletonLoading} 1s linear infinite alternate;
`;

const LoadingImage = styled.img`
  width: 40px;
  height: 40px;
`;

function OrderDetailsSkeleton() {
  return (
    <Container>
      <CloseImage src={closeIcon} /* Optionally handle click event here */ />
      <Order>
        <OrderMain>
          <OrderMainTextContainer>
            <LoadingComponent>&nbsp;</LoadingComponent>
            <LoadingComponent>&nbsp;</LoadingComponent>
            <LoadingComponent>&nbsp;</LoadingComponent>
          </OrderMainTextContainer>
          <OrderButtonContainer>
            <LoadingComponent>&nbsp;</LoadingComponent>
            <LoadingComponent>&nbsp;</LoadingComponent>
          </OrderButtonContainer>
        </OrderMain>
        <LineSeperator />

        {Array.from({ length: 5 }).map((_, index) => (
          <OrderDetail key={index}>
            <OrderDetailTextContainer>
              <LoadingComponent>&nbsp;</LoadingComponent>
              <LoadingComponent>&nbsp;</LoadingComponent>
            </OrderDetailTextContainer>
            <LineSeperator />
          </OrderDetail>
        ))}
      </Order>
    </Container>
  );
}

function OrderDetails() {
  const [OrdersData, SetOrdersData] = useState<OrdersDetailsData>(
    {} as OrdersDetailsData
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [isRefundInitiated, setIsRefundInitiated] = useState<boolean>(false);
  const [isRefundPopup, setIsRefundPopup] = useState<boolean>(false);
  const [refundLoading, setRefundLoading] = useState<boolean>(false);
  const [showRefundConfirmationPopup, setShowRefundConfirmationPopup] =
    useState<boolean>(false);
  const { orderId, lang } = useParams();

  const getOrderDataFunction = async () => {
    setLoading(true);
    const res = await getAdminOrdersDetails(orderId);
    if (res) {
      SetOrdersData(res);
    }
    setLoading(false);
  };

  useEffect(() => {
    getOrderDataFunction();
  }, []);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = (to: string) => {
    navigate(to);
  };

  const handleRefund = async () => {
    setIsRefundPopup(true);
    setRefundLoading(true);
    const res = await getRefund(orderId);
    if (res) {
      setIsRefundInitiated(res.data ? res.data : false);
      setRefundLoading(false);
    }
    setRefundLoading(false);
  };
  return loading ? (
    <OrderDetailsSkeleton />
  ) : (
    <Container>
      <CloseImage
        src={closeIcon}
        onClick={() => handleClick(`/${lang}/admin-dashboard/orders`)}
      />
      {showRefundConfirmationPopup && (
        <ConfirmationPopup
          text="Are you sure you want to refund this order"
          handleClose={() => {
            setShowRefundConfirmationPopup(false);
          }}
          onConfirmation={() => {
            handleRefund();
          }}
        />
      )}
      <Order>
        <OrderMain>
          <OrderMainTextContainer>
            <Orderkeys>{t("adminOrders.OrderDetails.order")}</Orderkeys>
            <OrderHeading>{OrdersData.productName}</OrderHeading>
            <OrderValues>
              {t("adminOrders.OrderDetails.orderNumber")}
              &nbsp;
              {OrdersData.akcOrderId}
            </OrderValues>
          </OrderMainTextContainer>
          <OrderButtonContainer>
            {OrdersData.orderStatus === "COMPLETED" ? (
              <>
                <DetailsLink $name={"COMPLETED"}>
                  {OrdersData.orderStatus}
                </DetailsLink>
                <DetailsLink
                  onClick={() => {
                    setShowRefundConfirmationPopup(true);
                  }}
                  $name={"REFUND"}
                >
                  {t("adminOrders.OrderDetails.refund")}
                </DetailsLink>
              </>
            ) : (
              <DetailsLink $name={""}>{OrdersData.orderStatus}</DetailsLink>
            )}
          </OrderButtonContainer>
        </OrderMain>
        <LineSeperator />
        {
          <OrderDetail>
            <OrderDetailTextContainer>
              <Orderkeys>{t("adminOrders.OrderDetails.studentName")}</Orderkeys>
              <OrderValues style={{ textDecoration: "underline" }}>
                {OrdersData.studentName}
              </OrderValues>
            </OrderDetailTextContainer>
            <LineSeperator />
            <OrderDetailTextContainer>
              <Orderkeys>{t("adminOrders.OrderDetails.date")}</Orderkeys>
              <OrderValues>{OrdersData.orderTime}</OrderValues>
            </OrderDetailTextContainer>
            <LineSeperator />
            <OrderDetailTextContainer>
              <Orderkeys>{t("adminOrders.OrderDetails.amount")}</Orderkeys>
              <OrderValues>{OrdersData.amount}</OrderValues>
            </OrderDetailTextContainer>
            <LineSeperator />
            <OrderDetailTextContainer>
              <Orderkeys>{t("adminOrders.OrderDetails.orderNumber")}</Orderkeys>
              <OrderValues>{OrdersData.akcOrderId}</OrderValues>
            </OrderDetailTextContainer>
            <LineSeperator />
            <OrderDetailTextContainer>
              <Orderkeys>
                {t("adminOrders.OrderDetails.akcPaymentID")}
              </Orderkeys>
              <OrderValues>{OrdersData.payment.akcPaymentId}</OrderValues>
            </OrderDetailTextContainer>
            <LineSeperator />
            <OrderDetailTextContainer>
              <Orderkeys>
                {t("adminOrders.OrderDetails.stripePaymentInentID")}
              </Orderkeys>
              <OrderValues>
                {OrdersData.payment.stripePaymentIntentId}
              </OrderValues>
            </OrderDetailTextContainer>
            <LineSeperator />
          </OrderDetail>
        }
      </Order>
      {isRefundPopup && (
        <>
          <OpacityContainer />
          <PopupContainer isBackgroundImage={false} backgroundImage="">
            <CloseImage
              src={closeIcon}
              onClick={() => {
                setIsRefundPopup(false);
                getOrderDataFunction();
              }}
              style={{ position: "absolute", right: "30px", top: "30px" }}
            />
            <PopupHeading
              headingColor=""
              headingWidth=""
              style={{ marginBottom: "0px" }}
            >
              {refundLoading ? (
                <LoadingImage src={loadingSvg} alt="...loading" />
              ) : isRefundInitiated ? (
                "Refund Initiated"
              ) : (
                "Refund couldn't be inititiated"
              )}
            </PopupHeading>
          </PopupContainer>
        </>
      )}
    </Container>
  );
}

export { OrderDetails };
