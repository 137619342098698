import React from "react";
import { FaUserLock } from "react-icons/fa6";
import styled, { css } from "styled-components";
import { CourseDetailsType } from "./CourseDetailsType";
import { device, mixin } from "@styles/theme";
import { returnType } from "../../../../lib/hooks/useCourseDetails";

// styled-components----

const Container = styled.div`
  margin-top: 1.875rem;
`;

const HightlightItemContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.blue[700]};
  font-size: 1.25rem;
  line-height: 1.75rem;
  @media ${device.tablet} {
    align-items: flex-start;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;
const HightlightIconContainer = styled.div`
  width: 2.75rem;
  height: 2.1875rem;
  @media ${device.tablet} {
    height: 1.5625rem;
    width: 1.9375rem;
    margin-top: 1rem;
  }
`;
const HightlightIcon = styled(FaUserLock)`
  width: 2.75rem;
  height: 2.1875rem;
  min-width: 2.75rem;
  min-height: 2.1875rem;
  @media ${device.tablet} {
    height: 1.5625rem;
    width: 1.9375rem;
    min-height: 1.5625rem;
    min-width: 1.9375rem;
  }
`;
interface loading {
  $loading: boolean;
}
const HightlightTextContainer = styled.div<loading>`
  flex-grow: 1;
  margin-left: 1.875rem;
  @media ${device.tablet} {
    margin-left: 1.375rem;
  }
  ${(props) =>
    props.$loading &&
    css`
      ${mixin.skeletonLoading()}
    `}
`;
const HightlightQuestionContainer = styled.span`
  font-weight: 700;
`;
// ---------------------

function CourseHighlights({ details }: { details: returnType<true | false> }) {
  const [loading, courseDetails] = details;
  return (
    <Container>
      {!loading ? (
        Object.keys(courseDetails.highlights).map((e) => (
          <HightlightItemContainer key={e}>
            <HightlightIconContainer>
              <HightlightIcon />
            </HightlightIconContainer>
            <HightlightTextContainer $loading={loading}>
              <HightlightQuestionContainer>{e}</HightlightQuestionContainer>
              &nbsp;{courseDetails.highlights[e]}
            </HightlightTextContainer>
          </HightlightItemContainer>
        ))
      ) : (
        <HightlightItemContainer>
          <HightlightIconContainer>
            <HightlightIcon />
          </HightlightIconContainer>
          <HightlightTextContainer $loading={loading}>
            <HightlightQuestionContainer>&nbsp;</HightlightQuestionContainer>
            &nbsp;
          </HightlightTextContainer>
        </HightlightItemContainer>
      )}
    </Container>
  );
}

export default CourseHighlights;
