import styled from "styled-components";
import { Link } from "react-router-dom";
import CourseComponent from "@components/CommonComponents/CourseComponent";
import SampleImage from "@images/sample-university.png";
import { TeacherCourseType } from "./TeacherCourseType";
// import {CourseType, EnrolledCoursesList} from "./SampleCourseData";
import getTimeTillStarted from "@components/CommonComponents/CourseComponent/getTimeTillStarted";
import { useEffect, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { device } from "@styles/theme";

// styled-components----
const NormalText = styled.span`
  font-size: 1.125rem;
  line-height: 1.5625rem;
`;
const BoldText = styled(NormalText)`
  font-weight: 700;
`;
interface ContainerPropsType {
  $active: boolean;
}
const Container = styled.div<ContainerPropsType>`
  padding: 1.875rem;
  background: #ffffff;
  border-radius: 20px;
  cursor: ${(props) => (props.$active ? "default" : "cursor")};
  @media ${device.tablet} {
    padding: 20px;
    border-radius: 10px;
  }
`;
const ApplicationsButton = styled.div`
  line-height: 1.5625rem;
  font-size: 1.125rem;
  border-radius: 10px;
  padding: 0.5625rem 2.25rem;
  background: ${(props) => props.theme.colors.gray[500]};
  color: ${(props) => props.theme.colors.gray[800]};
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${device.tablet} {
    border-radius: 5px;
    line-height: 1rem;
    font-size: 0.6875rem;
    width: 50%;
  }
`;
const DetailsLink = styled(Link)`
  line-height: 1.5625rem;
  font-size: 1.125rem;
  font-weight: 700;
  border-radius: 10px;
  padding: 0.5625rem 2.75rem;
  background: ${(props) => props.theme.colors.blue[700]};
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${device.tablet} {
    border-radius: 5px;
    line-height: 1rem;
    font-size: 0.6875rem;
    width: 50%;
  }
`;
const ClassStatusButton = styled.div`
  line-height: 1.5625rem;
  font-size: 1.125rem;
  font-weight: 700;
  border-radius: 10px;
  padding: 0.5625rem 2.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors.green[600]};
  color: #ffffff;
  @media ${device.tablet} {
    line-height: 1rem;
    font-size: 0.6875rem;
    border-radius: 5px;
    width: 50%;
  }
`;
const ExpandButton = styled.button`
  height: 2.6875rem;
  width: 2.6875rem;
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.colors.gray[500]};
  color: ${(props) => props.theme.colors.gray[800]};
  justify-content: center;
  border-radius: 10px;
  @media ${device.tablet} {
    display: none;
  }
`;
const Divider = styled.div`
  height: 0.125rem;
  background: ${(props) => props.theme.colors.gray[500]};
  margin: 1.875rem 0;
  @media ${device.tablet} {
    margin: 0.9375rem 0;
  }
`;
const LessionsComponentContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 1.25rem;
`;
const LessionCotnainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 0.5rem;
  row-gap: 1rem;
`;
const Square = styled.div`
  height: 0.5rem;
  width: 0.5rem;
  background: ${(props) => props.theme.colors.gray[500]};
  // margin: 0 1.25rem;
`;
const LessionDataContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  column-gap: 0.5rem;
  row-gap: 1rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const LessionSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  column-gap: 1rem;
  row-gap: 1rem;
`;
const LessionButtonContainer = styled.div`
  @media ${device.tablet} {
    width: 100%;
  }
`;
const LessionHeading = styled(BoldText)`
  font-size: 1.125rem;
  line-height: 1.5625rem;
  font-weight: 700;
  @media ${device.tablet} {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;
const LessionDate = styled(NormalText)`
  @media ${device.tablet} {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;
const LessionTime = styled(NormalText)`
  @media ${device.tablet} {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;
const JoinButton = styled(Link)`
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15.875rem;
  height: 2.6875rem;
  background: ${(props) => props.theme.colors.green[600]};
  color: #ffffff;
  border-radius: 10px;
  font-size: 700;
  font-weight: 700;
  @media ${device.tablet} {
    border-radius: 5px;
    line-height: 1rem;
    font-size: 0.6875rem;
    height: 2.1875rem;
    width: 100%;
  }
`;
const TimeLeftButton = styled.button`
  width: 15.875rem;
  height: 2.6875rem;
  background: ${(props) => props.theme.colors.gray[500]};
  color: ${(props) => props.theme.colors.gray[600]};
  border-radius: 10px;
`;

// ---------------------
export function Lession({
  name,
  date,
  startTime,
  endTime,
  started,
  linkToJoin,
}: {
  name: string;
  date: string;
  startTime: string;
  endTime: string;
  started: boolean;
  linkToJoin?: string;
}) {
  const [timeLeft, setTimeLeft] = useState<string>(
    getTimeTillStarted({ date, startTime })
  );
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(getTimeTillStarted({ date, startTime }));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <LessionCotnainer>
      <LessionDataContainer>
        <LessionHeading>{name}</LessionHeading>
        <LessionSubContainer>
          <Square />
          <LessionDate>{date}</LessionDate>
          <Square />

          <LessionTime>
            {startTime}&nbsp;-&nbsp;{endTime}
          </LessionTime>
        </LessionSubContainer>
      </LessionDataContainer>
      <LessionButtonContainer>
        {started ? (
          <JoinButton to={linkToJoin ? linkToJoin : "#"}>Join Now</JoinButton>
        ) : (
          <TimeLeftButton>{timeLeft}</TimeLeftButton>
        )}
      </LessionButtonContainer>
    </LessionCotnainer>
  );
}
function TeacherCourseComponent({
  Course,
  active,
  setActive,
  index,
}: {
  Course: TeacherCourseType;
  active: boolean;
  setActive: (index: number) => void;
  index: number;
}) {
  return (
    <Container
      $active={active}
      role="button"
      onClick={() => {
        setActive(index);
      }}
    >
      <CourseComponent data={Course}>
        <ApplicationsButton>
          {Course.applications}&nbsp;applications
        </ApplicationsButton>
        <DetailsLink to={`${Course.id}/details`}>Details</DetailsLink>
        <ClassStatusButton>Ongoing</ClassStatusButton>
        <ExpandButton
          onClick={(e) => {
            e.stopPropagation();
            if (active) {
              setActive(-1);
            } else {
              setActive(index);
            }
          }}
        >
          {active ? <FaAngleUp /> : <FaAngleDown />}
        </ExpandButton>
      </CourseComponent>
      {active && (
        <>
          <Divider />
          <LessionsComponentContainer>
            {Course.lessions.map((e) => (
              <Lession
                key={`${e.title}.${e.date}`}
                name={e.title}
                date={e.date}
                startTime={e.startTime}
                endTime={e.endTime}
                started={true}
                linkToJoin={e.linkToJoin}
              />
            ))}
          </LessionsComponentContainer>
        </>
      )}
    </Container>
  );
}

export default TeacherCourseComponent;
