import styled from "styled-components";
import AccordionItem from "./AccordionItem";
import { useState } from "react";
import { colors, device } from "@styles/theme";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 31px;
  gap: 20px;
  @media ${device.tablet} {
    margin-top: 20px;
  }
`;

const SimpleText = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.gray[800]};
`;
const HeadingText = styled(SimpleText)`
  font-weight: 700;
`;

export default function AccordionRenderer() {
  const [activeAccordion, setActiveAccordion] = useState(0);
  return (
    <Container>
      <AccordionItem
        onClick={() => {
          setActiveAccordion(0);
        }}
        heading="Teacher (any study)"
        isOpen={activeAccordion === 0}
      >
        <SimpleText>
          At AKC, we are constantly looking for new, enthusiastic teachers to
          join our team. As a tutor at AKC, you will provide training and mentor
          our students remotely, conveniently from home. The salary varies
          between 18-25 euros per hour, depending on your knowledge and
          experience.
          <br />
          <br /> Are you a passionate and motivated (former) student who enjoys
          sharing his or her knowledge and experience with others? Do you have
          experience in providing training and coaching? And do you have a
          flexible attitude? Then we are looking for you!
          <br />
          <br /> As a teacher at AKC, you will have the opportunity to impart
          your skills and knowledge to the new generation. You will be able to
          inspire and motivate students to achieve their goals.
        </SimpleText>
        <br />
        <HeadingText>What are we looking for in you as a teacher?</HeadingText>
        <br />
        <SimpleText>
          - Have completed the course with an average of at least an 8;
        </SimpleText>{" "}
        <SimpleText>
          - Motivated to have helped the students as much as possible;
        </SimpleText>
        <SimpleText>
          - Motivated to participate in the process of a training / knowledge
          guide;
        </SimpleText>{" "}
        <SimpleText>
          - Good command of the Dutch and/or English language;
        </SimpleText>{" "}
        <SimpleText>- Good communication skills.</SimpleText>
      </AccordionItem>
      <AccordionItem
        onClick={() => {
          setActiveAccordion(1);
        }}
        heading="Writer (study material)"
        isOpen={activeAccordion === 1}
      >
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima nobis,
        veritatis ipsum commodi quam excepturi dolor repellendus molestiae
        dignissimos et pariatur, rerum odit, sequi in! Commodi quo eveniet
        debitis nulla.
      </AccordionItem>
      <AccordionItem
        onClick={() => {
          setActiveAccordion(2);
        }}
        heading="Guest blogger (knowledge base)"
        isOpen={activeAccordion === 2}
      >
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima nobis,
        veritatis ipsum commodi quam excepturi dolor repellendus molestiae
        dignissimos et pariatur, rerum odit, sequi in! Commodi quo eveniet
        debitis nulla.
      </AccordionItem>
    </Container>
  );
}
