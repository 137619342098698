import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";
import { TeacherCourseDetailsType } from "@components/TeachersDashboard/TeacherComponents/TeachersCourseDetails/TeachersCourseDetails";
import { APITeacherCourseDetails } from "@lib/Types/API/Teacher/APITeacherCourseDetails";
import { mapAPITeacherCourseDetailsToComponent } from "@lib/mapperFunctions/Teachers/mapAPITeacherCourseDetailsToComponent";
type TeacherCourseDetailsResponseType =
  | {
      success: true;
      data: TeacherCourseDetailsType;
    }
  | {
      success: false;
      data: string;
    };
export const getTeachersCourseDetails = async function (
  id: string
): Promise<TeacherCourseDetailsResponseType> {
  const url = urlFunctions.getTeachersCourseDetails(id);
  const res = await API.sendGetRequest(url, true);
  if (res.success) {
    const data: APITeacherCourseDetails = res.data;
    const mappedData = await mapAPITeacherCourseDetailsToComponent(data);
    return {
      success: true,
      data: mappedData,
    };
  } else {
    return {
      success: false,
      data: "Could not get course details",
    };
  }
};
