import { UserRole, userInfo } from "@app/redux/slices/userSlice";
import { APIUserInfo } from "@lib/Types/API/APIuserinfo";
import { BASEURI } from "@API/CONSTANTS";
import { downloadFile } from "@API/services/EntitiesCrud/downloadFile";
import { assetsUrls } from "@styles/theme";
export const mapAPidatatoUserInfo = function (data: APIUserInfo): userInfo {
  const profile = data.userProfile;

  return {
    key: new Date().getTime(),
    loggedIn: true,
    role: data.role,
    userId: data.id.toString(),
    name: data.name,
    profileUrl: data.profilePicUrl
      ? `${data.profilePicUrl}?timestamp=${new Date().getTime()}`
      : assetsUrls.userPng2,
    email: data.email,
    phone: profile.phone ? profile.phone : undefined,
    address: profile.address
      ? profile.address.address
        ? profile.address.address
        : undefined
      : undefined,
    zipcode: profile.address
      ? profile.address.zipcode
        ? profile.address.zipcode
        : undefined
      : undefined,
    country: profile.address
      ? profile.address.country
        ? profile.address.country
        : undefined
      : undefined,
    city: profile.address
      ? profile.address.city
        ? profile.address.city
        : undefined
      : undefined,
    language: profile.language ? profile.language : undefined,
    grade: profile.currentGrade
      ? {
          id: profile.currentGrade.id.toString(),
          value: profile.currentGrade.gradeYear,
        }
      : undefined,
    study: profile.courseStudy
      ? {
          id: profile.courseStudy.id.toString(),
          value: profile.courseStudy.name,
        }
      : undefined,
    university: profile.university
      ? {
          id: profile.university.id.toString(),
          value: profile.university.universityName,
        }
      : undefined,
    univversityLogo: profile.university
      ? profile.university.universityLogoUrl
        ? profile.university.universityLogoUrl
        : undefined
      : undefined,
    dateOfBirth: profile.dateOfBirth ? profile.dateOfBirth : undefined,
    smartPoints: profile.smartPoints,
    infoAddedForTheFirstTime: profile.infoAddedForTheFirstTime,
    universityStudyAddedForTheFirstTime:
      profile.universityStudyAddedForTheFirstTime,
    courseOrderedForTheFirstTime: profile.courseOrderedForTheFirstTime,
    courseJoinedFortTheFirstTime: profile.courseJoinedFortTheFirstTime,
    verified: data.isVerified ? data.isVerified : false,
  };
};
