import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Select, { optionType } from "@lib/microComponents/Select";
import Search from "@lib/microComponents/Search";
import { Link, useAsyncError } from "react-router-dom";
import CourseView from "./CourseView";
import { AdminCourseView } from "./CourseRenderer";
import { useUniversityOptions } from "@lib/hooks/useUniversityOptions";
import { useStudyOption } from "@lib/hooks/useStudyOptions";
import { getAdminCourseList } from "@API/services/Data/getAdminCourseList";
import { mapAPIAdminCourseViewToComponent } from "@lib/mapperFunctions/mapAPIAdminCourseViewToComponent";
import { ReactToast } from "@lib/toast";
import { useTranslation } from "react-i18next";
import Skeleton from "@components/CommonComponents/CourseComponent/Skeleton";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { useSelector } from "react-redux";
import { useAdminUniversityOptions } from "@lib/hooks/useAdminUniversityOptions";
// start of styled-components
const Container = styled.div``;
const CourseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const CourseHeading = styled.h2`
  line-height: 1.9375rem;
  font-size: 1.375rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.gray[800]};
  align-self: last baseline;
`;
const FilterContainer = styled.div`
  display: flex;
  align-items: center;
`;
const StyledSelect = styled(Select)`
  margin-left: 1.25rem;
  width: 15rem;
`;
const StyledSearch = styled(Search)`
  margin-left: 1.25rem;
`;
const AddCourseLink = styled(Link)`
  height: 2.875rem;
  width: 2.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;
  font-weight: 700;
  border-radius: 10px;
  background: ${(props) => props.theme.colors.green[600]};
  color: #ffffff;
  margin-left: 1.25rem;
`;
const CourseListContainer = styled.div`
  margin-top: 1.875rem;
`;
// end of styled-components

function CoursesPage() {
  const [loadingUniversity, universityOptions] = useAdminUniversityOptions();
  const [studyOptions, setStudyOptions] = useState<optionType[]>([]);
  const [university, setUniversity] = useState<optionType | undefined | null>();
  const [study, setStudy] = useState<optionType | undefined | null>();
  const [courseList, setCourseList] = useState<AdminCourseView[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [pageNo, setPageNo] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState("");
  const userInfo = useSelector(selectUser);
  async function getAndSetStudyOptions() {
    if (university) {
      const studyOptions = await useStudyOption(university.id);
      setStudyOptions(studyOptions);
    }
  }
  useEffect(() => {
    getAndSetStudyOptions();
  }, [university]);
  function onFilterDataChange({
    universitySelected,
    studySelected,
    searchEntered,
  }: {
    universitySelected?: optionType;
    studySelected?: optionType;
    searchEntered?: string;
  }) {
    setTotalPages(0);
    setPageNo(0);
    setCourseList([]);
    getCourseList(0, true, universitySelected, studySelected, searchEntered);
  }
  useEffect(() => {
    getCourseList(
      pageNo,
      false,
      university ? university : undefined,
      study ? study : undefined,
      search
    );
  }, [pageNo]);
  async function getCourseList(
    pageNo: number,
    reset: boolean,
    university: optionType | undefined,
    study: optionType | undefined,
    search: string | undefined
  ) {
    if (reset) {
      setCourseList([]);
    }
    setLoading(true);
    if (!userInfo.role) {
      throw new Error("Not Authorized");
    }
    const response = await getAdminCourseList(
      userInfo.role,
      pageNo,
      10,
      search ? search : "",
      university ? university : undefined,
      study ? study : undefined
    );
    if (response.success) {
      const totalPages = Math.ceil(response.data.count / 10);
      setTotalPages(totalPages);
      const formattedData = response.data.data.map((item) =>
        mapAPIAdminCourseViewToComponent(item)
      );
      if (reset) {
        setCourseList(formattedData);
      } else {
        setCourseList((data) => [...data, ...formattedData]);
      }
    } else {
      ReactToast("Could not get course list");
    }
    setLoading(false);
  }
  const { t } = useTranslation();

  return (
    <Container>
      <CourseHeader>
        <CourseHeading>{t("adminCourses.courses")}</CourseHeading>
        <FilterContainer>
          <StyledSelect
            text={t("adminCourses.selectAUniversity")}
            onChange={(e) => {
              setUniversity(e);
              onFilterDataChange({ universitySelected: e ? e : undefined });
              setStudy(undefined);
            }}
            value={university}
            options={universityOptions}
          />
          <StyledSelect
            text={t("adminCourses.selectAStudy")}
            onChange={(e) => {
              setStudy(e);
              onFilterDataChange({ studySelected: e ? e : undefined });
            }}
            disabled={!university}
            value={study}
            options={studyOptions}
          />
          <StyledSearch
            onChange={(value) => {
              setSearch(value);
              onFilterDataChange({ searchEntered: value });
            }}
            placeholder={t("adminCourses.searchInCourses")}
          />
          <AddCourseLink to="add-course">+</AddCourseLink>
        </FilterContainer>
      </CourseHeader>
      <CourseListContainer>
        <CourseView
          totalPages={totalPages}
          pageNumber={pageNo}
          setPageNumber={setPageNo}
          loading={loading}
          items={courseList}
        />
      </CourseListContainer>
    </Container>
  );
}

export default CoursesPage;
