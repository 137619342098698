import { optionType } from "@components/AdminDashboard/SubPages/Courses/AddCourse/Components/FormSelect";
import { subjectList } from "@lib/Types/API/FormData/FormData";
export interface SubjectSchema {
  id: string;
  value: string;
  logo: string;
}
export const mapSubjectListToOptions = function (
  items: subjectList
): SubjectSchema[] {
  return items.map((item) => ({
    id: item.id.toString(),
    value: item.name,
    logo: item.universityLogoUrl,
  }));
};
