import styled, { keyframes } from "styled-components";
import SampleImage from "@images/sample-university.png";
// import  { CourseType } from "./SampleCourseData";
import { FaLocationDot } from "react-icons/fa6";
import { useEffect, useRef } from "react";

import { device } from "@styles/theme";
// styled-components----
const OuterContainer = styled.div`
  background: #ffffff;
  border-radius: 20px;
  padding: 1.875rem;
  width: 100%;
  @media ${device.tablet} {
    padding: 1.25rem;
    border-radius: 10px;
  }
`;
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // width:80%;
  gap: 1.25rem;
  @media ${device.tablet} {
    gap: 0.9375rem;
  }
`;
const ImageContainer = styled.div`
  height: 3.625rem;
  width: 3.625rem;
  @media ${device.tablet} {
    height: 2.75rem;
    width: 2.75rem;
  }
`;
const skeletonLoading = keyframes`
  0% {
    background: hsl(200,20%,70%);
  }
  100% {
    background: hsl(200,20%,95%)
  }
`;
const Image = styled.div`
  height: 3.625rem;
  width: 3.625rem;
  border-radius: 50%;
  animation: ${skeletonLoading} 1s linear infinite alternate;
  @media ${device.tablet} {
    height: 2.75rem;
    width: 2.75rem;
  }
`;
const ImageAndCourseDetailsContainer = styled.div`
  display: flex;
`;
const CourseDetailsContainer = styled.div`
  margin-left: 2rem;
  margin-top: 0.1875rem;
  display: flex;
  flex-direction: column;
  @media ${device.tablet} {
    margin-left: 15px;
  }
`;
const UniversityNameAndEventContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
  @media ${device.tablet} {
    line-height: 1rem;
    gap: 0.1875rem;
    align-items: normal;
    flex-direction: column;
  }
`;
const UniversityNameContainer = styled.div`
  display: flex;
  align-items: center;
`;
const UniversityName = styled.div`
  height: 20px;
  width: 8rem;
  animation: ${skeletonLoading} 1s linear infinite alternate;
  @media ${device.tablet} {
    height: 1rem;
  }
`;
const EventContainer = styled.div`
  height: 20px;
  width: 10rem;
  display: flex;
  align-items: center;
  animation: ${skeletonLoading} 1s linear infinite alternate;
  @media ${device.tablet} {
    height: 1rem;
  }
`;
const CourseMetaContainer1 = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  @media ${device.tablet} {
    margin-top: 0.25rem;
    display: none;
  }
`;
const CourseMetaContainer2 = styled(CourseMetaContainer1)`
  display: none;
  @media ${device.tablet} {
    display: flex;
  }
`;
const CourseMajor = styled.span`
  width: 7rem;
  height: 20px;
  animation: ${skeletonLoading} 1s linear infinite alternate;
`;
const CourseSubject = styled.span`
  width: 7rem;
  height: 20px;
  animation: ${skeletonLoading} 1s linear infinite alternate;
`;
const CourseLanguage = styled.div`
  width: 7rem;
  height: 20px;
  animation: ${skeletonLoading} 1s linear infinite alternate;
`;
const CourseLanguageIcon = styled.div`
  position: relative;
  border-radius: 0.75rem;
  overflow: hidden;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Squrare = styled.div`
  height: 0.5rem;
  width: 0.5rem;
  min-height: 0.5rem;
  width: 0.5rem;
  background: ${(props) => props.theme.colors.gray[500]};
  margin: 0 1.25rem;
  @media ${device.tablet} {
    margin: 0 0.625rem;
    height: 0.25rem;
    width: 0.25rem;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
  @media ${device.tablet} {
    gap: 0.625rem;
  }
`;

// ---------------------
function Skeleton({ ...props }: {}) {
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const element = containerRef.current;
    const changeLayout = () => {
      if (element) {
        const elementIsShort = element.offsetWidth < 950;
        if (elementIsShort) {
          element.style.display = "grid";
          element.style.gridTemplateColumns = "minmax(0,1fr)";
        } else {
          element.style.display = "flex";
        }
        ``;
        const elementIsVertyShort = element.offsetWidth <= 728;
      }
    };
    changeLayout();
    if (element) {
      window.addEventListener("resize", changeLayout);
    }
    return () => {
      window.removeEventListener("resize", changeLayout);
    };
  }, [containerRef.current]);
  return (
    <OuterContainer>
      <Container ref={containerRef} {...props}>
        <ImageAndCourseDetailsContainer id="image-and-course-details-container">
          <ImageContainer>
            <Image />
          </ImageContainer>
          <CourseDetailsContainer>
            <UniversityNameAndEventContainer>
              <UniversityNameContainer>
                <UniversityName />
              </UniversityNameContainer>
              <EventContainer></EventContainer>
            </UniversityNameAndEventContainer>

            <CourseMetaContainer1>
              <CourseMajor>{/* {data.study} */}</CourseMajor>
              <Squrare />
              <CourseSubject></CourseSubject>
              <Squrare />
              <CourseLanguage>
                <CourseLanguageIcon></CourseLanguageIcon>
              </CourseLanguage>
            </CourseMetaContainer1>
          </CourseDetailsContainer>
        </ImageAndCourseDetailsContainer>
        <CourseMetaContainer2>
          <CourseMajor>{/* {data.study} */}</CourseMajor>
          <Squrare />
          <CourseSubject>{/* {data.subject} */}</CourseSubject>
          <Squrare />
          <CourseLanguage>
            <CourseLanguageIcon></CourseLanguageIcon>
          </CourseLanguage>
        </CourseMetaContainer2>
      </Container>
    </OuterContainer>
  );
}

export default Skeleton;
