import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../../../../commonComponents/Header";
import AddButton from "@components/AdminDashboard/SubPages/Settings/commonComponents/AddButton";
import ViewComponent, { DataText } from "../common/ViewComponent";
import { useNavigate } from "react-router-dom";
import Modal from "@components/CommonComponents/Modal";
import AddUniversityForm from "./AddUniversityForm";
import { useAdminUniversityOptions } from "@lib/hooks/useAdminUniversityOptions";
import { useTranslation } from "react-i18next";
import LoadingComponent from "../LoadingComponent";
import EditUniversityForm from "./EditUniversityForm";
import { Id } from "react-toastify";
import { useSelector } from "react-redux";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { UserRole } from "@app/redux/slices/userSlice";
import ConfirmationPopup from "@components/CommonComponents/ConfirmationPopup";
import { LoadingSpinner } from "@lib/microComponents/LoadingSpinner";
import { DeleteOverlay } from "../common/DeleteOverlay";
import { ReactToast } from "@lib/toast";
import { deleteAUniversityService } from "@API/services/EntitiesCrud/deleteEntities";
// styled-components ----
const Container = styled.div``;
const UniversityComponentContainer = styled.div`
  position: relative;
  user-select: none;
`;

// ----------------------
const UniversitiesContainer = styled.div``;
export interface AdminUniversityView {
  id: string;
  value: string;
  numberOfSubjects: number;
  numberOfStudies: number;
  logoUrl: string;
}

interface EditInfo {
  universityId: string;
  universityName: string;
  universityLogoUrl: string;
}

function Universities() {
  const [loading, universities, reset] = useAdminUniversityOptions();
  useEffect(() => {});
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showEditForm, setShowEditForm] = useState<EditInfo>();
  const [showDeletePopup, setShowDeletePopup] = useState<
    AdminUniversityView | undefined
  >();
  const [deleting, setDeleting] = useState<string>();
  const userInfo = useSelector(selectUser);
  const navigate = useNavigate();
  const { t } = useTranslation();
  async function deleteAUniversity(
    universityId: string,
    universityName: string
  ) {
    const res = await deleteAUniversityService(universityId);
    if (res.success) {
      ReactToast.success(`${universityName} deleted`);
      reset();
    } else {
      ReactToast.error(`university ${universityName} could not be deleted`);
    }
    setShowDeletePopup(undefined);
    setDeleting(undefined);
  }
  return (
    <Container>
      {showModal && (
        <Modal
          handleClose={() => {
            setShowModal(false);
          }}
          heading={t(
            "adminSettings.universityStudySubjectPage.universities.addNewUniversity"
          )}
        >
          <AddUniversityForm
            onCreate={() => {
              setShowModal(false);
              reset();
            }}
          />
        </Modal>
      )}
      {showEditForm && (
        <Modal
          handleClose={() => {
            setShowEditForm(undefined);
          }}
          heading="Edit University"
        >
          <EditUniversityForm
            onCreate={() => {
              setShowEditForm(undefined);
            }}
            universityId={showEditForm.universityId}
            universityName={showEditForm.universityName}
            universityLogoUrl={showEditForm.universityLogoUrl}
          />
        </Modal>
      )}
      {showDeletePopup && (
        <ConfirmationPopup
          text={`Are you sure you want to delete university '${showDeletePopup.value}'`}
          handleClose={() => {
            setShowDeletePopup(undefined);
          }}
          onConfirmation={() => {
            setDeleting(showDeletePopup.id);
            deleteAUniversity(showDeletePopup.id, showDeletePopup.value);
          }}
        />
      )}
      <Header
        text={t(
          "adminSettings.universityStudySubjectPage.universities.universities"
        )}
        searchText={t(
          "adminSettings.universityStudySubjectPage.universities.searchInUniversities"
        )}
      >
        {userInfo.role === UserRole.ADMIN && (
          <AddButton
            onClick={() => {
              setShowModal(true);
            }}
          >
            {t(
              "adminSettings.universityStudySubjectPage.universities.addUniversity"
            )}
          </AddButton>
        )}
      </Header>
      <UniversitiesContainer>
        {!loading
          ? universities.map((university) => {
              return (
                <UniversityComponentContainer
                  role={deleting === university.id ? "div" : "button"}
                  tabIndex={0}
                  onClick={() => {
                    if (deleting === university.id) return;
                    navigate(`${university.id}/studies`);
                  }}
                >
                  {deleting === university.id && (
                    <DeleteOverlay>
                      <LoadingSpinner />
                    </DeleteOverlay>
                  )}
                  <ViewComponent
                    onClickDelete={() => {
                      if (deleting) return;
                      setShowDeletePopup(university);
                    }}
                    imageUrl={university.logoUrl}
                    university={true}
                    name={university.value}
                    onClickEdit={(e) => {
                      e.stopPropagation();
                      setShowEditForm({
                        universityId: university.id,
                        universityLogoUrl: university.logoUrl,
                        universityName: university.value,
                      });
                    }}
                    arrowVisible={true}
                  >
                    <DataText
                      number={university.numberOfStudies}
                      text={t(
                        "adminSettings.universityStudySubjectPage.studies"
                      )}
                    />
                    <DataText
                      number={university.numberOfSubjects}
                      text={t(
                        "adminSettings.universityStudySubjectPage.subjects"
                      )}
                    />
                  </ViewComponent>
                </UniversityComponentContainer>
              );
            })
          : Array.from({ length: 10 }).map(() => <LoadingComponent />)}
      </UniversitiesContainer>
    </Container>
  );
}
export default Universities;
