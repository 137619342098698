import { NonEnrolledCourseDataType } from "@components/CommonComponents/CourseComponent/NonEnrolledCourse";
import { CourseSearch } from "@lib/Types/API/APICourseSearchList";
import SampleUniversityImage from "@images/sample-university.png";
import { CourseStatus } from "@lib/Enums/CourseStatus";
export const mapCourseToUnenrolled = (
  item: CourseSearch
): NonEnrolledCourseDataType => ({
  //   image: item.university.universityLogoUrl,
  id: item.courseId,
  university: {
    id: item.university.id.toString(),
    universityLogoUrl: item.university.universityLogoUrl,
    universityName: item.university.universityName,
  },
  status: item.courseStatus === CourseStatus.PUBLISH,
  study: item.courseStudy.name,
  major: item.courseSubject.name,
  subject: item.courseSubject.name,
  language: item.language,
  type: item.courseType.courseType,
  action: item.actionForCurrentLoggedInUser,
});
