import React from "react";
import styled, { css } from "styled-components";
import { CourseDetailsType } from "./CourseDetailsType";
import { device, mixin } from "@styles/theme";
import { returnType } from "../../../../lib/hooks/useCourseDetails";

// styled-components----
const Container = styled.div`
  margin-top: 3.125rem;
`;
const QuestionAndAnswerContainer = styled.div`
  margin-top: 1.25rem;
`;
interface loading {
  $loading: boolean;
}
const QuestionContainer = styled.div<loading>`
  padding: 0.25rem 0rem 0.25rem 1.25rem;
  font-size: 1.375rem;
  line-height: 1.9375rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.gray[800]};
  border-left: 0.6875rem solid ${(props) => props.theme.colors.blue[700]};
  @media ${device.tablet} {
    line-height: 1.5625rem;
    font-size: 1.125rem;
    padding: 0.125rem 0rem 0.125rem 0.9375rem;
    border-width: 0.5rem;
  }
  ${(props) => props.$loading && mixin.skeletonLoading()}
`;

const AnswerContainer = styled.div<loading>`
  margin-top: 1.25rem;
  color: ${(props) => props.theme.colors.gray[800]};
  line-height: 1.5rem;
  @media ${device.tablet} {
    line-height: 1.25rem;
    font-size: 0.875rem;
    margin-top: 0.9375rem;
  }
  ${(props) =>
    props.$loading &&
    css`
      height: 4rem;
    `}
  ${(props) => props.$loading && mixin.skeletonLoading()}
`;

// ---------------------

function CourseQuestionnare({
  details,
}: {
  details: returnType<true | false>;
}) {
  const [loading, courseDetails] = details;
  return (
    <Container>
      {!loading ? (
        Object.keys(courseDetails.questionnaires).map((e) => (
          <QuestionAndAnswerContainer key={e}>
            <QuestionContainer $loading={loading}>{e}</QuestionContainer>
            <AnswerContainer $loading={loading}>
              {courseDetails.questionnaires[e]}
            </AnswerContainer>
          </QuestionAndAnswerContainer>
        ))
      ) : (
        <QuestionAndAnswerContainer>
          <QuestionContainer $loading={loading}>&nbsp;</QuestionContainer>
          <AnswerContainer $loading={loading}>&nbsp;</AnswerContainer>
        </QuestionAndAnswerContainer>
      )}
    </Container>
  );
}

export default CourseQuestionnare;
