import React, { useState } from "react";
import styled from "styled-components";
import { SlArrowDown } from "react-icons/sl";
import CourseSchedule from "./CourseSchedule";
import { device } from "@styles/theme";
import { TbMessages } from "react-icons/tb";
import { returnType } from "@lib/hooks/useCourseDetails";

const Container = styled.div``;

const MediumText = styled.h4`
  font-size: 1.025rem;
  color: #ffffff;
  font-weight: bold;
  @media ${device.mobileL} {
    font-size: 0.825rem;
  }
`;

const SmallText = styled.p`
  font-size: 0.785rem;
  color: #ffffff;
  font-weight: normal;
  margin: 20px 0;
  line-height: 2;
  max-width: 92%;
  @media ${device.mobileL} {
    font-size: 0.585rem;
  }
`;

const DropDownContainer = styled.div`
  padding: 0 20px;
  @media screen and (max-width: 810px) {
    padding: 0;
  }
`;

const Button = styled.button`
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  color: #a8bace;
  padding: 10px 35px;
  font-size: 1.025rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 10px;
  @media ${device.mobileL} {
    font-size: 0.825rem;
  }
`;

const MessageIcon = styled(TbMessages)`
  color: #a8bace;
`;

export const TextAndIcon = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ArrowDownIcon = styled(SlArrowDown)`
  background-color: #4883c4;
  color: #ffffff;
`;

export const ArrowUpIcon = styled(SlArrowDown)`
  background-color: #4883c4;
  color: #ffffff;
  transform: rotate(180deg);
`;

export const LineSeperator = styled.div`
  width: 100%;
  height: 0px;
  border-top: 2px solid #e6ebf0;
  margin: 30px 0;
  @media ${device.tablet} {
    margin: 15px 0;
  }
`;
const DropdownList = ({
  details,
  guide,
}: {
  details: returnType<true | false>;
  guide: boolean;
}) => {
  const [active, setActive] = useState<number>();
  const [loading, courseDetails] = details;

  const genericText =
    "In this training you will be prepared for your sexam. The full training consists of multiple training sessions. In these training hours you will be extensively supervised and well prepared for your exam.";

  const about = (function (): string {
    if (courseDetails && typeof courseDetails.questionnaires === "object") {
      const text = Object.values(courseDetails.questionnaires)[0];
      if (text) {
        return text;
      }
    }
    return genericText;
  })();

  return (
    <div>
      {!loading && (
        <Container>
          {!guide ? (
            <>
              <DropDownContainer>
                <TextAndIcon
                  onClick={() => {
                    if (active === 0) {
                      setActive(undefined);
                    } else {
                      setActive(0);
                    }
                  }}
                >
                  <MediumText>About this training</MediumText>
                  {!(active === 0) ? <ArrowDownIcon /> : <ArrowUpIcon />}
                </TextAndIcon>
                {active === 0 && <SmallText>{about}</SmallText>}
              </DropDownContainer>
              <LineSeperator />
              <DropDownContainer>
                <TextAndIcon
                  onClick={() => {
                    if (active === 1) {
                      setActive(undefined);
                    } else {
                      setActive(1);
                    }
                  }}
                >
                  <MediumText>Course schedule</MediumText>
                  {!(active === 1) ? <ArrowDownIcon /> : <ArrowUpIcon />}
                </TextAndIcon>
                {active === 1 && <CourseSchedule details={details} />}
              </DropDownContainer>
              <LineSeperator />
              <DropDownContainer>
                <TextAndIcon
                  onClick={() => {
                    if (active === 2) {
                      setActive(undefined);
                    } else {
                      setActive(2);
                    }
                  }}
                >
                  <MediumText>Support</MediumText>
                  {!(active === 2) ? <ArrowDownIcon /> : <ArrowUpIcon />}
                </TextAndIcon>
                {active === 2 && (
                  <>
                    <SmallText>
                      Do you have a question or are you experiencing any
                      problem? We would love to help you. Please contact our
                      support at any time.
                    </SmallText>
                    <Button>
                      Get support <MessageIcon />
                    </Button>
                  </>
                )}
              </DropDownContainer>
            </>
          ) : (
            <>
              <DropDownContainer>
                <TextAndIcon
                  onClick={() => {
                    if (active === 0) {
                      setActive(undefined);
                    } else {
                      setActive(0);
                    }
                  }}
                >
                  <MediumText>About this knowledge guide</MediumText>
                  {!(active === 0) ? <ArrowDownIcon /> : <ArrowUpIcon />}
                </TextAndIcon>
                {active === 0 && (
                  <SmallText>
                    In this digital knowledge guide you will find a
                    comprehensive summary of the subject matter, supplemented by
                    practice questions and their answers.
                  </SmallText>
                )}
              </DropDownContainer>
              <LineSeperator />
              <DropDownContainer>
                <TextAndIcon
                  onClick={() => {
                    if (active === 2) {
                      setActive(undefined);
                    } else {
                      setActive(2);
                    }
                  }}
                >
                  <MediumText>Support</MediumText>
                  {!(active === 2) ? <ArrowDownIcon /> : <ArrowUpIcon />}
                </TextAndIcon>
                {active === 2 && (
                  <>
                    <SmallText>
                      Do you have a question or are you experiencing any
                      problem? We would love to help you. Please contact our
                      support at any time.
                    </SmallText>
                    <Button>
                      Get support <MessageIcon />
                    </Button>
                  </>
                )}
              </DropDownContainer>
            </>
          )}

          <LineSeperator />
        </Container>
      )}
    </div>
  );
};

export default DropdownList;
