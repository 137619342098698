import { getOnlineStatus } from "@lib/util/main";
import axios from "axios";
import Cookies from "js-cookie";
import i18next from "i18next";
import { getLanguageLocale } from "@lib/Enums/Language";
export type APIResponse =
  | {
      success: true;
      data: any;
    }
  | {
      success: false;
      data: string;
    };

export const API = {
  async sendPostRequest(
    url: string,
    data: any,
    auth?: true
  ): Promise<APIResponse> {
    const language = getLanguageLocale(i18next.language);
    if (!getOnlineStatus()) {
      return { success: false, data: "Not connected to the internet" };
    }
    if (auth) {
      const token = Cookies.get("token");
      if (!token) {
        throw new Error("Not Authorised");
      }
    }
    const token = Cookies.get("token");
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
        "Accept-Language": language,
      },
      body: JSON.stringify(data),
    });
    if (res.ok) {
      const data = await res.json();
      return { success: true, data: data };
    } else {
      return { success: false, data: "Could not post" };
    }
  },
  async sendGetRequest(url: string, auth?: true) {
    const language = getLanguageLocale(i18next.language);
    if (!getOnlineStatus()) {
      return { success: false, data: "Not connected to the internet" };
    }
    if (auth) {
      const token = Cookies.get("token");
      if (!token) {
        throw new Error("Not Authorised");
      }
    }
    const token = Cookies.get("token");
    try {
      const res = await axios(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "Accept-Language": language,
        },
      });
      const data = await res.data;
      return { success: true, data: data };
    } catch (e) {
      return { success: false, data: "Could not Get" };
    }
  },
  async sendPutRequest(
    url: string,
    data: any,
    auth?: true
  ): Promise<APIResponse> {
    const language = getLanguageLocale(i18next.language);
    if (!getOnlineStatus()) {
      return { success: false, data: "Not connected to the internet" };
    }
    if (auth) {
      const token = Cookies.get("token");
      if (!token) {
        throw new Error("Not Authorised");
      }
    }
    const token = Cookies.get("token");
    const res = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
        "Accept-Language": language,
      },
      body: JSON.stringify(data),
    });
    if (res.ok) {
      const data = await res.json();
      return { success: true, data: data };
    } else {
      return { success: false, data: "Could not put" };
    }
  },
  async sendDeleteRequest(url: string, data?: any, auth?: true) {
    const language = getLanguageLocale(i18next.language);
    if (!getOnlineStatus()) {
      return { success: false, data: "Not connected to the internet" };
    }
    if (auth) {
      const token = Cookies.get("token");
      if (!token) {
        throw new Error("Not Authorised");
      }
    }
    const token = Cookies.get("token");
    const res = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
        "Accept-Language": language,
      },
      body: data ? JSON.stringify(data) : undefined,
    });
    if (res.ok) {
      return { success: true, data: undefined };
    } else {
      return { success: false, data: undefined };
    }
  },
  async getFile(url: string, auth?: true): Promise<APIResponse> {
    if (!getOnlineStatus()) {
      return { success: false, data: "Not connected to the internet" };
    }
    if (auth) {
      const token = Cookies.get("token");
      if (!token) {
        throw new Error("Not Authorised");
      }
    }
    const token = Cookies.get("token");
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.ok) {
      const data = await res.blob();
      return { success: true, data: data };
    } else {
      return { success: false, data: "Could not Get" };
    }
  },
};
