import { verifyToken } from "@API/services/Auth/main";
import { getUserProfile } from "@API/services/Data/getUserProfile";
import { userSlice } from "@app/redux/slices/userSlice";
import { mapAPidatatoUserInfo } from "@lib/mapperFunctions/mapAPidatatoUserInfo";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
export default function useLogin() {
  const [loading, setLoading] = useState(true);
  const [login, setLogin] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    tryToLogin();
  }, []);
  async function tryToLogin() {
    const token = Cookies.get("token");
    if (!token) {
      setLogin(false);
    } else {
      const tokenValidity = await verifyToken();
      if (tokenValidity) {
        const userInfo = await getUserProfile();
        if (userInfo.success) {
          dispatch(userSlice.actions.setUserInfo(userInfo.data));
          setLogin(true);
        }
      }
    }
    setLoading(false);
  }
  return [loading, login];
}
