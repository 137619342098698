import { AdminCourseView } from "@components/AdminDashboard/SubPages/Courses/CourseRenderer";
import { APIAdminCourseView } from "@lib/Types/API/APIAdminCourseView";
export const mapAPIAdminCourseViewToComponent = (
  data: APIAdminCourseView
): AdminCourseView => {
  const mapped: AdminCourseView = {
    id: data.id,
    University: data.university.universityName,
    universityId: "to be replaced",
    type: data.type.toString(),
    image: data["university-logo-url"]
      ? data["university-logo-url"]
      : "/assets/defaultuniversityicon.png",
    major: data.study,
    subject: data.subject,
    language: data.language,
    manager: data.manager
      ? {
          id: data.manager.id,
          name: data.manager.name,
          profilePicUrl: data.manager.profilePictureUrl
            ? data.manager.profilePictureUrl
            : "/assets/user.png",
        }
      : null,
    tags: {
      publish: data["edit-stage"][0] === "Y",
      teacher: data["edit-stage"][1] === "Y",
      date: data["edit-stage"][2] === "Y",
    },
    applications: data.statics.applications,
    promoClicks: data.statics["promo-clicks"],
    Sales: data.statics.sales,
    Payments: data.statics.payments,
  };
  return mapped;
};
