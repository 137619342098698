import { IconType } from "react-icons";
import {
  FaTableColumns,
  FaGraduationCap,
  FaBasketShopping,
  FaUser,
  FaGear,
  FaComments,
} from "react-icons/fa6";
import i18next from "../../i18config";
import { DashBoardNavigationArrayItemType } from "@components/CommonDashboard/Types";
const { t } = i18next;

const StudentDashBoardNavigationArray: DashBoardNavigationArrayItemType[] = [
  {
    id: 0,
    Icon: FaTableColumns,
    key: "overview",
    name: "Overview",
    link: "/dashboard/overview",
  },
  {
    id: 1,
    Icon: FaGraduationCap,
    key: "courses",
    name: "Courses",
    link: "/dashboard/courses",
  },
  {
    id: 2,
    Icon: FaBasketShopping,
    key: "orders",
    name: "Orders",
    link: "/dashboard/orders",
  },
  {
    id: 3,
    Icon: FaUser,
    key: "profile",
    name: "Student Profile",
    link: "/dashboard/profile",
  },
  {
    id: 4,
    Icon: FaComments,
    key: "support",
    name: "Support",
    link: "/dashboard/support",
  },
];

export default StudentDashBoardNavigationArray;
