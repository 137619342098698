import { ReactNode } from "react";
import styled, { css } from "styled-components";

export const Label = styled.label`
  display: block;
  font-size: 18px;
  line-height: 25px;
  color: ${(props) => props.theme.colors.gray[800]};
`;
export const SettingsInput = styled.input`
  background: ${(props) => props.theme.colors.gray[500]};
  padding: 1.125rem 1.875rem;
  border-radius: 0.625rem;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  width: 100%;
  color: ${(props) => props.theme.colors.gray[800]};
  &::placeholder {
    color: ${(props) => props.theme.colors.gray[600]};
  }
`;
interface ButtonProps {
  $loading: boolean;
}
const Button = styled.button<ButtonProps>`
  background: ${(props) => props.theme.colors.green[600]};
  font-size: 1.125rem;
  line-height: 1.5625rem;
  width: 100%;
  height: 3.8125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border-radius: 10px;
  color: #ffffff;
  position: relative;
  ${(props) =>
    props.$loading &&
    css`
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background: #ffffff;
        opacity: 0.85;
      }
    `}
`;

export const SettingsSubmitButton = ({
  loading,
  children,
  ...props
}: {
  loading: boolean;
  children: ReactNode;
}) => {
  return (
    <Button type="submit" $loading={loading} disabled={loading} {...props}>
      {children}
    </Button>
  );
};
