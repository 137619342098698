import { Message } from "@components/CommonComponents/Chat/ChatsComponent";
import { IncomingMessage } from "@lib/Types/Chat/IncomingMessage";

export function mapMessageToComponent(message: IncomingMessage): Message {
  const item: Message = {
    id: message.id.toString(),
    user: {
      id: message.sender.id,
      name: message.sender.name,
      role: message.sender.designation,
      profilePicUrl: message.sender.profileUrl
        ? message.sender.profileUrl
        : "/assets/user.png",
    },
    text: message.content,
    files: message.files.map((item) => ({
      type: item.type,
      url: item.url,
    })),
    timeStamp: new Date(message.time),
    reactions: message.reactions.map((reaction) => ({
      unicode: reaction.character,
      count: reaction.count,
    })),
  };
  return item;
}
