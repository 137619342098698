import { getAdminStudyList } from "@API/services/Admin/adminStudyList";
import { mapAdminStudyListToComponent } from "@lib/mapperFunctions/admin/mapAdminStudyLIstToComponent";
import { ReactToast } from "@lib/toast";

export const useAdminStudyOption = async function (id: string) {
  if (!id) {
    throw new Error("Provide an id to get study list");
  }
  const data = await getAdminStudyList(id);
  if (data.success) {
    const mappedList = mapAdminStudyListToComponent(data.data);
    return mappedList;
  } else {
    ReactToast(data.data);
    return [];
  }
};
