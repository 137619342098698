import GreetingComponent from "@components/CommonComponents/GreetingComponent";

import styled from "styled-components";
import ExtraInfo from "./ExtraInfo/ExtraInfo";
import CourseInfo from "./CoursesInfo.tsx/main";
import Suggestion from "./CourseSuggestions/Suggestion";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { useSelector } from "react-redux";
import { device, mixin } from "@styles/theme";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { CheckoutThankyouPopup } from "@/components/popups";
// styled-components----
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  flex-wrap: nowrap;
`;
const InnerContainer = styled.div`
  margin-top: 1.875rem;
  display: grid;
  grid-template-columns: minmax(0, 2.18fr) minmax(28.75rem, 1fr);
  gap: 1.875rem;
  flex-grow: 1;
  min-height: 0;
  @media ${device.laptopL} {
    display: flex;
    flex-direction: column-reverse;
    min-height: auto;
  }
  @media ${device.tablet} {
    margin-top: 1.25rem;
    gap: 1.25rem;
  }
`;
const CoursesContainer = styled.div`
  overflow-y: scroll;
  flex-grow: 1;
  ${mixin.hideScroll()}
`;

// ---------------------
function Overview() {
  const userInfo = useSelector(selectUser);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPaymentConfirmation, setShowPaymentConfirmation] =
    useState<boolean>(false);
  useEffect(() => {
    const isRecentPayment = searchParams.get("payment");
    if (isRecentPayment) {
      setShowPaymentConfirmation(true);
    }
  }, []);

  const renderConfettiEffect = () => {
    return showPaymentConfirmation ? (
      <CheckoutThankyouPopup
        onClick={() => {
          setShowPaymentConfirmation(false);
          searchParams.delete("payment");
          setSearchParams(searchParams);
        }}
      />
    ) : null;
  };

  return (
    <Container>
      {renderConfettiEffect()}
      <GreetingComponent
        username={userInfo.name ? userInfo.name : "there"}
        message={t("greetings.sub_greetings")}
      />
      <InnerContainer>
        <CoursesContainer>
          <CourseInfo />
          <Suggestion />
        </CoursesContainer>
        <ExtraInfo />
      </InnerContainer>
    </Container>
  );
}

export default Overview;
