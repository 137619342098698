import styled, { keyframes } from "styled-components";

import {
  FaAngleDown,
  FaAngleUp,
  FaBuildingColumns,
  FaChartSimple,
  FaCopy,
  FaEye,
  FaLocationDot,
  FaPenToSquare,
  FaSpinner,
} from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, {
  LegacyRef,
  MouseEventHandler,
  ReactElement,
  MouseEvent,
  ReactEventHandler,
  forwardRef,
  useState,
  ReactNode,
} from "react";
import { getPromoUrl } from "@API/services/getPromoUrl";
import { ReactToast } from "@lib/toast";
import { IconType } from "react-icons";
import { CourseAction } from "@lib/Enums/CourseAction";
import { Language, getLanguageInfo } from "@lib/Enums/Language";
import pageRouteNames from "@/constants/pageRouteNames";

// styled-components----
interface ContainerPropsType {
  $active: boolean;
}
const Container = styled.div<ContainerPropsType>`
  background: #ffffff;
  border-radius: 20px;
  cursor: ${(props) => (props.$active ? "auto" : "pointer")};
`;
const InnerContainer = styled.div`
  display: flex;
  padding: 1.875rem;
  justify-content: space-between;
`;
const DataContainer = styled.div`
  display: flex;
`;
const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 0.625rem;
`;
const CourseImageContainer = styled.div``;
const CourseImage = styled.img`
  height: 3.625rem;
  width: 3.625rem;
`;
const CourseDataContainer = styled.div`
  margin-left: 2rem;
`;
const UniversityNameAndCourseTypeContainer = styled.div`
  line-height: 1.25rem;
  font-size: 0.875rem;
  display: flex;
  color: ${(props) => props.theme.colors.gray[600]};
`;

const UniversityName = styled.div`
  display: flex;
  align-items: center;
`;
const CourseTypeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1.25rem;
`;
const UniversityIcon = styled(FaBuildingColumns)``;
const LocationIcon = styled(FaLocationDot)``;
const SquareIcon = styled.div`
  height: 0.5rem;
  width: 0.5rem;
  background: ${(props) => props.theme.colors.gray[500]};
  margin: 0 1.25rem;
`;
const CourseInfoContainer = styled.div`
  margin-top: 0.625rem;
  color: ${(props) => props.theme.colors.gray[800]};
  font-size: 1.125rem;
  line-height: 1.5625rem;
  display: flex;
  align-items: center;
`;
const CourseMajor = styled.div`
  font-weight: 700;
`;
const CourseSubject = styled.div``;
const CourseLanguage = styled.div`
  position: relative;
  border-radius: 0.75rem;
  overflow: hidden;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
`;
const StatusNeutralBadge = styled.div`
  padding: 0.1875rem 1.25rem;
  line-height: 0.9375rem;
  font-size: 0.625rem;
  color: ${(props) => props.theme.colors.gray[800]};
  background: ${(props) => props.theme.colors.gray[600]}26;
  height: 1.3125rem;
  margin-right: 0.625rem;
  border-radius: 10px;
`;
const StatusPositiveBadge = styled(StatusNeutralBadge)`
  background: ${(props) => props.theme.colors.green[600]}26;
`;
const StatusNegativeBadge = styled(StatusNeutralBadge)`
  background: ${(props) => props.theme.colors.red[500]}26;
`;
const CourseStatusContainer = styled.div`
  display: flex;
  margin-top: 1rem;
`;
const Button = styled.button`
  height: 2.6875rem;
  line-height: 1.5625rem;
  font-size: 1.125rem;
  color: ${(props) => props.theme.colors.gray[800]};
  background: ${(props) => props.theme.colors.gray[500]};
  border-radius: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
`;
const EditButtonLink = styled(Link)`
  height: 2.6875rem;
  line-height: 1.5625rem;
  font-size: 1.125rem;
  color: ${(props) => props.theme.colors.gray[800]};
  background: ${(props) => props.theme.colors.gray[500]};
  border-radius: 0.625rem;
  width: 13.4375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
`;
const StepsShow = styled.div`
  height: 2.6875rem;
  line-height: 1.5625rem;
  font-size: 1.125rem;
  color: ${(props) => props.theme.colors.gray[800]};
  background: ${(props) => props.theme.colors.gray[500]};
  border-radius: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  width: 125px;
  font-weight: 700;
`;
const ExpandButton = styled.button`
  height: 2.6875rem;
  width: 2.6875rem;
  line-height: 1.125rem;
  font-size: 1.125rem;
  color: ${(props) => props.theme.colors.gray[800]};
  background: ${(props) => props.theme.colors.gray[500]};
  border-radius: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ExpandedDownIcon = styled(FaAngleDown)``;
const ExpandedUpIcon = styled(FaAngleUp)``;
const LineBreak = styled.div`
  height: 2px;
  background: ${(props) => props.theme.colors.gray[500]};
  margin: 0 1.9063rem;
`;
const CourseStatisticsContainer = styled.div`
  height: 13.4688rem;
  padding: 2.4688rem 4.9375rem 2.75rem 7.5rem;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  column-gap: 1.875rem;
`;
const DataComponent = styled.div`
  height: 8.1875rem;
  color: ${(props) => props.theme.colors.gray[800]};
  box-shadow: 0px 0px 10px #0000001a;
  padding: 1.875rem;
  border-radius: 10px;
`;
const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const LoadingSpinner = styled(FaSpinner)`
  animation: ${rotateAnimation} 1s linear infinite;
`;
const PromolinkIcon = styled(FaCopy)``;
const PromoLinkButton = styled.button`
  user-select: none;
  width: 10.75rem;
  height: 1.5625rem;
  margin-right: 3.125rem;
  display: flex;
  align-items: center;
  background: none;
  color: ${(props) => props.theme.colors.gray[600]};
`;
const PromoLinkText = styled.div`
  margin-left: 0.5rem;
`;
const DataComponentHeading = styled.div`
  font-size: 1.125rem;
  line-height: 1.5625rem;
`;
const DataComponentData = styled.div`
  margin-top: 0.5rem;
  line-height: 2.4375rem;
  font-size: 1.75rem;
  font-weight: 700;
`;
interface CourseActionButonSyledPropType {
  $active: boolean;
}
const CourseActionButtonStyled = styled.button<CourseActionButonSyledPropType>`
  height: 2.6875rem;
  width: 2.6875rem;
  border-radius: 10px;
  background: ${(props) =>
    props.$active
      ? props.theme.colors.gray[800]
      : props.theme.colors.gray[500]};
  svg {
    height: 1.125rem;
    width: 1.125rem;
    color: ${(props) =>
      props.$active ? "#ffffff" : props.theme.colors.gray[800]};
  }
`;

const PagesLink = styled(Link)``;

const ManagerImageContainer = styled.div`
  position: relative;
  &:hover .manager-tooltip {
    visibility: visible;
  }
`;

const ManagerImage = styled.img`
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 1.25rem;
`;

const ManagerTooltip = styled.div`
  position: absolute;
  white-space: nowrap;
  bottom: -2rem;
  background: ${(props) => props.theme.colors.gray[800]};
  color: #ffffff;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 0.5rem 1rem;
  border-radius: 10px;
  font-size: 0.875rem;
  visibility: hidden;
`;
// ------------------------

// types ----
export interface AdminCourseView {
  id: string;
  University: string;
  universityId: string;
  type: string;
  image: string;
  major: string;
  subject: string;
  language: Language;
  manager: {
    id: string;
    profilePicUrl: string;
    name: string;
  } | null;
  tags: {
    publish: boolean;
    teacher: boolean;
    date: boolean;
  };
  applications: number;
  promoClicks: number;
  Sales: number;
  Payments: number;
}

// --------

function CourseActionButton({
  loading,
  onClick,
  children,
  active,
  ...props
}: {
  loading: boolean;
  children: ReactNode;
  active: boolean;
  onClick: (e: MouseEvent) => void;
}): ReactElement<HTMLButtonElement> {
  return (
    <CourseActionButtonStyled
      $active={active}
      onClick={onClick}
      disabled={loading}
      {...props}
    >
      {children}
    </CourseActionButtonStyled>
  );
}

const CourseRenderer = forwardRef(function (
  {
    item,
    onClick,
    active,
    handleActive,
    id,
    ...props
  }: {
    item: AdminCourseView;
    active: boolean;
    handleActive: (e: number) => void;
    onClick: () => void;
    id: number;
  },
  ref: LegacyRef<HTMLDivElement>
) {
  const [loadingLink, setLoadingLink] = useState<boolean>(false);
  const { t } = useTranslation();
  const [link, setLink] = useState<string>();
  const navigate = useNavigate();
  async function hanldePromoLink(e: any) {
    e.stopPropagation();
    if (link) {
      navigator.clipboard
        .writeText(link)
        .then(() => {
          ReactToast.success("Link copied to the clipboard");
        })
        .catch((err) => {
          ReactToast.error("There was an error in copying link");
        });
      return;
    }
    setLoadingLink(true);
    const linkRes = await getPromoUrl(item.id);
    if (linkRes.success) {
      setLink(linkRes.data.promoLink);
      navigator.clipboard
        .writeText(linkRes.data.promoLink)
        .then(() => {
          ReactToast.success("Link copied to the clipboard");
        })
        .catch((err) => {
          ReactToast.error("There was an error in copying link");
        });
    } else {
      ReactToast("There was an error in getting promo link");
    }
    setLoadingLink(false);
  }
  const languageInfo = getLanguageInfo(item.language);
  return (
    <Container
      $active={active}
      role="button"
      tabIndex={0}
      {...props}
      onClick={onClick}
      ref={ref}
    >
      <InnerContainer>
        <DataContainer>
          <CourseImageContainer>
            <CourseImage src={item.image}></CourseImage>
          </CourseImageContainer>
          <CourseDataContainer>
            <UniversityNameAndCourseTypeContainer>
              <UniversityName>
                <UniversityIcon />
                &nbsp;&nbsp;{item.University}
              </UniversityName>
              <CourseTypeContainer>
                <LocationIcon />
                &nbsp;&nbsp;{item.type}
              </CourseTypeContainer>
            </UniversityNameAndCourseTypeContainer>
            <CourseInfoContainer>
              <CourseMajor>{item.major}</CourseMajor>
              <SquareIcon />
              <CourseSubject>{item.subject}</CourseSubject>
              <SquareIcon />
              <CourseLanguage>
                <languageInfo.icon
                  style={{
                    height: "100%",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    display: "inline-block",
                  }}
                />
              </CourseLanguage>
            </CourseInfoContainer>
            <CourseStatusContainer>
              {item.tags.publish ? (
                <StatusPositiveBadge>
                  {t("adminCourses.published")}
                </StatusPositiveBadge>
              ) : (
                <StatusNeutralBadge>
                  {t("adminCourses.concept")}
                </StatusNeutralBadge>
              )}
              {item.tags.teacher ? (
                <StatusPositiveBadge>
                  {t("adminCourses.teacherSelected")}
                </StatusPositiveBadge>
              ) : (
                <StatusNegativeBadge>
                  {t("adminCourses.noTeacherSelected")}
                </StatusNegativeBadge>
              )}
              {item.tags.date ? (
                <StatusPositiveBadge>
                  {t("adminCourses.dateSelected")}
                </StatusPositiveBadge>
              ) : (
                <StatusNegativeBadge>
                  {t("adminCourses.noDateSelected")}
                </StatusNegativeBadge>
              )}
            </CourseStatusContainer>
          </CourseDataContainer>
        </DataContainer>

        <ButtonsContainer>
          {item.manager && (
            <ManagerImageContainer>
              <ManagerTooltip className="manager-tooltip">
                {item.manager.name}
              </ManagerTooltip>
              <ManagerImage src={item.manager.profilePicUrl}></ManagerImage>
            </ManagerImageContainer>
          )}

          <CourseActionButton
            active={false}
            loading={loadingLink}
            onClick={hanldePromoLink}
          >
            {!loadingLink ? (
              <>
                <PromolinkIcon />
              </>
            ) : (
              <LoadingSpinner />
            )}
          </CourseActionButton>
          <CourseActionButton
            active={false}
            loading={false}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/${item.id}/${pageRouteNames.COURSE}`);
            }}
          >
            <FaEye />
          </CourseActionButton>
          <CourseActionButton
            active={false}
            loading={false}
            onClick={() => {
              navigate(`${item.id}/edit-course`);
            }}
          >
            <FaPenToSquare />
          </CourseActionButton>
          {/* <PromoLinkButton disabled={loadingLink} onClick={hanldePromoLink}>
            {!loadingLink ? (
              <>
                <PromolinkIcon />
                <PromoLinkText>Copy promo link</PromoLinkText>
              </>
            ) : (
              <LoadingSpinner />
            )}
          </PromoLinkButton> */}

          {/* <EditButtonLink to={`${item.id}/edit-course`}>
            {t("adminCourses.edit")}
          </EditButtonLink> */}
          {/* <StepsShow>11&nbsp;/&nbsp;20</StepsShow> */}
          <CourseActionButton
            active={active}
            onClick={(e) => {
              e.stopPropagation();
              handleActive(id);
            }}
            loading={false}
          >
            <FaChartSimple />
          </CourseActionButton>
          {/* <ExpandButton
            onClick={(e) => {
              e.stopPropagation();
              handleActive(id);
            }}
          >
            {" "}
            {active ? <ExpandedUpIcon /> : <ExpandedDownIcon />}
          </ExpandButton> */}
        </ButtonsContainer>
      </InnerContainer>
      {active && (
        <>
          <LineBreak />
          <CourseStatisticsContainer>
            <DataComponent>
              <DataComponentHeading>
                {t("adminCourses.promoClicks")}
              </DataComponentHeading>
              <DataComponentData>{item.promoClicks}</DataComponentData>
            </DataComponent>
            <DataComponent>
              <DataComponentHeading>
                {t("adminCourses.applications")}
                <PagesLink to={`${item.id}/applications`}>View list</PagesLink>
              </DataComponentHeading>
              <DataComponentData>{item.applications}</DataComponentData>
            </DataComponent>
            <DataComponent>
              <DataComponentHeading>
                {t("adminCourses.sales")}
              </DataComponentHeading>
              <DataComponentData>{item.Sales}</DataComponentData>
            </DataComponent>
            <DataComponent>
              <DataComponentHeading>
                {t("adminCourses.payments")}
                <PagesLink to={`${item.id}/payments`}>View list</PagesLink>
              </DataComponentHeading>
              <DataComponentData>{item.Payments}</DataComponentData>
            </DataComponent>
          </CourseStatisticsContainer>
        </>
      )}
    </Container>
  );
});

export default CourseRenderer;
function onClick(event: MouseEvent<HTMLButtonElement, MouseEvent>): void {
  throw new Error("Function not implemented.");
}
