import { useEffect, useState } from "react";
import { OverviewDataType } from "./main";
import { getAdminOverview } from "@API/services/Data/getAdminOverview";
import { mapAdminOverviewData } from "@lib/mapperFunctions/mapAdminOverviewData";
import { useSelector } from "react-redux";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { optionType } from "./DayRangeSelector";
import { ReactToast } from "@lib/toast";

export type OverViewDataReturnType<Loading extends boolean> =
  Loading extends true ? [true, undefined] : [false, OverviewDataType];

export function useOverviewData(
  dayRange: optionType,
  from: Date | undefined,
  to: Date | undefined
): OverViewDataReturnType<true | false> {
  const userInfo = useSelector(selectUser);
  const [loading, setLoading] = useState<boolean>(true);
  const [overviewData, setOverviewData] = useState<OverviewDataType>();
  // if (
  //   !userInfo.role ||
  //   userInfo.role !== UserRole.ADMIN ||
  //   userInfo.role !== UserRole.MANAGER
  // ) {
  //   throw new Error("Not authorized");
  // }
  async function setOverviewDataAsync() {
    setLoading(true);
    const data = await getAdminOverview(dayRange.id, userInfo.role, from, to);
    if (data.success) {
      const formattedData = mapAdminOverviewData(data.data);
      setOverviewData(formattedData);
      setLoading(false);
    } else {
      ReactToast.error("Could not get overview data");
    }
  }
  useEffect(() => {
    setOverviewDataAsync();
  }, [dayRange, to, from]);
  return [loading, overviewData] as OverViewDataReturnType<true | false>;
}
