import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";

import { AdminSubject } from "@lib/Types/AdminData/AdminEntitiyList";

type AdminSubjectListType =
  | {
      success: true;
      data: AdminSubject[];
    }
  | {
      success: false;
      data: string;
    };

export const getAdminSubjectList = async function (
  id: string
): Promise<AdminSubjectListType> {
  const url = urlFunctions.getAdminSubjectList(id);
  const res = await API.sendGetRequest(url);
  if (res.success) {
    const data: AdminSubject[] = res.data;
    return {
      success: true,
      data: data,
    };
  } else {
    return {
      success: false,
      data: "There was an error in getting subject list",
    };
  }
};
