import { Language } from "@lib/Enums/Language";
import { createSlice } from "@reduxjs/toolkit";

interface Settings {
  language: Language | undefined;
}

const initialState: Settings = {
  language: undefined,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
});
