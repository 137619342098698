import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";
import { mapResponseToCourseDetails } from "@lib/mapperFunctions/mapEnrolledCourseDetails";
import { CourseDetailsResponse } from "@lib/Types/API/APICourseDetails";
import { EnrolledCourseDetailsData } from "@components/StudentDashBoardSubPages/StudentCourseOverview/EnrolledCourseDetails";
export const getCourseDetails = async function ({
  id,
}: {
  id: string;
}): Promise<EnrolledCourseDetailsData | undefined> {
  const url = urlFunctions.getStudentCourseDetails(id);
  const res = await API.sendGetRequest(url, true);
  if (res.success) {
    const data: CourseDetailsResponse = res.data;
    const mappedData: EnrolledCourseDetailsData =
      await mapResponseToCourseDetails({ item: data });
    return mappedData;
  } else {
  }
  return undefined;
};
