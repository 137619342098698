import styled from "styled-components";
import CourseComponent from "./main";
import { Link, useNavigate, useParams } from "react-router-dom";
import { colors, device } from "@styles/theme";
import { Language } from "@lib/Enums/Language";
import { useTranslation } from "react-i18next";
import { CourseAction } from "@lib/Enums/CourseAction";
import { useSelector } from "react-redux";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { UserRole } from "@app/redux/slices/userSlice";
import { UniversityInfo } from "./SampleCourseData";
import pageRouteNames from "@/constants/pageRouteNames";
// styled-components----
const FullLink = styled(Link)`
  display: block;
  width: 100%;
`;

const Container = styled.div`
  background: #ffffff;
  padding: 1.875rem;
  width: 100%;
  border-radius: 20px;
  @media ${device.tablet} {
    padding: 1.25rem;
    border-radius: 10px;
  }
`;
const CompletelyFullButton = styled.div`
  padding: 0.5625rem 0;
  width: 100%;
  background: ${(props) => props.theme.colors.gray[500]};
  border-radius: 10px;
  line-height: 25px;
  font-size: 18px;
  color: ${(props) => props.theme.colors.gray[800]};
  text-align: center;
  flex-grow: 1;
  min-width: 9rem;
`;

interface SignUpButtonProps {
  $isEnrolled: boolean;
}

const SignUpButton = styled.button<SignUpButtonProps>`
  padding: 0.5625rem 0;
  width: 100%;
  background: ${(props) =>
    props.$isEnrolled ? colors.green[600] : props.theme.colors.blue[800]};
  border-radius: 10px;
  line-height: 25px;
  font-size: 18px;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  min-width: 9rem;
  flex-grow: 1;
  @media ${device.tablet} {
    font-size: 0.6875rem;
    line-height: 1rem;
    padding: 0.375rem 0;
    border-radius: 5px;
  }
`;
// ---------------------
export interface NonEnrolledCourseDataType {
  id: string;
  // image: string;
  // university: string;
  university: UniversityInfo;
  study: string;
  status: boolean;
  major: string;
  subject: string;
  language: Language;
  type: string;
  action?: CourseAction;
}
function NonEnrolledCourse({
  item,
  renderSuggestion,
}: {
  item: NonEnrolledCourseDataType;
  renderSuggestion?: boolean;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { lang } = useParams();
  const userInfo = useSelector(selectUser);
  const something = !(
    userInfo.loggedIn || item.action === CourseAction.NO_ACTION
  );
  async function enrollInMasterClass() {
    navigate(`/${lang}/${item.id}/${pageRouteNames.COURSE}`);
  }
  function handleReserveButtonClick(action: CourseAction | undefined) {
    if (!userInfo.loggedIn) {
      navigate(`/${lang}/signin`, {
        state: { to: `/${item.id}/${pageRouteNames.COURSE}/checkout` },
      });
      return;
    }
    switch (action) {
      case CourseAction.RESERVE_YOUR_SPOT:
        if (userInfo.loggedIn) {
          if (item.type === "Masterclass") {
            enrollInMasterClass();
          } else {
            navigate(`/${item.id}/${pageRouteNames.COURSE}/checkout`);
          }
        } else {
          navigate(`/${lang}/signin`);
        }
        return;
      case CourseAction.STUDENT_COURSE_DETAIL:
        if (userInfo.role === UserRole.STUDENT) {
          navigate(`/${lang}/dashboard/overview/${item.id}/details`);
        }
        return;
      case CourseAction.TEACHER_COURSE_DETAIL:
        if (userInfo.role === UserRole.TEAHCER) {
          navigate(`/${lang}/teacher-dashboard/courses/${item.id}/details`);
        }
        return;
      case CourseAction.EDIT_COURSE:
        if (
          userInfo.role === UserRole.MANAGER ||
          userInfo.role === UserRole.ADMIN
        ) {
          navigate(`/${lang}/admin-dashboard/courses/${item.id}/edit-course`);
        }
        return;
      default:
        if (renderSuggestion) {
          navigate(`/${lang}/${item.id}/${pageRouteNames.COURSE}`);
        } else {
          return "";
        }
    }
  }
  function getReserveButtonText(action: CourseAction | undefined) {
    switch (action) {
      case CourseAction.RESERVE_YOUR_SPOT:
        return t("sdashboard.overview.course_components.sign_up_button");
      case CourseAction.STUDENT_COURSE_DETAIL:
        return "Details";
      case CourseAction.TEACHER_COURSE_DETAIL:
        return "Details";
      case CourseAction.EDIT_COURSE:
        return "Edit";
      default:
        return t("sdashboard.overview.course_components.sign_up_button");
    }
  }
  return (
    <Container
      onClick={() => {
        navigate(`/${lang}/${item.id}/${pageRouteNames.COURSE}`);
      }}
      role="button"
      aria-label="expand by clicking"
    >
      <CourseComponent data={item}>
        {(!userInfo.loggedIn || !(item.action === CourseAction.NO_ACTION)) && (
          <SignUpButton
            $isEnrolled={
              !(
                renderSuggestion ||
                CourseAction.RESERVE_YOUR_SPOT === item.action ||
                item.action === CourseAction.NO_ACTION
              )
            }
            onClick={(e) => {
              e.stopPropagation();
              handleReserveButtonClick(item.action);
            }}
          >
            {getReserveButtonText(item.action)}
          </SignUpButton>
        )}
      </CourseComponent>
    </Container>
  );
}

export default NonEnrolledCourse;
