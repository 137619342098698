import React from "react";
import { returnType } from "@lib/hooks/useCourseDetails";
import styled from "styled-components";
import { mixin, device } from "@styles/theme";

const Container = styled.div`
  display: flex;
  overflow-x: scroll;
  max-width: 100%;
  gap: 20px;
  ${mixin.hideScroll()};
`;

const CourseScheduleContainer = styled.div`
  background: #f2f5f8 0% 0% no-repeat padding-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
  margin-top: 20px;
  min-width: 234px;
`;

const MediumText = styled.h4`
  font-size: 1.025rem;
  color: #707070;
  font-weight: bold;
  @media ${device.mobileL} {
    font-size: 0.825rem;
  }
`;

const SmallText = styled.p`
  font-size: 0.785rem;
  color: #707070;
  font-weight: normal;
  @media ${device.mobileL} {
    font-size: 0.785rem;
  }
`;

const CourseSchedule = ({ details }: { details: returnType<true | false> }) => {
  const [loading, courseDetails] = details;

  return (
    <div>
      {!loading && (
        <Container>
          {courseDetails &&
            courseDetails.courseSchedules.map((schedule) => (
              <CourseScheduleContainer>
                <MediumText>{schedule.name}</MediumText>
                <SmallText>{schedule.date}</SmallText>
                <SmallText>
                  {schedule.startTime}- {schedule.endTime}
                </SmallText>
              </CourseScheduleContainer>
            ))}
        </Container>
      )}
    </div>
  );
};

export default CourseSchedule;
