import styled from "styled-components";
import TeachersRatingComponent from "./TeachersRatingComponent";
import AkcRatingComponent from "./AkcRatingComponent";
// styled.components------
const Container = styled.div`
  display: flex;
  gap: 1.875rem;
`;
// -----------------------

function RatingComponents() {
  return (
    <Container>
      <TeachersRatingComponent />
      <AkcRatingComponent />
    </Container>
  );
}

export default RatingComponents;
