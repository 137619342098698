import { API } from "@API/API";
import { mapResponseToAdminAdmins } from "@lib/mapperFunctions/mapAdminAdmin";
import { AdminData } from "@components/AdminDashboard/SubPages/Settings/SubPages/AdminAndManagers/AdminAdmin";
import { AdminAdminResponseArray } from "@lib/Types/API/APIAdminAdmin";
import { urlFunctions } from "@API/functions/createUrl";

export const getAdminAdmins = async (): Promise<AdminData[] | undefined> => {
  const url = urlFunctions.getAdminAdmin();
  const res = await API.sendGetRequest(url, true);
  if (res.success) {
    const data: AdminAdminResponseArray = res.data;
    const mappedData = mapResponseToAdminAdmins(data);
    return mappedData;
  }
  return undefined;
};
