import { getCourseSearchList } from "@API/services/Search/getCourseSearchList";
// import
import { NonEnrolledCourseDataType } from "@components/CommonComponents/CourseComponent/NonEnrolledCourse";
import { CourseSearchListType } from "@API/services/Search/getCourseSearchList";
import { CourseStatus } from "@lib/Enums/CourseStatus";
export const getCoursesForSearch = async (): Promise<
  NonEnrolledCourseDataType[] | undefined
> => {
  const items: CourseSearchListType = await getCourseSearchList(
    undefined,
    undefined,
    undefined
  );
  if (typeof items.data !== "string")
    return items.data.map((item) => ({
      id: item.courseId,
      university: {
        id: item.university.id.toString(),
        universityLogoUrl: item.university.universityLogoUrl,
        universityName: item.university.universityName,
      },
      study: item.courseStudy.name,
      subject: item.courseSubject.name,
      language: item.language,
      major: item.courseSubject.name,
      status: item.courseStatus === CourseStatus.PUBLISH,
      type: item.courseType.courseType,
    }));
  return undefined;
};
