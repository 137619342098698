import { FaBullseye } from "react-icons/fa6";
import styled from "styled-components";
import ProfileStatus from "./ProfileStatus";
import SmartPointsInfo from "./SmartPointsInfo";
import { device } from "@styles/theme";

// styled-components----
const ExtraInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
  @media ${device.tablet} {
    gap: 1.25rem;
  }
  @media ${device.laptopL} {
    flex-direction: row;
  }
  @media ${device.laptopS} {
    flex-direction: column;
    
  }
`;
// ---------------------

function ExtraInfo() {
  return (
    <ExtraInfoContainer>
      <ProfileStatus />
      <SmartPointsInfo />
    </ExtraInfoContainer>
  );
}

export default ExtraInfo;
