import React from "react";
import styled, { keyframes } from "styled-components";
import { TeacherData } from "../AdminTeachers";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { mapTeachersTableDataToProfileComponent } from "@lib/mapperFunctions/admin/mapTeachersTableDataToProfileComponent";
import { FaHourglassHalf, FaCircleCheck } from "react-icons/fa6";
// start of styled-components
const Container = styled.div`
  background: #ffffff;
  padding: 1.875rem;
  padding-bottom: 10rem;
  border-radius: 20px;
`;
const TableStyled = styled.table`
  width: 100%;
`;
const TableHeader = styled.thead``;
const TableHeading = styled.th`
  border-bottom: 0.125rem solid ${(props) => props.theme.colors.gray[500]};
  padding-bottom: 1.875rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.4375rem;
  color: ${(props) => props.theme.colors.gray[600]};
`;
const TableHeadingRow = styled.tr``;
const TableDataRow = styled.tr`
  border-bottom: 0.125rem solid ${(props) => props.theme.colors.gray[500]};
`;
const TableBody = styled.tbody``;
const TableDataCell = styled.td`
  padding: 1.125rem 0;
  font-size: 1rem;
  line-height: 1.4375rem;
  color: ${(props) => props.theme.colors.gray[800]};
  margin-right: 5rem;
`;
const TableCellDetailsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 4.25rem;
  align-items: center;
`;
const TableCellDataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 6.0625rem;
`;
const TableDataText = styled.span`
  display: inline-block;
`;
const Boldtext = styled.span``;
const Details = styled(Link)`
  padding: 0.375rem 2.875rem;
  font-size: 1rem;
  line-height: 1.4375rem;
  color: ${(props) => props.theme.colors.gray[800]};
  background: ${(props) => props.theme.colors.gray[500]};
  border-radius: 10px;
  margin-left: 1.875rem;
`;
const AccessToTableCell = styled(TableDataCell)``;

const skeletonLoading = keyframes`
  0% {
    background: hsl(200,20%,70%);
  }
  100% {
    background: hsl(200,20%,95%)
  }
`;
const PendingInvitationIcon = styled(FaHourglassHalf)`
  width: 0.875rem;
  height: 1.125rem;
  color: ${(props) => props.theme.colors.yellow[600]};
`;
const AcceptedInvitaionIcon = styled(FaCircleCheck)`
  width: 1.125rem;
  height: 1.125rem;
  color: ${(props) => props.theme.colors.green[600]};
`;
const LoadingComponent = styled.div`
  width: 100%;
  height: 2.1875rem;
  animation: ${skeletonLoading} 1s linear infinite alternate;
`;

function TableSkeleton() {
  return (
    <TableDataRow>
      <TableDataCell>
        <LoadingComponent />
      </TableDataCell>
      <TableDataCell>
        <LoadingComponent />
      </TableDataCell>
      <TableDataCell>
        <LoadingComponent />
      </TableDataCell>
      <TableDataCell>
        <LoadingComponent />
      </TableDataCell>
      <TableDataCell>
        <LoadingComponent />
      </TableDataCell>
    </TableDataRow>
  );
}

function Table({ data, loading }: { data: TeacherData[]; loading: boolean }) {
  const { t } = useTranslation();
  return (
    <Container>
      <TableStyled>
        <TableHeader>
          <TableHeadingRow>
            <TableHeading>
              {t("adminSettings.teachersPage.firstName")}
            </TableHeading>
            <TableHeading>
              {t("adminSettings.teachersPage.lastName")}
            </TableHeading>
            <TableHeading>{t("adminSettings.teachersPage.role")}</TableHeading>
            <TableHeading>
              <div></div>
            </TableHeading>
          </TableHeadingRow>
        </TableHeader>
        <TableBody>
          {loading
            ? Array.from({ length: 5 }).map((e) => <TableSkeleton />)
            : data.map((e) => (
                <TableDataRow>
                  <TableDataCell>
                    <TableDataText>{e.firstName}</TableDataText>
                  </TableDataCell>
                  <TableDataCell>
                    <TableCellDataContainer>
                      <TableDataText>{e.LastName}</TableDataText>
                    </TableCellDataContainer>
                  </TableDataCell>
                  <TableDataCell>
                    <TableCellDataContainer>
                      <TableDataText>{e.role}</TableDataText>
                    </TableCellDataContainer>
                  </TableDataCell>
                  <TableDataCell>
                    <TableCellDetailsContainer>
                      {e.accepted ? (
                        <AcceptedInvitaionIcon />
                      ) : (
                        <PendingInvitationIcon />
                      )}
                      <Details
                        to={`${e.teacherId}`}
                        state={mapTeachersTableDataToProfileComponent(e)}
                      >
                        {t("adminSettings.teachersPage.details")}
                      </Details>
                    </TableCellDetailsContainer>
                  </TableDataCell>
                </TableDataRow>
              ))}
        </TableBody>
      </TableStyled>
    </Container>
  );
}

export default Table;
