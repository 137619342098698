import { device, mixin } from "@styles/theme";
import { styled } from "styled-components";
import { Message } from "./ChatsComponent";
import { UserRole, getRoleString } from "@app/redux/slices/userSlice";
import { formatDateTime } from "./functions";
import Reaction from "./Reaction";
import { Dispatch, SetStateAction } from "react";
import "./chat-styles.css";
// styled.components ----

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  max-width: 90%;
  @media ${device.tablet} {
    max-width: 100%;
  }
`;
interface ChatProps {
  id: string;
  name: string;
  role: UserRole;
}
const ToChatStyled = styled.div<ChatProps>`
  background: #d6f7d1;
  /* background:  ${(props) =>
    mixin.shadeOfBlue(props.id, props.name, props.role)}; */
  padding: 1.25rem 1.25rem 1.5625rem 1.25rem;
  border-radius: 10px 10px 0px 10px;
  position: relative;
  align-self: flex-end;
  min-width: 5rem;
`;
const NameAndRoleContainer = styled.div`
  display: flex;
  margin-top: 0.5rem;
`;
const Name = styled.div`
  font-size: 1rem;
  line-height: 1.4375rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.gray[800]};
  @media ${device.tablet} {
    font-size: 0.875rem;
  }
`;
const Role = styled.div`
  font-size: 1rem;
  line-height: 1.4375rem;
  color: ${(props) => props.theme.colors.gray[800]}a6;
  @media ${device.tablet} {
    font-size: 0.875rem;
  }
`;
const MessageContainer = styled.div`
  margin-top: 0.25rem;
  font-size: 1rem;
  line-height: 1.4375rem;
  color: ${(props) => props.theme.colors.gray[800]};
  @media ${device.tablet} {
    font-size: 0.875rem;
  }
`;
const DateAndTimeContainer = styled.div`
  font-size: 0.75rem;
  line-height: 1.0625rem;
  color: ${(props) => props.theme.colors.gray[800]}a6;
  position: absolute;
  right: 0.625rem;
  top: 0.625rem;
`;
const ReactionAndAccountContainer = styled.div`
  margin-top: 1rem;
  right: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`;

const Avatar = styled.img`
  height: 2.125rem;
  width: 2.125rem;
  border-radius: 1.0625rem;
`;

const ReactionsContainer = styled.div``;

// ----------------------

export default function FromChat({
  message,
  emojiActive,
  setEmojiNumber,
}: {
  message: Message;
  emojiActive: string | null;
  setEmojiNumber: Dispatch<SetStateAction<string | null>>;
}) {
  return (
    <OuterContainer>
      <ToChatStyled
        id={message.user.id}
        name={message.user.name}
        role={message.user.role}
      >
        <DateAndTimeContainer>
          {formatDateTime(message.timeStamp)}
        </DateAndTimeContainer>
        <NameAndRoleContainer>
          <Name>{message.user.name}</Name>
          <Role>
            &nbsp;&nbsp;-&nbsp;&nbsp;{getRoleString(message.user.role)}
          </Role>
        </NameAndRoleContainer>
        <MessageContainer
          id="message"
          dangerouslySetInnerHTML={{ __html: message.text }}
        ></MessageContainer>
      </ToChatStyled>
      <ReactionAndAccountContainer>
        <ReactionsContainer>
          <Reaction
            position="LEFT"
            id={message.id}
            emojiActive={emojiActive}
            setEmojiNumber={setEmojiNumber}
            message={message}
          />
        </ReactionsContainer>
        <Avatar src={message.user.profilePicUrl} />
      </ReactionAndAccountContainer>
    </OuterContainer>
  );
}
