export enum IntentErrorType {
  ALREADY_ENROLLED = 101,
  MASTER_CLASS = 102,
  SUCCESS = 100,
  FAILURE = 103,
}

export interface APIPaymentIntent {
  clientSecret: string;
  statusCode: IntentErrorType;
}
