import { useSelect } from "downshift";
import { IconType } from "react-icons";
import {
  FaAngleDown,
  FaAngleUp,
  FaRegCalendarDays,
  FaSort,
} from "react-icons/fa6";
import styled from "styled-components";
import { useEffect, useState } from "react";

// start of styled-components
const SelectContainer = styled.div`
  position: relative;
`;
const SelectButtonContainer = styled.div``;
interface SelectButtonPropsType {
  $open: boolean;
}
const SelectButton = styled.button<SelectButtonPropsType>`
  width: 14rem;
  padding: 0.6563rem 1.25rem;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${(props) =>
    props.$open ? "10px 0px 0px 0px" : "10px 0px 0px 10px"};
  outline: none;
  border: none;
  cursor: pointer;
  color: ${(props) => props.theme.colors.gray[600]};
`;
const SelectButtonText = styled.span`
  line-height: 1.5625rem;
  font-size: 1.125rem;
  white-space: nowrap;
  overflow: hidden;
`;
const SelectButtonIconContainer = styled.div`
  width: 1.125rem;
  height: 1.125rem;
`;
const SelectButtonDownIcon = styled(FaAngleDown)`
  width: 1.125rem;
  height: 1.125rem;
  margin-left: 2rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const SelectButtonUpIcon = styled(FaAngleUp)`
  width: 1.125rem;
  height: 1.125rem;
  margin-left: 2rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const SortIcon = styled(FaSort)`
  width: 1.125rem;
  height: 1.125rem;
  min-width: 1.125rem;
  min-height: 1.125rem;
  margin-left: 2rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;
interface OptionsListPropsTyps {
  open: boolean;
}
const OptionsList = styled.ul<OptionsListPropsTyps>`
  width: 100%;
  background: ${(props) => props.theme.colors.gray[800]};
  position: absolute;
  right: 0;

  display: ${(props) => (props.open ? "block" : "none")};
  border-radius: 0 0 10px 10px;
  padding: 0 0rem;

  max-height: 20rem;
  overflow-y: scroll;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
`;

const ListItem = styled.li`
  padding: 0.5rem 1rem;
  cursor: pointer;
  text-align: left;
  color: #ffffff;
  transition: background 0.2s;
  transition: color 0.2s;
  line-height: 1.5625rem;
  font-size: 1.125rem;
  overflow-wrap: break-word;
  &:hover {
    background: ${(props) => props.theme.colors.gray[600]};
    color: #ffffff;
  }
`;
// end of styled-components
export interface optionType {
  id: string;
  value: string;
}
function itemToString(item: any) {
  return item ? item.value : "";
}
function DayRangeSelector({
  text,
  onChange,
  options,
  disabled,
  reset,
  value,
  sort = false,
  removeHyphen = false,
  ...props
}: {
  text: string;
  onChange: (item: optionType | null | undefined) => void;
  reset?: boolean;
  disabled?: boolean;
  value: optionType | null | undefined;
  sort?: boolean;
  removeHyphen?: boolean;
  options: optionType[];
}) {
  const [selectedItem, setSelectedItem] = useState<
    optionType | null | undefined
  >();
  const {
    selectItem,
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    onSelectedItemChange: (e) => {
      onChange(e.selectedItem);
      setSelectedItem(e.selectedItem);
    },
    items: options,
    itemToString,
  });
  // useEffect(() => {
  //   if (value) {
  //     selectItem(value);
  //   }
  // }, []);
  return (
    <SelectContainer {...props}>
      <SelectButtonContainer>
        <SelectButton
          $open={isOpen}
          type="button"
          disabled={disabled}
          {...getToggleButtonProps()}
        >
          <SelectButtonText>
            {value ? value.value : `${!removeHyphen ? "— " : ""}${text}`}
          </SelectButtonText>

          {!sort &&
            (isOpen ? <SelectButtonUpIcon /> : <SelectButtonDownIcon />)}
          {sort && <SortIcon />}
        </SelectButton>
      </SelectButtonContainer>
      <OptionsList open={isOpen} {...getMenuProps()}>
        {isOpen &&
          options.map((item, index) => {
            return (
              <ListItem key={`${item.id}.${index}`} {...getItemProps({ item })}>
                <span>{item.value}</span>
              </ListItem>
            );
          })}
      </OptionsList>
    </SelectContainer>
  );
}

export default DayRangeSelector;
