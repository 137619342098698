import styled from "styled-components";
// import  { CourseType } from "./SampleCourseData";
import { FaAngleDown, FaBuildingColumns, FaLocationDot } from "react-icons/fa6";
import { ReactNode, useEffect, useRef, useState } from "react";
import { NonEnrolledCourseDataType } from "./NonEnrolledCourse";
import { EnrolledCourseDetailsData } from "@components/StudentDashBoardSubPages/StudentCourseOverview/EnrolledCourseDetails";
import { CourseType, EnrolledCourses } from "./SampleCourseData";
import { assetsUrls, device } from "@styles/theme";
import { getLanguageInfo } from "@lib/Enums/Language";
import { TeacherCourseDetailsType } from "@components/TeachersDashboard/TeacherComponents/TeachersCourseDetails/TeachersCourseDetails";
// styled-components----
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // width:80%;
  gap: 1.25rem;
  @media ${device.tablet} {
    gap: 0.9375rem;
  }
`;
const ImageContainer = styled.div`
  width: 3.625rem;
  @media ${device.tablet} {
    width: 2.75rem;
  }
`;
const Image = styled.img`
  width: 3.625rem;
  @media ${device.tablet} {
    width: 2.75rem;
  }
`;
const ImageAndCourseDetailsContainer = styled.div`
  display: flex;
`;
const CourseDetailsContainer = styled.div`
  margin-left: 2rem;
  margin-top: 0.1875rem;
  display: flex;
  flex-direction: column;
  @media ${device.tablet} {
    margin-left: 0.9375rem;
  }
`;
const UniversityNameAndEventContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.gray[600]};
  font-size: 0.875rem;
  gap: 1.25rem;
  line-height: 1.25rem;
  @media ${device.tablet} {
    font-size: 0.625rem;
    line-height: 1rem;
    gap: 0.1875rem;
    align-items: normal;
    flex-direction: column;
  }
`;
const UniversityNameContainer = styled.div`
  display: flex;
  align-items: center;
`;
const EventContainer = styled.div`
  display: flex;
  align-items: center;
`;
const LocationIcon = styled(FaLocationDot)``;
const UniversityIcon = styled(FaBuildingColumns)``;
const NormalText = styled.span`
  font-size: 1.125rem;
  line-height: 1.5625rem;
  display: block;
`;
const BoldText = styled.span`
  font-size: 1.125rem;
  line-height: 1.5625rem;
  font-weight: 700;
  display: block;
`;
const CourseMetaContainer1 = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  color: ${(props) => props.theme.colors.gray[800]};
  @media ${device.tablet} {
    font-size: 0.75rem;
    line-height: 1.0625rem;
    margin-top: 0.25rem;
    display: none;
  }
`;
const CourseMetaContainer2 = styled(CourseMetaContainer1)`
  display: none;
  @media ${device.tablet} {
    display: flex;
  }
`;
const CourseMajor = styled.span`
  font-weight: 700;
`;
const CourseSubject = styled.span``;
const CourseLanguage = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
const CourseLanguageIcon = styled.div`
  position: relative;
  border-radius: 0.75rem;
  overflow: hidden;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
`;
const Square = styled.div`
  height: 0.5rem;
  width: 0.5rem;
  min-height: 0.5rem;
  min-width: 0.5rem;
  width: 0.5rem;
  background: ${(props) => props.theme.colors.gray[500]};
  margin: 0 1.25rem;
  @media ${device.tablet} {
    margin: 0 0.625rem;
    height: 0.25rem;
    width: 0.25rem;
    min-height: 0.25rem;
    min-width: 0.25rem;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
  @media ${device.tablet} {
    gap: 0.625rem;
  }
`;

// ---------------------
function CourseComponent({
  children,
  data,
  hideMetaContainer,
  ...props
}: {
  children?: ReactNode;
  hideMetaContainer?: boolean;
  data:
    | CourseType
    | NonEnrolledCourseDataType
    | EnrolledCourses
    | EnrolledCourseDetailsData
    | TeacherCourseDetailsType;
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const Flag = getLanguageInfo(data.language).icon;
  const LanguageName = getLanguageInfo(data.language).notation;
  useEffect(() => {
    const element = containerRef.current;
    const changeLayout = () => {
      if (element) {
        const elementIsShort = element.offsetWidth < 950;
        if (elementIsShort) {
          element.style.display = "grid";
          element.style.gridTemplateColumns = "minmax(0,1fr)";
        } else {
          element.style.display = "flex";
        }
        const elementIsVertyShort = element.offsetWidth <= 728;
      }
    };
    changeLayout();
    if (element) {
      window.addEventListener("resize", changeLayout);
    }
    return () => {
      window.removeEventListener("resize", changeLayout);
    };
  }, [containerRef.current]);
  return (
    <Container ref={containerRef} {...props}>
      <ImageAndCourseDetailsContainer id="image-and-course-details-container">
        <ImageContainer>
          <Image
            src={
              data.university.universityLogoUrl
                ? data.university.universityLogoUrl
                : assetsUrls.userPng2
            }
          />
        </ImageContainer>
        <CourseDetailsContainer>
          <UniversityNameAndEventContainer>
            <UniversityNameContainer>
              <UniversityIcon />
              &nbsp;&nbsp;{data.university.universityName}
            </UniversityNameContainer>
            <EventContainer>
              <LocationIcon />
              &nbsp;&nbsp;{data.type}
            </EventContainer>
          </UniversityNameAndEventContainer>

          <CourseMetaContainer1>
            <CourseMajor>{data.study}</CourseMajor>
            <Square />
            <CourseSubject>{data.subject}</CourseSubject>
            <Square />
            <CourseLanguage>
              <CourseLanguageIcon>
                <Flag
                  style={{
                    height: "100%",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    display: "inline-block",
                  }}
                />
              </CourseLanguageIcon>
              {LanguageName}
            </CourseLanguage>
          </CourseMetaContainer1>
        </CourseDetailsContainer>
      </ImageAndCourseDetailsContainer>
      {!hideMetaContainer && (
        <CourseMetaContainer2>
          <CourseMajor>{data.study}</CourseMajor>
          <Square />
          <CourseSubject>{data.subject}</CourseSubject>
          <Square />
          <CourseLanguage>
            <CourseLanguageIcon>
              <Flag
                style={{
                  height: "100%",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  display: "inline-block",
                }}
              />
            </CourseLanguageIcon>
            {LanguageName}
          </CourseLanguage>
        </CourseMetaContainer2>
      )}
      {children && <ButtonsContainer>{children}</ButtonsContainer>}
    </Container>
  );
}

export default CourseComponent;
