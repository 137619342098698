import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";
import { CourseStatus } from "@lib/Enums/CourseStatus";
import { AddCourseFormSubmitDataType } from "@lib/Types/AddCourseFormSubmitType";
import { mapFormToAPI } from "@lib/mapperFunctions/mapFormToAPI";
import { mapFormToAPIDrafted } from "@lib/mapperFunctions/mapFormToAPIDrafted";
import { ReactToast } from "@lib/toast";

type createCourseResponseType =
  | {
      success: true;
      data: { id: string };
    }
  | {
      success: false;
      data: string;
    };

export const createCourse = async function (
  data: AddCourseFormSubmitDataType,
  status: CourseStatus
): Promise<createCourseResponseType> {
  let formattedData;
  if (status === CourseStatus.PUBLISH) {
    formattedData = mapFormToAPI(data);
  } else {
    formattedData = mapFormToAPIDrafted(data);
  }
  const url = urlFunctions.createCourse();
  const res = await API.sendPostRequest(url, formattedData, true);
  const ResData: { id: string } = res.data;
  if (res.success) {
    return {
      success: true,
      data: { id: ResData.id },
    };
  } else {
    return {
      success: false,
      data: "Could not create course try again",
    };
  }
};
