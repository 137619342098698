import styled from "styled-components";
import DataComponent from "./DataComponent";
import { FaAngleDown, FaCalendar } from "react-icons/fa6";
import UniversitiesMetrics from "./UniversitiesMetrics";
import GreetingComponent from "@components/CommonComponents/GreetingComponent";
import { useSelector } from "react-redux";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { useEffect, useState } from "react";
import { OverviewtableData } from "./Table/main";
import { useTranslation } from "react-i18next";
import DateRangeSelector from "@lib/microComponents/DateRangeSelector";
import { DateRange } from "react-day-picker";
import { format, sub } from "date-fns";
import { optionType } from "@components/LandingPage/FormSelect";
import DayRangeSelector from "./DayRangeSelector";
import { useOverviewData } from "./userOverviewData";
import { UserRole } from "@app/redux/slices/userSlice";
// start of styled-components
const Container = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

const DataContainer = styled.div`
  flex-grow: 1;
`;

const HeadingAndFilterContainer = styled.div`
  margin-top: 1.875rem;
  display: flex;
  justify-content: space-between;
  align-items: end;
`;
const SubHeading = styled.h2`
  font-size: 1.375rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const FileterContainer = styled.div`
  display: flex;
`;
const DaysFilterContainer = styled.div``;
const DaysFilter = styled.button`
  height: 2.875rem;
  background: #ffffff;
  color: ${(props) => props.theme.colors.gray[800]};
  padding: 0rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 1.125rem;
  border-radius: 10px 0px 0px 10px;
`;

const DateFilterContainer = styled.div`
  position: relative;
`;

const DateFilter = styled(DaysFilter)`
  margin-left: 2px;
  border-radius: 0px 10px 10px 0px;
`;

const DateRangeContainer = styled.div`
  right: 0;
  position: absolute;
`;

// end of styled-components

const periodSelectorOptions = [
  {
    id: "0",
    key: 7,
    value: "last 7 days",
  },
  {
    id: "1",
    key: 30,
    value: "last 30 days",
  },
  {
    id: "2",
    key: 90,
    value: "last 3 months",
  },
];

export interface OverviewDataType {
  sales: {
    thisPeriod: number;
    lastPeriod: number;
    percentageChange: number;
  };
  metricsData: UnitData[];
  chartData: ChartData;
  universityTableData: OverviewtableData[] | null;
  managersTableData: OverviewtableData[] | null;
  coursesTableData: OverviewtableData[] | null;
}
interface UnitData {
  heading: string;
  percentIncrease: number;
  value: number;
  pastPeriodValue: number;
}
export type ChartData = [
  { sales: number[] },
  { sales: number[] },
  { startDate: string; endDate: string }
];

function AdminOverview() {
  const userInfo = useSelector(selectUser);
  const [dateRange, setDateRange] = useState<DateRange>({
    from: sub(new Date(), { days: 7 }),
    to: new Date(),
  });
  const [dayRange, setDayRange] = useState<optionType>(
    periodSelectorOptions[0]
  );
  const [showRangeSelector, setShowRangeSelector] = useState<boolean>(false);
  const overviewState = useOverviewData(dayRange, dateRange.from, dateRange.to);
  const { t } = useTranslation();
  useEffect(() => {
    setDateRange({
      from: undefined,
      to: undefined,
    });
  }, [dayRange]);
  return (
    <Container>
      <GreetingComponent
        username={userInfo.name ? userInfo.name : "there"}
        message={t("greetings.admin_sub_greetings")}
      />

      <HeadingAndFilterContainer>
        <SubHeading>
          <span style={{ fontWeight: 700 }}>
            {t("adminDashboard.overview")}&nbsp;
          </span>
          {userInfo.role === UserRole.ADMIN
            ? `${t("adminDashboard.all")} ${t("adminDashboard.universities")}`
            : t("adminDashboard.managerOverviewHeading")}
        </SubHeading>
        <FileterContainer>
          <DaysFilterContainer>
            <DayRangeSelector
              value={dayRange}
              onChange={(e) => {
                if (e) {
                  setDayRange(e);
                }
              }}
              text="select period"
              options={periodSelectorOptions}
            />
          </DaysFilterContainer>
          <DateFilterContainer>
            <DateFilter
              onClick={() => {
                setShowRangeSelector((value) => !value);
              }}
            >
              <FaCalendar
                style={{
                  height: "1.125rem",
                  width: "1.125rem",
                  marginRight: "1.8125rem",
                }}
              />
              {dateRange.from ? format(dateRange.from, "d MMM") : "   "}
              &nbsp;-&nbsp;
              {dateRange.to ? format(dateRange.to, "d MMM") : "    "}
            </DateFilter>
            {showRangeSelector && (
              <DateRangeContainer>
                <DateRangeSelector
                  setShow={setShowRangeSelector}
                  value={dateRange}
                  onChange={(e) => {
                    if (e) {
                      setDateRange(e);
                    }
                  }}
                />
              </DateRangeContainer>
            )}
          </DateFilterContainer>
        </FileterContainer>
      </HeadingAndFilterContainer>

      <DataContainer>
        <DataComponent overviewState={overviewState} />
        <UniversitiesMetrics overviewState={overviewState} />
      </DataContainer>
    </Container>
  );
}
<Container></Container>;

export default AdminOverview;
