import React from "react";
import View from "../main";
import StudentSupport from "@/components/StudentDashBoardSubPages/StudentSupport";
import styled from "styled-components";
import { device } from "@/styles/theme";

const Container = styled.div`
  padding: 50px;
  flex-grow: 1;
  height: 100%;
  @media ${device.tablet} {
    padding: 20px;
  }
`;

const Support = () => {
  return (
    <View isCheckout={false}>
      <Container>
        <StudentSupport />
      </Container>
    </View>
  );
};

export default Support;
