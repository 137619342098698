import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { UserRole } from "@app/redux/slices/userSlice";
import { optionType } from "@components/LandingPage/FormSelect";
import {
  APIAdminCourseView,
  AdminCourseListResponse,
} from "@lib/Types/API/APIAdminCourseView";
import { useSelector } from "react-redux";

type AdminCourseViewType =
  | {
      success: true;
      data: AdminCourseListResponse;
    }
  | {
      success: false;
      data: string;
    };

export const getAdminCourseList = async function (
  role: UserRole | undefined,
  offset: number,
  size: number,
  search: string,
  university?: optionType,
  study?: optionType
): Promise<AdminCourseViewType> {
  let url: string;
  if (role === UserRole.ADMIN) {
    url = urlFunctions.getAdminCourseList({
      offset,
      size,
      search,
      university: university ? university.id : undefined,
      study: study ? study.id : undefined,
    });
  } else if (role === UserRole.MANAGER) {
    url = urlFunctions.getManagerCourseList({
      offset,
      size,
      search,
      university: university ? university.id : undefined,
      study: study ? study.id : undefined,
    });
  } else {
    throw new Error("Not Authorised");
  }
  const res = await API.sendGetRequest(url, true);
  if (res.success) {
    const data: AdminCourseListResponse = res.data;
    return {
      success: true,
      data: data,
    };
  } else {
    return {
      success: false,
      data: "There was an error in getting course list",
    };
  }
};
