import React, { useEffect, useRef, useState } from "react";
import styled, { css, useTheme } from "styled-components";
import Logo from "@images/logo2.svg";
4;
import SearchBar from "./SearchBar";
import SearchView from "@components/View/SearchView";
import {
  FaMagnifyingGlass,
  FaBars,
  FaBell,
  FaRegBell,
  FaAngleDown,
  FaXmark,
} from "react-icons/fa6";
import { GB, NL } from "country-flag-icons/react/3x2";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { useDispatch, useSelector } from "react-redux";
import SamplePersonImage from "@images/demo-teacher-image.png";
import Cookies from "js-cookie";
import {
  UserRole,
  setUserInfoActionCreator,
} from "@app/redux/slices/userSlice";
import { dashboardUri } from "@app/CONSTANTS";
import { device } from "@styles/theme";
import SideMenu from "./SideMenu";
import { useTranslation } from "react-i18next";
import { selectSettings } from "@app/redux/publicSelectors/settingsSelector";
import { settingsSlice } from "@app/redux/slices/settingsSlice";
import { Language } from "@lib/Enums/Language";
import LanguageSelector from "./LanguageSelector";
// TO-DO connect the language indicator button to redux

const HeaderContainer = styled.div`
  height: 5.0625rem;
  width: 100%;
  z-index: 2000;
  background: #ffffff;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray[500]};
  @media ${device.tablet} {
    height: calc(5.0625rem);
    /* height: calc(5.0625rem + 3.8125rem); */
  }
`;
const InnerContainer = styled.div`
  padding: 0rem 3rem;
  height: 5.0625rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2000;
  @media ${device.tablet} {
    padding: 0 1rem;
  }
`;
const LogoImage = styled.img`
  height: 2.3rem;
`;
interface SearchBarContainerProps {
  $hidden: boolean;
}
const SearchInputContainer = styled.div<SearchBarContainerProps>`
  display: ${(props) => (props.$hidden ? "none" : "flex")};
  flex-grow: 1;
  @media ${device.tablet} {
    display: none;
  }
`;
const LogoAndSearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 47.8rem;
`;
const IconContainer = styled.div`
  margin-left: 3.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 1rem;
  border-radius: 12px 0 0 12px;
  background: ${(props) => props.theme.colors.gray[500]};
`;

const TopBarInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LanguageIndicatorContainer = styled.div`
  margin-left: 1.25rem;
  position: relative;
  height: 2.5rem;
  width: 5rem;
  background: #ffffff;
`;
interface LanguageIndicatorListPropType {
  $language: Language;
  $languageMenuOpen: boolean;
}
const LanguageIndicatorListCloseStyle = css`
  & > :nth-child(2) {
    display: none;
  }
`;
const LanguageIndicatorList = styled.ul<LanguageIndicatorListPropType>`
  display: flex;
  flex-direction: ${(props) =>
    props.$language === Language.DUTCH ? "column-reverse" : "column"};
  width: 5rem;
  box-shadow: ${(props) =>
    props.$languageMenuOpen ? "0px 0px 10px #00000029" : "none"};
  position: absolute;
  left: 0;
  top: 0;
  background: #ffffff;
  border-radius: 10px;
  ${(props) => !props.$languageMenuOpen && LanguageIndicatorListCloseStyle}
`;
const LanguageIndicatorButton = styled.button`
  width: 5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  background: none;
  align-items: center;
  @media ${device.tablet} {
    display: none;
  }
`;
const LanguageIndicatorText = styled.span`
  color: ${(props) => props.theme.colors.gray[800]};
  font-weight: 700;
  font-family: ${(props) => props.theme.font.font};
  display: inline-block;
  margin-left: 0.5rem;
  font-size: 0.8rem;
`;
const SignInLink = styled(Link)`
  text-decoration: none;
  background: ${(props) => props.theme.colors.blue[700]};
  height: 2.5rem;
  border-radius: 12px;
  color: #ffffff;
  width: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${(props) => props.theme.font.font};
  margin-left: 3rem;
  @media ${device.tablet} {
    display: none;
  }
`;
const MenuButton = styled.button`
  background: ${(props) => props.theme.colors.gray[500]};
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  color: ${(props) => props.theme.colors.gray[800]};
  font-family: ${(props) => props.theme.font.font};
  margin-left: 1.5625rem;
  width: 8.75rem;
  cursor: pointer;
  @media ${device.tabletS} {
    display: none;
  }
`;
const MenuButton2 = styled(MenuButton)`
  width: 3rem;
  display: none;
  @media ${device.tabletS} {
    display: flex;
  }
`;
const BarsContainer = styled.span`
  display: inline-block;
  margin-left: 0.6rem;
  @media ${device.tabletS} {
    margin-left: 0;
  }
`;
const NotificationsContainer = styled.div`
  margin-left: 1.875rem;
  display: flex;
  align-items: center;
  @media ${device.tablet} {
    display: none;
  }
`;
const NotificationIcon = styled(FaRegBell)`
  height: 26px;
  width: 23px;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const DashboardLink = styled(Link)`
  display: flex;
`;
const ProfilePicContainer = styled.div`
  height: 2.625rem;
  width: 2.625rem;
  @media ${device.mobileM} {
    display: none;
  }
`;
const ProfilePic = styled.img`
  height: 2.625rem;
  width: 2.625rem;
  border-radius: 1.3125rem;
  object-fit: cover;
`;
const NameContainer = styled.div`
  white-space: nowrap;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.gray[800]};
  margin-left: 1rem;
  @media ${device.laptopM} {
    display: none;
  }
`;
const DropDownButtonContainer = styled.div`
  position: relative;
`;
const DropDownButton = styled.button`
  margin-left: 1rem;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
`;
const DropDownMenuContainer = styled.div`
  position: absolute;
  bottom: -4.5rem;
  right: 0;
  border-radius: 5px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 25px #00000040;
  font-size: 1rem;
  z-index: 1201;
`;
interface DashBoardLinkPropsType {
  $active: boolean;
}
const DashBoardLink = styled.div<DashBoardLinkPropsType>`
  background: ${(props) =>
    props.$active ? props.theme.colors.gray[600] : "#ffffff"};
  padding: 0.5rem 1rem;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray[400]};
  color: ${(props) =>
    props.$active ? "#ffffff" : props.theme.colors.gray[800]};
`;
const LogOutButton = styled.button`
  padding: 0.5rem 1rem;
  width: 100%;
  color: ${(props) => props.theme.colors.gray[800]};

  background: #ffffff;
`;
function DropDownMenu({
  hide,
}: {
  hide: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUser);
  const { lang } = useParams();
  const { t } = useTranslation();
  const dropDownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleMenuOutsideClick = (event: any) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        hide(false);
      }
    };
    document.addEventListener("click", handleMenuOutsideClick);
    return () => {
      document.removeEventListener("click", handleMenuOutsideClick);
    };
  }, [dropDownRef.current]);
  return (
    <DropDownMenuContainer ref={dropDownRef}>
      <NavLink
        to={
          userInfo.role ? `/${lang}/${dashboardUri[userInfo.role]}` : "/error"
        }
      >
        {({ isActive }) => (
          <DashBoardLink $active={isActive}>
            {t("Header.accountmenu.dashboard")}
          </DashBoardLink>
        )}
      </NavLink>
      <LogOutButton
        onClick={() => {
          Cookies.remove("token");
          dispatch(setUserInfoActionCreator(new Date().getTime(), false));
        }}
      >
        {t("Header.accountmenu.logout")}
      </LogOutButton>
    </DropDownMenuContainer>
  );
}
function Header() {
  const theme = useTheme();
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { pathname } = useLocation();
  const [languageMenuOpen, setLanguageMenuOpen] = useState<boolean>(false);
  const userInfo = useSelector(selectUser);
  const { lang } = useParams();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const sideMenuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleMenuOutsideClick = (event: any) => {
      if (sideMenuRef.current && !sideMenuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener("click", handleMenuOutsideClick);
    return () => {
      document.removeEventListener("click", handleMenuOutsideClick);
    };
  }, [sideMenuRef.current]);
  const arrayOfRoutesForHidingSearchBar = ["details/chat"];
  function searchBarIsHidden() {
    let isHidden = false;
    for (let i = 0; i < arrayOfRoutesForHidingSearchBar.length; i++) {
      if (pathname.includes(arrayOfRoutesForHidingSearchBar[i])) {
        return true;
      }
    }
    return false;
  }

  return (
    <HeaderContainer>
      <InnerContainer>
        {showMenu && <SideMenu ref={sideMenuRef} />}
        <LogoAndSearchInputContainer>
          <Link to="/">
            <LogoImage src={Logo} alt="logo" />
          </Link>
          <SearchInputContainer $hidden={searchBarIsHidden()}>
            <SearchBar />
          </SearchInputContainer>
        </LogoAndSearchInputContainer>
        <TopBarInfoContainer>
          <LanguageIndicatorContainer>
            <LanguageSelector />
          </LanguageIndicatorContainer>
          {userInfo.loggedIn ? (
            <DashboardLink
              to={
                userInfo.role
                  ? `/${lang}/${dashboardUri[userInfo.role]}`
                  : "/error"
              }
            >
              {/* <NotificationsContainer>
                <NotificationIcon />
              </NotificationsContainer> */}

              <ProfilePicContainer>
                <ProfilePic src={userInfo.profileUrl} />
                <DropDownButtonContainer>
                  {isDropDownOpen && <DropDownMenu hide={setIsDropDownOpen} />}
                </DropDownButtonContainer>
              </ProfilePicContainer>

              <NameContainer>{userInfo.name}</NameContainer>
            </DashboardLink>
          ) : (
            <SignInLink to={`/${lang}/signin`}>{t("Header.SignIn")}</SignInLink>
          )}

          <MenuButton
            onClick={(e) => {
              e.stopPropagation();
              setShowMenu((value) => !value);
            }}
          >
            {!showMenu ? "Menu" : t("Header.MenuClose")}
            <BarsContainer>{showMenu ? <FaXmark /> : <FaBars />}</BarsContainer>
          </MenuButton>
          <MenuButton2
            onClick={(e) => {
              e.stopPropagation();
              setShowMenu((value) => !value);
            }}
          >
            <BarsContainer>{showMenu ? <FaXmark /> : <FaBars />}</BarsContainer>
          </MenuButton2>
        </TopBarInfoContainer>
      </InnerContainer>
    </HeaderContainer>
  );
}

export default Header;
