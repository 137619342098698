import StudentOrdersTable from "@components/StudentDashBoardSubPages/StudentOrders/StudentOrdersTable";
import React, { useEffect, useState } from "react";
import { ordersData } from "../../Orders/AdminOrders";
import styled from "styled-components";
import { getStudentOrders } from "@API/services/Order/getStudentOrders";
import { useNavigate, useParams } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa6";
import { getAdminCourseOrders } from "@API/services/Admin/getAdminCourseOrdersList";
import { mapAdminOrders } from "@lib/mapperFunctions/mapAPIAdminOrders";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const TableContainer = styled.div`
  margin-top: 3.125rem;
`;
const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;
const BackLink = styled.button`
  height: 2.6875rem;
  width: 2.6875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors.gray[600]};
  border-radius: 10px;
  color: #ffffff;
`;
const Heading = styled.div`
  line-height: 1.9375rem;
  font-size: 1.375rem;
  color: ${(props) => props.theme.colors.gray[800]};
  font-weight: 700;
  margin-left: 1.25rem;
`;
function AdminCoursePayments() {
  const [StudentOrderData, SetStudentOrdersData] = useState<ordersData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const { id } = useParams();
  if (!id) {
    navigate("/error");
    return <></>;
  }
  const getOrderDataFunction = async () => {
    setLoading(true);
    const res = await getAdminCourseOrders(id);
    if (res.success) {
      res.data;
      const mappedData = mapAdminOrders(res.data);
      SetStudentOrdersData(mappedData);
    }
    setLoading(false);
  };
  useEffect(() => {
    getOrderDataFunction();
  }, []);
  return (
    <Container>
      <HeadingContainer>
        <BackLink
          onClick={() => {
            navigate(-1);
          }}
        >
          <FaAngleLeft />
        </BackLink>
        <Heading>Payments</Heading>
      </HeadingContainer>
      <TableContainer>
        <StudentOrdersTable loading={loading} items={StudentOrderData} />
      </TableContainer>
    </Container>
  );
}

export default AdminCoursePayments;
