import React, { useRef, useState } from "react";
import { format } from "date-fns";
import { DateRange, DayPicker } from "react-day-picker";
import "./CalenderStyles.css";
import styled from "styled-components";
import { useOnClickOutside } from "usehooks-ts";

// styled-components----
const Container = styled.div`
  display: flex;
  margin-top: 0.2rem;
`;
//----------------------
function DateRangeSelector({
  onChange,
  value,
  setShow,
  ...props
}: {
  value: DateRange;
  onChange: (e: DateRange | undefined) => void;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    setShow(false);
  });
  return (
    <Container {...props} ref={ref}>
      <DayPicker
        mode="range"
        selected={value}
        defaultMonth={value.from}
        onSelect={(e) => {
          onChange(e);
        }}
      ></DayPicker>
    </Container>
  );
}

export default DateRangeSelector;
