import { useState } from "react";
import CustomHeading from "@lib/microComponents/CustomHeading";
import { useNavigate, useParams } from "react-router-dom";
// import Cookies from "js-cookie";
import styled from "styled-components";
import FormSubmitButton from "@components/LogInPages/commonComponents/FormSubmitButton";
import BaseLayout from "@components/LogInPages/BaseLayout";
import FormInput from "@components/LogInPages/commonComponents/FormInput";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { object, ref, string } from "yup";
import { forgotPassword } from "@API/services/Auth/main";
import { ReactToast } from "@lib/toast";
// import { loginWithPassword } from "api/services/Auth";
// start of styled-components

const FormContainer = styled.div`
  max-width: 35.75rem;
  margin: auto;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  width: 100%;
`;
const SignInForm = styled.form`
  background: white;
  margin-top: 3.5rem;
  display: flex;
  flex-direction: column;
  font-weight: 600;
`;
const FormLabel = styled.label`
  color: ${(props) => props.theme.colors.gray[800]};
  font-size: 0.9rem;
  font-family: ${(props) => props.theme.font.font};
`;

const ForgotPasswordLink = styled(Link)`
  font-size: 0.8rem;
  text-decoration: underline;
  margin-top: 1.1rem;
  font-weight: 400;
  color: ${(props) => props.theme.colors.gray[700]};
  font-family: ${(props) => props.theme.font.font};
`;
const Divider = styled.div`
  margin: 1.6rem 0rem;
  height: 2px;
  background: #eaefec;
`;
const SignUpLinkContainer = styled.div`
  margin: auto;
  margin-top: 2.5rem;
  color: ${(props) => props.theme.colors.gray[800]};

  font-size: 0.82rem;
  font-weight: 700;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SignUpLink = styled(Link)`
  color: #355f44;
  font-weight: 400;
  font-family: ${(props) => props.theme.font.font};
`;

const FormError = styled.div``;

// end of styled-components

const EMAIL = "email";
const CONFIRM_PASSWORD = "confirm password";
interface initialvaluesInterface {
  [EMAIL]: string;
}
const initialValues: initialvaluesInterface = {
  [EMAIL]: "",
};
function ForgotPassword() {
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const formik = useFormik({
    initialValues,
    validationSchema: object({
      [EMAIL]: string()
        .email("must be a valid email")
        .required("email is required"),
    }),
    onSubmit: async (values, actions) => {
      const res = await forgotPassword(values[EMAIL]);
      if (res.success) {
        if (res.data) {
          actions.resetForm();
          ReactToast.success("Email sent");
        } else {
          ReactToast.error("Cound'nt find email");
        }
      } else {
        ReactToast(res.data);
      }
      actions.setSubmitting(false);
    },
  });

  return (
    <BaseLayout>
      <FormContainer>
        {error && <FormError>{error}</FormError>}
        <CustomHeading sx={{ marginTop: "1.5rem" }}>
          Forgot password
        </CustomHeading>
        <SignInForm onSubmit={formik.handleSubmit}>
          <FormLabel htmlFor="new-password">Email</FormLabel>
          <FormInput
            placeholder="Your email"
            {...formik.getFieldProps(EMAIL)}
            disabled={loading}
          />
          <FormSubmitButton loading={formik.isSubmitting}>
            Send email
          </FormSubmitButton>
        </SignInForm>
      </FormContainer>
    </BaseLayout>
  );
}

export default ForgotPassword;
