export enum CourseAction {
  RESERVE_YOUR_SPOT = "RESERVE_YOUR_SPOT",
  NO_ACTION = "NO_ACTION",
  STUDENT_COURSE_DETAIL = "STUDENT_COURSE_DETAIL",
  TEACHER_COURSE_DETAIL = "TEACHER_COURSE_DETAIL",
  EDIT_COURSE = "EDIT_COURSE",
}

function getCourseAction(CourseAction: CourseAction | undefined) {
  switch (CourseAction) {
    
  }
}
