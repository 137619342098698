import React, { useEffect, useState } from "react";
import { SuggestedData, SuggestionData } from "./SuggestionDataType";
import { NonEnrolledCourseDataType } from "@components/CommonComponents/CourseComponent/NonEnrolledCourse";
import { GetSuggestedCourse } from "@API/services/CourseCRUD/getSuggestionCourses";
import NonEnrolledCourse from "@components/CommonComponents/CourseComponent/NonEnrolledCourse";
import styled from "styled-components";
import Skeleton from "@components/CommonComponents/CourseComponent/Skeleton";
import EmptyComponent1 from "@components/CommonComponents/EmptyContanetComponents/EmptyComponent2";
import EmptyComponent2 from "@components/CommonComponents/EmptyContanetComponents/EmptyComponent2";
import { useTranslation } from "react-i18next";
import {
  getLanguageFromLocaleString,
  getLanguageInfo,
} from "@lib/Enums/Language";
import { useSelector } from "react-redux";
import { selectSettings } from "@app/redux/publicSelectors/settingsSelector";
//styled-components
const Container = styled.div`
  //  padding:1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  justify-content: flex-start;
  row-gap: 1.5rem;
`;
const SuggestionTag = styled.div`
  //  padding:0rem 0.2rem;
  padding-top: 2rem;
  font-size: 22px;
  color: ${(props) => props.theme.colors.gray[800]};
  font-weight: 700;
`;
//styled-component-end
function Suggestion() {
  const [SuggestedDatas, SetSuggestedData] = useState<
    NonEnrolledCourseDataType[]
  >([]);
  const { t } = useTranslation();
  const [SuggestionLoading, SetSuggestionLoading] = useState<boolean>(true);
  const { language } = useSelector(selectSettings);
  const [SuggestionError, SetSuggestionError] = useState<boolean>(false);
  useEffect(() => {
    async function getCourseData() {
      SetSuggestionLoading(true);
      const CourseData = await GetSuggestedCourse();
      if (CourseData) {
        SetSuggestedData(CourseData);
      } else {
        SetSuggestionError(true);
      }
      SetSuggestionLoading(false);
    }
    getCourseData();
  }, []);
  return (
    <Container>
      <SuggestionTag>{t("sdashboard.courses.suggested_heading")}</SuggestionTag>
      {SuggestionError && (
        <SuggestionTag>There was error in loading the suggestion</SuggestionTag>
      )}
      {SuggestionLoading ? (
        Array.from({ length: 5 }).map(() => {
          return <Skeleton />;
        })
      ) : SuggestedDatas.length ? (
        SuggestedDatas.map((item) => (
          <NonEnrolledCourse
            renderSuggestion={true}
            key={item.id}
            item={item}
          />
        ))
      ) : (
        <EmptyComponent2
          to={`/${getLanguageInfo(language).locale}/dashboard/profile`}
        />
      )}
    </Container>
  );
}

export default Suggestion;
