import { downloadFile } from "@API/services/EntitiesCrud/downloadFile";
import { selectSettings } from "@app/redux/publicSelectors/settingsSelector";
import FullScreenModal from "@components/CommonComponents/Modal/FullScreenModal";
import LoadingScreenNew from "@components/LoadingScreenNew";
import { useEffect, useRef, useState } from "react";
import { FaAngleLeft } from "react-icons/fa6";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { container } from "webpack";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();
const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
};
const Container = styled.div`
  width: 100%;
`;
const PageNumber = styled.div`
  margin: 2rem auto;
  display: inline-block;
  padding: 1rem 1rem;
  background: ${(props) => props.theme.colors.gray[600]};
  color: #ffffff;
  font-weight: 700;
`;
const HeaderContainer = styled.div`
  z-index: 100;
`;

const BackButton = styled.button`
  height: 2.6875rem;
  width: 2.6875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors.gray[600]};
  border-radius: 10px;
  color: #ffffff;
`;

function PdfViewer() {
  const [blob, setBlob] = useState<Blob>();
  const [loading, setLoading] = useState<boolean>(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const [numPages, setNumPages] = useState<number>(0);
  const [pageWidth, setPageWidth] = useState<number>(0);
  const [fileLink, setFileLink] = useState<string>("");
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();
  const { name } = useParams();
  async function getFile() {
    setLoading(true);
    if (!name) {
      setError("File not found");
      return;
    }
    const fileRes = await downloadFile(name);
    if (fileRes.success) {
      // const buffer = await res.arrayBuffer();
      // const fileBlob = new Blob([buffer]);
      setBlob(fileRes.data);
      setLoading(false);
    } else {
      setError("There was an error in getting the file");
    }
  }
  useEffect(() => {
    getFile();
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      setPageWidth(container.clientWidth);
    }
  }, [containerRef.current]);

  return (
    <>
      {loading ? (
        <LoadingScreenNew />
      ) : !error ? (
        <Container ref={containerRef}>
          <HeaderContainer>
            <BackButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <FaAngleLeft />
            </BackButton>
          </HeaderContainer>
          <Document
            file={blob}
            onLoadSuccess={({ numPages }) => {
              setNumPages(numPages);
            }}
            options={options}
          >
            {Array.from({ length: numPages }).map((e, index) => {
              return (
                <>
                  <PageNumber>
                    {index + 1}/{numPages}
                  </PageNumber>
                  <Page
                    pageNumber={index + 1}
                    width={pageWidth}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                  />
                </>
              );
            })}
          </Document>
        </Container>
      ) : (
        <div>{error}</div>
      )}
    </>
  );
}

export default PdfViewer;
