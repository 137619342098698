import React, { useRef } from "react";
import styled from "styled-components";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { useOnClickOutside } from "usehooks-ts";
// styled-components ----
const Container = styled.div``;
// ----------------------

function EmojiSelector({
  handleClose,
  onChange,
}: {
  handleClose: () => void;
  onChange: (e: any) => void;
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(containerRef, () => {
    console.log("click outside");
    handleClose();
  });
  return (
    <Container ref={containerRef}>
      <Picker
        data={data}
        onEmojiSelect={(e: any) => {
          handleClose();
          onChange(e);
        }}
      />
    </Container>
  );
}

export default EmojiSelector;
