import { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import AdminDashBoard from "@components/AdminDashboard";
import LandingPage from "@components/LandingPage";
import StudentOrders from "@components/StudentDashBoardSubPages/StudentOrders";
import SignIn from "@components/LogInPages/SignIn";
import SignUp from "@components/LogInPages/SignUp";
import AdminOverview from "@components/AdminDashboard/SubPages/AdminOverview";
import Courses from "@components/AdminDashboard/SubPages/Courses";
import AddCourse from "@components/AdminDashboard/SubPages/Courses/AddCourse";
import Orders, {
  OrderDetails,
} from "@components/AdminDashboard/SubPages/Orders";
import { StudentDetails } from "@components/AdminDashboard/SubPages/Students";
import CourseView from "@components/View/CourseView";
import Settings from "@components/AdminDashboard/SubPages/Settings";
import Students from "@components/AdminDashboard/SubPages/Students";
import StudentDasboard from "@components/StudentDasboard";
import StudentOverview from "@components/StudentDashBoardSubPages/StudentOverview";
import StudentCourses from "@components/StudentDashBoardSubPages/Courses";
import SearchView from "@components/View/SearchView";
import FOURoFOUR from "@components/FOURoFOUR";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import useLogin from "@app/hooks/useLogin";
import { UserRole, userSlice } from "@app/redux/slices/userSlice";
import Cookies from "js-cookie";
import { getUserProfile } from "@API/services/Data/getUserProfile";
import StudentProfile from "@components/StudentDashBoardSubPages/StudentProfile";
import CourseDetails from "@components/StudentDashBoardSubPages/StudentCourseOverview/main";
import Universities from "@components/AdminDashboard/SubPages/Settings/SubPages/UniversitiesStudiesAndSubjects/SubPages/Universities/main";
import Studies from "@components/AdminDashboard/SubPages/Settings/SubPages/UniversitiesStudiesAndSubjects/SubPages/Studies";
import Subjects from "@components/AdminDashboard/SubPages/Settings/SubPages/UniversitiesStudiesAndSubjects/SubPages/Subjects";
import AdminAndManagers from "@components/AdminDashboard/SubPages/Settings/SubPages/AdminAndManagers";
import StudentSupport from "@components/StudentDashBoardSubPages/StudentSupport";
import Teachers from "@components/AdminDashboard/SubPages/Settings/SubPages/Teachers";
import TeacherDashBoard from "@components/TeachersDashboard/main";
import TeacherCourses from "@components/TeachersDashboard/SubPages/TeacherCourses";
import NewCheckoutModal from "@components/CommonComponents/NewCheckoutModal";
import StudentChat from "@components/StudentDashBoardSubPages/StudentCourseOverview/StudentChat";
import WorkAtAkc from "@components/View/WorkersDashboard";
import CourseFeedbackComponent from "@components/StudentDashBoardSubPages/CourseFeedbackComponent";
import { LocalizedSwitch } from "./LocalizedSwitch";
import { LocalizedRouter } from "./LocalizedRouter";
import { Language } from "@lib/Enums/Language";
import { en } from "@lib/intl/locales/Routes/en/translation";
import { useRedirectUri } from "@app/hooks/useRedirectUri";
import PageNotFound from "@components/PageNotFound";
import ChangePassword from "@components/LogInPages/ChangePassword";
import ForgotPassword from "@components/LogInPages/ForgotPassword/main";
import LoadingScreenNew from "@components/LoadingScreenNew";
import PdfViewer from "@lib/pdfViewer/main";
import CheckoutModalForStudyGuide from "@components/CommonComponents/NewCheckoutModal/CheckoutModalForStudyGuide";
import VerifyAccount from "@components/LogInPages/VerifyAccount";
import StudentCourseOverview from "@components/StudentDashBoardSubPages/StudentCourseOverview";
import ApplicationsList from "@components/TeachersDashboard/SubPages/TeacherCourses/ApplicationsList";
import AdminCourseApplications from "@components/AdminDashboard/SubPages/Courses/AdminCourseApplications";
import AdminCoursePayments from "@components/AdminDashboard/SubPages/Courses/AdminCoursePayments";
import AboutUs from "@components/View/AboutUs";
import pageRouteNames from "./constants/pageRouteNames";
import Support from "./components/View/Support";
import { useSearchData } from "./components/View/hooks/useSearchData";
export const Redirect = ({ to }: { to: string }) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to, { replace: true });
  }, []);
  return <></>;
};

function OAuthHandlerComponent() {
  const [searchParams, _] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    login();
  }, []);
  const redirect_uri = searchParams.get("redirect_uri");
  const login = async () => {
    try {
      const token = searchParams.get("token");
      if (token) {
        Cookies.set("token", token);
        const userInfoRes = await getUserProfile();
        if (userInfoRes.success) {
          dispatch(userSlice.actions.setUserInfo(userInfoRes.data));
          if (redirect_uri) {
            navigate(`${redirect_uri}`);
            return;
          }
          navigate("/signin");
        } else {
          navigate("/error");
        }
      } else {
        navigate("/error");
      }
    } catch (e) {
      navigate("/error");
    }
  };
  return <LoadingScreenNew />;
}

function AppRoutes() {
  const [data, err, isLoading] = useSearchData({
    universityId: undefined,
    studyId: undefined,
    groupId: undefined,
  });
  const user = useSelector(selectUser);
  const [loading, login] = useLogin();
  const [redirectUri, redirectFunction] = useRedirectUri(user.role);
  return loading ? (
    <LoadingScreenNew />
  ) : (
    <LocalizedRouter
      defaultLanguage={Language.ENGLISH_US}
      RouterComponent={BrowserRouter}
      languages={Language}
      appStrings={{ en }}
    >
      <LocalizedSwitch>
        <Routes>
          <Route index element={<LandingPage />} />
          <Route
            path="oauth/google"
            element={
              <OAuthHandlerComponent />
              // user.role === UserRole.ADMIN ? (
              //   <Redirect to="/admin-dashboard" />
              // ) : (
              //   <Redirect to="/dashboard" />
              // )
            }
          />
          <Route
            path="signup"
            // element={
            //   user.loggedIn ? (
            //     <Redirect to={user.role ? redirectUri : "/error"} />
            //   ) : (
            //     <SignUp />
            //   )
            // }
            element={<SignUp />}
          />
          <Route path="signin" element={<SignIn />} />
          <Route
            path="change-password"
            element={
              user.loggedIn ? (
                <Redirect to={user.role ? redirectUri : "/error"} />
              ) : (
                <ChangePassword />
              )
            }
          ></Route>
          <Route
            path="forgot-password"
            element={
              user.loggedIn ? (
                <Redirect to={user.role ? redirectUri : "/error"} />
              ) : (
                <ForgotPassword />
              )
            }
          ></Route>
          <Route
            path="verify-email"
            element={
              user.loggedIn ? (
                <Redirect to={user.role ? redirectUri : "/error"} />
              ) : (
                <VerifyAccount />
              )
            }
          ></Route>

          <Route path="search" element={<SearchView />} />

          <Route path={`:id/${pageRouteNames.COURSE}`}>
            <Route index element={<CourseView />} />
          </Route>
          <Route path="workAtAkc" element={<WorkAtAkc />} />
          <Route path="about" element={<AboutUs />} />
          <Route
            path={`/:id/${pageRouteNames.COURSE}/checkout`}
            element={<NewCheckoutModal />}
          />
          <Route
            path={`:id/${pageRouteNames.COURSE}/studyGuide/checkout`}
            element={<CheckoutModalForStudyGuide />}
          />
          <Route path="support" element={<Support />} />
          <Route
            path="admin-dashboard"
            element={
              (user.loggedIn && user.role === UserRole.ADMIN) ||
              user.role === UserRole.MANAGER ? (
                <AdminDashBoard />
              ) : (
                <Redirect to="/signin" />
              )
            }
          >
            <Route
              index
              element={<Redirect to="/admin-dashboard/overview" />}
            />
            <Route path="overview" element={<AdminOverview />} />
            <Route path="courses">
              <Route index element={<Courses />}></Route>
              <Route path="add-course" element={<AddCourse />} />
              <Route path=":id/edit-course" element={<AddCourse />} />
              <Route
                path=":id/applications"
                element={<AdminCourseApplications />}
              ></Route>
              <Route
                path=":id/payments"
                element={<AdminCoursePayments />}
              ></Route>
            </Route>

            <Route path="courses/:id/edit-course" element={<AddCourse />} />
            <Route path="orders" element={<Orders />} />
            <Route path="orders/:orderId" element={<OrderDetails />} />
            <Route path="students/:userId" element={<StudentDetails />} />

            <Route path="profile" element={<StudentProfile />} />

            <Route path="settings">
              <Route index element={<Settings />} />
              <Route path="admin&managers">
                <Route index element={<AdminAndManagers />} />
                <Route path=":userId" element={<StudentProfile />} />
              </Route>
              <Route path="teachers">
                <Route index element={<Teachers />} />
                <Route path=":userId" element={<StudentProfile />} />
              </Route>

              <Route path="universities">
                <Route index element={<Universities />} />
                <Route path=":id/studies">
                  <Route index element={<Studies />} />
                  <Route path=":id/subjects" element={<Subjects />} />
                </Route>
              </Route>
            </Route>
            <Route path="students" element={<Students />} />
          </Route>
          <Route
            path="teacher-dashboard"
            element={
              user.loggedIn && user.role === UserRole.TEAHCER ? (
                <TeacherDashBoard />
              ) : (
                <Redirect to="/signin" />
              )
            }
          >
            <Route
              index
              element={<Redirect to="/teacher-dashboard/courses" />}
            />
            <Route path="courses">
              <Route index element={<TeacherCourses />} />
              <Route path=":id/details">
                <Route
                  index
                  element={<StudentCourseOverview user={UserRole.TEAHCER} />}
                />
                <Route path=":name/pdf" element={<PdfViewer />}></Route>
                <Route path="chat" element={<StudentChat />} />
                <Route path="applications" element={<ApplicationsList />} />
              </Route>
            </Route>
            <Route path="profile" element={<StudentProfile />} />
          </Route>
          <Route
            path="dashboard"
            element={
              user.loggedIn && user.role === UserRole.STUDENT ? (
                <StudentDasboard />
              ) : (
                <Redirect to="/signin" />
              )
            }
          >
            <Route index element={<Redirect to="/dashboard/overview" />} />
            <Route path="overview">
              <Route index element={<StudentOverview />} />
              <Route path=":id/details">
                <Route
                  index
                  element={<CourseDetails user={UserRole.STUDENT} />}
                />
                <Route path=":name/pdf" element={<PdfViewer />}></Route>
                <Route path="chat" element={<StudentChat />} />
              </Route>
            </Route>
            <Route path="courses">
              <Route index element={<StudentCourses />} />
              <Route path=":id/details">
                <Route
                  index
                  element={<CourseDetails user={UserRole.STUDENT} />}
                />
                <Route path=":name/pdf" element={<PdfViewer />}></Route>
                <Route path="chat" element={<StudentChat />} />
              </Route>
            </Route>
            <Route path="orders" element={<StudentOrders />} />
            <Route path="profile" element={<StudentProfile />} />
            <Route path="support" element={<StudentSupport />} />
            <Route path="feedback" element={<CourseFeedbackComponent />} />
          </Route>
          <Route path="error" element={<FOURoFOUR />}></Route>
          <Route path="*" element={<PageNotFound />}></Route>
        </Routes>
      </LocalizedSwitch>
    </LocalizedRouter>
  );
}

export default AppRoutes;
