import { UserRole } from "@app/redux/slices/userSlice";
import StudentTable from "@components/AdminDashboard/SubPages/Orders/StudentTable";
import CourseApplications from "@components/CommonComponents/CourseApplications";
import React from "react";

function ApplicationsList() {
  return <CourseApplications user={UserRole.TEAHCER} />;
}

export default ApplicationsList;
