import { Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { EnrolledCourseDetailsData } from "./EnrolledCourseDetails";
import { urlFunctions } from "@API/functions/createUrl";
import { downloadFile } from "@API/services/EntitiesCrud/downloadFile";
import { format } from "date-fns";
import { device, mixin } from "@styles/theme";
import PdfViewer from "@lib/pdfViewer/main";
import { useState } from "react";
import { FaUpload } from "react-icons/fa6";
import { TeacherCourseDetailsType } from "@components/TeachersDashboard/TeacherComponents/TeachersCourseDetails/TeachersCourseDetails";
import Modal from "@components/CommonComponents/Modal";
import UploadStudyMaterials from "@components/TeachersDashboard/TeacherComponents/TeachersCourseDetails/UploadStudyMaterials";
import { useSelector } from "react-redux";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { UserRole } from "@app/redux/slices/userSlice";

// styled.components ----
const Container = styled.div`
  width: 100%;
  padding: 1.875rem;
  background: #ffffff;
  border-radius: 20px;
  ${mixin.hideScroll()}
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
  @media ${device.tablet} {
    padding: 1.25rem;
    border-radius: 10px;
  }
`;
const HeadingContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
const Heading = styled.div`
  font-weight: 700;
  font-size: 1.375rem;
  line-height: 1.9375rem;
  color: ${(props) => props.theme.colors.gray[800]};
  @media ${device.tablet} {
    font-size: 1.125rem;
    line-height: 1.5625rem;
  }
`;
const Divider = styled.div`
  background: ${(props) => props.theme.colors.gray[500]};
  height: 2px;
  margin-top: 1.25rem;
  @media ${device.tablet} {
    margin-top: 0.9375rem;
  }
`;
const FilesContainer = styled.div`
  margin-top: 1.25rem;
  display: flex;
  gap: 1.25rem;
  flex-direction: column;
  overflow-y: scroll;
  ${mixin.hideScroll()}
  @media ${device.tablet} {
    margin-top: 0.9375rem;
  }
`;
export const UploadButton = styled.button`
  background: none;
  svg {
    width: 1.375rem;
    height: 1.375rem;
    color: #a8bace;
  }
`;

const DownloadButton = styled.button`
  width: 10.875rem;
  min-width: 10.875rem;
  height: 2.6875rem;
  background: ${(props) => props.theme.colors.blue[700]};
  border-radius: 10px;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  font-weight: 700;
  color: #ffffff;
  @media ${device.tablet} {
    border-radius: 5px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    height: 33px;
  }
`;
// ----------------------

// --file-contianer-styles--
const FileContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.25rem;
`;
const FileInfoContainer = styled.div``;
const FileName = styled.div`
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  color: ${(props) => props.theme.colors.gray[800]};
  @media ${device.tablet} {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;
const NofilesContainer = styled.div`
  font-size: 1.125rem;
  line-height: 1.5625rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const DateContainer = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${(props) => props.theme.colors.gray[800]};
  @media ${device.tablet} {
    font-size: 0.5rem;
    line-height: 0.75rem;
  }
`;
// -------------------------

function FileContainer({
  link,
  availableUntil,
  name,
}: {
  link: string;
  availableUntil: Date;
  name: string;
}) {
  const [file, setFile] = useState("");
  const [show, setShow] = useState<boolean>(false);
  const navigate = useNavigate();
  async function viewFile(name: string) {
    navigate(`${name}/pdf`);
    // const linkElement = document.createElement("a");
    // linkElement.href = fileUrl;
    // linkElement.download = name;
    // linkElement.click();
  }
  return (
    <FileContainerStyled>
      <FileInfoContainer>
        <FileName>{name}</FileName>
        <DateContainer>
          Available until {format(availableUntil, "do MMMM yyyy")}
        </DateContainer>
      </FileInfoContainer>
      <DownloadButton
        onClick={() => {
          viewFile(link.split("/private/")[1]);
        }}
      >
        View Doc
      </DownloadButton>
    </FileContainerStyled>
  );
}

function StudyMaterialFiles({
  data,
}: {
  data: EnrolledCourseDetailsData | TeacherCourseDetailsType | undefined;
}) {
  const [showStudyMaterialsPopup, setShowStudyMaterialsPopup] =
    useState<boolean>(false);
  const navigate = useNavigate();
  if (!data) return;
  const userInfo = useSelector(selectUser);
  const files = data.StudyMaterials.file;
  const filesPresent = files && files.length;
  return files ? (
    <Container>
      {showStudyMaterialsPopup && data && (
        <Modal
          heading="Upload study material"
          handleClose={() => {
            setShowStudyMaterialsPopup(false);
          }}
        >
          <UploadStudyMaterials
            onFileUploadSuccess={() => {}}
            courseId={data.id}
            closeModal={() => {}}
          />
        </Modal>
      )}
      <HeadingContainer>
        <Heading>Study materials</Heading>
        {userInfo.role === UserRole.TEAHCER && (
          <UploadButton
            onClick={() => {
              setShowStudyMaterialsPopup(true);
            }}
          >
            <FaUpload />
          </UploadButton>
        )}
      </HeadingContainer>
      <Divider />
      <FilesContainer>
        {filesPresent ? (
          files.map((file) => (
            <FileContainer
              link={file.link}
              availableUntil={new Date()}
              name={file.name}
            ></FileContainer>
          ))
        ) : (
          <NofilesContainer>No study material provided</NofilesContainer>
        )}
      </FilesContainer>
    </Container>
  ) : (
    <></>
  );
}

export default StudyMaterialFiles;
