import { StudentData } from "@components/AdminDashboard/SubPages/Students/StudentTable";
import { Student } from "@lib/Types/API/APIAdminStudents";
export const mapResponseToAdminStudents = (items: Student[]): StudentData[] => {
  return items.map((e) => ({
    firstName: e.name.split(" ")[0],
    lastName: e.name.split(" ")[1],
    university: e.universityName,
    study: e.studyName,
    studentId: e.studentId,
    grade: e.grade,
    email: e.email,
  }));
};
