import EnrolledCourse from "@components/CommonComponents/CourseComponent/EnrolledCourse";
import React, { useState } from "react";
import styled from "styled-components";
import CourseInfo from "../StudentOverview/CoursesInfo.tsx/main";
import Suggestion from "../StudentOverview/CourseSuggestions/Suggestion";
const Heading = styled.h2`
  line-height: 1.9375rem;
  font-size: 1.375rem;
  color: ${(props) => props.theme.colors.gray[800]};
  font-weight: 700;
`;
const Container = styled.div``;
const EntolledCoursesContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 1.25rem;
`;

export default function Courses() {
  const [active, setActive] = useState<number>();
  return (
    <Container>
      <EntolledCoursesContainer>
        <CourseInfo />
        <Suggestion />
      </EntolledCoursesContainer>
    </Container>
  );
}
