export enum ISignupResponseCode {
  SUCCESS = 200,
  FAILURE = 203,
  EMAIL_ALREADY_EXIST = 201,
}

export interface ISignupResponse {
  success: boolean;
  message: string;
  data: {
    token: string;
  };
  statusCode: ISignupResponseCode;
}
