import ScaleDownForAdminDashBoard from "@styles/ScaleDownForAdminDashBoard";
import DashBoardNavigationArray from "./AdminDashBoardNavigationArray";
import CommonDashboard from "@components/CommonDashboard";
function AdminDashBoard() {
  return (
    <>
      <ScaleDownForAdminDashBoard />
      <CommonDashboard DashBoardNavigationArray={DashBoardNavigationArray} />
    </>
  );
}

export default AdminDashBoard;
