import React, { useState } from "react";
import styled, { css } from "styled-components";
import { FaBuildingColumns, FaLocationDot } from "react-icons/fa6";
import { device, mixin } from "@styles/theme";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { useTranslation } from "react-i18next";
import { returnType } from "../../../../lib/hooks/useCourseDetails";
import { UserRole } from "@app/redux/slices/userSlice";
import { CourseAction } from "@lib/Enums/CourseAction";
import { selectSettings } from "@app/redux/publicSelectors/settingsSelector";
import { Language, getLanguageInfo } from "@lib/Enums/Language";
import { enrollInMasterClassService } from "@API/services/Payment/enrolInMasterClassService";
import { ReactToast } from "@lib/toast";
import pageRouteNames from "@/constants/pageRouteNames";
// styled-components----
const Container = styled.div`
  white-space: nowrap;
  padding: 1.875rem 3.125rem;
  height: 9.3125rem;
  box-shadow: 0px 10px 25px #00000029;
  position: sticky;
  top: 0;
  z-index: 1000;
  background: #ffffff;
  display: flex;
  gap: 3.125rem;
  justify-content: space-between;
  align-items: center;
  @media ${device.laptop} {
    padding: 1.25rem;
    height: auto;
  }
  @media ${device.tabletS} {
  }
  @media ${device.mobileL} {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;

const HeaderDataContainer = styled.div`
  display: flex;
`;
interface loading {
  $loading: boolean;
}
const HeaderDataImageContainer = styled.div<loading>`
  height: 5.5rem;
  width: 5.5rem;
  ${(props) => props.$loading && mixin.skeletonLoading()}
  @media ${device.laptop} {
    height: 2.75rem;
    width: 2.75rem;
  }
`;
const HeaderDataImage = styled.img`
  height: 5.5rem;
  width: 5.5rem;
  @media ${device.laptop} {
    height: 2.75rem;
    width: 2.75rem;
  }
`;
const HeaderDataInfoContainer = styled.div`
  margin-left: 3.125rem;
  margin-top: 0.625rem;

  @media ${device.laptop} {
    margin-left: 1.25rem;
    margin-top: 0.3125rem;
  }
`;
const UniversityAndCourseTypeContainer = styled.div`
  font-size: 1.125rem;
  line-height: 1.5625rem;
  gap: 1.25rem;
  @media ${device.laptop} {
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    flex-wrap: wrap;
    gap: 0.625rem;
  }
  display: flex;
  color: ${(props) => props.theme.colors.gray[600]};
`;

const UniversityName = styled.div`
  display: flex;
  align-items: center;
`;
const UniversityNameText = styled.div<loading>`
  ${(props) =>
    props.$loading &&
    css`
      width: 10rem;
      ${mixin.skeletonLoading()}
    `}
`;
const UniversityIcon = styled(FaBuildingColumns)``;
const CourseType = styled.div`
  display: flex;
  align-items: center;
  @media ${device.laptop} {
  }
`;
const CourseTypeText = styled.div<loading>`
  ${(props) =>
    props.$loading &&
    css`
      width: 10rem;
      ${mixin.skeletonLoading()}
    `}
`;
const CourseTypeIcon = styled(FaLocationDot)``;
const CourseInfoContainer = styled.div`
  margin-top: 0.5rem;
  color: ${(props) => props.theme.colors.gray[800]};
  display: flex;
  font-size: 1.5625rem;
  line-height: 2.1875rem;
  align-items: center;
  @media ${device.laptop} {
    font-size: 0.75rem;
    line-height: 1.0625rem;
  }
  @media ${device.mobileL} {
    display: none;
  }
`;
const CourseInfoContainer2 = styled(CourseInfoContainer)`
  display: none;
  @media ${device.mobileL} {
    display: flex;
  }
`;
const CourseMajor = styled.div<loading>`
  font-weight: 700;
  ${(props) =>
    props.$loading &&
    css`
      width: 10rem;
      ${mixin.skeletonLoading()}
    `}
`;
const CourseSubject = styled.div<loading>`
  ${(props) =>
    props.$loading &&
    css`
      width: 10rem;
      ${mixin.skeletonLoading()}
    `}
`;
const CourseLanguage = styled.div<loading>`
  position: relative;
  border-radius: 0.75rem;
  overflow: hidden;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  ${(props) =>
    props.$loading &&
    css`
      width: 10rem;
      ${mixin.skeletonLoading()}
      height: 100%;
      border-radius: 0;
    `}
`;

const SquareIcon = styled.div`
  height: 0.625rem;
  width: 0.625rem;
  background: ${(props) => props.theme.colors.gray[500]};
  margin: 0 1.875rem;
  @media ${device.laptop} {
    margin: 0 0.625rem;
    height: 4px;
    width: 4px;
  }
`;

const HeaderButtonsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  @media ${device.laptopL} {
    position: fixed;
    z-index: 1000;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #ffffff;
    padding: 1.875rem 2.5rem;
  }
  @media ${device.tablet} {
    padding: 1.25rem;
    flex-direction: column;
  }
`;
const Availability = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.gray[800]};
  @media ${device.tablet} {
  }
`;
const AvailabilityAndPriceButtonContainer = styled.div`
  display: flex;
  @media ${device.tablet} {
    justify-content: space-between;
  }
`;
interface AvailablilityIconPropsType {
  $available: boolean;
}
const AvailablilityIcon = styled.div<AvailablilityIconPropsType>`
  height: 1rem;
  width: 1rem;
  border-radius: 0.5rem;
  background: ${(props) =>
    props.$available
      ? props.theme.colors.green[600]
      : props.theme.colors.red[500]};
  margin-right: 0.625rem;
`;
const PriceButton = styled.div`
  padding: 15.5008px 20px;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  border-radius: 10px;
  background: ${(props) => props.theme.colors.gray[400]};
  margin-left: 1.25rem;
  display: flex;
  @media ${device.tablet} {
    padding: 0.5625rem 1.3125rem;
  }
`;
const SalePrice = styled.div`
  color: ${(props) => props.theme.colors.gray[800]};
  margin-right: 0.625rem;
`;
interface PricePropType {
  $sale: boolean;
}
const Price = styled.div<PricePropType>`
  color: ${(props) =>
    props.$sale ? props.theme.colors.gray[700] : props.theme.colors.gray[800]};
  text-decoration: ${(props) => (props.$sale ? "line-through" : "none")};
  font-weight: 700;
`;
interface ReserveButtonPropType {
  $available: boolean;
  $isWorking: boolean;
}
const ReserveButton = styled.button<ReserveButtonPropType>`
  max-width: 26.125rem;
  flex-grow: 1;
  height: 3.5rem;
  font-weight: 700;

  background: ${(props) =>
    props.$available
      ? props.theme.colors.green[600]
      : props.theme.colors.red[500]};
  text-align: center;
  color: #ffffff;
  border-radius: 10px;
  margin-left: 1.25rem;
  position: relative;
  @media ${device.tablet} {
    display: block;
    height: 3.1875rem;
    width: 100%;
    margin-left: 0;
    max-width: 100%;
  }
  ${(props) =>
    props.$isWorking &&
    css`
      &::after {
        position: absolute;
        content: "";
        background: #ffffff80;
        height: 100%;
        width: 100%;
        border-radius: 10px;
        top: 0;
        left: 0;
      }
    `}
`;
const ReserverButtonBreak = styled.br`
  display: none;
  @media ${device.tablet} {
    display: block;
  }
`;
// ---------------------

function CourseHeader({ details }: { details: returnType<true | false> }) {
  const userInfo = useSelector(selectUser);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, courseDetails] = details;
  const { lang } = useParams();
  const languageInfo = getLanguageInfo(
    loading ? Language.ENGLISH_US : courseDetails.language
  );

  function handleReserveButtonClick(action: CourseAction) {
    if (loading) return;
    if (!userInfo.loggedIn) {
      navigate(`/${lang}/signin`, {
        state: { to: `/${details[1].id}/${pageRouteNames.COURSE}/checkout` },
      });
      return;
    }
    switch (action) {
      case CourseAction.RESERVE_YOUR_SPOT:
        if (userInfo.loggedIn) {
          navigate(`/${courseDetails.id}/${pageRouteNames.COURSE}/checkout`);
        } else {
          navigate(`/${lang}/signin`);
        }
        return;
      case CourseAction.STUDENT_COURSE_DETAIL:
        if (userInfo.role === UserRole.STUDENT) {
          navigate(`/${lang}/dashboard/overview/${courseDetails.id}/details`);
        }
        return;
      case CourseAction.TEACHER_COURSE_DETAIL:
        if (userInfo.role === UserRole.TEAHCER) {
          navigate(
            `/${lang}/teacher-dashboard/courses/${courseDetails.id}/details`
          );
        }
        return;
      case CourseAction.EDIT_COURSE:
        if (
          userInfo.role === UserRole.MANAGER ||
          userInfo.role === UserRole.ADMIN
        ) {
          navigate(
            `/${lang}/admin-dashboard/courses/${courseDetails.id}/edit-course`
          );
        }
        return;
      default:
        return "";
    }
  }
  function getReserveButtonText(action: CourseAction) {
    if (!userInfo.loggedIn) {
      return t("course.buttons.reserve");
    }
    switch (action) {
      case CourseAction.RESERVE_YOUR_SPOT:
        return t("course.buttons.reserve");
      case CourseAction.STUDENT_COURSE_DETAIL:
        return "Course details";
      case CourseAction.TEACHER_COURSE_DETAIL:
        return "Course details";
      case CourseAction.EDIT_COURSE:
        return "Edit Course";
      default:
        return "";
    }
  }
  return (
    <Container>
      <HeaderDataContainer>
        <HeaderDataImageContainer $loading={loading}>
          {!loading && <HeaderDataImage src={courseDetails.universityImage} />}
        </HeaderDataImageContainer>
        <HeaderDataInfoContainer>
          <UniversityAndCourseTypeContainer>
            <UniversityName>
              <UniversityIcon />
              &nbsp;&nbsp;
              <UniversityNameText $loading={loading}>
                {!loading ? courseDetails.university : <>&nbsp;</>}
              </UniversityNameText>
            </UniversityName>
            <CourseType>
              <CourseTypeIcon />
              &nbsp;&nbsp;
              <CourseTypeText $loading={loading}>
                {!loading ? courseDetails.type : <>&nbsp;</>}
              </CourseTypeText>
            </CourseType>
          </UniversityAndCourseTypeContainer>
          <CourseInfoContainer>
            <CourseMajor $loading={loading}>
              {!loading ? courseDetails.major : <>&nbsp;</>}
            </CourseMajor>
            <SquareIcon />
            <CourseSubject $loading={loading}>
              {!loading ? courseDetails.subject : <>&nbsp;</>}
            </CourseSubject>
            <SquareIcon />
            <CourseLanguage $loading={loading}>
              {!loading ? (
                <languageInfo.icon
                  style={{
                    height: "100%",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    display: "inline-block",
                  }}
                />
              ) : (
                <>&nbsp;</>
              )}
            </CourseLanguage>
          </CourseInfoContainer>
        </HeaderDataInfoContainer>
      </HeaderDataContainer>

      {!loading && (
        <HeaderButtonsContainer>
          <AvailabilityAndPriceButtonContainer>
            <Availability>
              <AvailablilityIcon $available={courseDetails.available} />
              {courseDetails.available
                ? t("course.indicator.available")
                : t("course.indicator.notavailable")}
            </Availability>
            <PriceButton>
              {courseDetails.salePrice < courseDetails.price && (
                <SalePrice>&euro;&nbsp;{courseDetails.salePrice}</SalePrice>
              )}
              <Price $sale={courseDetails.salePrice < courseDetails.price}>
                {" "}
                {courseDetails.price === 0 ? (
                  <>Register for free!</>
                ) : (
                  <>&euro;&nbsp;{courseDetails.price}</>
                )}
              </Price>
            </PriceButton>
          </AvailabilityAndPriceButtonContainer>
          <ReserverButtonBreak />
          {(!userInfo.loggedIn || CourseAction.NO_ACTION) !==
            courseDetails.action && (
            <ReserveButton
              $isWorking={false}
              onClick={() => {
                handleReserveButtonClick(courseDetails.action);
              }}
              $available={true}
            >
              {getReserveButtonText(courseDetails.action)}
            </ReserveButton>
          )}
        </HeaderButtonsContainer>
      )}
      <CourseInfoContainer2>
        <CourseMajor $loading={loading}>
          {!loading ? courseDetails.major : <>&nbsp;</>}
        </CourseMajor>
        <SquareIcon />
        <CourseSubject $loading={loading}>
          {!loading ? courseDetails.subject : <>&nbsp;</>}
        </CourseSubject>
        <SquareIcon />
        <CourseLanguage $loading={loading}>
          {!loading ? (
            <languageInfo.icon
              style={{
                height: "100%",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                display: "inline-block",
              }}
            />
          ) : (
            <>&nbsp;</>
          )}
        </CourseLanguage>
      </CourseInfoContainer2>
    </Container>
  );
}

export default CourseHeader;
