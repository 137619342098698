const { parse, differenceInSeconds, format, add } = require("date-fns");

interface getTimeTillStartedType {
  startTime: string;
  date: string;
}
export default function getTimeTillStarted(params: getTimeTillStartedType) {
  const currentDate = new Date();
  const eventDate: Date = parse(params.date, "yyyy-MM-dd", new Date());
  const startTime: Date = parse(params.startTime, "HH:mm:ss", eventDate);

  const diff = differenceInSeconds(startTime, currentDate);

  if (diff <= 0) {
    return "starting soon";
  } else {
    const hours = Math.floor(diff / 3600);
    const minutes = Math.floor((diff % 3600) / 60);
    const seconds = diff % 60;
    return `${hours} : ${minutes} : ${seconds}`;
  }
}
