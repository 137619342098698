import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";
import { TeacherCourseType } from "@components/TeachersDashboard/TeacherComponents/TeacherCourseType";
import { APITeachersCourseType } from "@lib/Types/API/Teacher/APITeachersCourseType";
import { mapAPITeachersCourseDataToComponent } from "@lib/mapperFunctions/Teachers/mapAPITeachersCourseDataToComponent";

type TeacherUploadResponseType =
  | {
      success: true;
      data: boolean;
    }
  | {
      success: false;
      data: string;
    };

interface APITeacherUploadPayloadType {
  type: "DOCUMENT";
  name: string;
  link: string;
  expiryDate: string;
}

export const uploadStudyMaterialByTeacher = async function (
  courseId: string,
  name: string,
  link: string,
  expirationDate: string
): Promise<TeacherUploadResponseType> {
  const url = urlFunctions.uploadStudyMaterialByTeacher(courseId);
  const data: APITeacherUploadPayloadType = {
    type: "DOCUMENT",
    name,
    link,
    expiryDate: expirationDate,
  };
  const res = await API.sendPutRequest(url, data, true);
  if (res.success) {
    return {
      success: true,
      data: true,
    };
  }
  return {
    success: false,
    data: "Could not upload study material",
  };
};
