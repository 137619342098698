import React, { useEffect, useState } from "react";
import { IconType } from "react-icons";
import { FaChalkboardUser, FaSitemap, FaUser } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import SettingsMenuItem from "./SettingsMenuItem";
import AddableSelect from "@lib/microComponents/AddableSelect";
import { useStudyOption } from "@lib/hooks/useStudyOptions";
import { optionType } from "@components/LandingPage/FormSelect";
import { useTranslation } from "react-i18next";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { useSelector } from "react-redux";
import { UserRole } from "@app/redux/slices/userSlice";
// styled-components ----
const Container = styled.div``;
const SettingsMenuContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.5rem;
  margin-top: 1.875rem;
`;
const Heading = styled.div`
  font-size: 1.375rem;
  line-height: 1.9375rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const AddSelectContainer = styled.div`
  margin-top: 1rem;
  width: 25rem;
`;
// ----------------------
interface SettingsMenuItemsInterface {
  id: number;
  title: string;
  icon: IconType;
  link: string;
}
const SettingsMenuItems: SettingsMenuItemsInterface[] = [
  {
    id: 0,
    title: "adminSettings.adminsAndManagers",
    icon: FaUser,
    link: "admin&managers",
  },
  {
    id: 1,
    title: "adminSettings.universityStudySubject",
    icon: FaSitemap,
    link: "universities",
  },
  {
    id: 2,
    title: "adminSettings.teachers",
    icon: FaChalkboardUser,
    link: `teachers`,
  },
];
const ManagerMenuItems: SettingsMenuItemsInterface[] = [
  {
    id: 1,
    title: "adminSettings.universityStudySubject",
    icon: FaSitemap,
    link: "universities",
  },
  {
    id: 2,
    title: "adminSettings.teachers",
    icon: FaChalkboardUser,
    link: `teachers`,
  },
];
function Settings() {
  const [options, setOptions] = useState<optionType[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    getStudyOptions();
  }, []);
  async function getStudyOptions() {
    const data = await useStudyOption("1");
    setOptions(data);
  }
  const userInfo = useSelector(selectUser);
  const { t } = useTranslation();
  if (!userInfo.role) {
    navigate("/error");
  }
  const ManagerMenuItem = SettingsMenuItems[1];
  return (
    <>
      <Container>
        <Heading>{t("adminSettings.settings")}</Heading>
        <SettingsMenuContainer>
          {userInfo.role === UserRole.ADMIN &&
            SettingsMenuItems.map((e) => {
              return (
                <SettingsMenuItem
                  title={t(e.title)}
                  key={e.id}
                  Icon={e.icon}
                  link={e.link}
                />
              );
            })}
          {userInfo.role === UserRole.MANAGER &&
            ManagerMenuItems.map((e) => (
              <SettingsMenuItem
                title={t(e.title)}
                key={e.id}
                Icon={e.icon}
                link={e.link}
              />
            ))}
        </SettingsMenuContainer>
      </Container>
    </>
  );
}

export default Settings;
