import { UserRole, userInfo } from "@app/redux/slices/userSlice";
import { StudentData } from "@components/AdminDashboard/SubPages/Students/StudentTable";
import { AdminStudentResponse } from "@lib/Types/API/APIAdminStudents";
import { v4 as uuid } from "uuid";
import { ManagerData } from "@components/AdminDashboard/SubPages/Settings/SubPages/AdminAndManagers/AdminManagers";
import { AdminData } from "@components/AdminDashboard/SubPages/Settings/SubPages/AdminAndManagers/AdminAdmin";
import { assetsUrls } from "@styles/theme";
export function mapAdminsTableDataToProfileComponent(
  data: AdminData | ManagerData
): userInfo {
  let id;
  if (data.role === UserRole.MANAGER) {
    id = data.managerId;
  } else {
    id = data.AdminId;
  }
  const item = data;
  const mappedData: userInfo = {
    key: new Date().getTime(),
    loggedIn: false,
    role: data.role,
    userId: id,
    name: item.firstName + " " + item.LastName,
    profileUrl: item.profilePicUrl ? item.profilePicUrl : assetsUrls.userPng2,
    email: item.email,
    phone: undefined,
    univversityLogo: undefined,
    address: undefined,
    city: undefined,
    country: undefined,
    zipcode: undefined,
    dateOfBirth: undefined,
    language: undefined,
    university: undefined,
    study: undefined,
    grade: undefined,
    smartPoints: undefined,
    universityStudyAddedForTheFirstTime: undefined,
    infoAddedForTheFirstTime: undefined,
    courseOrderedForTheFirstTime: undefined,
    courseJoinedFortTheFirstTime: undefined,
    verified: undefined,
  };
  return mappedData;
}
