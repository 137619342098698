import { createAction, createSlice } from "@reduxjs/toolkit";
import { getActionCreatorName } from "../util/getActionCreatorName";
import { optionType } from "@components/LandingPage/FormSelect";
import { Language } from "@lib/Enums/Language";

export enum UserRole {
  ADMIN = "ADMIN",
  STUDENT = "STUDENT",
  TEAHCER = "TEACHER",
  MANAGER = "MANAGER",
}

export function getRoleString(role: UserRole) {
  switch (role) {
    case UserRole.ADMIN:
      return "Admin";
    case UserRole.MANAGER:
      return "Manager";
    case UserRole.TEAHCER:
      return "Teacher";
    case UserRole.STUDENT:
      return "Student";
    default:
      return "";
  }
}
export interface userInfo {
  key: number;
  loggedIn: boolean;
  role: UserRole | undefined;
  userId: string | undefined;
  name: string | undefined;
  profileUrl: string | undefined;
  email: string | undefined;
  phone: string | undefined;
  address: string | undefined;
  city: string | undefined;
  country: string | undefined;
  zipcode: number | undefined;
  dateOfBirth: string | undefined;
  language: Language | undefined;
  university: optionType | undefined;
  univversityLogo: string | undefined;
  study: optionType | undefined;
  grade: optionType | undefined;
  smartPoints: number | undefined;
  universityStudyAddedForTheFirstTime: boolean | undefined;
  infoAddedForTheFirstTime: boolean | undefined;
  courseOrderedForTheFirstTime: boolean | undefined;
  courseJoinedFortTheFirstTime: boolean | undefined;
  verified: boolean | undefined;
}

export const initialStateOfUserProfile: userInfo = {
  key: new Date().getTime(),
  loggedIn: false,
  role: undefined,
  userId: undefined,
  name: undefined,
  profileUrl: undefined,
  email: undefined,
  phone: undefined,
  address: undefined,
  zipcode: undefined,
  city: undefined,
  country: undefined,
  dateOfBirth: undefined,
  language: undefined,
  university: undefined,
  univversityLogo: undefined,
  study: undefined,
  grade: undefined,
  smartPoints: undefined,
  universityStudyAddedForTheFirstTime: undefined,
  infoAddedForTheFirstTime: undefined,
  courseOrderedForTheFirstTime: undefined,
  courseJoinedFortTheFirstTime: undefined,
  verified: undefined,
};

export const userSlice = createSlice({
  name: "userInfo",
  initialState: initialStateOfUserProfile,
  reducers: {
    setUserInfo: (state, action) => {
      state.key = action.payload.key;
      state.loggedIn = action.payload.loggedIn;
      state.role = action.payload.role;
      state.userId = action.payload.userId;
      state.name = action.payload.name;
      state.profileUrl = action.payload.profileUrl;
      state.email = action.payload.email;
      state.phone = action.payload.phone;
      state.address = action.payload.address;
      state.zipcode = action.payload.zipcode;
      state.country = action.payload.country;
      state.city = action.payload.city;
      state.dateOfBirth = action.payload.dateOfBirth;
      state.language = action.payload.language;
      state.university = action.payload.university;
      state.univversityLogo = action.payload.univversityLogo;
      state.study = action.payload.study;
      state.grade = action.payload.grade;
      state.smartPoints = action.payload.smartPoints;
      state.infoAddedForTheFirstTime = action.payload.infoAddedForTheFirstTime;
      state.universityStudyAddedForTheFirstTime =
        action.payload.universityStudyAddedForTheFirstTime;
      state.courseOrderedForTheFirstTime =
        action.payload.courseOrderedForTheFirstTime;
      state.courseJoinedFortTheFirstTime =
        action.payload.courseJoinedFortTheFirstTime;
      state.verified = action.payload.verified;
    },
  },
});

const actionNames = getActionCreatorName(userSlice);

// Custom action creators

function setUserInfoActionCreatorCallback(
  key: number,
  loggedIn: boolean,
  role?: UserRole,
  userId?: string,
  name?: string,
  profileUrl?: string,
  email?: string,
  phone?: string,
  address?: string,
  zipcode?: number,
  country?: string,
  city?: string,
  dateOfBirth?: string,
  language?: Language,
  university?: optionType,
  univversityLogo?: string,
  study?: optionType,
  grade?: optionType,
  smartPoints?: number,
  universityStudyAddedForTheFirstTime?: boolean,
  infoAddedForTheFirstTime?: boolean,
  courseOrderedForTheFirstTime?: boolean,
  courseJoinedFortTheFirstTime?: boolean,
  verified?: boolean
): { payload: userInfo } {
  if (loggedIn === true) {
    return {
      payload: {
        key,
        loggedIn,
        role,
        userId,
        name,
        profileUrl,
        email,
        phone,
        address,
        zipcode,
        country,
        city,
        dateOfBirth,
        language,
        university,
        univversityLogo,
        study,
        grade,
        smartPoints,
        universityStudyAddedForTheFirstTime,
        infoAddedForTheFirstTime,
        courseOrderedForTheFirstTime,
        courseJoinedFortTheFirstTime,
        verified,
      },
    };
  } else {
    return {
      payload: {
        key,
        loggedIn,
        role: undefined,
        userId: undefined,
        name: undefined,
        profileUrl: undefined,
        email: undefined,
        phone: undefined,
        address: undefined,
        zipcode: undefined,
        country: undefined,
        city: undefined,
        dateOfBirth: undefined,
        language: undefined,
        university: undefined,
        univversityLogo: undefined,
        study: undefined,
        grade: undefined,
        smartPoints: undefined,
        universityStudyAddedForTheFirstTime: undefined,
        infoAddedForTheFirstTime: undefined,
        courseOrderedForTheFirstTime: undefined,
        courseJoinedFortTheFirstTime: undefined,
        verified: undefined,
      },
    };
  }
}
export const setUserInfoActionCreator = createAction(
  actionNames.setUserInfo,
  setUserInfoActionCreatorCallback
);

// ------------------------------
