import { getCurrentDateTime } from "@API/services/Chat/ChatInterface";
import { OutGoingMessage } from "@lib/Types/Chat/OutGoingMessage";

export function mapMessageToAPI(message: string): OutGoingMessage {
  const item: OutGoingMessage = {
    content: message,
    time: getCurrentDateTime(),
    files: [],
  };
  return item;
}
