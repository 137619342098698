import { selectUser } from "@app/redux/publicSelectors/userSelector";
import PhoneNumberInput from "@lib/microComponents/PhoneNumberInput";
import Select from "@lib/microComponents/Select";
import { Field, useFormik } from "formik";
import { useState } from "react";
import { FaPenToSquare } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { object, string } from "yup";
import EditSelect from "./EditSelect";
import DatePicker from "@lib/microComponents/DatePicker";
import { format, getDate, parseISO } from "date-fns";
import { updateProfile } from "@API/services/StudentCrud/updateProfile";
import { ReactToast } from "@lib/toast";
import { getUserProfile } from "@API/services/Data/getUserProfile";
import { mapAPidatatoUserInfo } from "@lib/mapperFunctions/mapAPidatatoUserInfo";
import { UserRole, userInfo, userSlice } from "@app/redux/slices/userSlice";
import { device } from "@styles/theme";
import { Language, getLanguageInfo } from "@lib/Enums/Language";
import { useTranslation } from "react-i18next";
import { RootState } from "@app/redux/store";
import { useSelect } from "downshift";
import { City, Country } from "country-state-city";
import TempFormSelect from "./_temp_Edit_Select";

// styled-components----
const Container = styled.div`
  border-right: 2px solid ${(props) => props.theme.colors.gray[500]};
  padding-right: 3.125rem;
  @media ${device.laptopL} {
    padding-bottom: 3.125rem;
    border-right: none;
    border-bottom: 2px solid ${(props) => props.theme.colors.gray[500]};
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  @media ${device.tablet} {
    padding-right: 0;
    padding-bottom: 1.5625rem;
  }
`;
const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${device.tabletS} {
    flex-direction: column;
    gap: 0.625rem;
  }
`;
const Heading = styled.div`
  line-height: 1.9375rem;
  font-size: 1.375rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.gray[800]};
  @media ${device.tablet} {
    font-size: 1.125rem;
    line-height: 1.5625rem;
  }
`;
const EditButton = styled.button`
  height: 1.625rem;
  background: ${(props) => props.theme.colors.gray[500]};
  border-radius: 10px;
  color: ${(props) => props.theme.colors.gray[800]};
  padding: 0 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${device.tablet} {
    width: 4.5rem;
    font-size: 0.875rem;
    padding: 0 0.625rem;
  }
`;
const EditButtonIcon = styled(FaPenToSquare)`
  width: 0.75rem;
  height: 0.8125rem;
  min-width: 0.75rem;
  min-height: 0.8125rem;
  margin-left: 0.5rem;
  position: relative;
  top: -0.0625rem;
`;
const DetailsContainer = styled.div`
  margin-top: 3.125rem;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  width: 100%;
  gap: 1.875rem;
  @media ${device.tablet} {
    margin-top: 1.375rem;
  }
  @media ${device.tabletS} {
    gap: 1.4375rem;
  }
`;
const KeyValueContainer = styled.div`
  white-space: nowrap;
  display: grid;
  gap: 1rem;
  grid-template-columns: 4fr 9fr;
  width: 100%;
  font-size: 1rem;
  line-height: 1.4375rem;
  color: ${(props) => props.theme.colors.gray[800]};
  @media ${device.tablet} {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  @media ${device.tabletS} {
    grid-template-columns: minmax(0, 1fr);
    gap: 0.625rem;
  }
`;
const Keys = styled.div`
  font-weight: 700;
`;
const Value = styled.div`
  display: flex;
  flex-direction: column;
`;
const AddressLine = styled.div``;
const FieldInput = styled.input`
  background: ${(props) => props.theme.colors.gray[500]};
  border-radius: 5px;
  padding: 0.3125rem 1rem;
  color: ${(props) => props.theme.colors.gray[800]};
  &::placeholder {
    color: ${(props) => props.theme.colors.gray[700]};
  }
`;
const DatePickerContainer = styled.div`
  position: relative;
`;
const DatePickerButton = styled.button`
  user-select: none;
  padding: 0.6563rem 1.25rem;
  width: 15rem;
  background: ${(props) => props.theme.colors.gray[500]};
  color: ${(props) => props.theme.colors.gray[700]};
  border-radius: 5px;
`;
const DatePickerStyled = styled(DatePicker)`
  position: absolute;
  z-index: 1000;
`;

const SelectorContainer = styled.div`
  max-width: 100%;
`;
const ActionButtonsContainer = styled.div``;
const SaveButton = styled.button`
  padding: 0.4rem 0.8rem;
  border-radius: 5px;
  font-weight: 700;
  color: #ffffff;
  background: ${(props) => props.theme.colors.green[600]};
  @media ${device.tablet} {
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
  }
`;
const CancelButton = styled.button`
  margin-left: 1rem;
  border-radius: 5px;
  font-weight: 700;
  padding: 0.4rem 0.8rem;
  color: #ffffff;
  background: ${(props) => props.theme.colors.red[500]};
  @media ${device.tablet} {
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
    margin-left: 0.625rem;
  }
`;

// ---------------------
export interface StudnetProfileFormType {
  phone?: string | undefined;
  address?: string | undefined;
  zipcode?: number | undefined;
  country?: string | undefined;
  city?: string | undefined;
  dateOfBirth?: Date | undefined;
  language?: { id: Language; value: string } | undefined;
  name?: string | undefined;
}

function StudentInfo({
  loading,
  userInfo,
}: {
  loading: boolean;
  userInfo: userInfo;
}) {
  const profile = useSelector(selectUser);
  const [editActive, setEditActive] = useState<boolean>(false);
  const [showDate, setShowDate] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      phone: userInfo.phone,
      address: userInfo.address,
      zipcode: userInfo.zipcode,
      country: userInfo.country
        ? { id: "0", value: userInfo.country }
        : undefined,
      city: userInfo.city ? { id: "0", value: userInfo.city } : undefined,
      dateOfBirth: userInfo.dateOfBirth
        ? parseISO(userInfo.dateOfBirth)
        : undefined,
      language: !userInfo.language
        ? undefined
        : {
            id: userInfo.language,
            value: getLanguageInfo(userInfo.language).name,
          },
    },
    onSubmit: async (data) => {
      const res = await updateProfile(
        {
          ...data,
          country: data.country ? data.country.value : undefined,
          city: data.city ? data.city.value : undefined,
        },
        userInfo.role
      );
      if (res.success) {
        ReactToast("Profile Update Successfull");
        const userInfo = await getUserProfile();
        if (userInfo.success) {
          dispatch(userSlice.actions.setUserInfo(userInfo.data));
        }
      } else {
        ReactToast("Profile could not be updated");
      }
      setEditActive(false);
    },
  });
  const cities = formik.values.country
    ? City.getCitiesOfCountry(formik.values.country.id) || []
    : [];
  function getDateOfBirth() {
    const dateOfBirth = formik.getFieldProps("dateOfBirth").value;
    if (dateOfBirth) {
      const formattedDate = format(dateOfBirth, "yyyy-MM-dd");
      return formattedDate;
    }
    return undefined;
  }
  function getHeading(role: UserRole | undefined) {
    switch (role) {
      case UserRole.ADMIN:
        return "Admin info";
      case UserRole.STUDENT:
        return t("sdashboard.profile.info.heading");
      case UserRole.MANAGER:
        return "Manager info";
      case UserRole.TEAHCER:
        return "Teacher info";
      default:
        return t("sdashboard.profile.info.heading");
    }
  }
  return (
    <Container>
      <HeadingContainer>
        <Heading>{getHeading(userInfo.role)}</Heading>
        {profile.userId === userInfo.userId &&
          (!editActive ? (
            <EditButton
              type="button"
              onClick={() => {
                setEditActive(true);
              }}
            >
              {t("sdashboard.profile.edit")}
              <EditButtonIcon />
            </EditButton>
          ) : (
            <ActionButtonsContainer>
              <SaveButton
                type="submit"
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                {t("sdashboard.profile.save")}
              </SaveButton>
              <CancelButton
                type="button"
                onClick={() => {
                  setEditActive(false);
                }}
              >
                {t("sdashboard.profile.cancel")}
              </CancelButton>
            </ActionButtonsContainer>
          ))}
      </HeadingContainer>
      <DetailsContainer>
        <KeyValueContainer>
          <Keys>{t("sdashboard.profile.info.email")}</Keys>
          <Value>{userInfo.email}</Value>
        </KeyValueContainer>
        <KeyValueContainer>
          <Keys>{t("sdashboard.profile.info.phone")}</Keys>
          {!editActive ? (
            <Value>
              {userInfo.phone
                ? userInfo.phone
                : t("sdashboard.profile.not_provided")}
            </Value>
          ) : (
            <PhoneNumberInput
              value={formik.getFieldProps("phone").value}
              onChange={(e) => {
                if (e) {
                  formik.setFieldValue("phone", e);
                }
              }}
            />
          )}
        </KeyValueContainer>
        <KeyValueContainer>
          <Keys>{t("sdashboard.profile.info.address")}</Keys>
          <Value>
            {!editActive ? (
              <AddressLine>
                {userInfo.address
                  ? userInfo.address
                  : t("sdashboard.profile.not_provided")}
              </AddressLine>
            ) : (
              <FieldInput
                placeholder={t("sdashboard.profile.info.form.address.address")}
                {...formik.getFieldProps("address")}
              />
            )}

            {userInfo.zipcode && !editActive && (
              <AddressLine>{userInfo.zipcode}</AddressLine>
            )}
            {editActive && (
              <FieldInput
                {...formik.getFieldProps("zipcode")}
                style={{ marginTop: "0.5rem" }}
                placeholder={t("sdashboard.profile.info.form.address.zipcode")}
              />
            )}
            {userInfo.country && !editActive && (
              <AddressLine>{userInfo.country}</AddressLine>
            )}
            {editActive && (
              <TempFormSelect
                text={t("sdashboard.profile.info.form.address.country")}
                onChange={(e) => {
                  formik.setFieldValue("country", e);
                }}
                value={formik.values.country}
                options={Country.getAllCountries().map((item) => {
                  return {
                    id: item.isoCode,
                    value: item.name,
                  };
                })}
                style={{ marginTop: "0.5rem" }}
              ></TempFormSelect>
            )}
            {userInfo.city && !editActive && (
              <AddressLine>{userInfo.city}</AddressLine>
            )}
            {editActive && (
              <TempFormSelect
                text={t("sdashboard.profile.info.form.address.city")}
                onChange={(e) => {
                  formik.setFieldValue("city", e);
                }}
                value={formik.values.city}
                options={cities.map((item, index) => {
                  return {
                    id: item.name,
                    value: item.name,
                  };
                })}
                disabled={!formik.values.country}
                style={{ marginTop: "0.5rem" }}
              ></TempFormSelect>
            )}
          </Value>
        </KeyValueContainer>
        <KeyValueContainer>
          <Keys>{t("sdashboard.profile.info.dob")}</Keys>
          {!editActive ? (
            <Value>
              {userInfo.dateOfBirth
                ? userInfo.dateOfBirth
                : t("sdashboard.profile.not_provided")}
            </Value>
          ) : (
            <DatePickerContainer>
              <DatePickerButton
                onClick={() => {
                  setShowDate((showDate) => !showDate);
                }}
              >
                {getDateOfBirth()
                  ? getDateOfBirth()
                  : t("sdashboard.profile.info.form.date_placeholder")}
              </DatePickerButton>
              {showDate && (
                <DatePickerStyled
                  setShow={setShowDate}
                  value={formik.getFieldProps("dateOfBirth").value}
                  onChange={(e) => {
                    formik.setFieldValue("dateOfBirth", e);
                  }}
                  showYear={true}
                />
              )}
            </DatePickerContainer>
          )}
        </KeyValueContainer>
        <KeyValueContainer>
          <Keys>{t("sdashboard.profile.info.language")}</Keys>
          {!editActive ? (
            <Value>
              {userInfo.language
                ? "English"
                : t("sdashboard.profile.not_provided")}
            </Value>
          ) : (
            <EditSelect
              text={t("sdashboard.profile.info.form.language_placeholder")}
              onChange={(e) => {
                if (e) {
                  formik.setFieldValue("language", e);
                }
              }}
              value={formik.getFieldProps("language").value}
              options={[
                {
                  id: Language.ENGLISH_US,
                  value: getLanguageInfo(Language.ENGLISH_US).name,
                },
                {
                  id: Language.DUTCH,
                  value: getLanguageInfo(Language.DUTCH).name,
                },
              ]}
            />
          )}
        </KeyValueContainer>
      </DetailsContainer>
    </Container>
  );
}

export default StudentInfo;
