import React from "react";
import Header from "@components/CommonDashboard/Header";
import styled from "styled-components";
import image from "@images/vector1.svg"

// styled-components----
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`;
const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  background: ${(props) => props.theme.colors.gray[500]};
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Heading = styled.div`
  font-size: 2rem;
  font-weight: 700;
  font: normal normal bold 1.6875rem/2.5rem Poppins;
  color: ${(props) => props.theme.colors.gray[800]};
  margin-top:2.75rem;
  margin-bottom: 0.875rem;
`;

const SubHeading = styled.div`
  font-size: 2rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.gray[600]};
  font: normal normal normal 1.375rem/2.0625rem Poppins;
`;

const PageNotFoundImage = styled.img`
width: 17.375rem;
height: 13.25rem;
`;
// ---------------------

function PageNotFound() {
  return (
    <Container>
      <Header />
      <InnerContainer>
        <ContentContainer>
          <PageNotFoundImage src={image} />
          <Heading>Oops! Page not found…</Heading>
          <SubHeading>Sorry, we couldn’t find this page</SubHeading>
        </ContentContainer>
      </InnerContainer>
    </Container>
  );
}

export default PageNotFound;
