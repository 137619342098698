import { getStudyList } from "@API/services/CourseCRUD/getStudyList";
import { optionType } from "@components/AdminDashboard/SubPages/Courses/AddCourse/Components/FormSelect";
import { mapStudyListToOptions } from "@lib/mapperFunctions/mapStudyListToOptions";
import { ReactToast } from "@lib/toast";
import { useEffect, useState } from "react";

export const useStudyOption = async function (id: string) {
  if (!id) {
    throw new Error("Provide an id to get study list");
  }
  const data = await getStudyList(id);
  if (data.success) {
    const mappedList = mapStudyListToOptions(data.data);
    return mappedList;
  } else {
    ReactToast(data.data);
    return [];
  }
};
