import React, { ReactNode } from "react";
import { v4 as uuid } from "uuid";
import Backdrop from "./Backdrop";
import styled from "styled-components";
import { FaXmark, FaZ } from "react-icons/fa6";
import ScheduleForm from "./ScheduleForm";
import { ScheduleItemType } from "@components/AdminDashboard/SubPages/Courses/AddCourse/formInitialValues";
import { SCHEDULE } from "@components/AdminDashboard/SubPages/Courses/AddCourse/validationSchema";
import { array, date, object, string } from "yup";
import { FormikProps, useFormik } from "formik";
import { editScheduleByTeacher } from "@API/services/Teacher/editScheduleOfACourse";
import { ReactToast } from "@lib/toast";
// styled-components ----
const Container = styled.div`
  z-index: 3001;
  position: relative;
  width: 90%;
  background: white;
  padding: 1.875rem;
  border-radius: 20px;
  opacity: 1;
`;
const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const CloseButton = styled.button`
  margin: none;
  padding: none;
  background: none;
`;
const CrossIcon = styled(FaXmark)`
  height: 1.625rem;
  width: 1rem;
  opacity: 0.15;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5625rem;
`;
const Heading = styled.h2`
  font-size: 1.5625rem;
  line-height: 2.1875rem;
  color: ${(props) => props.theme.colors.gray[800]};
  font-weight: 700;
  padding: 0 1.875rem;
`;
const HeadingLine = styled.div`
  height: 0.125rem;
  background: ${(props) => props.theme.colors.gray[500]};
`;
// ----------------------
const validationSchema = object({
  [SCHEDULE]: array()
    .of(
      object().shape({
        name: string()
          .required("Schedule name is required ")
          .min(5, "Schedule name must be at least 5 characters long")
          .max(50, "Schedule name must be at most 50 characters long"),
        date: date().required("Schedule date is required"),
        startTime: string().required("Start time is required"),
        endTime: string().required("End time is required"),
        id: string().required(),
      })
    )
    .min(1),
});
interface formikConfigType {
  initialValues: {
    [SCHEDULE]: ScheduleItemType[];
  };
  onSubmit: () => void;
  validationSchema: typeof validationSchema;
}

function TeachersScheduleEditModal({
  courseId,
  scheduleItems,
  handleClose,
  heading,
}: {
  courseId: string;
  scheduleItems: ScheduleItemType[];
  handleClose: () => void;
  heading: string;
}) {
  const formik = useFormik({
    initialValues: {
      [SCHEDULE]: scheduleItems,
    },
    onSubmit: async (values, actions) => {
      const res = await editScheduleByTeacher(courseId, values[SCHEDULE]);
      if (res.success) {
        ReactToast.success("Schedule edited successfully");
      } else {
        ReactToast.error("Could not edit schedule");
      }
    },
    validationSchema: validationSchema,
  });
  const handleSubmit = () => {};
  return (
    <Backdrop>
      <Container>
        <CloseButtonContainer>
          <CloseButton onClick={handleClose}>
            <CrossIcon />
          </CloseButton>
        </CloseButtonContainer>
        <HeadingContainer>
          <Heading>{heading}</Heading>
          <HeadingLine />
        </HeadingContainer>
        <ScheduleForm formik={formik} />
      </Container>
    </Backdrop>
  );
}

export default TeachersScheduleEditModal;
export type Formik = FormikProps<formikConfigType["initialValues"]>;
