import pageRouteNames from "@/constants/pageRouteNames";
import { selectSettings } from "@app/redux/publicSelectors/settingsSelector";
import { getLanguageInfo } from "@lib/Enums/Language";
import { returnType } from "@lib/hooks/useCourseDetails";
import { convertCurrency } from "@lib/util/main";
import { device } from "@styles/theme";
import { useNavigation } from "react-day-picker";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

// styled.components ----
const Container = styled.div`
  display: flex;
  gap: 8.25rem;
  align-items: center;
  position: relative;
  @media ${device.tablet} {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 2rem;
  }
`;
const KnowledgeGuideInfoContainer = styled.div``;
const KnowledgeGuideHeading = styled.div`
  font-size: 1.375rem;
  line-height: 1.9375rem;
  font-weight: 700;
  border-left: 11px solid ${(props) => props.theme.colors.blue[700]};
  padding-left: 1.25rem;
  color: ${(props) => props.theme.colors.gray[800]};
  @media ${device.tablet} {
    line-height: 1.5625rem;
    font-size: 1.125rem;
    border-width: 0.5rem;
  }
`;
const KnowledgeGuideSubHeading = styled.div`
  font-size: 1rem;
  color: ${(props) => props.theme.colors.gray[800]};
  font-weight: 700;
  line-height: 1.25rem;
  margin-top: 1.25rem;
  @media ${device.tablet} {
    font-size: 14px;
    line-height: 18px;
  }
`;

const KnowledgeGuideDescriptiton = styled(KnowledgeGuideSubHeading)`
  font-weight: 500;
  margin-top: 0.9375rem;
  @media ${device.tablet} {
    font-size: 14px;
    line-height: 18px;
  }
`;

const KnowledgeGuideFreeInfoContainer = styled.div`
  font-size: 0.75rem;
  line-height: 1.125rem;
  background: ${(props) => props.theme.colors.gray[400]};
  padding: 1.25rem 1.375rem;
  border-radius: 10px;
  color: ${(props) => props.theme.colors.gray[800]};
  margin-top: 0.9375rem;
`;

const KnowledgeGuideImageContainer = styled.div``;

const KnowledgeGuideImage = styled.img`
  width: 14.625rem;
  height: 19.625rem;
`;

const KnowledgeGuidePurchaseButton = styled.button`
  width: 100%;
  max-width: 26.125rem;
  height: 3.4375rem;
  background: ${(props) => props.theme.colors.green[600]};
  color: #ffffff;
  font-weight: 700;
  margin-top: 1.5625rem;
  border-radius: 10px;
`;
const PriceAndLanguageContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
const PriceContainer = styled.div`
  width: 7.25rem;
  height: 3rem;
  background: ${(props) => props.theme.colors.gray[500]};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5rem;
  color: ${(props) => props.theme.colors.gray[800]};
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5625rem;
`;
const LanguageIndicator = styled.div`
  background: ${(props) => props.theme.colors.gray[500]};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5rem;
  color: ${(props) => props.theme.colors.gray[800]};
  font-weight: 700;
  padding: 0.9375rem 1.6875rem;
  margin-top: 0.625rem;
  @media ${device.tablet} {
    padding: 0.9375rem;
  }
`;
const LanguageIndicatorText = styled.span`
  @media ${device.tablet} {
    display: none;
  }
`;

const LanguageIconContainer = styled.div`
  margin-left: 0.625rem;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 1.125rem;
  width: 1.125rem;
  border-radius: 0.5625rem;
  overflow: hidden;
  svg {
    position: absolute;
    height: 100%;
    min-height: 100%;
  }
  @media ${device.tablet} {
    margin-left: 0;
  }
`;
// -----------------------
function CourseStudyGuide({ details }: { details: returnType<true | false> }) {
  const [loading, courseDetails] = details;
  const navigate = useNavigate();
  const languageOfCourse = getLanguageInfo(
    courseDetails ? courseDetails.language : undefined
  );
  const { language } = useSelector(selectSettings);
  return (
    <Container>
      {!loading && (
        <>
          <KnowledgeGuideInfoContainer>
            <KnowledgeGuideHeading>
              Prefer just the digital knowledge guide?
            </KnowledgeGuideHeading>
            <KnowledgeGuideSubHeading>
              No time/will to attend the training? Then you can also only
              purchase the knowledge guide.
            </KnowledgeGuideSubHeading>
            <KnowledgeGuideDescriptiton>
              In this digital knowledge guide you will find an extensive summary
              of the subject matter, supplemented with practice questions and
              their answers.
            </KnowledgeGuideDescriptiton>
            <KnowledgeGuideFreeInfoContainer>
              When you have booked a training for this course, you will receive
              this knowledge guide free of charge as part of your training.
            </KnowledgeGuideFreeInfoContainer>
            <KnowledgeGuidePurchaseButton
              onClick={() => {
                navigate(
                  `/${courseDetails.id}/${pageRouteNames.COURSE}/studyGuide/checkout`
                );
              }}
            >
              Purchase this knowledge guide
            </KnowledgeGuidePurchaseButton>
          </KnowledgeGuideInfoContainer>
          <KnowledgeGuideImageContainer>
            <KnowledgeGuideImage src={"/assets/book.png"}></KnowledgeGuideImage>
          </KnowledgeGuideImageContainer>
          <PriceAndLanguageContainer>
            <PriceContainer>
              {convertCurrency(courseDetails.guide.price)}
            </PriceContainer>
            <LanguageIndicator>
              <LanguageIndicatorText>
                {languageOfCourse.name} guide
              </LanguageIndicatorText>
              <LanguageIconContainer>
                <languageOfCourse.icon />
              </LanguageIconContainer>
            </LanguageIndicator>
          </PriceAndLanguageContainer>
        </>
      )}
    </Container>
  );
}

export default CourseStudyGuide;
