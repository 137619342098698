import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";
import { UserRole } from "@app/redux/slices/userSlice";
export const inviteTeamMember = async (
  name: string,
  email: string,
  universityIds: number[] | undefined,
  role: UserRole
): Promise<boolean> => {
  const url = urlFunctions.inviteTeamMember();
  let data;
  if (role === UserRole.ADMIN) {
    data = {
      name,
      email,
      role: UserRole.ADMIN,
    };
  } else if (role === UserRole.TEAHCER) {
    data = {
      name,
      email,
      role: UserRole.TEAHCER,
    };
  } else if (role === UserRole.MANAGER) {
    data = {
      name,
      email,
      universityIds,
      role: UserRole.MANAGER,
    };
  }
  const res = await API.sendPostRequest(url, data, true);
  if (res.success) {
    return true;
  }
  return false;
};
