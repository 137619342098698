import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";

type Response =
  | {
      success: true;
      data: Blob;
    }
  | {
      success: false;
      error: string;
    };

export async function downloadFile(fileurl: string): Promise<Response> {
  const url = urlFunctions.downloadUrl(fileurl);
  const res = await API.getFile(url, true);

  if (res.success) {
    const fileBlob = await res.data;
    return {
      success: true,
      data: fileBlob,
    };
  } else {
    return {
      success: false,
      error: "Could not get image",
    };
  }
}
