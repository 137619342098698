import { CourseDetailsType } from "@components/View/CourseView/CourseDetailsType";
import { APICourseResponse } from "@lib/Types/API/APICourseResponse";
import { formatDuration } from "@lib/util/main";
import { CourseStatus } from "@lib/Enums/CourseStatus";
import { assetsUrls } from "@styles/theme";
const NOT_SPECIFIED = "Not specified";
export const mapAPICourseDetailsToComponent = function (
  response: APICourseResponse
): CourseDetailsType {
  const item = response.course;
  const data: CourseDetailsType = {
    id: item.id,
    universityImage: item.university.universityLogoUrl
      ? item.university.universityLogoUrl
      : "/assets/defaultuniversityicon.png",
    university: item.university.universityName,
    type: item.type ? item.type.courseType : NOT_SPECIFIED,
    major: item.courseSubject ? item.courseSubject.name : NOT_SPECIFIED,
    subject: item.courseStudy.name,
    language: item.language,
    available: item.status === CourseStatus.PUBLISH,
    price: item.pricing.originalPrice,
    salePrice: item.pricing.salePrice,
    courseDuration: formatDuration(item.totalCourseDuration),
    courseFormat: "Digital Course via Zoom",
    guide: {
      knowledgeGuideAvailable: item.courseStudyMaterials
        ? item.courseStudyMaterials.available
        : false,
      price: item.courseStudyMaterials ? item.courseStudyMaterials.price : 0,
      sellIndividually: item.courseStudyMaterials
        ? item.courseStudyMaterials.sellIndividually
        : false,
    },
    features: item.courseDescription.features,
    questionnaires: item.courseDescription.questionnaires,
    highlights: item.courseDescription.highlights,
    teacher: {
      name: item.teacher ? item.teacher.name : NOT_SPECIFIED,
      image: item.teacher
        ? item.teacher.profilePicUrl
          ? item.teacher.profilePicUrl
          : "/assets/user.png"
        : assetsUrls.userPng,
      profile: {
        "3": item.teacherUsps.usp1 ? item.teacherUsps.usp1 : NOT_SPECIFIED,
        "4": item.teacherUsps.usp2 ? item.teacherUsps.usp2 : NOT_SPECIFIED,
      },
    },
    courseSchedules: item.courseSchedules.map((schedule) => ({
      id: schedule.id,
      course: null,
      name: schedule.name,
      date: schedule.date,
      startTime: schedule.startTime,
      endTime: schedule.endTime,
      zoomMeetingLink: schedule.zoomMeetingLink,
    })),
    rating: 4.6,
    action: response.courseAction,
  };
  return data;
};
