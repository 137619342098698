import React from "react";
import styled from "styled-components";
import Vector1 from "@images/vector1.svg";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { device } from "@styles/theme";
import { useTranslation } from "react-i18next";
// styled-components----
const GreetingsContainer = styled.div`
  background: transparent linear-gradient(90deg, #4883c4 0%, #1b67bc 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 20px;
  padding: 27.5px 50px;
  display: flex;
  position: relative;
  @media ${device.laptopL} {
    padding-bottom: 7rem;
  }
  @media ${device.laptop} {
    padding: 20px;
    padding-bottom: 7rem;
  }
  @media ${device.tablet} {
    border-radius: 10px;
    padding-bottom: 3.4375rem;
  }
`;
const HeadingsContainer = styled.div``;
const GreetingText = styled.h3`
  color: #ffffff;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.8125rem;
  @media ${device.tablet} {
    font-size: 1.125rem;
    line-height: 1.5625rem;
  }
`;
const SubGreeting = styled.h1`
  line-height: 1.4375rem;
  font-size: 1rem;
  color: #ffffff;
  @media ${device.tablet} {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;
const Vector = styled.img`
  height: 9.375rem;
  position: absolute;
  bottom: 0;
  right: 8.875rem;
  @media ${device.laptopL} {
    right: 4rem;
    height: 8rem;
  }
  @media ${device.tablet} {
    height: 4.625rem;
    right: 1rem;
  }
`;
//----------------------

function CourseFeedbackGreetings() {
  const { t } = useTranslation();
  return (
    <GreetingsContainer>
      <HeadingsContainer>
        <GreetingText>Thank you for your participation</GreetingText>
        <SubGreeting>We hope we have helped you well</SubGreeting>
      </HeadingsContainer>
      <Vector src={Vector1} />
    </GreetingsContainer>
  );
}

export default CourseFeedbackGreetings;
