import styled from "styled-components";
import FormSelect, { optionType } from "./FormSelect";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUniversityOptions } from "@lib/hooks/useUniversityOptions";
import { useStudyOption } from "@lib/hooks/useStudyOptions";
import { useDispatch, useSelector } from "react-redux";
import { searchSlice } from "@app/redux/slices/searchSlice";
import {
  selectSubject,
  selectStudy,
  selectUniversity,
} from "./searchSelectors";
import { useGradeYearOptions } from "@lib/hooks/useGradeYearOptions";
import { useTranslation } from "react-i18next";
import { device } from "@styles/theme";
import { useSubjectOptions } from "@lib/hooks/useSubjectOptions";
import NewSelect from "@lib/microComponents/NewSelect";
import NewFormSelect from "./NewHomePageFormSelect";
// styled-components----
const Form = styled.form`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
`;
const FormHeading = styled.h3`
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  color: ${(props) => props.theme.colors.gray[800]};
  @media ${device.tablet} {
    font-size: 1.125rem;
    line-height: 1.5625rem;
  }
`;

const SearchButton = styled.button`
  height: 3.8rem;
  border-radius: 10px;
  width: 100%;
  font-family: "Poppins", sans-serif;
  background: ${(props) => props.theme.colors.green[600]};
  color: #ffffff;
  font-weight: 700;
  @media ${device.tablet} {
    height: 3.6875rem;
  }
`;
// ---------------------
const testOptions = [
  { id: "0", value: "okay" },
  { id: "1", value: "notokay" },
];

function LadingPageForm() {
  const dispatch = useDispatch();
  const university = useSelector(selectUniversity);
  const universityOptions = useUniversityOptions();
  const study = useSelector(selectStudy);
  const [studyOptions, setStudyOptions] = useState<optionType[]>([]);
  const [subjectOptions, setSubjectOptions] = useState<optionType[]>([]);
  const subject = useSelector(selectSubject);
  const { lang } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  async function getAndSetStudyOptions(universityId: string) {
    const options = await useStudyOption(universityId);
    setStudyOptions(options);
  }
  async function getAndSetSubjectOptions(studyId: string) {
    const options = await useSubjectOptions(studyId);
    setSubjectOptions(options);
  }
  useEffect(() => {
    if (university) {
      getAndSetStudyOptions(university.id);
    }
  }, [university]);
  useEffect(() => {
    if (study) {
      getAndSetSubjectOptions(study.id);
    }
  }, [study]);
  return (
    <Form
      onSubmit={() => {
        navigate(`/${lang}/search`);
      }}
    >
      <FormHeading>{t("landingPage.form.heading")}</FormHeading>
      <NewFormSelect
        onChange={(e) => {
          dispatch(searchSlice.actions.setUniversity(e));
          dispatch(searchSlice.actions.setStudy(undefined));
          dispatch(searchSlice.actions.setStudy(undefined));
        }}
        value={university}
        options={universityOptions}
        text={t("landingPage.form.universitySelector")}
      ></NewFormSelect>
      <NewFormSelect
        onChange={(e) => {
          dispatch(searchSlice.actions.setStudy(e));
          dispatch(searchSlice.actions.setSubject(undefined));
        }}
        disabled={!university}
        text={t("landingPage.form.studySelector")}
        value={study}
        options={studyOptions}
      ></NewFormSelect>
      <NewFormSelect
        onChange={(e) => {
          dispatch(searchSlice.actions.setSubject(e));
        }}
        disabled={!study}
        text={t("landingPage.form.subjectSelector")}
        value={subject}
        options={subjectOptions}
      ></NewFormSelect>
      <SearchButton style={{ marginTop: "1.2rem" }}>
        {t("landingPage.form.searchButton")}
      </SearchButton>
    </Form>
  );
}

export default LadingPageForm;
