import { StudnetProfileFormType } from "@components/StudentDashBoardSubPages/StudentProfile/StudentInfo";
import { APIUserInfoUpdateSchema } from "@lib/Types/API/APIUserInfoUpdateSchema";
import { format } from "date-fns";

export function mapStudentProfileToAPI(
  data: StudnetProfileFormType
): APIUserInfoUpdateSchema {
  return {
    phone: data.phone,
    address: {
      address: data.address,
      zipcode: data.zipcode,
      country: data.country,
      city: data.city,
    },
    dateOfBirth: data.dateOfBirth
      ? format(data.dateOfBirth, "yyyy-MM-dd")
      : undefined,
    language: data.language ? "ENGLISH_US" : undefined,
    name: data.name ? data.name : undefined,
  };
}
