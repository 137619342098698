import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../../commonComponents/Header";
import AddButton from "../../commonComponents/AddButton";
import Table from "./Table";
import Modal from "@components/CommonComponents/Modal";
import AddMembersForm from "./AddMemberForm";
import { useTranslation } from "react-i18next";
import { ManagerData } from "./AdminManagers";
import { getAdminManagers } from "@API/services/Admin/adminManagerList";
import { AdminData } from "./AdminAdmin";
import { getAdminAdmins } from "@API/services/Admin/adminAdminList";
// styled-comoponents ----
const Container = styled.div``;
const TableContainer = styled.div`
  margin-top: 1.875rem;
`;
// -----------------------

export default function AdminAndManagers() {
  const [Managers, setManagers] = useState<ManagerData[]>([]);
  const [Admins, setAdmins] = useState<AdminData[]>([]);
  const [loadingA, setLoadingA] = useState<boolean>(true);
  const [loadingM, setLoadingM] = useState<boolean>(true);
  const getAdminManager = async () => {
    setLoadingA(true);
    const data: ManagerData[] | undefined = await getAdminManagers();
    if (data) setManagers(data);
    setLoadingA(false);
  };
  const getAdminAdmin = async () => {
    setLoadingM(true);
    const data: AdminData[] | undefined = await getAdminAdmins();
    if (data) setAdmins(data);
    setLoadingM(false);
  };
  useEffect(() => {
    getAdminManager();
    getAdminAdmin();
  }, []);
  
  const [showModal, setShowModal] = useState<boolean>(false);
  const { t } = useTranslation();
  return (
    <Container>
      {showModal && (
        <Modal
          handleClose={() => {
            setShowModal(false);
          }}
          heading={t("adminSettings.adminsAndManagersPage.inviteANewAdminOrManager")}
        >
          <AddMembersForm />
        </Modal>
      )}
      <Header text={t("adminSettings.adminsAndManagers")} searchText={t("adminSettings.adminsAndManagers")}>
        <AddButton
          onClick={() => {
            setShowModal(true);
          }}
        >
          {t("adminSettings.adminsAndManagersPage.inviteNew")}
        </AddButton>
      </Header>
      <TableContainer>
        <Table managerData={Managers} adminData={Admins} loadingA={loadingA} loadingM={loadingM} />
      </TableContainer>
    </Container>
  );
}
