import { CourseDetailsType } from "@components/View/CourseView/CourseDetailsType";
import {
  CardElement,
  CardNumberElement,
  Elements,
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripePaymentElementOptions } from "@stripe/stripe-js";
import { useState } from "react";
import styled from "styled-components";
import "./TempCss.css";
import { IntentErrorType } from "@lib/Types/API/Payment/PaymentIntent";
import { useSelector } from "react-redux";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { FRONTEND_URI } from "@API/CONSTANTS";
import { LoadingComponent } from "@components/AdminDashboard/SubPages/Orders/OrderDetails";

import { usePaymentIntent } from "@lib/hooks/usePaymentIntent";
import { useNavigate, useParams } from "react-router-dom";
import MasterClassInrollmentComponent from "./MasterClassEnrollmentComponent";
import { CheckoutErrorPopup } from "@/components/popups";
import { device } from "@/styles/theme";
import { StripeLoadingSkeleton } from "./StripeLoadingSkeleton";
// styled.components -----
const Container = styled.div`
  height: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto 0;
  gap: 25px;
  height: 100%;
  justify-content: center;
`;

const BigText = styled.h2`
  font-size: 1.563rem;
  color: #ffffff;
  font-weight: bold;
  @media ${device.mobileL} {
    font-size: 1.263rem;
  }
`;

const SmallText = styled.p`
  font-size: 0.825rem;
  color: #ffffff;
  font-weight: normal;
  @media ${device.mobileL} {
    font-size: 0.585rem;
  }
`;
const FormContainer = styled.div`
  width: 100%;
`;

const CheckoutForm = styled.form``;
// -----------------------

function CheckoutFormComponent({ item }: { item: CourseDetailsType }) {
  const userInfo = useSelector(selectUser);
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string | null>(null);
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // return_url: "https://akc.pages.dev/dashboard?payment=true",
        return_url: `${FRONTEND_URI}/dashboard/overview?payment=true`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error") {
      setError(error.message ? error.message : "");
    } else if (error.type === "validation_error") {
      setMessage(error.message ? error.message : "");
    } else {
      setError("An unexpected error occurred.");
    }

    setLoading(false);
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: "accordion",
    defaultValues: {
      billingDetails: {
        email: userInfo.email ? userInfo.email : "",
        name: userInfo.name ? userInfo.name : "",
      },
    },
  };
  return (
    <Container>
      {error ? (
        <CheckoutErrorPopup
          onButtonClick={() => {
            setError("");
            elements?.getElement("cardNumber")?.clear();
          }}
        />
      ) : null}
      <CheckoutForm id="checkout-form" onSubmit={handleSubmit}>
        <LinkAuthenticationElement
          id="link-authentication-element"
          onChange={(e) => {}}
        />
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <button disabled={loading || !stripe || !elements} id="submit">
          <span id="button-text">
            {loading ? <div className="spinner" id="spinner"></div> : "Pay now"}
          </span>
        </button>
        {message && <div id="payment-message">{message}</div>}
      </CheckoutForm>
    </Container>
  );
}

export default function ({
  item,
  guide,
}: {
  item: CourseDetailsType;
  guide: boolean;
}) {
  const navigate = useNavigate();
  const { lang } = useParams();
  const [loading, paymentIntentReturnType, statusCode] = usePaymentIntent(
    item.id,
    guide
  );
  switch (statusCode) {
    case IntentErrorType.ALREADY_ENROLLED:
      navigate(`/${lang}/dashboard/overview/${item.id}/details`);
    case IntentErrorType.FAILURE:
      return <div>Error</div>;
    case IntentErrorType.MASTER_CLASS:
      return <MasterClassInrollmentComponent courseId={item.id} />;
  }
  const appearance: { theme: "stripe" } = {
    theme: "stripe",
  };

  return (
    <Container>
      <InnerContainer>
        {!loading && paymentIntentReturnType.success ? (
          <>
            <BigText style={{ color: "#707070" }}>Payment method</BigText>
            <FormContainer>
              <Elements
                key={paymentIntentReturnType.data}
                options={{
                  clientSecret: paymentIntentReturnType.data,
                  appearance,
                }}
                stripe={paymentIntentReturnType.promise}
              >
                <CheckoutFormComponent item={item} />
              </Elements>
            </FormContainer>
            <SmallText
              style={{
                color: "#707070",
                textAlign: "center",
                lineHeight: "1.7",
              }}
            >
              This is a secure checkout through{" "}
              <span style={{ textDecoration: "underline" }}>
                Stripe Payments
              </span>
              . You can only checkout with the same email address as your
              student profile. Please read our{" "}
              <span style={{ textDecoration: "underline" }}>disclaimer</span>{" "}
              for further information.
            </SmallText>
          </>
        ) : (
          <StripeLoadingSkeleton />
        )}
      </InnerContainer>
    </Container>
  );
}
