import {
  AdminOrdersDataResponseType,
  courseOrderResponse,
} from "@lib/Types/API/APIOrderDetails";
import { mapAdminOrders } from "@lib/mapperFunctions/mapAPIAdminOrders";
import { ordersData } from "@components/AdminDashboard/SubPages/Orders/AdminOrders";
import { urlFunctions } from "@API/functions/createUrl";
import { API } from "@API/API";
import { UserRole } from "@app/redux/slices/userSlice";
import { optionType } from "@components/LandingPage/FormSelect";

type response =
  | {
      success: true;
      data: courseOrderResponse[];
    }
  | {
      success: false;
      data: null;
    };

export const getAdminCourseOrders = async (
  courseId: string
): Promise<response> => {
  const sizeValue: number = 10;
  let url = urlFunctions.getAdminCourseOrderItems(courseId);
  const repsonse = await API.sendGetRequest(url, true);
  if (repsonse.success) {
    const data: courseOrderResponse[] = repsonse.data;
    return {
      success: true,
      data: data,
    };
  } else {
    return {
      success: false,
      data: null,
    };
  }
};
