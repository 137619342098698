import { UserRole } from "@app/redux/slices/userSlice";
import { TeacherData } from "@components/AdminDashboard/SubPages/Settings/SubPages/Teachers/AdminTeachers";
import { AdminTeacherResponseArray } from "@lib/Types/API/APIAdminTeachers";

export const mapResponseToAdminTeachers = (
  item: AdminTeacherResponseArray
): TeacherData[] => {
  return item.map((e) => ({
    teacherId: e.id,
    firstName: e.name.split(" ")[0],
    LastName: e.name.split(" ")[1],
    role: UserRole.TEAHCER,
    email: e.email,
    accepted: e.acceptedInvite,
  }));
};
