import {
  Table,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableDataCell,
} from "@lib/microComponents/Table";
import { StudentDataType } from "@lib/testData/mockStudentsData";
import { Link, useParams } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import { AdminStudentResponse } from "@lib/Types/API/APIAdminStudents";
import { mapAdminStudyListToComponent } from "@lib/mapperFunctions/admin/mapAdminStudyLIstToComponent";
import { mapAdminStudentsListToProfileComponent } from "@lib/mapperFunctions/admin/mapAdminStudentsListToProfileComponent";
import { Dispatch, SetStateAction, useCallback, useRef } from "react";
import { selectSettings } from "@/app/redux/publicSelectors/settingsSelector";
export interface StudentData {
  firstName: string;
  lastName: string;
  university: string;
  study: string;
  studentId: string;
  grade: string;
  email: string;
}
// styled-components----
const Container = styled.div`
  background: #ffffff;
  border-radius: 20px;
  padding: 1.875rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const FirstNameTableCell = styled(TableDataCell)`
  font-weight: 700;
`;
const LastNameTableCell = styled(TableDataCell)`
  font-weight: 700;
`;
const EmailCell = styled(TableDataCell)``;
const DetailsTableCell = styled(TableDataCell)``;
const DetailsLink = styled(Link)`
  height: 2.1875rem;
  width: 9.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.gray[800]};
  border-radius: 10px;
  background: ${(props) => props.theme.colors.gray[500]};
`;
const skeletonLoading = keyframes`
    0% {
      background: hsl(200,20%,70%);
    }
    100% {
      background: hsl(200,20%,95%)
    }
  `;
const LoadingComponent = styled.div`
  width: 100%;
  height: 2.1875rem;
  animation: ${skeletonLoading} 1s linear infinite alternate;
`;
// ---------------------
function TableSkeleton() {
  return (
    <TableRow>
      <FirstNameTableCell>
        <LoadingComponent />
      </FirstNameTableCell>
      <LastNameTableCell>
        <LoadingComponent />
      </LastNameTableCell>
      <EmailCell>
        <LoadingComponent />
      </EmailCell>
      <DetailsTableCell>
        <LoadingComponent />
      </DetailsTableCell>
    </TableRow>
  );
}

function StudentTable({
  items,
  loading,
}: {
  items: StudentData[];
  loading: boolean;
}) {
  const { t } = useTranslation();
  const { lang } = useParams();
  return (
    <Container>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>{t("adminStudents.firstName")}</TableHeaderCell>
            <TableHeaderCell>{t("adminStudents.lastName")}</TableHeaderCell>
            <TableHeaderCell>{t("adminStudents.email")}</TableHeaderCell>
            <TableHeaderCell>
              <div></div>
            </TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {!loading &&
            items.map((e, index) => {
              return (
                <TableRow>
                  <FirstNameTableCell>{e.firstName}</FirstNameTableCell>
                  <LastNameTableCell>{e.lastName}</LastNameTableCell>
                  <EmailCell>{e.email}</EmailCell>
                  <DetailsTableCell>
                    <DetailsLink
                      to={{
                        pathname: `/${lang}/admin-dashboard/students/${e.studentId}`,
                      }}
                      state={mapAdminStudentsListToProfileComponent(e)}
                    >
                      {t("adminStudents.details")}
                    </DetailsLink>
                  </DetailsTableCell>
                </TableRow>
              );
            })}
          {loading && Array.from({ length: 5 }).map((e) => <TableSkeleton />)}
        </TableBody>
      </Table>
    </Container>
  );
}
export default StudentTable;
