import { ReactNode } from "react";

import styled from "styled-components";

interface ContainerPropTypes {
  $height: string;
}

const Container = styled.div<ContainerPropTypes>`
  padding: 2rem;
  height: ${(props) => props.$height};
  flex-grow: 1;
  background: #ffffff26;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  width: 100%;
  color: ${(props) => props.theme.colors.gray[800]};
`;

function NoEnrollmentIndicator({
  children,
  height,
}: {
  children: ReactNode;
  height: string;
}) {
  return <Container $height={height}>{children}</Container>;
}

export default NoEnrollmentIndicator;
