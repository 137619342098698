import { store } from "@app/redux/store";
import theme from "@styles/theme";
import { ThemeProvider } from "styled-components";
import { Provider as StoreProvider, useSelector } from "react-redux";
import { ReactToast, ReactToastContainer } from "@lib/toast";
import AppRoutes from "./AppRoutes";
import { verifyToken } from "@API/services/Auth/main";
import { Suspense, useEffect } from "react";
import { toast } from "react-toastify";
import { useSearchData } from "./components/View/hooks/useSearchData";
const NETWORK_ERROR = "NETWORK_ERROR";
const NETWORK_SUCCESS = "NETWORK_SUCCESS";
function App() {
  const SendNetworkError = () => {
    ReactToast.error("Not connected to internet", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
      toastId: NETWORK_ERROR,
    });
  };
  const SendNetworkIsBackMessage = () => {
    ReactToast.success("Back online", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
      toastId: NETWORK_SUCCESS,
    });
  };
  useEffect(() => {
    if (!navigator.onLine) {
      SendNetworkError();
    }
    window.addEventListener("online", () => {
      toast.dismiss(NETWORK_ERROR);
      SendNetworkIsBackMessage();
    });
    window.addEventListener("offline", () => {
      toast.dismiss(NETWORK_SUCCESS);
      SendNetworkError();
    });
  });
  return (
    <Suspense fallback="...is loading">
      <ThemeProvider theme={theme}>
        <StoreProvider store={store}>
          <ReactToastContainer />
          <AppRoutes />
        </StoreProvider>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
