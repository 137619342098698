import { useState } from "react";
import {
  FaTableColumns,
  FaGraduationCap,
  FaBasketShopping,
  FaUser,
  FaGear,
} from "react-icons/fa6";
import styled from "styled-components";
import { Link, NavLink, useParams } from "react-router-dom";
import { IconType } from "react-icons";
import { DashBoardNavigationArrayItemType } from "../Types";
import { device } from "@styles/theme";
import { useTranslation } from "react-i18next";
import { FaArrowRightFromBracket } from "react-icons/fa6";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { setUserInfoActionCreator } from "@app/redux/slices/userSlice";
import ConfirmationPopup from "@components/CommonComponents/ConfirmationPopup";

// styled-components-----
const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 1.375rem 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
interface LinkProps {
  $isActive: boolean;
}
const LinkList = styled.ul`
  @media ${device.tablet} {
    display: flex;
    justify-content: space-between;
  }
`;
const DashboardLink = styled.div<LinkProps>`
  padding: 1rem 1.375rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1.125rem;
  text-decoration: none;
  color: ${(props) =>
    props.$isActive ? "#ffffff" : props.theme.colors.gray[800]};
  background: ${(props) =>
    props.$isActive ? props.theme.colors.blue[700] : "#ffffff"};
  @media ${device.laptopM} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media ${device.tablet} {
    padding: 0.6rem 1rem;
  }
`;

const ListItem = styled.li`
  @media ${device.tablet} {
    margin-top: 0 !important;
    display: flex;
  }
`;
const LinkText = styled.span`
  display: inline-block;
  margin-left: 1.5rem;
  font-family: ${(props) => props.theme.font.font};
  @media ${device.laptopM} {
    display: none;
  }
`;
const SignOutButtonContainer = styled.div`
  margin-bottom: 1.375rem;
  @media ${device.tablet} {
    display: none;
  }
`;
const SignOutButton = styled.button`
  padding: 0 22px;
  background: none;
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 25px;
  color: ${(props) => props.theme.colors.gray[600]};
  svg {
    margin-right: 1.625rem;
    height: 1.125rem;
    min-height: 1.125rem;
    width: 1.125rem;
    min-width: 1.125rem;
  }
`;
const SignoutButtonText = styled.span`
  @media ${device.laptopM} {
    display: none;
  }
`;
// ----------------------
function SideBar({
  DashboardNavigationArray,
}: {
  DashboardNavigationArray: DashBoardNavigationArrayItemType[];
}) {
  const { t } = useTranslation();
  const [active, setActive] = useState<number>(0);
  const { lang } = useParams();
  const [showSignoutConfirmation, setShowSignoutConfirmation] = useState(false);
  const dispatch = useDispatch();
  function logout() {
    Cookies.remove("token");
    dispatch(setUserInfoActionCreator(new Date().getTime(), false));
  }

  return (
    <Container>
      {showSignoutConfirmation && (
        <ConfirmationPopup
          handleClose={() => {
            setShowSignoutConfirmation(false);
          }}
          onConfirmation={() => {
            logout();
          }}
          text="Are you sure you want to sign out?"
        ></ConfirmationPopup>
      )}
      <LinkList>
        {DashboardNavigationArray.map((e) => {
          return (
            <ListItem
              key={e.id}
              style={{ marginTop: `${e.id === 0 ? "0" : "1.1rem"}` }}
              onClick={() => {
                setActive(e.id);
              }}
            >
              <NavLink to={`/${lang}${e.link}`}>
                {({ isActive }) => {
                  return (
                    <DashboardLink $isActive={isActive} id={e.id.toString()}>
                      <e.Icon
                        style={{
                          height: "1.125rem",
                          width: "1.125rem",
                          minHeight: "1.125rem",
                          minWidth: "1.125rem",
                        }}
                      />
                      <LinkText>{t(`menu.${e.key}`)}</LinkText>
                    </DashboardLink>
                  );
                }}
              </NavLink>
            </ListItem>
          );
        })}
      </LinkList>
      <SignOutButtonContainer>
        <SignOutButton
          onClick={() => {
            setShowSignoutConfirmation(true);
          }}
        >
          <FaArrowRightFromBracket />
          <SignoutButtonText>Sign out</SignoutButtonText>
        </SignOutButton>
      </SignOutButtonContainer>
    </Container>
  );
}

export default SideBar;
