import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";
import { TeacherCourseType } from "@components/TeachersDashboard/TeacherComponents/TeacherCourseType";
import { APITeachersCourseType } from "@lib/Types/API/Teacher/APITeachersCourseType";
import { mapAPITeachersCourseDataToComponent } from "@lib/mapperFunctions/Teachers/mapAPITeachersCourseDataToComponent";

type TeacherCourseListResponseType =
  | {
      success: true;
      data: TeacherCourseType[];
    }
  | {
      success: false;
      data: string;
    };

export const getTeacherCoursesList =
  async function (): Promise<TeacherCourseListResponseType> {
    const url = urlFunctions.getTeacherCourses();
    const res = await API.sendGetRequest(url, true);
    if (res.success) {
      const data: APITeachersCourseType[] = res.data;
      const mapData: TeacherCourseType[] =
        await mapAPITeachersCourseDataToComponent(data);
      return {
        success: true,
        data: mapData,
      };
    }
    return {
      success: false,
      data: "Can not get course list",
    };
  };
