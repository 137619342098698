import { assetsUrls, colors, device } from "@styles/theme";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  background: #fff;
  padding-top: 71px;
  padding-bottom: 92px;
`;
const InnerContainer = styled.div`
  padding: 0 50px;
  display: flex;
  gap: 8.25rem;
  max-width: 75.3125rem;
  margin: auto;
  @media ${device.laptopL} {
    gap: 100px;
  }
  @media ${device.laptop} {
    gap: 50px;
  }
  @media ${device.tablet} {
    flex-direction: column;
    padding: 0 20px;
  }
`;

const Image = styled.img`
  width: 544px;
  height: 430px;
  @media ${device.laptopL} {
    width: 400px;
    height: 316px;
  }
  @media ${device.tablet} {
    margin: auto;
  }
  @media ${device.mobileL} {
    width: 100%;
    height: auto;
  }
`;

const Heading = styled.div`
  line-height: 1.9375rem;
  font-size: 1.375rem;
  font-weight: 700;
  border-left: 11px solid ${colors.blue[700]};
  padding-left: 20px;
  display: flex;
  align-items: center;
  color: ${colors.gray[800]};
  @media screen {
  }
`;

const Text = styled.p`
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${colors.gray[800]};
  margin-top: 1.8125rem;
`;
const TextContainer = styled.div``;

const ActionButton = styled.a`
  display: inline-block;
  padding: 0.5625rem 4.5rem;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.25rem;
  color: #ffff;
  margin-top: 3.0625rem;
  background-color: ${colors.blue[700]};
  border-radius: 0.625rem;
  @media ${device.laptopS} {
    padding: 0.5625rem 1.25rem;
  }
  @media ${device.tablet} {
    padding: auto;
    width: 100%;
    max-width: 20rem;
  }
`;

function SecondSection() {
  return (
    <Container>
      <InnerContainer>
        <Image src={assetsUrls.loginPagePng}></Image>
        <TextContainer>
          <Heading>Good side income, nice from home!</Heading>
          <Text>
            Working at AKC is not only enjoyable and fun, but it’s also fat pay!
            After all, you can work from home and choose where you want to sit
            while surrounded by enthusiastic students.
            <br />
            <br /> Sharing your knowledge and experience with others can be very
            inspiring not only for others, but also for yourself! So what are
            you waiting for? Join our team at AKC and let’s go for it together!
          </Text>
          <ActionButton href="mailto:info@academischkenniscentrum.nl">
            I want to start right away!
          </ActionButton>
        </TextContainer>
      </InnerContainer>
    </Container>
  );
}

export default SecondSection;
