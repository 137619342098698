import { createUniversity, updateUniversity } from "@API/services/EntitiesCrud";
import { downloadFile } from "@API/services/EntitiesCrud/downloadFile";
import {
  Label,
  SettingsInput,
  SettingsSubmitButton,
} from "@components/AdminDashboard/SubPages/Settings/commonComponents/FormComponents";
import Drop from "@lib/microComponents/Drop";
import ImageEdit from "@lib/microComponents/ImageEdit";
import UploadButton from "@lib/microComponents/UploadButton";
import { ReactToast } from "@lib/toast";
import { cropImage, getDefaultCropValue } from "@lib/util/cropImage";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Crop } from "react-image-crop";
import styled from "styled-components";
import { object, string } from "yup";
import { v4 as uuid } from "uuid";
import LoadingScreenNew from "@components/LoadingScreenNew";

// styled-components ----
const Container = styled.div`
  margin-top: 1.875rem;
  display: flex;
  flex-direction: column;
  padding: 1.875rem;
  padding-top: 0rem;
`;
const Form = styled.form``;
const IconLabel = styled(Label)`
  margin-top: 0.625rem;
`;
const NameInput = styled(SettingsInput)`
  margin-top: 0.625rem;
`;
const UniversitySubmitButton = styled(SettingsSubmitButton)`
  margin-top: 1.875rem;
`;
const UploadButtonContainer = styled.div`
  margin-top: 1.25rem;
`;
const Error = styled.div`
  font-size: 0.9rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.red[500]};
  margin-top: 0.5rem;
`;
const UrlInput = styled.input`
  height: 0;
  width: 0;
  opacity: 1;
`;
const ImageEditContainer = styled.div`
  margin-top: 1.25rem;
`;

// ----------------------

const defaultCropValue: Crop = {
  x: 0,
  y: 0,
  width: 100,
  height: 100,
  unit: "px",
};

const NAME = "university-name";
const ICON = "university-icon";

function EditUniversityForm({
  universityId,
  universityLogoUrl,
  universityName,
  onCreate,
}: {
  universityId: string;
  universityName: string;
  universityLogoUrl: string;
  onCreate: () => void;
}) {
  const [cropValue, setCropValue] = useState<Crop>(defaultCropValue);
  const [loading, setLoading] = useState<boolean>(true);
  const [fileBlob, setFileBlob] = useState<Blob>();
  const [url, setUrl] = useState<string>("");
  const [loadingImage, setLoadingImage] = useState<boolean>();
  const [filename, setFilename] = useState(
    universityLogoUrl
      ? universityLogoUrl.split("/public/")[1]
        ? universityLogoUrl.split("/public/")[1]
        : `${uuid()}.png`
      : `${uuid()}.png`
  );
  // const [filename, setFilename] = useState<string>(`${uuid()}.png`);

  const formik = useFormik({
    initialValues: {
      // [NAME]: universityName,
      // [ICON]: universityLogoUrl,
      [NAME]: universityName,
      [ICON]: undefined,
    },
    validationSchema: object({
      [NAME]: string().required("name field is required").min(5),
      [ICON]: string().notRequired().min(1),
    }),
    onSubmit: async (values, actions) => {
      const res = await updateUniversity(
        universityId,
        values[NAME],
        values[ICON]
      );
      if (res.success) {
        ReactToast("University updated");
        onCreate();
        actions.resetForm();
      } else {
        ReactToast("Couldn't update university");
      }
      actions.setSubmitting(false);
    },
  });
  async function onFileBlobChange() {
    if (fileBlob) {
      setUrl(URL.createObjectURL(fileBlob));
      const crop = await getDefaultCropValue(fileBlob);
      formik.setFieldValue(ICON, undefined);
      setCropValue(crop);
    }
  }
  async function downloadFileAndSetBlob() {
    setLoadingImage(true);
    setLoading(true);
    if (!universityLogoUrl) {
      setLoading(false);
      return;
    }
    fetch(universityLogoUrl, {})
      .then((data) => {
        data
          .blob()
          .then((blob) => {
            setFileBlob(blob);
            setLoading(false);
            setLoadingImage(false);
          })
          .catch((error) => {
            setLoading(false);
            setLoadingImage(false);
            ReactToast.error(
              "There was an error in processing university image"
            );
          });
      })
      .catch(() => {
        setLoading(false);
        setLoadingImage(false);
        ReactToast.error("There was an error in getting university image");
      });
  }
  useEffect(() => {
    onFileBlobChange();
  }, [fileBlob]);
  useEffect(() => {
    // downloadFileAndSetBlob();
    // formik.setFieldValue(NAME, universityName);
  }, [universityLogoUrl]);
  return (
    <Container>
      {loadingImage ? (
        <LoadingScreenNew />
      ) : (
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          <Label>University name</Label>
          {formik.errors[NAME] && formik.touched[NAME] && (
            <Error>{formik.errors[NAME]}</Error>
          )}
          <NameInput placeholder="University" {...formik.getFieldProps(NAME)} />
          <IconLabel>University icon</IconLabel>
          {formik.errors[ICON] && formik.touched[ICON] && (
            <Error>{formik.errors[ICON]}</Error>
          )}
          <Drop
            fileSizeInMB={1}
            onFileLoad={(blob) => {
              setFileBlob(blob);
            }}
            clearFile={() => {
              setFileBlob(undefined);
              setUrl("");
              formik.setFieldValue(ICON, undefined);
            }}
            fileType="image"
          />
          {url && !formik.getFieldProps(ICON).value && (
            <ImageEditContainer>
              <ImageEdit
                src={url}
                value={cropValue}
                onChange={(crop) => {
                  setCropValue(crop);
                }}
              />
            </ImageEditContainer>
          )}
          <UploadButtonContainer>
            <UploadButton
              uploaded={!!formik.getFieldProps(ICON).value}
              disabled={!fileBlob}
              onSuccess={(url) => {
                formik.setFieldValue(ICON, url);
              }}
              fileFunction={async () => {
                if (fileBlob) {
                  const newFile = await cropImage(fileBlob, cropValue);
                  return newFile.image;
                } else {
                  return undefined;
                }
              }}
              filename={filename}
              isPublic={true}
            />
          </UploadButtonContainer>
          <UniversitySubmitButton loading={formik.isSubmitting}>
            Edit University
          </UniversitySubmitButton>
        </Form>
      )}
    </Container>
  );
}

export default EditUniversityForm;
