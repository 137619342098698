import { getCourseSearchList } from "@API/services/Search/getCourseSearchList";
import { searchSlice } from "@app/redux/slices/searchSlice";
import CallToSignIn from "@API/CallToSignIn";
import NonEnrolledCourse from "@components/CommonComponents/CourseComponent/NonEnrolledCourse";
import {
  selectStudy,
  selectSubject,
  selectUniversity,
} from "@components/LandingPage/searchSelectors";
import {
  CourseSearch,
  CourseSearchList,
} from "@lib/Types/API/APICourseSearchList";
import { useStudyOption } from "@lib/hooks/useStudyOptions";
import { useUniversityOptions } from "@lib/hooks/useUniversityOptions";
import { mapCourseToUnenrolled } from "@lib/mapperFunctions/mapCourseToUnenrolled";
import Select, { optionType } from "@lib/microComponents/Select";
import { ReactToast } from "@lib/toast";
import { device } from "@styles/theme";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Skeleton from "@components/CommonComponents/CourseComponent/Skeleton";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSubjectOptions } from "@lib/hooks/useSubjectOptions";
import EmptySearch from "@components/CommonComponents/EmptyContanetComponents/EmptySearch";
import View from "../main";
import { useSearchData } from "../hooks/useSearchData";
// styled-components----
const Container = styled.div`
  padding: 3.125rem;
  max-height: 100%;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  @media ${device.tablet} {
    padding: 1.25rem;
  }
`;
const CoursesContainer = styled.div`
  row-gap: 1.25rem;
  margin-top: 3.125rem;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  column-gap: 1.25rem;
  @media ${device.laptopL} {
    margin-top: 1.875rem;
  }
  @media ${device.laptop} {
    margin-top: 1.25rem;
  }
`;

const EmptyCourseContainer = styled.div`
  flex-grow: 1;
`;

const ControlsContainer = styled.div`
  margin-top: 21px;
  width: 100%;
  display: flex;
  align-items: center;
  @media ${device.laptopL} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SelectorAndSortContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  @media ${device.laptopL} {
    margin-top: 1.25rem;
    display: none;
  }
  @media ${device.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const SelectContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(9rem, 23rem));
  column-gap: 1.25rem;
  margin-left: 1.875rem;
  @media ${device.laptopL} {
    margin-left: 0;
  }
`;
const Heading = styled.div`
  font-size: 1.375rem;
  line-height: 1.9375rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const SelectorStyled = styled(Select)``;
const SortSelectorContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(9rem, 23rem));
  margin-left: 2.5rem;
  @media ${device.tablet} {
    margin-left: 0;
    margin-top: 1.25rem;
    width: 9rem;
  }
`;
// ---------------------

function SearchView() {
  const dispatch = useDispatch();
  const universityOptions = useUniversityOptions();
  const [studyOptions, setStudyOptions] = useState<optionType[]>([]);
  const [subjectOptions, setSubjectOptions] = useState<optionType[]>([]);
  const university = useSelector(selectUniversity);
  const major = useSelector(selectStudy);
  const subject = useSelector(selectSubject);
  const [sort, setSort] = useState<optionType | null>();
  // const [searchResults, setSearchResults] = useState<CourseSearch[]>([]);
  // const [loading, setLoading] = useState<boolean>(true);
  const [data, err, isLoading] = useSearchData({
    universityId: university ? university.id : undefined,
    studyId: major ? major.id : undefined,
    groupId: subject ? subject.id : undefined,
  });
  // async function getSearchResult() {
  //   setLoading(true);
  //   const universityId = university ? university.id : undefined;
  //   const study = major ? major.id : undefined;
  //   const grade = subject ? subject.id : undefined;
  //   const data = await getCourseSearchList(universityId, study, grade);

  //   if (data.success) {
  //     setSearchResults(data.data);
  //   } else {
  //     ReactToast("Something went wrong");
  //   }
  //   setLoading(false);
  // }
  async function getAndSetStudyOptions(universityId: string) {
    const options = await useStudyOption(universityId);
    setStudyOptions(options);
  }
  async function getAndSetSubjectOptions(studyId: string) {
    const options = await useSubjectOptions(studyId);
    setSubjectOptions(options);
  }
  useEffect(() => {
    if (university) {
      getAndSetStudyOptions(university.id);
    }
  }, [university]);
  useEffect(() => {
    if (major) {
      getAndSetSubjectOptions(major.id);
    }
  }, [major]);
  // useEffect(() => {
  //   setSearchResults([]);
  //   getSearchResult();
  // }, [university, major, subject]);
  const { t } = useTranslation();
  return (
    <View isCheckout={false}>
      <Container>
        <CallToSignIn />
        <ControlsContainer>
          <Heading>{t("search.heading")}</Heading>
          <SelectorAndSortContainer>
            <SelectContainer>
              <SelectorStyled
                options={universityOptions}
                onChange={(e) => {
                  dispatch(searchSlice.actions.setUniversity(e));
                  dispatch(searchSlice.actions.setStudy(undefined));
                  dispatch(searchSlice.actions.setSubject(undefined));
                }}
                text={t("search.university")}
                value={university}
              />
              <SelectorStyled
                options={studyOptions}
                disabled={!university}
                onChange={(e) => {
                  dispatch(searchSlice.actions.setStudy(e));
                  dispatch(searchSlice.actions.setSubject(undefined));
                }}
                text={t("search.major")}
                value={major}
              />
              <SelectorStyled
                options={subjectOptions}
                disabled={!major}
                onChange={(e) => {
                  dispatch(searchSlice.actions.setSubject(e));
                }}
                text={t("search.subject")}
                value={subject}
              />
            </SelectContainer>
            <SortSelectorContainer>
              <SelectorStyled
                options={[
                  {
                    id: "0",
                    value: t("search.sort.label1"),
                  },
                  {
                    id: "1",
                    value: t("search.sort.label2"),
                  },
                ]}
                onChange={() => {}}
                value={sort}
                text={t("search.sort.heading")}
                removeHyphen
                sort
              ></SelectorStyled>
            </SortSelectorContainer>
          </SelectorAndSortContainer>
        </ControlsContainer>

        {isLoading ? (
          <CoursesContainer>
            {Array.from({ length: 10 }).map(() => {
              return <Skeleton />;
            })}
          </CoursesContainer>
        ) : data && data.length ? (
          <CoursesContainer>
            {data.map((item) => (
              <NonEnrolledCourse
                key={item.courseId}
                item={mapCourseToUnenrolled(item)}
              />
            ))}
          </CoursesContainer>
        ) : (
          <EmptyCourseContainer>
            <EmptySearch />
          </EmptyCourseContainer>
        )}
      </Container>
    </View>
  );
}

export default SearchView;
