import { Crop } from "react-image-crop";

function pcntToLength(percentage: number, length: number) {
  return (length * percentage) / 100;
}

type returnType =
  | {
      success: true;
      image: Blob;
      message: undefined;
    }
  | {
      success: false;
      image: undefined;
      message: string;
    };
function createReturnObject(
  success: boolean,
  message?: string,
  data?: Blob
): returnType {
  if (success) {
    if (!data) {
      throw new Error("data is required if success is true");
    } else {
      return {
        success,
        image: data,
        message: undefined,
      };
    }
  } else {
    if (!message) {
      throw new Error("message is required if success is false");
    } else {
      return {
        success,
        image: undefined,
        message,
      };
    }
  }
}
export function cropImage(imageBlob: Blob, crop: Crop): Promise<returnType> {
  return new Promise((resolve, reject) => {
    const imageElement = new Image();
    imageElement.src = URL.createObjectURL(imageBlob);
    imageElement.onerror = () => {
      reject(createReturnObject(false, "There was an error in cropping image"));
    };
    imageElement.onabort = () => {
      reject(createReturnObject(false, "The cropping action was aborted"));
    };
    imageElement.onload = () => {
      const width = crop.width;
      const height = crop.height;

      const canvas = document.createElement("canvas");
      const imageHeight = imageElement.height;
      const imageWidth = imageElement.width;
      canvas.width = pcntToLength(width, imageWidth);
      canvas.height = pcntToLength(height, imageHeight);
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        reject(createReturnObject(false, "Could not create context"));
        return;
      }
      ctx.drawImage(
        imageElement,
        pcntToLength(crop.x, imageWidth),
        pcntToLength(crop.y, imageHeight),
        pcntToLength(width, imageWidth),
        pcntToLength(height, imageHeight),
        0,
        0,
        pcntToLength(width, imageWidth),
        pcntToLength(height, imageHeight)
      );
      canvas.toBlob((imageBlob) => {
        if (!imageBlob) {
          reject(createReturnObject(false, "Error in creating blob object"));
          return;
        }
        resolve(createReturnObject(true, undefined, imageBlob));
      });
    };
  });
}

export function getDefaultCropValue(image: Blob): Promise<Crop> {
  return new Promise((resolve, reject) => {
    const tempImage = new Image();
    tempImage.src = URL.createObjectURL(image);
    tempImage.onload = () => {
      const width = tempImage.width;
      const height = tempImage.height;
      if (height === width) {
        resolve({
          x: 0,
          y: 0,
          width: 100,
          height: 100,
          unit: "%",
        });
      }
      if (height > width) {
        resolve({
          x: 0,
          y: 0,
          width: 100,
          height: (width * 100) / height,
          unit: "%",
        });
      }
      if (height < width) {
        resolve({
          x: 0,
          y: 0,
          width: (height * 100) / width,
          height: 100,
          unit: "%",
        });
      }
    };
  });
}
