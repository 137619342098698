import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";
import { StudentData } from "@components/CommonComponents/CourseApplications/StudentsTable";
import { APIStudentsData } from "@lib/Types/API/APIStudentsData";

import { mapAPITeacherStudentsToComponent } from "@lib/mapperFunctions/Teachers/mapAPITeacherStudentsToComponent";
type TeacherCourseApplicationResponseType =
  | {
      success: true;
      data: StudentData[];
    }
  | {
      success: false;
      data: string;
    };
export const getTeacherCourseApplications = async function (
  id: string
): Promise<TeacherCourseApplicationResponseType> {
  const url = urlFunctions.getTeacherCourseApplications(id);
  const res = await API.sendGetRequest(url, true);
  if (res.success) {
    const data: APIStudentsData[] = res.data;
    const mappedData = mapAPITeacherStudentsToComponent(data);
    return {
      success: true,
      data: mappedData,
    };
  } else {
    return {
      success: false,
      data: "Could not get course details",
    };
  }
};
