import { TeacherCourseType } from "@components/TeachersDashboard/TeacherComponents/TeacherCourseType";
import { APITeachersCourseType } from "@lib/Types/API/Teacher/APITeachersCourseType";
import SampleUniversityImage from "@images/sample-university-2.png";
import { Language } from "@lib/Enums/Language";
export const mapAPITeachersCourseDataToComponent = (
  data: APITeachersCourseType[]
): TeacherCourseType[] => {
  const mappedData: TeacherCourseType[] = data.map((item) => {
    return {
      id: item["course-id"],
      applications: item.applications,
      // image: SampleUniversityImage,
      // university: item.university,
      university: {
        id: item.university.id,
        universityName: item.university.universityName,
        universityLogoUrl: item.university.universityLogoUrl,
      },
      study: item.study,
      subject: item.subject,
      group: item.group,
      status: item.status === "ACTIVE",
      language: item.language,
      type: item["course-type"],
      major: item["study"],
      lessions: item.schedule.map((lession) => ({
        date: lession.date,
        endTime: lession["end-time"],
        id: lession.id,
        linkToJoin: lession["link-to-join"],
        title: lession.name,
        startTime: lession["start-time"],
      })),
    };
  });
  return mappedData;
};
