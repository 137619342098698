import React from "react";
import styled from "styled-components";
import Header from "@components/CommonDashboard/Header";
import theme, { assetsUrls, colors, device, mixin } from "@styles/theme";
import { useTranslation } from "react-i18next";
import GreetingComponent from "@components/CommonComponents/GreetingComponent";
import CallToSignIn from "@API/CallToSignIn";

const Container = styled.div`
  height: 100dvh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background: #fff;
`;

const ContentContainer = styled.div`
  width: 100%;
  min-height: 0;
  flex-grow: 1;
  overflow-y: scroll;
  position: relative;
  padding: 50px;
  padding-top: 89px;
  ${mixin.hideScroll()}
  @media ${device.tablet} {
    padding: 20px;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 764px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% calc(100% - 10rem));
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(230, 235, 240, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );
  width: 100%;
  z-index: 1;
  @media ${device.tablet} {
    height: 400px;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% calc(100% - 5rem));
  }
`;

const InnerContainer = styled.div`
  max-width: 120rem;
  margin: auto;
  display: flex;
  gap: 153px;
  position: relative;
  z-index: 2;
  @media ${device.desktopXS} {
    gap: 120px;
  }
  @media ${device.laptopL} {
    gap: 90px;
  }
  @media ${device.laptop} {
    flex-direction: column-reverse;
  }
`;

const LeftContainer = styled.div``;

const RightImage = styled.img`
  width: 632px;
  height: 553px;
  @media ${device.desktopXS} {
    width: 500px;
    height: 437px;
  }
  @media ${device.laptopL} {
    width: 400px;
    height: 350px;
  }
  @media ${device.laptopL} {
    width: 400px;
    height: 350px;
  }
  @media ${device.laptop} {
    width: 400px;
    height: 350px;
  }
  @media ${device.tablet} {
    max-width: 300px;
    width: 100%;
    height: auto;
  }
`;

const RightContainer = styled.div`
  @media ${device.laptop} {
    display: flex;
    justify-content: center;
  }
`;
const Heading = styled.div`
  color: ${colors.blue[700]};
  font-size: 1.375rem;
  line-height: 1.9375rem;
`;

const SubHeading = styled.div`
  margin-top: 0.625rem;
  font-size: 2.375rem;
  line-height: 3.3125rem;
  font-weight: 700;
  color: ${colors.blue[700]};
`;

const TextContainer = styled.div`
  margin-top: 30px;
`;

const SimpleText = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.gray[800]};
`;
const HeadingText = styled(SimpleText)`
  font-weight: 700;
`;
const CallToSignInContainer = styled.div`
  margin-top: 4.8125rem;
`;

function About() {
  const { t } = useTranslation();
  return (
    <Container>
      <Header />
      <ContentContainer>
        <Background />
        <InnerContainer>
          <LeftContainer>
            <Heading>AKC (Academisch Kenniscentrum)</Heading>
            <SubHeading>About us</SubHeading>
            <TextContainer>
              <SimpleText>
                At AKC, we are constantly looking for new, enthusiastic teachers
                to join our team. As a tutor at AKC, you will provide training
                and mentor our students remotely, conveniently from home. The
                salary varies between 18-25 euros per hour, depending on your
                knowledge and experience.
                <br />
                <br /> Are you a passionate and motivated (former) student who
                enjoys sharing his or her knowledge and experience with others?
                Do you have experience in providing training and coaching? And
                do you have a flexible attitude? Then we are looking for you!
                <br />
                <br /> As a teacher at AKC, you will have the opportunity to
                impart your skills and knowledge to the new generation. You will
                be able to inspire and motivate students to achieve their goals.
              </SimpleText>
              <br />
              <HeadingText>
                What are we looking for in you as a teacher?
              </HeadingText>
              <br />
              <SimpleText>
                - Have completed the course with an average of at least an 8;
              </SimpleText>{" "}
              <SimpleText>
                - Motivated to have helped the students as much as possible;
              </SimpleText>
              <SimpleText>
                - Motivated to participate in the process of a training /
                knowledge guide;
              </SimpleText>{" "}
              <SimpleText>
                - Good command of the Dutch and/or English language;
              </SimpleText>{" "}
              <SimpleText>- Good communication skills.</SimpleText>
              <br />
              <HeadingText>
                What are we looking for in you as a teacher?
              </HeadingText>
              <br />
              <SimpleText>
                At AKC, we are constantly looking for new, enthusiastic teachers
                to join our team. As a tutor at AKC, you will provide training
                and mentor our students remotely, conveniently from home. The
                salary varies between 18-25 euros per hour, depending on your
                knowledge and experience.
                <br />
                <br /> Are you a passionate and motivated (former) student who
                enjoys sharing his or her knowledge and experience with others?
                Do you have experience in providing training and coaching? And
                do you have a flexible attitude? Then we are looking for you!
                <br />
              </SimpleText>
            </TextContainer>
          </LeftContainer>
          <RightContainer>
            <RightImage src={assetsUrls.about}></RightImage>
          </RightContainer>
        </InnerContainer>
        <CallToSignInContainer>
          <CallToSignIn />
        </CallToSignInContainer>
      </ContentContainer>
    </Container>
  );
}

export default About;
