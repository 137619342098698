import styled from "styled-components";
import { v4 as uuid } from "uuid";
import { Formik } from "../../main";
import FormTextInput from "../FormTextInput";
import FormHeadings from "../FormHeadings";
import Select, { optionType } from "../FormSelect";
import FormNumberInput from "../FormNumberInput";
import {
  EVENT,
  GRADE_YEAR,
  MANAGER,
  STUDY,
  SUBJECT,
  UNIVERSITY,
  minimumNumberOfGroups,
  minimumNumberOfSpots,
} from "../../validationSchema";
import { useUniversityOptions } from "@lib/hooks/useUniversityOptions";
import { useStudyOption } from "@lib/hooks/useStudyOptions";
import { useGroupOptions } from "@lib/hooks/useGroupOptions";
import FormSelect from "../FormSelect";
import { useSubjectOptions } from "@lib/hooks/useSubjectOptions";
import { useEventTypeOptions } from "@lib/hooks/useEvnetTypeOptions";
import { useGradeYearOptions } from "@lib/hooks/useGradeYearOptions";
import { useEffect, useState } from "react";
import NewFormSelect from "../NewFormSelect";
import { createSubject } from "@API/services/EntitiesCrud";
import { ReactToast } from "@lib/toast";
import { Language, getLanguageInfo } from "@lib/Enums/Language";
import TempFormSelect from "../_temp_FormSelect";
import { LanguageOptionType } from "../../formInitialValues";
import { useAdminUniversityOptions } from "@lib/hooks/useAdminUniversityOptions";
import { useAdminAndManagerOptions } from "@lib/hooks/useAdminAndManagerOptions";
import ManagerSelector, {
  AddCourseManagerListOptionType,
} from "./ManagerSelector";
import { LoadingSpinner } from "@lib/microComponents/LoadingSpinner";
import { useSelector } from "react-redux";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { UserRole } from "@app/redux/slices/userSlice";
// styled-components -----
const Container = styled.div``;
const FormHeadingStyled = styled(FormHeadings)`
  flex-grow: 1;
`;
const FormHeadingContainer = styled.div`
  display: flex;
  width: 100%;
`;
const ManagerSelectorContainer = styled.div`
  margin-left: 1.9375rem;
`;
const FormInputsContainer = styled.div`
  display: grid;
  min-width: 0;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-column-gap: 1.875rem;
  grid-row-gap: 2.5rem;
  margin-top: 1.1875rem;
`;
// -----------------------

const languageOptions: LanguageOptionType[] = [
  {
    id: Language.ENGLISH_US,
    value: getLanguageInfo(Language.ENGLISH_US).name,
  },
  {
    id: Language.DUTCH,
    value: getLanguageInfo(Language.DUTCH).name,
  },
];
function findManagerObject(
  options: AddCourseManagerListOptionType[],
  userId: string | undefined
): AddCourseManagerListOptionType | undefined {
  let option: AddCourseManagerListOptionType | undefined = undefined;
  options.forEach((item) => {
    if (item.id === userId) {
      option = item;
    }
  });
  return option;
}
function GeneralDetails({ formik }: { formik: Formik }) {
  const userInfo = useSelector(selectUser);
  const [loadingUniversity, universityOptions] = useAdminUniversityOptions();
  const [studyOptions, setStudyOptions] = useState<optionType[]>([]);
  const groupOptions = useGroupOptions();
  const eventTypeOptions = useEventTypeOptions();
  const [subjectOptions, setSubjectOptions] = useState<optionType[]>([]);
  const gradeOptions = useGradeYearOptions();
  const [loadingManagerOptions, managerOptions] = useAdminAndManagerOptions();
  async function getStudyOptionsAsync(universityId: string) {
    formik.setFieldTouched("study", false);
    const studyOptions = await useStudyOption(universityId);
    setStudyOptions(studyOptions);
  }
  async function getSubjectOptions(studyId: string) {
    formik.setFieldTouched("subject", false);
    const subjectOptions = await useSubjectOptions(studyId);
    setSubjectOptions(subjectOptions);
  }
  // next line temporary
  useEffect(() => {
    if (formik.values.university) {
      getStudyOptionsAsync(formik.values.university.id);
    }
  }, []);
  useEffect(() => {
    if (formik.values.study) {
      getSubjectOptions(formik.values.study.id);
    }
  }, []);
  return (
    <Container>
      <FormHeadingContainer>
        <FormHeadingStyled>General details</FormHeadingStyled>

        <ManagerSelectorContainer>
          <ManagerSelector
            text="Select Manager"
            options={managerOptions}
            formik={formik}
            value={formik.values.manager}
            onChange={() => {}}
            name={MANAGER}
            id="Manager"
            disabled={userInfo.role !== UserRole.ADMIN}
          />
        </ManagerSelectorContainer>
      </FormHeadingContainer>
      <FormInputsContainer>
        <FormTextInput
          formik={formik}
          id="name"
          name="name"
          label="Name"
          placeholder="Type here.."
        ></FormTextInput>
        <TempFormSelect
          id="university"
          label="University"
          name="university"
          value={formik.values.university}
          onChange={(university) => {
            formik.setFieldValue(STUDY, undefined);
            formik.setFieldValue(SUBJECT, undefined);
            if (university) {
              getStudyOptionsAsync(university.id);
            }
          }}
          formik={formik}
          text="Select a university"
          options={universityOptions}
        />
        <TempFormSelect
          id="study"
          label="Study"
          name="study"
          value={formik.values.study}
          onChange={(study) => {
            formik.setFieldValue("subject", undefined);
            if (study) {
              getSubjectOptions(study.id);
            }
          }}
          disabled={!formik.values.university}
          formik={formik}
          text="Select a study"
          options={studyOptions}
        />
        <NewFormSelect
          formik={formik}
          id="subject"
          value={formik.values.subject}
          disabled={!formik.values.study}
          createEntity={async (input) => {
            if (formik.values.study) {
              const res = await createSubject(input, formik.values.study.id);
              if (res.success) {
                ReactToast.success("Subject created successfully");
                const newOption = {
                  id: res.data.id,
                  value: res.data.name,
                };
                subjectOptions.push(newOption);
                return newOption;
              } else {
                ReactToast.error("Subject creation failed");
                return undefined;
              }
            }
          }}
          name="subject"
          label="Subject"
          text="Select A Subject"
          options={subjectOptions}
        ></NewFormSelect>
        <TempFormSelect
          onChange={() => {}}
          value={formik.values.event}
          id="event"
          label="Type of Event"
          name={EVENT}
          formik={formik}
          text="Select a type"
          options={eventTypeOptions}
        />
        {/* <FormTextInput
          formik={formik}
          id="subject"
          name="subject"
          label="Subject"
          placeholder="Type here.."
        ></FormTextInput> */}

        <FormNumberInput
          formik={formik}
          id="spots"
          name="spots"
          label="Available Spots"
          placeholder=""
          min={minimumNumberOfSpots}
        ></FormNumberInput>
        <FormNumberInput
          formik={formik}
          id="groups"
          name="groups"
          label="groups"
          placeholder="groups"
          min={minimumNumberOfGroups}
        ></FormNumberInput>

        <TempFormSelect
          id={GRADE_YEAR}
          label="Grade Year"
          onChange={() => {}}
          value={formik.values[GRADE_YEAR]}
          name={GRADE_YEAR}
          formik={formik}
          text="Select a grade year"
          options={gradeOptions}
        />
        <TempFormSelect
          id="language"
          label="Language"
          name="language"
          value={formik.values.language}
          onChange={() => {}}
          formik={formik}
          text="Select a language"
          options={languageOptions}
        />
      </FormInputsContainer>
    </Container>
  );
}

export default GeneralDetails;
