import styled from "styled-components";
import { colors, device, mixin } from "@styles/theme";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import React from "react";

const Container = styled.div`
  height: 37.0625rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 0 1.875rem;
  padding-top: 2.5rem;
  padding-bottom: 3.625rem;
`;

const FaqHeading = styled.div`
  font-size: 1.375rem;
  line-height: 1.9375rem;
  font-weight: 700;
  border-left: 11px solid ${(props) => props.theme.colors.blue[700]};
  padding: 0.25rem 0 0.25rem 1.25rem;
  color: ${(props) => props.theme.colors.gray[800]};
  @media ${device.tablet} {
    line-height: 1.5625rem;
    font-size: 1.125rem;
    border-width: 0.5rem;
  }
`;

const QaMainContainer = styled.div`
  overflow: hidden;
  overflow-y: auto;
  cursor: pointer;
  &::-webkit-scrollbar {
    width: 0.9375rem; /* For Chrome, Safari, and newer Edge */
  }
  //--
  // scrollbar-width: thin; /* For Firefox */
  // scrollbar-color: transparent transparent; /* For Firefox */

  // &::-webkit-scrollbar-thumb {
  //   background-color: transparent;
  // }
`;

const Divider = styled.div`
  height: 0.125rem;
  width: 100%;
  background: ${(props) => props.theme.colors.gray[600]};
  opacity: 0.35;
  margin: 2rem 0;
`;

type QuestionAndAnswer = {
  q: string;
  a: string;
};

const QCard = styled.div`
  text-align: left;
  font-size: 1rem;
  line-height: 1.4375rem;
  font-weight: 700;
  letter-spacing: 0rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;

const ACard = styled.div`
  text-align: left;
  font-size: 1rem;
  line-height: 1.5625rem;
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.gray[800]};
  margin-top: 0.625rem;
  a {
    color: ${colors.blue[700]};
  }
`;

function QaComponent() {
  const [selectedQuestion, setSelectedQuestion] = useState<number | null>(0);
  const { t } = useTranslation();
  const handleToggleAnswer = (index: number | null) => {
    setSelectedQuestion((prev) => (prev === index ? null : index));
  };

  return (
    <Container>
      <FaqHeading>FAQ</FaqHeading>
      <Divider />
      <QaMainContainer>
        {Array.from({ length: 9 }, (_, index) => (
          <React.Fragment key={index}>
            <QCard onClick={() => handleToggleAnswer(index)}>
              {t(`course.qa.${String.fromCharCode(97 + index)}.question`)}
            </QCard>
            {selectedQuestion === index && (
              <ACard
                dangerouslySetInnerHTML={{
                  __html: t(
                    `course.qa.${String.fromCharCode(97 + index)}.answer`
                  ),
                }}
              ></ACard>
            )}
            <Divider />
          </React.Fragment>
        ))}
      </QaMainContainer>
    </Container>
  );
}

export default QaComponent;
