import { Dispatch, SetStateAction, useState } from "react";
import { FaRegFaceSmile } from "react-icons/fa6";
import styled, { css } from "styled-components";
import { Message } from "./ChatsComponent";
import EmojiSelector from "@lib/microComponents/EmojiSelector";
import { reactOnMessage } from "@API/services/Chat/reactOnMessage";

// styled-components ----

interface ReactionStyledProps {
  $position: boolean;
}

const ReactionStyled = styled.div<ReactionStyledProps>`
  display: flex;
  flex-direction: ${(props) => (props.$position ? "row-reverse" : "row")};
  align-items: flex-start;
`;

const ReactionButtonContainer = styled.div`
  height: 2.125rem;
  display: flex;
  align-items: center;
`;

const ReactionButton = styled.button`
  color: ${(props) => props.theme.colors.gray[600]};
  background: none;
`;

const ReactionButtonIcon = styled(FaRegFaceSmile)`
  height: 1.125rem;
  width: 1.125rem;
  min-height: 1.125rem;
  min-width: 1.125rem;
`;
const ReactionsEmojisContainer = styled.div`
  display: flex;
  gap: 0.3125rem;
  flex-wrap: wrap;
`;
const EmojiContainer = styled.div`
  height: 2.125rem;
  border-radius: 1.0625rem;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 0.4375rem 0.625rem;
  justify-content: space-between;
`;
const EmojiIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.2rem;
`;

const EmojiCount = styled.div`
  display: flex;
  align-items: center;
`;

interface EmojiSelectorContainerProps {
  $position: boolean;
}

const EmojiSelectorContainer = styled.div<EmojiSelectorContainerProps>`
  position: absolute;
  top: 0;
  z-index: 4000;
  ${(props) =>
    !props.$position
      ? css`
          left: 4rem;
        `
      : css`
          right: 2rem;
        `}
`;
const ReactionEmojiIcon = styled.div``;
// ----------------------

export default function Reaction({
  id,
  setEmojiNumber,
  emojiActive,
  message,
  position,
}: {
  id: string;
  setEmojiNumber: Dispatch<SetStateAction<string | null>>;
  emojiActive: string | null;
  message: Message;
  position: "LEFT" | "RIGHT";
}) {
  const [showSelector, setShowSelector] = useState<boolean>(false);
  function convertUnicodeNumberToEmoji(unicode: string) {
    try {
      const unicodeString = unicode;
      const unicodeNumber = parseInt(unicodeString, 16);
      const emoji = String.fromCodePoint(unicodeNumber);
      return emoji;
    } catch (e) {
      return "";
    }
  }

  return (
    <ReactionStyled $position={position === "LEFT"}>
      <ReactionButtonContainer>
        <ReactionButton
          onClick={(e) => {
            e.stopPropagation();
            if (emojiActive === id) {
              setEmojiNumber(null);
            } else {
              setEmojiNumber(id);
            }
          }}
        >
          <ReactionButtonIcon />
        </ReactionButton>
      </ReactionButtonContainer>
      {emojiActive && emojiActive === id && (
        <EmojiSelectorContainer $position={position === "LEFT"}>
          <EmojiSelector
            onChange={(e) => {
              reactOnMessage(id, e.unified);
            }}
            handleClose={() => {
              setEmojiNumber(null);
            }}
          />
        </EmojiSelectorContainer>
      )}
      <ReactionsEmojisContainer>
        {message.reactions.map((reaction) => {
          return (
            <EmojiContainer key={`${message.id}.${reaction.unicode}`}>
              <EmojiIcon>
                {convertUnicodeNumberToEmoji(reaction.unicode)}
              </EmojiIcon>
              {/* <EmojiIcon>{emoji}</EmojiIcon> */}
              <EmojiCount>{reaction.count}</EmojiCount>
            </EmojiContainer>
          );
        })}
      </ReactionsEmojisContainer>
    </ReactionStyled>
  );
}
