import styled from "styled-components";

// styled.components -----
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Heading = styled.div`
  margin-top: 2.25rem;
  font-size: 1.375rem;
  line-height: 1.9375rem;
  color: ${(props) => props.theme.colors.gray[800]};
  font-weight: 700;
`;

const SubHeading = styled.div`
  margin-top: 0.625rem;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;

const Image = styled.img`
  width: 17rem;
  height: 10.75rem;
`;
// -----------------------
function EmptySearch() {
  return (
    <Container>
      <InnerContainer>
        <Image src="/assets/empty-search.svg" />
        <Heading>No courses found!</Heading>
        <SubHeading>Please change your filters or get back later..</SubHeading>
      </InnerContainer>
    </Container>
  );
}

export default EmptySearch;
