import React, { useCallback, useEffect, useState } from "react";
import { FaAngleLeft } from "react-icons/fa6";
import _ from "lodash";
import {
  Link,
  useNavigate,
  useParams,
  unstable_usePrompt,
} from "react-router-dom";
import styled from "styled-components";
import Heading from "@lib/microComponents/Heading";
import FormHeadings from "./Components/FormHeadings";
import {
  Formik,
  FormikConfig,
  FormikProps,
  useFormik,
  useFormikContext,
} from "formik";
import validationSchema, {
  NAME,
  STUDY,
  SUBJECT,
  UNIVERSITY,
  minimumOriginalPrice,
  minimumSalePrice,
  saveFormSchema,
} from "./validationSchema";
import FormInputWithEuroIcon from "./Components/FormInputWithEuroIcon";
import Schedule from "./Components/Schedule";
import KnowledgeGuide from "./Components/KnowledgeGuide";
import Questionnaire from "./Components/Questions";
import { formikConfig } from "./formInitialValues";
import Features from "./Components/Features";
import Highlights from "./Components/Highlights";
import Teacher from "./Components/Teacher";
import GeneralDetails from "./Components/GeneralDetails";
import { useFormikInstance } from "./useFormikInstance";
import { CourseStatus } from "@lib/Enums/CourseStatus";
import { LoadingButton } from "@lib/microComponents/LoadingSpinner";
import { ReactToast } from "@lib/toast";
import { createCourse } from "@API/services/CourseCRUD/createCourse";
import { editCourse } from "@API/services/CourseCRUD/editCourse";
import LoadingScreenNew from "@components/LoadingScreenNew";
//----styled-components--------
const Container = styled.div`
  padding-bottom: 6.25rem;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const FormHeadingsWithMargin = styled(FormHeadings)`
  margin-top: 3.125rem;
`;
const HeadingAndBackButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;
const BackLink = styled.button`
  height: 2.6875rem;
  width: 2.6875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors.gray[600]};
  border-radius: 10px;
  color: #ffffff;
`;
const PageHeading = styled(Heading)`
  margin-left: 1.875rem;
`;

interface FormButton {
  $loading: boolean;
}
const FormButton = styled.button`
  height: 2.875rem;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  padding: 0.6563rem 2.25rem;
  color: #ffffff;
  border-radius: 10px;
  font-weight: 700;
`;
const SavedState = styled.div`
  font-size: 1.125rem;
  line-height: 1.5625rem;
  color: ${(props) => props.theme.colors.gray[600]};
  margin-right: 3.125rem;
`;
const FormButtonsAndStateContainer = styled.div`
  display: flex;
  align-items: center;
`;
const FormButtonPublishAndSaveContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: flex-end;
`;

interface SaveButtonProps {
  $loading: boolean;
}
const SaveButtonContainer = styled.div`
  width: 12.0625rem;
  height: 2.875rem;
  position: relative;
`;
const SaveButton = styled.button<SaveButtonProps>`
  width: 12.0625rem;
  height: 2.875rem;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  color: #ffffff;
  border-radius: 10px;
  font-weight: 700;
  background: ${(props) => props.theme.colors.gray[600]};
`;

interface PublishButtonProps {
  $disabled: boolean;
  $loading: boolean;
}

const PublishButtonContainer = styled.div`
  width: 8.75rem;
  height: 2.875rem;
  position: relative;
  margin-left: 1.25rem;
`;
const PublishButton = styled.button<PublishButtonProps>`
  width: 8.75rem;
  height: 2.875rem;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  color: #ffffff;
  border-radius: 10px;
  font-weight: 700;
  background: ${(props) => props.theme.colors.green[600]};
`;
const CourseForm = styled.form``;
const FormContainer = styled.div`
  margin-top: 1.875rem;
  background: #ffffff;
  padding: 3.125rem;
  border-radius: 1.25rem;
`;
const FormInputsContainer = styled.div`
  display: grid;
  min-width: 0;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-column-gap: 1.875rem;
  grid-row-gap: 2.5rem;
  margin-top: 1.1875rem;
`;
const BottomPublishButtonContainer = styled.div`
  margin-top: 1.875rem;
  display: flex;
  justify-content: flex-end;
`;
//----------------------------

export enum FormType {
  ADD = "ADD",
  EDIT = "EDIT",
}

function AddCourseForm() {
  const { id: courseId } = useParams();
  const [loading, formik, alreadyPublished] = useFormikInstance(courseId);
  const [saving, setSaving] = useState<boolean>(false);
  const [saveInitialized, setSaveInitialized] = useState<boolean>(false);
  const [savedJustNow, setSaveJustNow] = useState<boolean>(false);
  const [saveTimeout, setSaveTimeout] = useState<any>();
  const [formvalues, setFormvalues] = useState<any>();
  const navigate = useNavigate();
  function setFieldTouchedBeforeSave() {
    formik.setTouched({
      ...formik.touched,
      [NAME]: true,
      [UNIVERSITY]: true,
      [STUDY]: true,
      [SUBJECT]: true,
    });
  }
  function validateOnSaveClick() {
    formik.validateField(NAME);
    formik.validateField(UNIVERSITY);
    formik.validateField(STUDY);
    formik.validateField(SUBJECT);
    const isValid = saveFormSchema.isValidSync({
      [NAME]: formik.values[NAME],
      [UNIVERSITY]: formik.values[UNIVERSITY],
      [STUDY]: formik.values[STUDY],
      [SUBJECT]: formik.values[SUBJECT],
    });
    // return (
    //   !formik.errors[NAME] &&
    //   !formik.errors[UNIVERSITY] &&
    //   !formik.errors[STUDY] &&
    //   !formik.errors[SUBJECT]
    // );
    return isValid;
  }
  function unloadHandler(e: BeforeUnloadEvent) {
    e.preventDefault();
    // alert("your changes will be removed");
    return "";
  }
  useEffect(() => {
    window.onbeforeunload = unloadHandler;
    return () => {
      window.removeEventListener("beforeunload", unloadHandler);
    };
    window.onbeforeunload = () => {};
  }, []);
  function handleValuesChange() {
    const isEqual = _.isEqual(
      Object.assign(formik.values),
      Object.assign(formvalues)
    );
    if (isEqual) {
      return;
    } else {
      setFormvalues(formik.values);
      handleSaveClick();
    }
  }
  // useEffect(() => {
  //   if (saveInitialized) {
  //     handleValuesChange();
  //   }
  //   console.log(formik.values, "form values");
  // });
  function handleSaveInterval() {
    const isValid = validateOnSaveClick();
    if (isValid) {
      handleSaveClick();
    }
  }
  // useEffect(() => {
  //   const interval = setInterval(handleSaveInterval, 1000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  function handleSaveState() {
    setSaveJustNow(true);
    if (saveTimeout) {
      clearTimeout(saveTimeout);
    }
    const timeOut = setTimeout(() => {
      setSaveJustNow(false);
    }, 5000);
    setSaveTimeout(timeOut);
  }
  function initializeSaveHandler(): boolean {
    if (saveInitialized || alreadyPublished) {
      return true;
    }
    setFormvalues(formik.values);
    setSaveInitialized(true);
    return true;
  }
  const handlePublishClick = useCallback(() => {
    formik.submitForm();
  }, [formik]);

  const handleSaveClick = useCallback(async () => {
    if (saving) {
      return;
    }
    const status = CourseStatus.DRAFTED;
    // formik.setFieldValue(STATUS, CourseStatus.DRAFTED);
    const data = { ...formik.values, status };
    setFieldTouchedBeforeSave();
    const formIsValid = validateOnSaveClick();
    if (formIsValid) {
      setSaving(true);
      if (!courseId) {
        //@ts-ignore
        const res = await createCourse(data, CourseStatus.DRAFTED);
        if (res.success) {
          handleSaveState();
          ReactToast.success(
            "Course drafted successfully. you can edit it now"
          );
          navigate(`/admin-dashboard/courses/${res.data.id}/edit-course`);
        } else {
          ReactToast("Couldn't save the course");
        }
      } else {
        //@ts-ignore
        const res = await editCourse(data, courseId, CourseStatus.DRAFTED);
        if (res.success) {
          handleSaveState();
        } else {
          ReactToast(res.data);
        }
      }
      setSaving(false);
    }
    // formik.submitForm();
  }, [formik]);

  return !loading ? (
    <Container>
      {initializeSaveHandler() ? <></> : <></>}
      <Header>
        <HeadingAndBackButtonContainer>
          <BackLink
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaAngleLeft />
          </BackLink>
          <PageHeading>
            {courseId ? "Edit Course" : "Add a new course"}
          </PageHeading>
        </HeadingAndBackButtonContainer>
        <FormButtonsAndStateContainer>
          {savedJustNow && <SavedState>Saved Just now!</SavedState>}
          <FormButtonPublishAndSaveContainer>
            {!alreadyPublished && (
              <SaveButtonContainer>
                <SaveButton
                  $loading={saving}
                  disabled={saving || formik.isSubmitting}
                  onClick={handleSaveClick}
                  type="button"
                >
                  Save Concept
                </SaveButton>
                {saving && <LoadingButton />}
              </SaveButtonContainer>
            )}

            <PublishButtonContainer>
              <PublishButton
                $loading={formik.isSubmitting}
                disabled={formik.isSubmitting || saving}
                $disabled={!!Object.keys(formik.errors)}
                onClick={handlePublishClick}
                type="button"
              >
                Publish
              </PublishButton>
              {formik.isSubmitting && <LoadingButton />}
            </PublishButtonContainer>
          </FormButtonPublishAndSaveContainer>
        </FormButtonsAndStateContainer>
      </Header>
      <FormContainer>
        <CourseForm onSubmit={formik.handleSubmit}>
          <GeneralDetails formik={formik}></GeneralDetails>
          <FormHeadingsWithMargin>Pricing</FormHeadingsWithMargin>
          <FormInputsContainer>
            <FormInputWithEuroIcon
              formik={formik}
              id="originalPrice"
              name="originalPrice"
              label="Original price"
              placeholder="Type here"
              min={minimumOriginalPrice}
            />
            <FormInputWithEuroIcon
              formik={formik}
              id="salePrice"
              name="salePrice"
              label="Sale price"
              placeholder="Type here"
              min={minimumSalePrice}
            />
          </FormInputsContainer>
          <Schedule formik={formik} />
          <Questionnaire formik={formik} />
          <Teacher formik={formik} />
          <Features formik={formik} />
          <Highlights formik={formik} />
          <KnowledgeGuide formik={formik} />
          {/* <TestComponent formik={formikik} /> */}
        </CourseForm>
      </FormContainer>
      <BottomPublishButtonContainer>
        <FormButtonsAndStateContainer>
          {savedJustNow && <SavedState>Saved Just now!</SavedState>}
          <FormButtonPublishAndSaveContainer>
            {!alreadyPublished && (
              <SaveButtonContainer>
                <SaveButton
                  $loading={saving}
                  disabled={saving || formik.isSubmitting}
                  onClick={handleSaveClick}
                  type="button"
                >
                  Save Concept
                </SaveButton>
                {saving && <LoadingButton />}
              </SaveButtonContainer>
            )}
            <PublishButtonContainer>
              <PublishButton
                $loading={formik.isSubmitting}
                disabled={formik.isSubmitting || saving}
                $disabled={!!Object.keys(formik.errors)}
                onClick={handlePublishClick}
                type="button"
              >
                Publish
              </PublishButton>
              {formik.isSubmitting && <LoadingButton />}
            </PublishButtonContainer>
          </FormButtonPublishAndSaveContainer>
        </FormButtonsAndStateContainer>
      </BottomPublishButtonContainer>
    </Container>
  ) : (
    <LoadingScreenNew />
  );
}

export type Formik = FormikProps<formikConfig["initialValues"]>;
export default AddCourseForm;
