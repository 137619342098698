import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import CourseDetails from "./CourseDetails";
import DropdownList from "./DropdownList";
import { LoadingComponent } from "@components/AdminDashboard/SubPages/Orders/OrderDetails";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { FaUserLock } from "react-icons/fa";
import CheckoutFormComponent from "../CheckoutModal/CheckoutFormComponent";
import { CourseDetailsSkeleton } from "./CourseDetails";
import { mixin, device } from "@styles/theme";
import {
  LineSeperator,
  ArrowDownIcon,
  ArrowUpIcon,
  TextAndIcon,
} from "./DropdownList";
import { returnType, useCourseDetails } from "@lib/hooks/useCourseDetails";

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  @media screen and (max-width: 810px) {
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
  }
`;

const LeftContainer = styled.div`
  width: 50%;
  background-color: #4883c4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 6.4375rem;
  padding-bottom: 4.375rem;
  gap: 5.625rem;
  overflow-y: scroll;
  ${mixin.hideScroll()};
  @media ${device.laptopL} {
    padding: 70px;
  }
  @media screen and (max-width: 1350px) {
    padding: 70px 50px;
  }
  @media ${device.laptop} {
    padding: 70px 20px;
  }
  @media screen and (max-width: 810px) {
    width: 100%;
    padding: 40px 25px;
    overflow-y: visible;
    gap: 15px;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.4375rem 6.4375rem;
  background-color: #ffffff;
  width: 50%;
  @media screen and (max-width: 1350px) {
    padding: 13px 50px;
  }
  @media ${device.laptop} {
    padding: 13px 20px;
  }
  @media screen and (max-width: 810px) {
    width: 100%;
    height: 100%;
    padding: 40px 25px;
  }
`;

const LeftMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 25px;
  @media screen and (max-width: 810px) {
    gap: 15px;
  }
`;

const RightMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto 0;
  gap: 25px;
`;

const BigText = styled.h2`
  font-size: 1.563rem;
  color: #ffffff;
  font-weight: bold;
  @media ${device.mobileL} {
    font-size: 1.263rem;
  }
`;

const MediumText = styled.h4`
  font-size: 1.025rem;
  color: #ffffff;
  font-weight: bold;
  display: flex;
  align-items: center;
  @media ${device.mobileL} {
    font-size: 0.825rem;
  }
`;

const SmallText = styled.p`
  font-size: 0.825rem;
  color: #ffffff;
  font-weight: normal;
  @media ${device.mobileL} {
    font-size: 0.585rem;
  }
`;

const LeftMainContainerTop = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LeftMainContainerMiddle = styled.div``;

const LeftMainContainerBottom = styled.div``;

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  background: #3c6ba0 0% 0% no-repeat padding-box;
  padding: 12px 25px;
  border-radius: 10px;
  gap: 7px;
  justify-content: space-between;
  @media ${device.mobileL} {
    padding: 12px 15px;
  }
`;

const LeftContainerFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
`;

const UserLockIcon = styled(FaUserLock)`
  color: #a8bace;
  width: 35px;
  height: 35px;
  margin-right: 20px;
`;

const StartIcon = styled(AiFillStar)`
  color: #eabc5c;
  width: 22px;
  height: 22px;
`;

const OutlinedStarIcon = styled(AiOutlineStar)`
  color: #eabc5c;
  width: 22px;
  height: 22px;
`;

const StarIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const MobileDropDown = styled.div``;
function MainSkeleton() {
  return (
    <Container>
      <LeftContainer>
        <LeftMainContainer>
          <LeftMainContainerTop>
            <LoadingComponent style={{ height: "40px", width: "70%" }} />
          </LeftMainContainerTop>
          <LeftMainContainerMiddle>
            {window.innerWidth < 810 ? (
              <>
                <CourseDetailsSkeleton />
                <LoadingComponent
                  style={{
                    width: "100%",
                    height: "2px",
                    margin: "30px 0",
                  }}
                />
                <LoadingComponent style={{ height: "20px", width: "100%" }} />
                <LoadingComponent
                  style={{
                    width: "100%",
                    height: "2px",
                    margin: "30px 0",
                  }}
                />
              </>
            ) : (
              <>
                <LoadingComponent style={{ height: "20px", width: "100%" }} />
                <LoadingComponent
                  style={{
                    width: "100%",
                    height: "2px",
                    margin: "30px 0",
                  }}
                />
                <LoadingComponent style={{ height: "20px", width: "100%" }} />
                <LoadingComponent
                  style={{
                    width: "100%",
                    height: "2px",
                    margin: "30px 0",
                  }}
                />
                <LoadingComponent style={{ height: "20px", width: "100%" }} />
                <LoadingComponent
                  style={{
                    width: "100%",
                    height: "2px",
                    margin: "30px 0",
                  }}
                />
                <LeftMainContainerBottom>
                  <LoadingComponent
                    style={{ height: "20px", width: "50%", margin: "0 auto" }}
                  />
                  <LoadingComponent
                    style={{
                      height: "20px",
                      width: "47%",
                      margin: "10px auto",
                    }}
                  />
                </LeftMainContainerBottom>
              </>
            )}
          </LeftMainContainerMiddle>
        </LeftMainContainer>
      </LeftContainer>
      <RightContainer style={{ paddingTop: "103px" }}>
        <RightMainContainer>
          <LoadingComponent style={{ height: "20px", width: "40%" }} />
          <LoadingComponent style={{ borderRadius: "10px", height: "40px" }} />
          <LoadingComponent style={{ height: "40px", width: "40%" }} />
        </RightMainContainer>
      </RightContainer>
    </Container>
  );
}

const CheckoutModalForStudyGuide = () => {
  const [isMobileDropdownVisible, setisMobileDropdownVisible] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) {
    navigate("/error");
    return;
  }
  const details: returnType<boolean> = useCourseDetails(id);

  const [loading, courseDetails] = details;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (windowWidth >= 810) {
        setisMobileDropdownVisible(true);
      } else {
        setisMobileDropdownVisible(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [windowWidth]);

  function renderStars(rating: number) {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    const stars = [];
    for (let i = 0; i < fullStars; i++) {
      stars.push(<StartIcon key={i} />);
    }
    if (hasHalfStar) {
      stars.push(
        <StartIcon key="half" style={{ width: "16px", height: "16px" }} />
      );
    }
    const remainingStars = 5 - stars.length;
    for (let i = 0; i < remainingStars; i++) {
      stars.push(<OutlinedStarIcon key={`outline-${i}`} />);
    }

    return stars;
  }

  const handleMobileDropdown = () => {
    setisMobileDropdownVisible(!isMobileDropdownVisible);
  };

  return (
    <div>
      {!loading ? (
        <Container>
          <LeftContainer>
            <LeftMainContainer>
              <LeftMainContainerTop>
                <BigText>Buy your knowledge guide</BigText>
                <PriceContainer>
                  <SmallText>
                    &euro;&nbsp;
                    {!loading &&
                      courseDetails.guide.knowledgeGuideAvailable &&
                      courseDetails.guide.price}
                  </SmallText>
                </PriceContainer>
              </LeftMainContainerTop>

              <LeftMainContainerMiddle>
                <CourseDetails
                  details={
                    loading
                      ? details
                      : [
                          loading,
                          {
                            ...courseDetails,
                            universityImage: "/assets/book.png",
                          },
                        ]
                  }
                />
              </LeftMainContainerMiddle>

              {window.innerWidth < 810 && (
                <>
                  <LineSeperator style={{ margin: "0" }} />
                  <MobileDropDown onClick={handleMobileDropdown}>
                    <TextAndIcon style={{ margin: "0" }}>
                      <MediumText>
                        View details of this knowledge guide
                      </MediumText>
                      {!isMobileDropdownVisible ? (
                        <ArrowDownIcon />
                      ) : (
                        <ArrowUpIcon />
                      )}
                    </TextAndIcon>
                  </MobileDropDown>
                  <LineSeperator style={{ margin: "0 0 20px 0" }} />
                </>
              )}

              {isMobileDropdownVisible && (
                <LeftMainContainerBottom>
                  <DropdownList details={details} guide={true} />
                </LeftMainContainerBottom>
              )}
            </LeftMainContainer>
            {isMobileDropdownVisible && (
              <LeftContainerFooter>
                <MediumText>
                  <UserLockIcon />
                  Not passed?
                  <MediumText style={{ fontWeight: "normal" }}>
                    &nbsp;Next course for free{" "}
                  </MediumText>
                </MediumText>
                <StarIconContainer>
                  {renderStars(courseDetails.rating)}
                  <SmallText style={{ marginLeft: "10px" }}>
                    Rated with a {courseDetails.rating} out of 5.0
                  </SmallText>
                </StarIconContainer>
              </LeftContainerFooter>
            )}
          </LeftContainer>
          <RightContainer>
            <CheckoutFormComponent item={courseDetails} guide={true} />
          </RightContainer>
        </Container>
      ) : (
        <MainSkeleton />
      )}
    </div>
  );
};

export default CheckoutModalForStudyGuide;
