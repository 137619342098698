import {
  createStudy,
  createSubject,
  createUniversity,
  updateSubject,
} from "@API/services/EntitiesCrud";
import {
  Label,
  SettingsInput,
  SettingsSubmitButton,
} from "@components/AdminDashboard/SubPages/Settings/commonComponents/FormComponents";
import { ReactToast } from "@lib/toast";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { object, string } from "yup";
import { useTranslation } from "react-i18next";

// styled-components ----
const Container = styled.div`
  margin-top: 1.875rem;
  display: flex;
  flex-direction: column;
  padding: 1.875rem;
  padding-top: 0rem;
`;
const Form = styled.form``;
const IconLabel = styled(Label)`
  margin-top: 1.25rem;
`;
const NameInput = styled(SettingsInput)`
  margin-top: 0.625rem;
`;
const UniversitySubmitButton = styled(SettingsSubmitButton)`
  margin-top: 5rem;
`;
// ----------------------

const NAME = "subject-name";

function AddSubjectForm({
  studyId,
  onCreate,
  edit,
}: {
  studyId: string;
  onCreate: () => void;
  edit?: {
    subjectMame: string;
    subjectId: string;
  };
}) {
  const formik = useFormik({
    initialValues: {
      [NAME]: "",
    },
    validationSchema: object({
      [NAME]: string().required("name field is required").min(5),
    }),
    onSubmit: async (values, actions) => {
      if (edit) {
        const res = await updateSubject(values[NAME], edit.subjectId);
        if (res.success) {
          ReactToast("Subject updated");
          actions.resetForm();
          onCreate();
        } else {
          ReactToast("Coundn't update subject");
        }
      } else {
        const res = await createSubject(values[NAME], studyId);
        if (res.success) {
          ReactToast("subject created");
          actions.resetForm;
          onCreate();
        } else {
          ReactToast(res.error);
        }
      }
      actions.setSubmitting(false);
    },
  });
  useEffect(() => {
    if (edit) {
      formik.setFieldValue(NAME, edit.subjectMame);
    }
  }, []);

  const { t } = useTranslation();

  return (
    <Container>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <Label>
          {t(
            "adminSettings.universityStudySubjectPage.subjectsPage.subjectName"
          )}
        </Label>
        <NameInput
          placeholder={t(
            "adminSettings.universityStudySubjectPage.subjectsPage.subjectName"
          )}
          {...formik.getFieldProps(NAME)}
        />
        <UniversitySubmitButton loading={formik.isSubmitting}>
          {edit
            ? "Edit subject"
            : t(
                "adminSettings.universityStudySubjectPage.subjectsPage.addSubject"
              )}
        </UniversitySubmitButton>
      </Form>
    </Container>
  );
}

export default AddSubjectForm;
