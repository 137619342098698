import React from "react";
import styled from "styled-components";
import Header from "@components/CommonDashboard/Header";
import theme, { colors, device, mixin } from "@styles/theme";
import { useTranslation } from "react-i18next";
import Accordion from "./Accordion";
import SecondSection from "./SecondSection";

const Container = styled.div`
  height: 100dvh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background: ${colors.gray[500]};
`;

const ContentContainer = styled.div`
  width: 100%;
  min-height: 0;
  flex-grow: 1;
  overflow-y: scroll;
  position: relative;
  ${mixin.hideScroll()}
`;

const Background = styled.div`
  margin-top: 5.0625rem;
  position: fixed;
  top: 0;
  left: 0;
  height: 25rem;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% calc(100% - 10rem));
  background: ${theme.colors.blue[700]};
  width: 100%;
  z-index: 1;
  @media ${device.tablet} {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% calc(100% - 5rem));
  }
`;

const HeadingContainer = styled.div`
  padding-top: 4.5rem;
  padding-left: 2.9375rem;
  width: 30%;
  @media ${device.laptop} {
    width: 100%;
    max-width: 30rem;
    padding-left: 50px;
  }
  @media ${device.tablet} {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const Heading = styled.h2`
  font-size: 2.375rem;
  line-height: 3.3125rem;
  font-weight: 700;
  color: #fff;
`;

const SubHeading = styled.h3`
  font-size: 1rem;
  line-height: 1.25rem;
  color: #fff;
`;

// const InnerContainer = styled.div`
//   height: 1000rem;
//   font-size: 1rem;
//   position: relative;
//   z-index: 2;
//   line-height: 1.125rem;
//   color: #fff;
//   display: flex;
//   justify-content: flex-end;
// `;

const InnerContainer = styled.div`
  width: 100%;
  position: relative;
  z-index: 2;
`;

const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
const AccordionContainer = styled.div`
  margin-right: 3.125rem;
  margin-top: 7.8125rem;
  margin-bottom: 4.8125rem;
  width: 60%;
  @media ${device.laptop} {
    margin-right: 50px;
    margin-top: 210px;
    margin-left: 50px;
    margin-bottom: 50px;
    width: auto;
  }
  @media ${device.tablet} {
    margin-right: 20px;
    margin-top: 180px;
    margin-left: 20px;
    margin-bottom: 20px;
  }
`;
const SecondSectionContainer = styled.div``;

function main() {
  const { t } = useTranslation();
  return (
    <Container>
      <Header />
      <ContentContainer>
        <Background>
          <HeadingContainer>
            <Heading>{t("WorkAtAkc.heading")}</Heading>
            <SubHeading>
              {t("WorkAtAkc.para1")} {t("WorkAtAkc.para2")}
            </SubHeading>
          </HeadingContainer>
        </Background>
        <InnerContainer>
          <AccordionSection>
            <AccordionContainer>
              <Accordion />
            </AccordionContainer>
          </AccordionSection>
          <SecondSectionContainer>
            <SecondSection />
          </SecondSectionContainer>
        </InnerContainer>
      </ContentContainer>
    </Container>
  );
}

export default main;
