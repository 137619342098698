import React, { useEffect, useRef, useState } from "react";
import "react-image-crop/dist/ReactCrop.css";
import styled from "styled-components";
import ReactCrop, { type Crop } from "react-image-crop";

// styled.components ----
const Container = styled.div``;

const ImageWithStyling = styled.img`
  background: #00000040;
`;
// ----------------------
function pcntToLength(percentage: number, length: number) {
  return (length * percentage) / 100;
}
function ImageEdit({
  src,
  value,
  onChange,
}: {
  src: string;
  value: Crop | undefined;
  onChange: (crop: Crop) => void;
}) {
  return (
    <Container>
      <ReactCrop
        crop={value}
        onChange={(pxcrop, percentCrop) => {
          onChange(percentCrop);
        }}
        aspect={1}
        minWidth={100}
        minHeight={100}
        circularCrop={true}
        ruleOfThirds={true}
        keepSelection={true}
      >
        <img src={src} />
      </ReactCrop>
    </Container>
  );
}

export default ImageEdit;
