import { useState } from "react";
import { FaMagnifyingGlass } from "react-icons/fa6";
import styled from "styled-components";

const Container = styled.div`
  height: 2.875rem;
  display: flex;
  width: 23rem;
`;
const SearchInput = styled.input`
  display: block;
  flex-shrink: 1;
  height: 100%;
  border-radius: 10px 0 0 10px;
  padding: 0.6563rem 1.25rem;
  padding-right: 0rem;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  min-width: 5rem;
  color: ${(props) => props.theme.colors.gray[800]};
  &::placeholder {
    color: ${(props) => props.theme.colors.gray[600]};
  }
`;
const SearchButton = styled.button`
  background: #ffffff;
  height: 100%;
  border: none;
  outline: none;
  border-radius: 0px 10px 10px 0px;
  padding: 0.6563rem 1.25rem;
`;
const SearchButtonIcon = styled(FaMagnifyingGlass)`
  height: 18px;
  width: 18px;
  color: ${(props) => props.theme.colors.gray[800]};
`;
function Search({
  placeholder,
  onChange,
  ...props
}: {
  placeholder: string;
  onChange: (value: string) => void;
}) {
  const [value, setValue] = useState("");
  return (
    <Container>
      <SearchInput
        value={value}
        onChange={(e) => {
          setValue(e.target.value);

          if (e.target.value === "") {
            onChange(e.target.value);
          }
        }}
        {...props}
        placeholder={placeholder}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onChange(value);
          }
        }}
      />
      <SearchButton
        onClick={() => {
          onChange(value);
        }}
      >
        <SearchButtonIcon />
      </SearchButton>
    </Container>
  );
}

export default Search;
