import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";
import { CourseSearchList } from "@lib/Types/API/APICourseSearchList";
import { APIPaymentIntent } from "@lib/Types/API/Payment/PaymentIntent";
import { PaymentIntent } from "@stripe/stripe-js";
export type paymentIntentResponseType =
  | {
      success: true;
      data: APIPaymentIntent;
    }
  | {
      success: false;
      data: string;
    };
export const getPaymentIntent = async function (
  courseId: string,
  guide: boolean
): Promise<paymentIntentResponseType> {
  const url = urlFunctions.getPaymentIntent();
  const data = {
    "course-id": courseId,
    isEnrollmentForStudyMaterialOnly: guide,
  };
  const res = await API.sendPostRequest(url, data, true);
  if (res.success) {
    const data: APIPaymentIntent = res.data;
    return {
      success: true,
      data: data,
    };
  } else {
    return {
      success: false,
      data: "There was an error in processing payment",
    };
  }
};
