import { arrayMove } from "@dnd-kit/sortable";
import { Formik } from "../useFormikInstance";
import { AddCourseFormFieldsName } from "../validationSchema";

export function formikTouchedSorter(
  oldIndex: number,
  newIndex: number,
  formik: Formik,
  name: AddCourseFormFieldsName
) {
  const touchedArray = formik.touched[name];
  if (!Array.isArray(touchedArray)) return undefined;
  const items = formik.values[name];
  if (!Array.isArray(items)) return;
  const newArray = [...touchedArray];
  const tempArray: any[] = [];
  for (let i = 0; i < items.length; i++) {
    if (newArray[i]) {
      tempArray[i] = newArray[i];
    } else {
      tempArray[i] = undefined;
    }
  }
  const newFieldTouchedItems = arrayMove(tempArray, oldIndex, newIndex);
  const newTouchedObject = Object.assign({
    ...formik.touched,
    [name]: newFieldTouchedItems,
  });
  formik.setTouched(newTouchedObject);
}

export function deleteTheFormikError(
  indexToDelete: number,
  formik: Formik,
  name: AddCourseFormFieldsName
) {
  const touchedArray = formik.touched[name];
  if (!Array.isArray(touchedArray)) return;
  const newArray = [...touchedArray];
  const tempArray: any[] = [];
  const items = formik.values[name];
  if (!Array.isArray(items)) return;
  for (let i = 0; i < items.length; i++) {
    if (newArray[i]) {
      tempArray[i] = newArray[i];
    } else {
      tempArray[i] = undefined;
    }
  }
  const newFieldTouchedItems = tempArray.filter((item, index) => {
    if (indexToDelete !== index) {
      return true;
    }
  });
  const newTouchedObject = Object.assign({
    ...formik.touched,
    [name]: newFieldTouchedItems,
  });
  formik.setTouched(newTouchedObject);
}
