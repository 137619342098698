import { createUniversity } from "@API/services/EntitiesCrud";
import { UserRole } from "@app/redux/slices/userSlice";
import {
  Label,
  SettingsInput,
  SettingsSubmitButton,
} from "@components/AdminDashboard/SubPages/Settings/commonComponents/FormComponents";
import { ReactToast } from "@lib/toast";
import { useFormik } from "formik";
import styled from "styled-components";
import { object, ref, string } from "yup";
import { changePassword } from "@API/services/Auth/main";
import { useSelector } from "react-redux";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { useTranslation } from "react-i18next";

// styled-components ----
const Container = styled.div`
  margin-top: 1.875rem;
  display: flex;
  flex-direction: column;
  padding: 1.875rem;
  padding-top: 0rem;
`;
const Form = styled.form``;
const IconLabel = styled(Label)`
  margin-top: 1.25rem;
`;
const RoleLabel = styled(Label)`
  display: block;
  margin-top: 1.25rem;
`;
const PasswordSubmitButton = styled(SettingsSubmitButton)`
  margin-top: 5rem;
`;
const EmailInput = styled(SettingsInput)`
  margin-top: 0.625rem;
`;
const InputContainer = styled.div``;
const Error = styled.div`
  font-size: 0.9rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.red[500]};
  margin-top: 0.5rem;
`;
const StyledLabel = styled(Label)`
  margin-top: 1.25rem;
`;
// ----------------------

const roleOptions = [{ id: UserRole.TEAHCER, value: "Teacher" }];

const EXISTING_PASSWORD = "existing password";
const NEW_PASSWORD = "new password";
const CONFIRM_PASSWORD = "confirm password";
interface initialvaluesInterface {
  [EXISTING_PASSWORD]: string;
  [NEW_PASSWORD]: string;
  [CONFIRM_PASSWORD]: string;
}
const initialValues: initialvaluesInterface = {
  [EXISTING_PASSWORD]: "",
  [NEW_PASSWORD]: "",
  [CONFIRM_PASSWORD]: "",
};
function ChangePasswordForm({ handleClose }: { handleClose: () => void }) {
  const { t } = useTranslation();
  const userInfo = useSelector(selectUser);
  const formik = useFormik({
    initialValues,
    validationSchema: object({
      [EXISTING_PASSWORD]: string().required(),
      [NEW_PASSWORD]: string()
        .required()
        .min(6, "password should be atleast 6 characters"),
      [CONFIRM_PASSWORD]: string()
        .oneOf([ref(NEW_PASSWORD)], "Password must match")
        .required("confirm password is required"),
    }),
    onSubmit: async (values, actions) => {
      const res = await changePassword({
        oldpassword: values[EXISTING_PASSWORD],
        newpassword: values[NEW_PASSWORD],
      });
      if (res) {
        ReactToast("Password changed");
        handleClose();
        actions.resetForm();
      } else {
        ReactToast("Couldn't change password");
      }
      actions.setSubmitting(false);
    },
  });
  return (
    <Container>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <Label>
          {t("sdashboard.profile.changepassword.form.password_label")}
        </Label>
        <InputContainer>
          <EmailInput
            type="password"
            placeholder={t(
              "sdashboard.profile.changepassword.form.password_label"
            )}
            {...formik.getFieldProps(EXISTING_PASSWORD)}
          />
          {formik.touched[EXISTING_PASSWORD] &&
            formik.errors[EXISTING_PASSWORD] && (
              <Error>{formik.errors[EXISTING_PASSWORD]}</Error>
            )}
        </InputContainer>
        <StyledLabel>New password</StyledLabel>
        <InputContainer>
          <EmailInput
            type="password"
            placeholder={t(
              "sdashboard.profile.changepassword.form.password_label"
            )}
            {...formik.getFieldProps(NEW_PASSWORD)}
          />
          {formik.touched[NEW_PASSWORD] && formik.errors[NEW_PASSWORD] && (
            <Error>{formik.errors[NEW_PASSWORD]}</Error>
          )}
        </InputContainer>
        <StyledLabel>Confirm password</StyledLabel>
        <InputContainer>
          <EmailInput
            type="password"
            placeholder={t(
              "sdashboard.profile.changepassword.form.password_label"
            )}
            {...formik.getFieldProps(CONFIRM_PASSWORD)}
          />
          {formik.touched[CONFIRM_PASSWORD] &&
            formik.errors[CONFIRM_PASSWORD] && (
              <Error>{formik.errors[CONFIRM_PASSWORD]}</Error>
            )}
        </InputContainer>

        <PasswordSubmitButton loading={formik.isSubmitting}>
          {t("sdashboard.profile.changepassword.form.heading")}
        </PasswordSubmitButton>
      </Form>
    </Container>
  );
}

export default ChangePasswordForm;
