import { useCombobox, useMultipleSelection } from "downshift";
import { useEffect, useMemo, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import styled, { css } from "styled-components";
// styled.components ----

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
`;
interface InputContainerPropType {
  $open: boolean;
}

const InputContainer = styled.div<InputContainerPropType>`
  margin-top: 0.625rem;
  width: 100%;
  padding: 1.125rem 1.875rem;
  display: flex;
  align-items: center;
  box-shadow: ${(props) => (props.$open ? "0px 0px 10px #00000029" : "none")};
  border-radius: 10px;
  background: ${(props) => props.theme.colors.gray[500]};
`;
interface SelectorInputPropType {
  $open: boolean;
}
const SelectorInput = styled.input<SelectorInputPropType>`
  min-width: 0px;
  flex-grow: 1;
  height: 100%;
  padding-right: 0rem;
  background: ${(props) => props.theme.colors.gray[500]};
  border-radius: ${(props) => (props.$open ? "10px 0 0 0px" : "10px 0 0 10px")};
  color: ${(props) => props.theme.colors.gray[800]};
  &::placeholder {
    color: ${(props) => props.theme.colors.gray[600]};
    line-height: 1.5625rem;
    font-size: 1.125rem;
  }
`;

const ArrowDown = styled(FaAngleDown)`
  width: 1.125rem;
  height: 1.125rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const ArrowUp = styled(FaAngleUp)`
  width: 1.125rem;
  height: 1.125rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;
interface SelectorButtonPropType {
  $open: boolean;
}

const SelectorButton = styled.button<SelectorButtonPropType>`
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: ${(props) => (props.$open ? "0 10px 0px 0" : "0 10px 10px 0")};
  background: ${(props) => props.theme.colors.gray[500]};
`;
interface OptionsListPropsTyps {
  $open: boolean;
}
const OptionsList = styled.ul<OptionsListPropsTyps>`
  width: 100%;
  position: absolute;
  right: 0;
  background: #ffffff;
  display: ${(props) => (props.$open ? "block" : "none")};
  border-radius: 0 0 15px 15px;
  /* padding: 0.5rem 0rem; */
  /* margin-top: 0.3125rem; */
  max-height: 20rem;
  overflow-y: scroll;
  z-index: 1000;
  box-shadow: 0px 10px 10px #00000029;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
`;
interface ListItemPorpsType {
  $highlighted: boolean;
  $selected: boolean;
}
const ListItem = styled.li<ListItemPorpsType>`
  display: flex;
  padding: 0rem 0.3125rem;
  cursor: pointer;
  text-align: left;
  color: ${(props) => props.theme.colors.gray[800]};
  transition: background 0.2s;
  transition: color 0.2s;
  line-height: 1.5625rem;
  font-size: 1.125rem;
  overflow-wrap: break-word;
  height: 4.5625rem;
  /* &:last-child {
    border-bottom: none;
  } */
  ${(props) =>
    props.$highlighted &&
    css`
      background: ${props.theme.colors.blue[700]};
      color: #ffffff;
      font-weight: 700;
      div {
        border-bottom: none;
      }
    `}
  ${(props) =>
    props.$selected &&
    css`
      background: ${props.theme.colors.green[600]};
      color: #ffffff;
      font-weight: 700;
    `}
`;

const ListItemInnerContainer = styled.div`
  flex-grow: 1;
  margin: 0px 1.5625rem;
  height: 100%;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray[500]};
  display: flex;
  align-items: center;
`;
// ----------------------

export interface optionType {
  id: string;
  value: string;
}

function getFilteredOptions(
  options: optionType[],
  selectedItems: optionType[] | undefined,
  inputValue: string
) {
  const lowerCasedInputValue = inputValue.toLowerCase();
  return options.filter(function filterOptions(option) {
    return (
      (!selectedItems || !selectedItems.includes(option)) &&
      option.value.toLowerCase().includes(lowerCasedInputValue)
    );
  });
}

export function MultiSelect({
  options,
  text,
  onChange,
  values,
}: {
  options: optionType[];
  text: string;
  onChange: (selectedItems: optionType[]) => void;
  values: optionType[] | undefined;
}) {
  const [inputValue, setInputValue] = useState("");
  const items = useMemo(
    () => getFilteredOptions(options, values, inputValue),
    [values, inputValue, options]
  );

  const { getSelectedItemProps, getDropdownProps, removeSelectedItem } =
    useMultipleSelection({
      selectedItems: values,
      onStateChange({ selectedItems: newSelectedItems, type }) {
        switch (type) {
          case useMultipleSelection.stateChangeTypes
            .SelectedItemKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
          case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
            if (newSelectedItems) onChange(newSelectedItems);
            break;
          default:
            break;
        }
      },
    });
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useCombobox({
    items,
    itemToString(item) {
      return item ? item.value : "";
    },
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    selectedItem: null,
    inputValue,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
            highlightedIndex: 0, // with the first option highlighted.
          };
        default:
          return changes;
      }
    },

    onStateChange({
      inputValue: newInputValue,
      type,
      selectedItem: newSelectedItem,
    }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (newSelectedItem) {
            if (values) {
              onChange([...values, newSelectedItem]);
            } else {
              onChange([newSelectedItem]);
            }
            setInputValue("");
          }
          break;

        case useCombobox.stateChangeTypes.InputChange:
          if (!newInputValue) {
            setInputValue("");
          } else {
            setInputValue(newInputValue);
          }
          break;
        default:
          break;
      }
    },
  });

  return (
    <Container>
      <InputContainer $open={isOpen && !!items.length}>
        {/* <label className="w-fit" {...getLabelProps()}>
          Pick some books:
        </label> */}

        {/* {selectedItems.map(function renderSelectedItem(
            selectedItemForRender,
            index
          ) {
            return (
              <span
                className="bg-gray-100 rounded-md px-1 focus:bg-red-400"
                key={`selected-item-${index}`}
                {...getSelectedItemProps({
                  selectedItem: selectedItemForRender,
                  index,
                })}
              >
                {selectedItemForRender.value}
                <span
                  className="px-1 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeSelectedItem(selectedItemForRender);
                  }}
                >
                  &#10005;
                </span>
              </span>
            );
          })} */}
        <SelectorInput
          $open={isOpen && !!items.length}
          placeholder={text}
          {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))}
        />
        <SelectorButton
          $open={isOpen && !!items.length}
          aria-label="toggle menu"
          type="button"
          {...getToggleButtonProps()}
        >
          {isOpen ? <ArrowUp /> : <ArrowDown />}
        </SelectorButton>
      </InputContainer>
      <OptionsList $open={isOpen && !!items.length} {...getMenuProps()}>
        {isOpen &&
          items.map((item, index) => (
            <ListItem
              $highlighted={highlightedIndex === index}
              $selected={!!selectedItem && selectedItem.id === item.id}
              key={`${item.value}${index}`}
              {...getItemProps({ item, index })}
            >
              <ListItemInnerContainer>{item.value}</ListItemInnerContainer>
            </ListItem>
          ))}
      </OptionsList>
    </Container>
  );
}
