import { FaSpinner } from "react-icons/fa6";
import styled, { keyframes } from "styled-components";

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
export const LoadingSpinner = styled(FaSpinner)`
  animation: ${rotateAnimation} 1s linear infinite;
`;

const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  opacity: 0.5;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;
export const LoadingButton = () => {
  return (
    <SpinnerContainer>
      <LoadingSpinner />
    </SpinnerContainer>
  );
};
