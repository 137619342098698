import { urlFunctions } from "@API/functions/createUrl";
import { UserRole } from "@app/redux/slices/userSlice";
import { Message } from "@components/CommonComponents/Chat/ChatsComponent";
import { IncomingMessage } from "@lib/Types/Chat/IncomingMessage";
import Cookies from "js-cookie";
import SockJS from "sockjs-client";

function trackPropertyChanges(obj: any, propertyName: any, onChange: any) {
  return new Proxy(obj, {
    set(target, property, value) {
      if (property === propertyName) {
        // Trigger some action when the specific property changes
        onChange(value, target[property]);
        // console.log(
        //   `property ${String(property)} is changing it's value from ${
        //     target[property]
        //   } to ${value}`,
        //   target,
        //   obj
        // );
      }
      target[property] = value;
      return true;
    },
  });
}

export function getCurrentDateTime() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  return formattedDateTime;
}

interface files {
  url: string;
  previewUrl: string;
  type: string;
  uploadedBy: number;
}
export interface sendMessageFormat {
  content: string;
  time: string;
  files: files[];
}
export default class ChatInterface {
  public connected = false;
  private stompClient: any = null;
  private connectionSubscriber = (connected: boolean) => {};
  private messageSubscriber = (message: IncomingMessage) => {};
  private courseId: string | null = null;
  constructor(courseId: string) {
    const chatUrl = urlFunctions.chat();
    let socket = new SockJS(chatUrl);
    const stompClient = Stomp.over(socket);
    const proxyStompClient = trackPropertyChanges(
      stompClient,
      "connected",
      (newValue: any, oldValue: any) => {
        this.setConnect(newValue);
      }
    );
    this.stompClient = proxyStompClient;
    this.courseId = courseId;
  }
  disconnect() {
    if (this.stompClient) {
      this.stompClient.disconnect();
    }
  }
  connect() {
    const courseId = this.courseId;
    const token = Cookies.get("token");
    const StompClient = this.stompClient;
    if (!courseId || !token || !StompClient) return;

    const headers = {
      Authorization: "Bearer " + token,
    };
    const onMessage = function (this: ChatInterface, message: any) {
      if (message.body) {
        const parsedResult = JSON.parse(message.body);
        if (parsedResult.body) {
          this.messageSubscriber(parsedResult.body);
        } else {
          this.messageSubscriber(parsedResult);
        }
        // this.messageSubscriber(parsedResult);
      } else {
        const parsedResult = JSON.parse(message);
        this.messageSubscriber(parsedResult.body.body);
      }
    };
    const onConnect = function (
      this: ChatInterface,
      frame: any,
      something: any
    ) {
      StompClient.subscribe(
        urlFunctions.inComingChat() + "/" + courseId,
        onMessageBinded
      );
    };
    const onConnectBinded = onConnect.bind(this);
    const onMessageBinded = onMessage.bind(this);

    this.stompClient.connect(headers, onConnectBinded);
    this.stompClient.ws.onclose = () => {
      this.setConnect(false);
    };
  }
  setConnect(status: boolean) {
    this.connected = status;
    this.connectionSubscriber(status);
  }
  sendMessage(message: any) {
    const token = Cookies.get("token");
    const StompClient = this.stompClient;
    const courseId = this.courseId;
    const connected = this.connected;
    if (!StompClient || !courseId || !token) return;

    const headers = {
      Authorization: "Bearer " + token,
    };
    StompClient.send(
      urlFunctions.outGoingChat() + "/" + courseId,
      headers,
      JSON.stringify(message)
    );
  }
  subscribeToConnection(cb: (connected: boolean) => void) {
    this.connectionSubscriber = cb;
  }
  subscribeToMessages(cb: (message: IncomingMessage) => void) {
    this.messageSubscriber = cb;
  }
}
