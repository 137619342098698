import { NonEnrolledCourseDataType } from "@components/CommonComponents/CourseComponent/NonEnrolledCourse";
import SampleUniversityImage from "@images/sample-university.png";
import {
  SuggestionSearchList,
  SuggestionResponse,
} from "@lib/Types/API/APISuggestionResponse";
export const MapCourseToSuggestion = async ({
  item,
}: {
  item: SuggestionSearchList;
}): Promise<NonEnrolledCourseDataType[]> => {
  let AnswerList: NonEnrolledCourseDataType[] | null = [];
  for (let i = 0; i < item.length; i++) {
    let obj: NonEnrolledCourseDataType = await CHangeToNonEnrolled(item[i]);
    AnswerList.push(obj);
  }
  return AnswerList;
};
const CHangeToNonEnrolled = async (
  item: SuggestionResponse
): Promise<NonEnrolledCourseDataType> => {
  return {
    id: item["course-id"],
    // image: SampleUniversityImage,
    // university: item.university,
    university: {
      id: item.university.id,
      universityName: item.university.universityName,
      universityLogoUrl: item.university.universityLogoUrl,
    },
    study: item.study,
    status: item.status === "ACTIVE" ? true : false,
    major: item.subject,
    subject: item.subject,
    language: item.language,
    type: item["course-type"],
  };
};
