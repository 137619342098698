import { UserRole, userInfo } from "@app/redux/slices/userSlice";
import { StudentData } from "@components/AdminDashboard/SubPages/Students/StudentTable";
import { AdminStudentResponse } from "@lib/Types/API/APIAdminStudents";
import { v4 as uuid } from "uuid";
import { TeacherData } from "@components/AdminDashboard/SubPages/Settings/SubPages/Teachers/AdminTeachers";
import { assetsUrls } from "@styles/theme";
export function mapTeachersTableDataToProfileComponent(
  data: TeacherData
): userInfo {
  const item = data;
  const mappedData: userInfo = {
    key: new Date().getTime(),
    loggedIn: false,
    role: UserRole.TEAHCER,
    userId: item.teacherId,
    name: item.firstName + " " + item.LastName,
    profileUrl: assetsUrls.userPng2,
    email: item.email,
    phone: undefined,
    address: undefined,
    zipcode: undefined,
    city: undefined,
    country: undefined,
    univversityLogo: undefined,
    dateOfBirth: undefined,
    language: undefined,
    university: undefined,
    study: undefined,
    grade: undefined,
    smartPoints: undefined,
    universityStudyAddedForTheFirstTime: undefined,
    infoAddedForTheFirstTime: undefined,
    courseOrderedForTheFirstTime: undefined,
    courseJoinedFortTheFirstTime: undefined,
    verified: undefined,
  };
  return mappedData;
}
