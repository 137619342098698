import { addMinutes } from "date-fns";
import Cookies from "js-cookie";

export type sessionReturnType =
  | {
      success: true;
      data: any;
    }
  | {
      success: false;
      data: null;
    };

export const sessionS = {
  getItem: (key: string): sessionReturnType => {
    const token = Cookies.get("token");
    if (!token) {
      return {
        success: false,
        data: null,
      };
    }
    const item = Cookies.get(`${token}.${key}`);
    if (!item) return { success: false, data: null };
    const convertedValue = JSON.parse(item);
    return { success: true, data: convertedValue };
  },
  setItem: (
    key: string,
    value: { [key: string]: string },
    expirationTimeInSeconds: number
  ): boolean => {
    const token = Cookies.get("token");
    if (!token) {
      return false;
    }
    const convertedValue = JSON.stringify(value);
    Cookies.set(`${token}.${key}`, convertedValue, {
      expires: addMinutes(new Date(), 10),
    });

    return true;
  },
};
