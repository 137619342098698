import { ReactNode, useState } from "react";
import { FaAngleDown, FaAngleUp, FaBullseye } from "react-icons/fa6";
import styled from "styled-components";
import { FaCircleCheck } from "react-icons/fa6";
import { device } from "@styles/theme";
import { useSelector } from "react-redux";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { useTranslation } from "react-i18next";

// styled-components----
interface ContainerProps {
  $active: boolean;
}
const Container = styled.div<ContainerProps>`
  background: transparent linear-gradient(180deg, #a8bace 0%, #8fa7c1 100%) 0%
    0% no-repeat padding-box;
  border-radius: 20px;
  flex-direction: column;
  @media ${device.tablet} {
    border-radius: 10px;
  }
  cursor: ${(props) => (props.$active ? "auto" : "pointer")};
  @media ${device.laptopL} {
    width: 50%;
    align-self: flex-start;
  }
  @media ${device.laptopS} {
    width: 100%;
  }
`;
const ProfileStatusContainer = styled.div`
  padding: 30px;
  color: #ffffff;
  width: 100%;
  @media ${device.tablet} {
    padding: 1.5625rem 1.25rem;
  }
`;
const ProfileStatusHeader = styled.div`
  display: flex;
`;
const ProfileStatusImageContainer = styled.div`
  height: 3.125rem;
  width: 3.125rem;
  @media ${device.tablet} {
    height: 2.5rem;
    width: 2.5rem;
  }
`;
const ProfileStatusImage = styled(FaBullseye)`
  height: 3.125rem;
  width: 3.125rem;
  min-height: 3.125rem;
  min-width: 3.125rem;
  @media ${device.tablet} {
    height: 2.5rem;
    width: 2.5rem;
    min-height: 2.5rem;
    min-width: 2.5rem;
  }
`;
const ProfileStatusHeaderTextContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-left: 1.25rem;
`;
const ProfileStatusHeaderHeadingAndToggleButton = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  @media ${device.tablet} {
    font-size: 1rem;
    line-height: 1.4375rem;
  }
`;
const ToggleButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  color: #ffffff;
  cursor: pointer;
`;
const ProfileStatusHeaderPercentageIndicator = styled.div`
  font-size: 1rem;
  line-height: 1.4375rem;
  margin-top: 2.5px;
  @media ${device.tablet} {
    font-size: 0.75rem;
    line-height: 1.0625rem;
  }
`;
const Divider = styled.div`
  height: 2px;
  background: ${(props) => props.theme.colors.gray[500]};
  opacity: 0.33;
  margin: 0 1.25rem;
`;
const StepsContainer = styled.div`
  padding: 1.875rem;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 1.25rem;
`;
const StepContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
`;
const CheckIcon = styled(FaCircleCheck)`
  height: 1.125rem;
  width: 1.125rem;
  margin-right: 1.25rem;
`;
const StepPointsContainer = styled.div`
  width: 2.25rem;
  height: 1.3125rem;
  font-weight: 700;
  font-size: 0.625rem;
  line-height: 0.9375rem;
  background: #ffffff26;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`;
interface StepTextAndCheckButtonContainerPropsType {
  $completed: boolean;
}
const StepTextAndCheckButtonContainer = styled.div<StepTextAndCheckButtonContainerPropsType>`
  display: flex;
  align-items: center;
  text-decoration: ${(props) => (props.$completed ? "line-through" : "none")};
  opacity: ${(props) => (props.$completed ? "0.5" : "1")};
`;
// ---------------------
const Steps = function ({
  completed,
  children,
}: {
  completed: boolean;
  children: ReactNode;
}) {
  return (
    <StepContainer>
      <StepTextAndCheckButtonContainer $completed={completed}>
        <CheckIcon />
        {children}
      </StepTextAndCheckButtonContainer>
      <StepPointsContainer>+25</StepPointsContainer>
    </StepContainer>
  );
};
function ProfileStatus() {
  const [active, setActive] = useState<boolean>(false);
  const userProfile = useSelector(selectUser);
  const { t } = useTranslation();
  function calculatePercentageOfProfileCompletion() {
    const arrayOfActionsStatus = [
      !!userProfile.infoAddedForTheFirstTime,
      !!userProfile.universityStudyAddedForTheFirstTime,
      !!userProfile.courseOrderedForTheFirstTime,
      !!userProfile.courseJoinedFortTheFirstTime,
    ];
    let percentage = 0;
    for (let item of arrayOfActionsStatus) {
      if (item) {
        percentage += 25;
      }
    }
    return percentage;
  }
  return (
    <Container
      $active={active}
      onClick={() => {
        setActive(true);
      }}
      role="button"
      aria-label="expand by clicking"
    >
      <ProfileStatusContainer>
        <ProfileStatusHeader>
          <ProfileStatusImageContainer>
            <ProfileStatusImage />
          </ProfileStatusImageContainer>
          <ProfileStatusHeaderTextContainer>
            <ProfileStatusHeaderHeadingAndToggleButton>
              {t("sdashboard.overview.extra.status.heading")}{" "}
              <ToggleButton
                onClick={(e) => {
                  e.stopPropagation();
                  setActive((value) => !value);
                }}
              >
                {" "}
                {active ? <FaAngleUp /> : <FaAngleDown />}
              </ToggleButton>
            </ProfileStatusHeaderHeadingAndToggleButton>
            <ProfileStatusHeaderPercentageIndicator>
              {calculatePercentageOfProfileCompletion()}%&nbsp;
              {t("sdashboard.overview.extra.status.completed")}
              &nbsp;&#x2022;&nbsp;
              {t("sdashboard.overview.extra.status.points_per_task")}
            </ProfileStatusHeaderPercentageIndicator>
          </ProfileStatusHeaderTextContainer>
        </ProfileStatusHeader>
      </ProfileStatusContainer>
      {active && (
        <>
          <Divider />
          <StepsContainer>
            <Steps completed={!!userProfile.infoAddedForTheFirstTime}>
              {t("sdashboard.overview.extra.status.task1")}
            </Steps>
            <Steps
              completed={!!userProfile.universityStudyAddedForTheFirstTime}
            >
              {t("sdashboard.overview.extra.status.task2")}
            </Steps>
            <Steps completed={!!userProfile.courseOrderedForTheFirstTime}>
              {t("sdashboard.overview.extra.status.task3")}
            </Steps>
            <Steps completed={!!userProfile.courseJoinedFortTheFirstTime}>
              {t("sdashboard.overview.extra.status.task4")}
            </Steps>
          </StepsContainer>
        </>
      )}
    </Container>
  );
}

export default ProfileStatus;
