import { differenceInMinutes, formatDistance, parse } from "date-fns";

export function getTotalNumberOfMinutes(
  startTime: string | undefined,
  endTime: string | undefined
) {
  if (!startTime || !endTime) {
    return 0;
  }
  const startDate = parse(startTime, "HH:mm", new Date());
  const endDate = parse(endTime, "HH:mm", new Date());
  if (startDate > endDate) {
    // return "Invalid Schedule, schedule end time can not be beyon the start time";
    return 0;
  }
  const minutesDifference = differenceInMinutes(endDate, startDate);
  return minutesDifference;
}
