import React, { useState } from "react";
import styled from "styled-components";
import FormHeadings from "../FormHeadings";
import Text from "@lib/microComponents/Text";
import { Formik } from "../../main";
import FormInputWithEuroIcon from "../FormInputWithEuroIcon";
import DatePicker from "@lib/microComponents/DatePicker";
import { FaCalendarDays, FaCircleCheck, FaUpload } from "react-icons/fa6";
import BooleanSelector from "./BooleanSelector";
import {
  AVAILABLE_FROM,
  AVAILABLE_UPTO,
  SELL_INDIVIDUALLY,
  STUDY_GUIDE,
  STUDY_GUIDE_AVAILABLE,
} from "../../validationSchema";
import { format } from "date-fns";
import Modal from "@components/CommonComponents/Modal";
import FileSelectorModal from "./FileSelectorModal";
import FormError from "../FormError";
// styled-components----
const Container = styled.div`
  margin-top: 3.125rem;
`;
const InputsContainer = styled.div`
  display: grid;
  grid-template-columns:
    minmax(0, 1fr)
    minmax(0, 1fr)
    minmax(0, 1fr)
    minmax(0, 1fr);
  column-gap: 1.9375rem;
  margin-top: 1.5625rem;
  row-gap: 1.8125rem;
`;
const CellContainer = styled.div`
  position: relative;
`;
const InputLabel = styled.label``;
const InputContainer = styled.div`
  width: 100%;
  height: 2.9375rem;
  margin-top: 0.625rem;
  border-radius: 10px;
`;
const DatePickerContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
const DatePickerButton = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 1.25rem;
  color: ${(props) => props.theme.colors.gray[600]};
  border-radius: 10px;
  background: ${(props) => props.theme.colors.gray[500]};
`;
const DatePickerStyled = styled(DatePicker)`
  position: absolute;
  z-index: 2000;
`;
const DatePickerText = styled(Text)`
  color: ${(props) => props.theme.colors.gray[600]};
`;
const DatePickerIcon = styled(FaCalendarDays)`
  height: 1.125rem;
  width: 1rem;
  color: ${(props) => props.theme.colors.gray[800]};
  margin-right: 1.125rem;
`;
const FileSelectorButton = styled.button`
  background: none;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;

const FileSelectedContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.blue[700]};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`;

const FileSelectedIcon = styled(FaCircleCheck)`
  height: 1.125rem;
  width: 1.125rem;
  color: #ffffff;
`;

const FileHiddenInput = styled.input`
  opacity: 0;
  height: 0;
  width: 0;
`;

const FileSelectorIcon = styled(FaUpload)`
  height: 1.125rem;
  width: 1.125rem;
  min-height: 1.125rem;
  height: 1.125rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const FileSelectorText = styled.div`
  color: ${(props) => props.theme.colors.gray[600]};
  font-size: 1.125rem;
  line-height: 1.5625rem;
  margin-left: 1rem;
`;
// ---------------------

function KnowledgeGuide({ formik }: { formik: Formik }) {
  const [available, setAvailable] = useState<boolean>(
    formik.getFieldProps(STUDY_GUIDE_AVAILABLE).value
  );
  const [showFromDatePicker, setShowFromDatePicker] = useState<boolean>(false);
  const [showToDatePicker, setShowToDatePicker] = useState<boolean>(false);
  const [showFileModal, setShowFileModal] = useState<boolean>(false);
  return (
    <Container>
      <FormHeadings>Knowlege Guide</FormHeadings>
      <InputsContainer>
        <CellContainer>
          <InputLabel>
            <Text>Available?</Text>
          </InputLabel>
          <InputContainer>
            <BooleanSelector
              formik={formik}
              name="studyGuideAvailable"
              onChange={(e) => setAvailable(e)}
            />
          </InputContainer>
          <FormError name={STUDY_GUIDE_AVAILABLE} formik={formik} />
        </CellContainer>
        {available && (
          <>
            {showFileModal && (
              <Modal
                heading="Upload document"
                handleClose={() => {
                  setShowFileModal(false);
                }}
              >
                <FileSelectorModal
                  closeModal={() => {
                    setShowFileModal(false);
                  }}
                  onFileUploadSuccess={(url) => {
                    formik.setFieldValue(STUDY_GUIDE, url);
                  }}
                  formik={formik}
                />
              </Modal>
            )}
            <CellContainer>
              <FormInputWithEuroIcon
                id="guidePrice"
                name="guidePrice"
                placeholder="Type here..."
                formik={formik}
                min={0}
                label="Price"
                disabled={!available}
              ></FormInputWithEuroIcon>
            </CellContainer>
            <CellContainer>
              <InputLabel>
                <Text>Upload document</Text>
              </InputLabel>
              <InputContainer>
                <FileSelectorButton
                  onClick={() => {
                    setShowFileModal((value) => !value);
                  }}
                  type="button"
                >
                  {!formik.getFieldProps(STUDY_GUIDE).value ? (
                    <>
                      <FileSelectorIcon />
                      <FileSelectorText>
                        Select file(max 100MB)
                      </FileSelectorText>
                    </>
                  ) : (
                    <FileSelectedContainer>
                      <FileSelectedIcon />
                    </FileSelectedContainer>
                  )}
                </FileSelectorButton>
                <FormError name={STUDY_GUIDE} formik={formik} />
              </InputContainer>
            </CellContainer>
            <div className="placeholder for empty elements in grid"></div>
            <CellContainer>
              <InputLabel>
                <Text>Available From</Text>
              </InputLabel>
              <InputContainer>
                <DatePickerContainer>
                  <DatePickerButton
                    type="button"
                    onClick={() => {
                      setShowFromDatePicker(!showFromDatePicker);
                    }}
                    disabled={!available}
                  >
                    <DatePickerIcon />
                    <DatePickerText>
                      {" "}
                      {formik.getFieldProps(AVAILABLE_FROM).value
                        ? format(
                            formik.getFieldProps(AVAILABLE_FROM).value,
                            "PP"
                          )
                        : "Select a date"}
                    </DatePickerText>
                  </DatePickerButton>
                  {available && showFromDatePicker && (
                    <DatePickerStyled
                      value={formik.getFieldProps(AVAILABLE_FROM).value}
                      setShow={setShowFromDatePicker}
                      onChange={(e) => {
                        formik.setFieldValue(AVAILABLE_FROM, e);
                      }}
                    />
                  )}
                </DatePickerContainer>
              </InputContainer>
              <FormError name={AVAILABLE_FROM} formik={formik} />
            </CellContainer>
            <CellContainer>
              <InputLabel>
                <Text>Available Until</Text>
              </InputLabel>
              <InputContainer>
                <DatePickerContainer>
                  <DatePickerButton
                    type="button"
                    onClick={() => {
                      setShowToDatePicker(!showToDatePicker);
                    }}
                    disabled={!available}
                  >
                    <DatePickerIcon />
                    <DatePickerText>
                      {formik.getFieldProps(AVAILABLE_UPTO).value
                        ? format(
                            formik.getFieldProps(AVAILABLE_UPTO).value,
                            "PP"
                          )
                        : "Select a date"}
                    </DatePickerText>
                  </DatePickerButton>
                  {available && showToDatePicker && (
                    <DatePickerStyled
                      value={formik.getFieldProps(AVAILABLE_UPTO).value}
                      setShow={setShowToDatePicker}
                      onChange={(e) => {
                        formik.setFieldValue(AVAILABLE_UPTO, e);
                      }}
                    />
                  )}
                </DatePickerContainer>
              </InputContainer>
              <FormError name={AVAILABLE_UPTO} formik={formik} />
            </CellContainer>
            <CellContainer>
              <InputLabel>
                <Text>Sell Individually?</Text>
              </InputLabel>
              <InputContainer>
                <BooleanSelector
                  disabled={!available}
                  formik={formik}
                  name="sell-individually"
                />
              </InputContainer>
              <FormError name={SELL_INDIVIDUALLY} formik={formik} />
            </CellContainer>
          </>
        )}
      </InputsContainer>
    </Container>
  );
}

export default KnowledgeGuide;
