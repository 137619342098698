import { AdminSubjectView } from "@components/AdminDashboard/SubPages/Settings/SubPages/UniversitiesStudiesAndSubjects/SubPages/Subjects/main";
import {
  AdminStudy,
  AdminSubject,
} from "@lib/Types/AdminData/AdminEntitiyList";

export const mapAdminSubjectListToComponents = function (
  items: AdminSubject[]
): AdminSubjectView[] {
  const mappedItems: AdminSubjectView[] = items.map((item) => ({
    id: item.subjectId.toString(),
    value: item.subjectName,
    logo: item.universityLogoUrl,
  }));
  return mappedItems;
};
