import styled, { keyframes } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { device } from "@styles/theme";
import { useState } from "react";
import Modal from "@components/CommonComponents/Modal";
import ChangePasswordForm from "./ChangePaswordForm";
import { useTranslation } from "react-i18next";
import EditPictureForm from "./EditPictureForm";
import { RootState } from "@app/redux/store";
import { UserRole, userInfo, userSlice } from "@app/redux/slices/userSlice";
import { FaPenToSquare } from "react-icons/fa6";
import { useFormik } from "formik";
import { updateProfile } from "@API/services/StudentCrud/updateProfile";
import { ReactToast } from "@lib/toast";
import { getUserProfile } from "@API/services/Data/getUserProfile";
import { object, string } from "yup";

// styled-components ----
const Container = styled.div`
  margin-top: -1.875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.5625rem;
  padding-bottom: 3.75rem;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray[500]};
  gap: 1.375rem;
  flex-wrap: wrap;
  @media ${device.tablet} {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    margin-top: -1.4375rem;
    padding-bottom: 1.8125rem;
  }
`;
const ProfilePicAndNameContainer = styled.div`
  display: flex;
`;
const ProfilePicContainer = styled.div`
  position: relative;
  height: 10.3125rem;
  width: 10.3125rem;
  @media ${device.tablet} {
    height: 5.5625rem;
    width: 5.5625rem;
  }
`;
const ProfilePic = styled.img`
  height: 10.3125rem;
  width: 10.3125rem;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  @media ${device.tablet} {
    height: 5.5625rem;
    width: 5.5625rem;
  }
`;
const StudentUniversityImage = styled.img`
  height: 2.75rem;
  width: 2.75rem;
  position: absolute;
  bottom: 0;
  right: 0;
  @media ${device.tablet} {
    height: 1.4375rem;
    width: 1.4375rem;
  }
`;
export const ChangePasswordButton = styled.button`
  width: 13.875rem;
  height: 2.875rem;
  border-radius: 10px;
  background: ${(props) => props.theme.colors.gray[500]};
  font-size: 1.125rem;
  color: ${(props) => props.theme.colors.gray[800]};
  @media ${device.tabletS} {
    height: 2.75rem;
    width: 100%;
    font-size: 16px;
  }
`;
const StudentNameAndProfilePicButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2.875rem;
  font-size: 0;
  @media ${device.tablet} {
    justify-content: flex-end;
    margin-left: 1.625rem;
  }
`;
const StudentNameContainer = styled.div``;
const StudentName = styled.div`
  white-space: nowrap;
  display: flex;
  align-items: center;
  line-height: 1.9375rem;
  font-size: 1.375rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.gray[800]};
  @media ${device.tablet} {
    font-size: 1.125rem;
    line-height: 1.5625rem;
  }
`;
const StudentNameEditFormContainer = styled.div`
  gap: 0.75rem;
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
`;
const EditNameButton = styled.button`
  height: 0.9375rem;
  width: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  background: none;
`;
const StudentNameInputContainer = styled.div`
  position: relative;
`;
const StudentNameInput = styled.input`
  border-radius: 10px;
  height: 2rem;
  outline: none;
  padding: 0rem 1rem;
  font-size: 14px;
  border-radius: 3.2px;
  background: ${(props) => props.theme.colors.gray[500]};
  font-family: ${(props) => props.theme.font.font};
  color: ${(props) => props.theme.colors.gray[800]};
  &::placeholder {
    font-family: ${(props) => props.theme.font.font};
    color: ${(props) => props.theme.colors.gray[600]};
  }
  width: 10rem;
`;
const ButtonsContainer = styled.div``;
interface SaveButtonProps {
  $loading: boolean;
}
const SaveButton = styled.button<SaveButtonProps>`
  font-size: 0.875rem;
  border-radius: 5px;
  font-weight: 700;
  color: #ffffff;
  background: ${(props) => props.theme.colors.green[600]};
  @media ${device.tablet} {
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
  }
`;
const CancelButton = styled.button`
  font-size: 0.875rem;
  margin-left: 1rem;
  border-radius: 5px;
  font-weight: 700;
  color: #ffffff;
  background: ${(props) => props.theme.colors.red[500]};
  @media ${device.tablet} {
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
    margin-left: 0.625rem;
  }
`;
const StudentNameEditIcon = styled(FaPenToSquare)`
  width: 0.75rem;
  height: 0.8125rem;
  min-width: 0.75rem;
  min-height: 0.8125rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const EditPictureButton = styled.button`
  text-align: left;
  text-decoration: underline;
  line-height: 1.4375rem;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.gray[600]};
  background: none;
  margin: 0;
  padding: 0;
  margin-top: 0.3125rem;
  border: none;
  @media ${device.tablet} {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-top: 0.125rem;
  }
`;

const Error = styled.div`
  font-size: 0.625rem;
  line-height: 0.75rem;
  color: ${(props) => props.theme.colors.red[500]};
  position: absolute;
  top: 2rem;
  left: 0;
`;
const Input = styled.input``;
// ----------------------
const FIRST_NAME = "first-name";
const LAST_NAME = "last-name";

function ProfilePicAndChangePassword({
  loading,
  userInfo,
}: {
  loading: boolean;
  userInfo: userInfo;
}) {
  const userRole = useSelector((state: RootState) => state.userInfo.role);
  const profile = useSelector(selectUser);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showEditPictureModal, setEditPictureModal] = useState<boolean>(false);
  const [nameChangeActive, setNameChangeActive] = useState<boolean>(false);
  const [savingName, setSavingname] = useState<boolean>(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      [FIRST_NAME]: "",
      [LAST_NAME]: "",
    },
    validationSchema: object({
      [FIRST_NAME]: string().required("required"),
      [LAST_NAME]: string().required("required"),
    }),
    onSubmit: async (data) => {
      setSavingname(true);
      const res = await updateProfile(
        { name: data[FIRST_NAME] + " " + data[LAST_NAME] },
        userInfo.role
      );
      if (res.success) {
        ReactToast.success("Name Update Successfull");
        const userInfo = await getUserProfile();
        if (userInfo.success) {
          dispatch(userSlice.actions.setUserInfo(userInfo.data));
        }
        setNameChangeActive(false);
      } else {
        ReactToast("Name could not be updated");
      }
      setSavingname(false);
    },
  });
  const { t } = useTranslation();

  return (
    <Container>
      {showModal && (
        <Modal
          heading={t("sdashboard.profile.changepassword.form.heading")}
          handleClose={() => {
            setShowModal(false);
          }}
        >
          <ChangePasswordForm
            handleClose={() => {
              setShowModal(false);
            }}
          />
        </Modal>
      )}
      {showEditPictureModal && (
        <Modal
          heading={t("sdashboard.profile.editpic.modal.heading")}
          handleClose={() => {
            setEditPictureModal(false);
          }}
        >
          <EditPictureForm
            handleClose={() => {
              setEditPictureModal(false);
            }}
          />
        </Modal>
      )}
      <ProfilePicAndNameContainer>
        <ProfilePicContainer>
          <ProfilePic
            key={userInfo.key ? userInfo.key : new Date().getTime()}
            src={userInfo.profileUrl}
          />
          {userInfo.role === UserRole.STUDENT && userInfo.university && (
            <StudentUniversityImage
              src={
                userInfo.univversityLogo
                  ? userInfo.univversityLogo
                  : "/assets/defaultuniversityicon.png"
              }
            />
          )}
        </ProfilePicContainer>
        <StudentNameAndProfilePicButtonContainer>
          <StudentNameContainer>
            {nameChangeActive ? (
              <StudentNameEditFormContainer>
                <StudentNameInputContainer>
                  <StudentNameInput
                    placeholder="First name"
                    {...formik.getFieldProps(FIRST_NAME)}
                  ></StudentNameInput>
                  {formik.errors[FIRST_NAME] && formik.touched[FIRST_NAME] && (
                    <Error>{formik.errors[FIRST_NAME]}</Error>
                  )}
                </StudentNameInputContainer>
                <StudentNameInputContainer>
                  <StudentNameInput
                    placeholder="Last name"
                    {...formik.getFieldProps(LAST_NAME)}
                  />
                  {formik.errors[LAST_NAME] && formik.touched[LAST_NAME] && (
                    <Error>{formik.errors[LAST_NAME]}</Error>
                  )}
                </StudentNameInputContainer>
                {formik.errors[LAST_NAME] && formik.touched[LAST_NAME] && (
                  <Error>{formik.errors[LAST_NAME]}</Error>
                )}
                <ButtonsContainer>
                  <SaveButton
                    $loading={savingName}
                    disabled={savingName}
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                  >
                    Save
                  </SaveButton>
                  <CancelButton
                    onClick={() => {
                      setNameChangeActive(false);
                    }}
                  >
                    Cancel
                  </CancelButton>
                </ButtonsContainer>
              </StudentNameEditFormContainer>
            ) : (
              <StudentName>
                {userInfo.name}
                {userInfo.userId === profile.userId && (
                  <EditNameButton
                    onClick={() => {
                      setNameChangeActive(true);
                    }}
                  >
                    <StudentNameEditIcon />
                  </EditNameButton>
                )}
              </StudentName>
            )}
          </StudentNameContainer>
          {profile.userId === userInfo.userId && (
            <EditPictureButton
              onClick={() => {
                setEditPictureModal(true);
              }}
            >
              {t("sdashboard.profile.editpic.buttontext")}
            </EditPictureButton>
          )}
        </StudentNameAndProfilePicButtonContainer>
      </ProfilePicAndNameContainer>
      {profile.userId === userInfo.userId && (
        <ChangePasswordButton
          onClick={() => {
            setShowModal(true);
          }}
          type="button"
        >
          {t("sdashboard.profile.changepassword.button_heading")}
        </ChangePasswordButton>
      )}
    </Container>
  );
}

export default ProfilePicAndChangePassword;
