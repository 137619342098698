import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";

type response =
  | {
      success: true;
      data: boolean;
    }
  | {
      success: false;
      data: null;
    };

export const getRefund = async (id: string | undefined): Promise<response> => {
  const url = urlFunctions.getRefund(id);
  const response = await API.sendGetRequest(url, true);
  if (response.success) {
    const data = response.data;
    return {
      success: true,
      data: data,
    };
  }
  return {
    success: false,
    data: null,
  };
};
