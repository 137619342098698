import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";
import { APIUserInfo } from "@lib/Types/API/APIuserinfo";

type response =
  | {
      success: true;
      data: APIUserInfo;
    }
  | {
      success: false;
      data: string;
    };

export const getUserDetails = async function (
  userid: string
): Promise<response> {
  const url = urlFunctions.getUserDetails(userid);
  const res = await API.sendGetRequest(url);
  if (res.success) {
    const data: APIUserInfo = res.data;
    return {
      success: true,
      data: data,
    };
  } else {
    return {
      success: false,
      data: "There was an error in getting university list",
    };
  }
};
