import { selectSettings } from "@app/redux/publicSelectors/settingsSelector";
import { UserRole } from "@app/redux/slices/userSlice";
import {
  getLanguageFromLocaleString,
  getLanguageInfo,
} from "@lib/Enums/Language";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export const useRedirectUri = (
  role: UserRole | undefined
): [string, (role: UserRole) => string] => {
  const params = useParams();
  const settings = useSelector(selectSettings);
  const lang = settings.language
    ? getLanguageInfo(settings.language).locale
    : "en";
  function redirect(role: UserRole) {
    return uriEnum[role];
  }
  const uriEnum = {
    [UserRole.ADMIN]: `/${lang}/admin-dashboard`,
    [UserRole.STUDENT]: `/${lang}/dashboard`,
    [UserRole.TEAHCER]: `/${lang}/teacher-dashboard`,
    [UserRole.MANAGER]: `/${lang}/admin-dashboard`,
  };
  if (!role) return ["", redirect];
  return [uriEnum[role], redirect];
};
