import React, { useState } from "react";
import { Helmet } from "react-helmet";

function ScaleDownForAdminDashBoard() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const WIDTH = 1920;
  const diff = WIDTH - windowWidth;
  let scale: number;
  if (diff >= 0) {
    scale = windowWidth / WIDTH;
  } else {
    scale = windowWidth / WIDTH + 1;
  }
  return (
    <Helmet>
      {/* <meta
        name="viewport"
        content={`width=device-width, initial-scale=${scale}`}
      /> */}
    </Helmet>
  );
}

export default ScaleDownForAdminDashBoard;
