import React from "react";
import styled from "styled-components";
import Vector1 from "@images/vector1.svg";
import { Link, useParams } from "react-router-dom";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { useSelector } from "react-redux";
import { device } from "@styles/theme";
// styled-components----
const GreetingsContainer = styled.div`
  background: transparent linear-gradient(90deg, #4883c4 0%, #1b67bc 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 20px;
  padding: 1.71875rem 3.125rem;
  display: flex;
  position: relative;
  @media ${device.laptopM} {
    padding: 1.25rem;
    border-radius: 10px;
  }
`;
const HeadingsContainer = styled.div``;

const Heading = styled.div`
  font-size: 2rem;
  line-height: 2.8125rem;
  font-weight: 700;
  color: #ffffff;
  @media ${device.laptopM} {
    font-size: 1.125rem;
    line-height: 1.5625rem;
  }
`;
const LinksContainer = styled.div`
  display: flex;
  margin-top: 0.6875rem;
  gap: 1.25rem;
  @media ${device.tablet} {
    gap: 10px;
    flex-direction: column;
  }
`;
const Vector = styled.img`
  height: 9.375rem;
  position: absolute;
  bottom: 0;
  right: 8.875rem;
  @media ${device.laptopM} {
    right: 6.25rem;
  }
  @media ${device.laptopM} {
    right: 1rem;
    height: 6.25rem;
  }
  @media ${device.mobileL} {
    display: none;
  }
`;

const SignUpLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.875rem;
  background: ${(props) => props.theme.colors.green[600]};
  font-size: 1.125rem;
  line-height: 1.5625rem;
  border-radius: 12px;
  font-weight: 700;
  color: #ffffff;
  @media ${device.laptopM} {
    max-width: 14.3125rem;
    max-width: 180px;
    padding: 0.375rem 0.625rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-radius: 10px;
  }
`;
const SignInLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.875rem;
  background: #ffffff26;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  border-radius: 12px;
  color: #ffffff;
  max-width: 7.5rem;
  @media ${device.laptopM} {
    padding: 0.375rem 1.25rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-radius: 10px;
  }
`;
//----------------------

function GreetingsComponent() {
  const userInfo = useSelector(selectUser);
  const { lang } = useParams();
  return !userInfo.loggedIn ? (
    <GreetingsContainer>
      <HeadingsContainer>
        <Heading>Create a student account</Heading>
        <LinksContainer>
          <SignUpLink to={`/${lang}/signup`}>Create an account</SignUpLink>
          <SignInLink to={`/${lang}/signin`}>Sign in</SignInLink>
        </LinksContainer>
      </HeadingsContainer>
      <Vector src={Vector1} />
    </GreetingsContainer>
  ) : (
    <></>
  );
}

export default GreetingsComponent;
