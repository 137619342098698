import React from "react";
// import "react-phone-number-input/style.css";
import PhoneNumber, { isValidPhoneNumber } from "react-phone-number-input";
import "./style.css";
function PhoneNumberInput({
  onChange,
  value,
  ...props
}: {
  value: string;
  onChange: (e: string | undefined) => void;
}) {
  return (
    <PhoneNumber
      value={value}
      onChange={(e) => {
        onChange(e);
      }}
      {...props}
    />
  );
}

export default PhoneNumberInput;
