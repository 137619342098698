import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";
import { mapStudentProfileToAPI } from "@lib/mapperFunctions/mapStudentProflieToAPI";
import { UniversityAndStudyFormType } from "@components/StudentDashBoardSubPages/StudentProfile/StudyAndUniversityInfo";
import { mapStudentUniversityAndStudyToAPI } from "@lib/mapperFunctions/mapStudentUniversityAndStudyToAPI";

type updateStudentUniversityAndStudyResponseType =
  | {
      success: true;
      data: any;
    }
  | {
      success: false;
      data: string;
    };

export const updateStudentUniversityAndStudy = async function (
  data: UniversityAndStudyFormType
): Promise<updateStudentUniversityAndStudyResponseType> {
  const formattedData = mapStudentUniversityAndStudyToAPI(data);
  const url = urlFunctions.updateStudentUniversityAndStudy();
  const res = await API.sendPutRequest(url, formattedData, true);
  if (res.success) {
    return {
      success: true,
      data: null,
    };
  } else {
    return {
      success: false,
      data: "Could not update profile try again",
    };
  }
};
