import { AddCourseManagerListOptionType } from "@components/AdminDashboard/SubPages/Courses/AddCourse/Components/GeneralDetails/ManagerSelector";
import { ManagerData } from "@components/AdminDashboard/SubPages/Settings/SubPages/AdminAndManagers/AdminManagers";
import { assetsUrls } from "@styles/theme";

export const mapManagerResponseAddCourseManagerListOptionType = (
  data: ManagerData
): AddCourseManagerListOptionType => {
  return {
    id: data.managerId,
    value: data.firstName + " " + data.LastName,
    profilePicUrl: data.profilePicUrl ? data.profilePicUrl : assetsUrls.userPng,
  };
};
