import { UserRole } from "@app/redux/slices/userSlice";
import hash_sum from "hash-sum";
import { css, keyframes } from "styled-components";

export const colors = {
  gray: {
    400: "#F2F5F8",
    500: "#E6EBF0",
    600: "#A8BACE",
    700: "#A0A0A0",
    800: "#707070",
  },
  blue: {
    700: "#4883C4",
    800: "#226CBE",
  },
  green: {
    600: "#36CC1C",
  },
  yellow: {
    500: "#EABC5C",
    600: "#FFB100",
  },
  red: {
    500: "#E34D4D",
  },
};

const font = {
  font: "'Poppins', sans-serif",
};
export const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tabletS: "575px",
  tablet: "768px",
  laptopS: "900px",
  laptop: "1024px",
  laptopM: "1180px",
  laptopL: "1440px",
  desktopXS: "1700px",
  desktopS: "1920px",
  desktop: "2560px",
};
export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tabletS: `(max-width: ${size.tabletS})`,
  tablet: `(max-width: ${size.tablet})`,
  laptopS: `(max-width: ${size.laptopS})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopM: `(max-width: ${size.laptopM})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktopXS: `(max-width: ${size.desktopXS})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
};
export default { colors, font };

export const mixin = {
  skeletonLoading() {
    const skeletonLoadingAnimation = keyframes`
    0% {
      background: hsl(200,20%,70%);
    }
    100% {
      background: hsl(200,20%,95%)
    }
  `;
    return css`
      animation: ${skeletonLoadingAnimation} 1s linear infinite alternate;
    `;
  },
  customScroll: () => css`
    &::-webkit-scrollbar {
      width: 0.4rem;
      height: 1px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: ${colors.gray[400]};
    }
  `,
  hideScroll: () => css`
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
    }
  `,
  shadeOfBlue: (id: string, name: string, role: UserRole) => {
    const hash = hash_sum(`${id}${name}${role}`);
    return `#00${hash.slice(0, 2)}ff`;
  },
};

export const assetsUrls = {
  userPng: "/assets/user.png",
  userPng2: "/assets/user2.png",
  goal: "/assets/goal_vector.png",
  guide: "/assets/book.png",
  loginPagePng: "/assets/login-page.png",
  about: "/assets/about_us.png",
  masterclass_checkout: "/assets/masterclass_checkout_png.jpg",
};
