import React from "react";
import styled from "styled-components";
import EmptyFolderImage from "@images/folder.jpg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// styled.components ----
const Container = styled.div`
  width: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  height: 21rem;
  flex-direction: column;
`;
const Image = styled.img`
  height: 10.6875rem;
  width: 10.6875rem;
`;
const Heading = styled.div`
  font-size: 1.375rem;
  line-height: 1.9375rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const Button = styled(Link)`
  padding: 0.5625rem 2.4375rem;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  border-radius: 10px;
  background: ${(props) => props.theme.colors.gray[500]};
  color: ${(props) => props.theme.colors.gray[800]};
  margin-top: 0.6875rem;
`;
// ----------------------
function EmptyComponent1({
  to,
  buttonHidden,
}: {
  to: string;
  buttonHidden?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <Container>
      <Image src={EmptyFolderImage} />
      <Heading>{t("sdashboard.empty.non_enrolled.heading")}</Heading>
      {!buttonHidden && (
        <Button to={to}>{t("sdashboard.empty.non_enrolled.button")}</Button>
      )}
    </Container>
  );
}

export default EmptyComponent1;
