import {
  Table,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableDataCell,
} from "@lib/microComponents/Table";
import { StudentDataType } from "@lib/testData/mockStudentsData";
import { ordersData } from "./AdminOrders";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Dispatch, SetStateAction, useCallback, useRef } from "react";

// styled-components----
const Container = styled.div`
  background: #ffffff;
  border-radius: 20px;
  padding: 1.875rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const ProsuctTableCell = styled(TableDataCell)`
  font-weight: 700;
`;
const NameTableCell = styled(TableDataCell)`
  font-weight: 700;
`;
interface OrderStatus {
  $name: string;
}
const UniversityTableCell = styled(TableDataCell)``;
const StudyTableCell = styled(TableDataCell)``;
const DetailsTableCell = styled(TableDataCell)``;
export const DetailsLink = styled(Link)<OrderStatus>`
  height: 2.1875rem;
  padding: 0 3.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.gray[800]};
  border-radius: 10px;
  background: ${(props) =>
    props.$name == "COMPLETED" ? "#36CC1C26" : props.theme.colors.gray[500]};
`;
const skeletonLoading = keyframes`
  0% {
    background: hsl(200,20%,70%);
  }
  100% {
    background: hsl(200,20%,95%)
  }
`;
const LoadingComponent = styled.div`
  width: 100%;
  height: 2.1875rem;
  animation: ${skeletonLoading} 1s linear infinite alternate;
`;
// ---------------------

function TableSkeleton() {
  return (
    <TableRow>
      <ProsuctTableCell>
        <LoadingComponent>&nbsp;</LoadingComponent>
      </ProsuctTableCell>
      <NameTableCell>
        <LoadingComponent>&nbsp;</LoadingComponent>
      </NameTableCell>
      <UniversityTableCell>
        <LoadingComponent>&nbsp;</LoadingComponent>
      </UniversityTableCell>
      <StudyTableCell>
        <LoadingComponent>&nbsp;</LoadingComponent>
      </StudyTableCell>
      <DetailsTableCell>
        <LoadingComponent>&nbsp;</LoadingComponent>
      </DetailsTableCell>
    </TableRow>
  );
}

function StudentTable({
  items,
  loading,
  totalPages,
  pageNumber,
  setPageNumber,
  ...props
}: {
  items: ordersData[];
  totalPages: number;
  pageNumber: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
  loading: boolean;
}) {
  const { t } = useTranslation();
  const { lang } = useParams();
  const observer = useRef<IntersectionObserver>();
  const lastBookElementRef = useCallback(
    (node: HTMLTableRowElement) => {
      if (loading) return;
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (pageNumber < totalPages - 1) {
            setPageNumber((value) => value + 1);
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, setPageNumber]
  );
  return (
    <Container {...props}>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>
              {t("sdashboard.orders.table.product")}
            </TableHeaderCell>
            {items.length > 0 && items[0].name && (
              <TableHeaderCell> {t("adminOrders.name")}</TableHeaderCell>
            )}

            <TableHeaderCell>
              {t("sdashboard.orders.table.number")}
            </TableHeaderCell>
            <TableHeaderCell>
              {t("sdashboard.orders.table.date")}
            </TableHeaderCell>
            <TableHeaderCell>
              {t("sdashboard.orders.table.amount")}
            </TableHeaderCell>
            <TableHeaderCell>
              {t("sdashboard.orders.table.status")}
            </TableHeaderCell>
            <TableHeaderCell>
              <div></div>
            </TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {items.map((e, index) => {
            if (index === items.length - 1) {
              return (
                <TableRow
                  key={`${index}.${e.akcOrderId}`}
                  ref={lastBookElementRef}
                >
                  <ProsuctTableCell>
                    {e.subject} - {e.study} -{e.language}
                  </ProsuctTableCell>
                  {e.name && <NameTableCell>{e.name}</NameTableCell>}
                  <UniversityTableCell>{e.akcOrderId}</UniversityTableCell>
                  <StudyTableCell>{e.Date}</StudyTableCell>
                  <StudyTableCell>{e.amount}</StudyTableCell>
                  <DetailsTableCell>
                    <DetailsLink to={`${e.OrderId}`} $name={e.Status}>
                      {e.Status}
                    </DetailsLink>
                  </DetailsTableCell>
                </TableRow>
              );
            } else {
              return (
                <TableRow key={`${index}.${e.akcOrderId}`}>
                  <ProsuctTableCell>
                    {e.subject} - {e.study} -{e.language}
                  </ProsuctTableCell>
                  {e.name && <NameTableCell>{e.name}</NameTableCell>}
                  <UniversityTableCell>{e.akcOrderId}</UniversityTableCell>
                  <StudyTableCell>{e.Date}</StudyTableCell>
                  <StudyTableCell>{e.amount}</StudyTableCell>
                  <DetailsTableCell>
                    <DetailsLink to={`${e.OrderId}`} $name={e.Status}>
                      {e.Status}
                    </DetailsLink>
                  </DetailsTableCell>
                </TableRow>
              );
            }
          })}
          {loading &&
            Array.from({ length: 10 }).map((e, index) => (
              <TableSkeleton key={index} />
            ))}
        </TableBody>
      </Table>
    </Container>
  );
}
export default StudentTable;
