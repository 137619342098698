import { TeacherCourseDetailsType } from "@components/TeachersDashboard/TeacherComponents/TeachersCourseDetails/TeachersCourseDetails";
import { APITeacherCourseDetails } from "@lib/Types/API/Teacher/APITeacherCourseDetails";
import { format, parse } from "date-fns";
const parseTime = (time: string): string => {
  const parsedTime = parse(time, "HH:mm:ss", new Date());
  const formattedTime = format(parsedTime, "HH:mm");
  return formattedTime;
};
export const mapAPITeacherCourseDetailsToComponent = (
  data: APITeacherCourseDetails
): TeacherCourseDetailsType => {
  const mappedData: TeacherCourseDetailsType = {
    id: data.courseId,
    // image: SampleUniversityImage,
    // university: data.courseUniversity.universityName,
    university: {
      id: data.courseUniversity.id.toString(),
      universityName: data.courseUniversity.universityName,
      universityLogoUrl: data.courseUniversity.universityLogoUrl,
    },
    study: data.courseStudy,
    subject: data.courseSubject,
    major: data.courseSubject,
    type: data.courseType,
    language: data.courseLanguage,
    lessions: data.schedule.map((lession) => ({
      date: lession.date,
      endTime: parseTime(lession["end-time"]),
      id: lession.id,
      linkToJoin: lession["link-to-join"],
      title: lession.name,
      startTime: parseTime(lession["start-time"]),
    })),
    StudyMaterials: {
      available: data.studyMaterials
        ? data.studyMaterials.available
          ? true
          : false
        : false,
      AvailableFrom: data.studyMaterials
        ? data.studyMaterials["available-from"]
        : null,
      AvailableUntil: data.studyMaterials
        ? data.studyMaterials["available-until"]
        : null,
      SellIndividually: data.studyMaterials
        ? data.studyMaterials["sell-individually"]
          ? data.studyMaterials["sell-individually"]
          : false
        : false,
      file: data.studyMaterials
        ? data.studyMaterials.file
          ? data.studyMaterials.file
          : []
        : [],
      price: data.studyMaterials
        ? data.studyMaterials.price
          ? data.studyMaterials.price
          : 0
        : 0,
    },
    applications: data.applications,
    enrolledForGuideOnly: false,
  };
  return mappedData;
};
