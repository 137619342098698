enum FileType {
  DOCUMENT = "DOCUMENT",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}
export type typeOfFile = "image" | "document" | "video" | "both" | "all";

export function getMimeType(type: FileType) {
  switch (type) {
    case FileType.DOCUMENT:
      return "";
  }
}

const baseExtensions = {
  image: ["png", "jpg", "jpeg", "svg"],
  document: ["pdf", "docx"],
  video: ["mp4", "mpeg"],
};

export const extensions = {
  ...baseExtensions,
  both: [...baseExtensions.image, ...baseExtensions.document],
  all: [
    ...baseExtensions.image,
    ...baseExtensions.document,
    ...baseExtensions.video,
  ],
};
