import { ReactNode } from "react";
import styled from "styled-components";

// styled-components ----
const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000040;
  z-index: 2000;
`;
// ---------------------
function Backdrop({ children }: { children: ReactNode }) {
  return <Container>{children}</Container>;
}

export default Backdrop;
