import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../../../../commonComponents/Header";
import AddButton from "@components/AdminDashboard/SubPages/Settings/commonComponents/AddButton";
import ViewComponent, { DataText } from "../common/ViewComponent";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAdminStudyOption } from "@lib/hooks/useAdminStudyOptions";
import Modal from "@components/CommonComponents/Modal";
import AddStudyForm from "./AddStudyForm";
// styled-components ----
const Container = styled.div``;
import { useTranslation } from "react-i18next";
import LoadingComponent from "../LoadingComponent";
import ConfirmationPopup from "@components/CommonComponents/ConfirmationPopup";
import { DeleteOverlay } from "../common/DeleteOverlay";
import { LoadingSpinner } from "@lib/microComponents/LoadingSpinner";
import { deleteAStudyService } from "@API/services/EntitiesCrud/deleteEntities";
import { ReactToast } from "@lib/toast";
// ----------------------

export interface AdminStudyView {
  id: string;
  value: string;
  numberOfSubjects: number;
  logo: string;
}
interface studyEditInfo {
  studyName: string;
  studyId: string;
}
const StudiesContainer = styled.div``;
const StudyComponentContainer = styled.div`
  position: relative;
  user-select: none;
`;

function Studies() {
  const [studies, setStudies] = useState<AdminStudyView[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeletePopup, setShowDeletePopup] = useState<
    AdminStudyView | undefined
  >();
  const [deleting, setDeleting] = useState<string>();
  const [showEditForm, setShowEditForm] = useState<studyEditInfo>();
  const navigate = useNavigate();
  const { id } = useParams();
  async function getStudiesList() {
    setLoading(true);
    if (id) {
      const options = await useAdminStudyOption(id);
      setStudies(options);
    }
    setLoading(false);
  }
  useEffect(() => {
    getStudiesList();
  }, []);
  async function deleteAStudy(studyId: string, studyName: string) {
    const res = await deleteAStudyService(studyId);
    if (res.success) {
      ReactToast.success(`${studyName} deleted`);
      getStudiesList();
    } else {
      ReactToast.error(`study ${studyName} could not be deleted`);
    }
    setShowDeletePopup(undefined);
    setDeleting(undefined);
  }
  const { t } = useTranslation();
  return (
    <Container>
      {showModal && (
        <Modal
          handleClose={() => {
            setShowModal(false);
          }}
          heading={t(
            "adminSettings.universityStudySubjectPage.studiesPage.addNewStudy"
          )}
        >
          {id && (
            <AddStudyForm
              onCreate={() => {
                setStudies([]);
                getStudiesList();
                setShowModal(false);
              }}
              universityId={id}
            />
          )}
        </Modal>
      )}
      {showDeletePopup && (
        <ConfirmationPopup
          text={`Are you sure you want to delete study '${showDeletePopup.value}'`}
          handleClose={() => {
            setShowDeletePopup(undefined);
          }}
          onConfirmation={() => {
            setDeleting(showDeletePopup.id);
            deleteAStudy(showDeletePopup.id, showDeletePopup.value);
          }}
        />
      )}
      {showEditForm && (
        <Modal
          handleClose={() => {
            setShowEditForm(undefined);
          }}
          heading="Edit Study"
        >
          {id && (
            <AddStudyForm
              onCreate={() => {
                setStudies([]);
                getStudiesList();
                setShowEditForm(undefined);
              }}
              edit={{
                studyId: showEditForm.studyId,
                studyName: showEditForm.studyName,
              }}
              universityId={id}
            />
          )}
        </Modal>
      )}
      <Header
        text={t("adminSettings.universityStudySubjectPage.Studies")}
        searchText={t(
          "adminSettings.universityStudySubjectPage.studiesPage.searchInStudies"
        )}
      >
        <AddButton
          onClick={() => {
            setShowModal(true);
          }}
        >
          {t("adminSettings.universityStudySubjectPage.studiesPage.addStudy")}
        </AddButton>
      </Header>
      <StudiesContainer>
        {!loading
          ? studies.map((study) => {
              return (
                <StudyComponentContainer
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    navigate(`${study.id}/subjects`);
                  }}
                >
                  {deleting === study.id && (
                    <DeleteOverlay>
                      <LoadingSpinner />
                    </DeleteOverlay>
                  )}
                  <ViewComponent
                    onClickDelete={() => {
                      if (deleting) return;
                      setShowDeletePopup(study);
                    }}
                    imageUrl={
                      study.logo
                        ? study.logo
                        : "/assets/defaultuniversityicon.png"
                    }
                    university={false}
                    name={study.value}
                    onClickEdit={(e) => {
                      e.stopPropagation();
                      setShowEditForm({
                        studyId: study.id,
                        studyName: study.value,
                      });
                    }}
                    arrowVisible={true}
                  >
                    <DataText
                      number={study.numberOfSubjects}
                      text={t(
                        "adminSettings.universityStudySubjectPage.subjects"
                      )}
                    />
                  </ViewComponent>
                </StudyComponentContainer>
              );
            })
          : Array.from({ length: 10 }).map(() => <LoadingComponent />)}
      </StudiesContainer>
    </Container>
  );
}

export default Studies;
