import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";
import { ScheduleItemType } from "@components/AdminDashboard/SubPages/Courses/AddCourse/formInitialValues";
import {
  TeacherScheduleEditFormType,
  TeacherScheduleEditResponse,
} from "@lib/Types/API/Teacher/APITeacherScheduleEditFormType";
import { mapScheduleItemToAPI } from "@lib/mapperFunctions/Teachers/mapScheduleItemToAPI";
type TeacherScheduleEditFormTypeResponse =
  | {
      success: true;
      data: TeacherScheduleEditResponse;
    }
  | {
      success: false;
      data: string;
    };
export const editScheduleByTeacher = async function (
  courseId: string,
  data: ScheduleItemType[]
): Promise<TeacherScheduleEditFormTypeResponse> {
  const url = urlFunctions.editScheduleByTeacher(courseId);
  const postData = mapScheduleItemToAPI(data);
  const res = await API.sendPostRequest(url, postData, true);
  if (res.success) {
    const data: TeacherScheduleEditResponse = res.data;
    return {
      success: true,
      data: data,
    };
  } else {
    return {
      success: false,
      data: "Could not update schedule",
    };
  }
};
