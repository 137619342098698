import { Message } from "@components/CommonComponents/Chat/ChatsComponent";
import ChatFunctions from "@lib/util/ChatDB";
import { useEffect, useState } from "react";

export const useShouldScrollChat = function (chats: Message[]): string[] {
  const [internalChats, setInternalChats] = useState<string[]>(
    chats.map((item) => {
      return item.id;
    })
  );
  useEffect(() => {
    chats.forEach((item) => {
      if (internalChats.includes(item.id)) {
      } else {
        setInternalChats((value) => [...value, item.id]);
      }
    });
  }, [chats]);
  return internalChats;
};
