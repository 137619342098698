import styled from "styled-components";
import { useParams } from "react-router-dom";
import CourseComponent from "../CourseComponent";
import { useCourseDetails } from "@lib/hooks/useCourseDetails";
import ErrorScreen from "../ErrorScreen";
import ChatEditor from "./ChatEditor";
import ChatsComponent, { Message } from "./ChatsComponent";
import { UserRole } from "@app/redux/slices/userSlice";
import { useCallback, useEffect, useState } from "react";
import SampleteacherImage from "@images/demo-teacher-image.png";
import { Language } from "@lib/Enums/Language";
import ChatInterface from "@API/services/Chat";
import { IncomingMessage } from "@lib/Types/Chat/IncomingMessage";
import { mapMessageToComponent } from "@lib/mapperFunctions/Chat/mapMessageToComponent";
import { mapMessageToAPI } from "@lib/mapperFunctions/Chat/mapMessageToAPI";
import LoadingScreenNew from "@components/LoadingScreenNew";
import { getChatHistory } from "@API/services/Chat/getChatHistory";
import ChatFunctions from "@lib/util/ChatDB";
import { TeacherCourseDetailsType } from "@components/TeachersDashboard/TeacherComponents/TeachersCourseDetails/TeachersCourseDetails";
import { getTeachersCourseDetails } from "@API/services/Teacher/getTeacherCourseDetails";
import { device } from "@styles/theme";
import { useSelector } from "react-redux";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { getCourseDetails } from "@API/services/CourseCRUD/getCourseDetails";
// export interface CourseType {
//     id: string;
//     image: string;
//     university: string;
//     study: string;
//     status: boolean;
//     major: string;
//     subject: string;
//     language: Language;
//     type: string;
//   }

// styled.components -----
const Container = styled.div`
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("/assets/chat-bg.jpg");
    z-index: 0;
    border-radius: 20px;
    opacity: 0.05;
  }
`;
const CourseDetailsContainer = styled.div`
  background: #ffffff;
  border-radius: 20px;
  padding: 1.875rem;
  z-index: 1;
  @media ${device.laptopL} {
    padding: 1.25rem;
    border-radius: 10px;
  }
`;
const ChatsContainer = styled.div`
  padding-bottom: 1.875rem;
  flex-grow: 1;
  width: 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
  @media ${device.laptopL} {
    padding-bottom: 10px;
  }
`;
const ChatsAndEditorContianer = styled.div`
  padding: 1.875rem;
  display: flex;
  flex-grow: 1;
  min-height: 0;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
  @media ${device.laptopL} {
    padding: 0rem;
  }
`;

const ConnectedContainer = styled.div`
  display: flex;
`;

interface AvailablilityIconPropsType {
  $available: boolean;
}
const AvailablilityIcon = styled.div<AvailablilityIconPropsType>`
  height: 1rem;
  width: 1rem;
  border-radius: 0.5rem;
  background: ${(props) =>
    props.$available
      ? props.theme.colors.green[600]
      : props.theme.colors.red[500]};
  margin-right: 0.625rem;
`;

const EditorContainer = styled.div`
  position: relative;
`;

// const FilesContainer = styled.div`
//   display: flex;
//   height: 5rem;
//   width: 100%;
//   position: absolute;
//   background: red;
//   top: -5rem;
// `;

const SampleChats: Message[] = [];

const sampleCourse = {
  id: "CRS00001",
  image: SampleteacherImage,
  university: "University of Leiden",
  study: "Law",
  status: true,
  subject: "Law",
  major: "Law",
  language: "DUTCH",
  type: "MASTERCLASS",
};

function Chat({ courseId }: { courseId: string }) {
  const userInfo = useSelector(selectUser);
  const [chats, setChats] = useState(SampleChats);
  if (!courseId) {
    return <ErrorScreen />;
  }

  const [detailsLoading, course] = useCourseDetails(courseId);
  const [loading, setLoading] = useState<boolean>(true);
  const [chatConnected, setChatConnected] = useState<boolean>(false);
  const [stompClient, setStompClient] = useState<ChatInterface>();
  const [messages, setMessages] = useState<Message[]>([]);
  const [courseData, setCourseData] = useState<TeacherCourseDetailsType>();
  const onMessage = (message: IncomingMessage) => {
    const mappedMessage = mapMessageToComponent(message);
    setMessages((messages) => {
      const [isNewMessage, newMessages] = ChatFunctions.updateMessage(
        messages,
        mappedMessage
      );
      return newMessages;
    });
  };
  async function setUpChat() {
    setLoading(true);
    if (stompClient) {
      setLoading(false);
      return;
    }
    const chatRes = await getChatHistory(courseId);
    if (chatRes.success) {
      setMessages(chatRes.data);
    }
    const client = new ChatInterface(courseId);
    setStompClient(client);
    if (userInfo.role === UserRole.TEAHCER) {
      const detailsRes = await getTeachersCourseDetails(courseId);
      if (detailsRes.success) {
        setCourseData(detailsRes.data);
      }
    } else if (userInfo.role === UserRole.STUDENT) {
      const detailsRes = await getCourseDetails({ id: courseId });
      if (detailsRes) {
        setCourseData(detailsRes);
      }
    }

    setLoading(false);
  }

  useEffect(() => {
    setUpChat();
    return () => {
      // ensure before disconnecting that stopClient is connected or else
      // it will give error
      if (stompClient && stompClient.connected) {
        stompClient.disconnect();
      }
    };
  }, [stompClient]);
  useEffect(() => {
    if (stompClient) {
      stompClient.subscribeToMessages((message) => {
        onMessage(message);
      });
      stompClient.subscribeToConnection((connected) => {
        setChatConnected(true);
      });
      stompClient.connect();
    }
  }, [stompClient]);
  const handleSubmit = (content: string) => {
    if (stompClient) {
      const message = mapMessageToAPI(content);
      stompClient.sendMessage(message);
    } else {
    }
  };

  return !detailsLoading && !loading ? (
    <Container>
      <CourseDetailsContainer>
        {courseData && (
          <CourseComponent
            hideMetaContainer={true}
            data={courseData}
          ></CourseComponent>
        )}
      </CourseDetailsContainer>
      <ChatsAndEditorContianer>
        <ChatsContainer>
          <ChatsComponent chats={messages} />
        </ChatsContainer>
        <EditorContainer>
          {/* <FilesContainer>something</FilesContainer> */}
          <ChatEditor connected={chatConnected} onSubmit={handleSubmit} />
        </EditorContainer>
      </ChatsAndEditorContianer>
    </Container>
  ) : (
    <LoadingScreenNew />
  );
}

export default Chat;
