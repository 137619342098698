import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../../../../commonComponents/Header";
import AddButton from "@components/AdminDashboard/SubPages/Settings/commonComponents/AddButton";
import ViewComponent, { DataText } from "../common/ViewComponent";
import Modal from "@components/CommonComponents/Modal";
import AddSubjectForm from "./AddSubjectForm";
import { useTranslation } from "react-i18next";
import LoadingComponent from "../LoadingComponent";
import { useAdminSubjectOptions } from "@lib/hooks/useAdminSubjectOptions";
import { useParams } from "react-router-dom";
import ConfirmationPopup from "@components/CommonComponents/ConfirmationPopup";
import { DeleteOverlay } from "../common/DeleteOverlay";
import { LoadingSpinner } from "@lib/microComponents/LoadingSpinner";
import { deleteASubjectService } from "@API/services/EntitiesCrud/deleteEntities";
import { ReactToast } from "@lib/toast";
// styled-components ----
const Container = styled.div``;
// ----------------------
const SubjectContainer = styled.div`
  position: relative;
  user-select: none;
`;

interface subjectEditInfo {
  subjectName: string;
  subjectId: string;
}
export interface AdminSubjectView {
  id: string;
  value: string;
  logo: string;
}

function Subjects() {
  const [subjects, setSubjects] = useState<AdminSubjectView[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showEditForm, setShowEditForm] = useState<subjectEditInfo>();
  const [showDeletePopup, setShowDeletePopup] = useState<AdminSubjectView>();
  const [deleting, setDeleting] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const { id } = useParams();
  async function getSubjectList() {
    setLoading(true);
    if (id) {
      const options = await useAdminSubjectOptions(id);
      setSubjects(options);
    }
    setLoading(false);
  }
  useEffect(() => {
    getSubjectList();
  }, []);
  async function deleteASubject(subjectId: string, subjectName: string) {
    const res = await deleteASubjectService(subjectId);
    if (res.success) {
      ReactToast.success(`${subjectName} deleted`);
      getSubjectList();
    } else {
      ReactToast.error(`subject ${subjectName} could not be deleted`);
    }
    setShowDeletePopup(undefined);
    setDeleting(undefined);
  }
  const { t } = useTranslation();

  return (
    <Container>
      {showModal && (
        <Modal
          handleClose={() => {
            setShowModal(false);
          }}
          heading={t(
            "adminSettings.universityStudySubjectPage.subjectsPage.addNewSubject"
          )}
        >
          {id && (
            <AddSubjectForm
              onCreate={() => {
                setSubjects([]);
                getSubjectList();
                setShowEditForm(undefined);
                setShowModal(false);
              }}
              studyId={id}
            />
          )}
        </Modal>
      )}
      {showEditForm && (
        <Modal
          handleClose={() => {
            setShowEditForm(undefined);
          }}
          heading="Edit subject"
        >
          {id && (
            <AddSubjectForm
              onCreate={() => {
                setSubjects([]);
                getSubjectList();
                setShowEditForm(undefined);
                setShowModal(false);
              }}
              studyId={id}
              edit={{
                subjectId: showEditForm.subjectId,
                subjectMame: showEditForm.subjectName,
              }}
            />
          )}
        </Modal>
      )}
      {showDeletePopup && (
        <ConfirmationPopup
          text={`Are you sure you want to delete subject '${showDeletePopup.value}'`}
          handleClose={() => {
            setShowDeletePopup(undefined);
          }}
          onConfirmation={() => {
            setDeleting(showDeletePopup.id);
            deleteASubject(showDeletePopup.id, showDeletePopup.value);
          }}
        />
      )}
      <Header
        text={t("adminSettings.universityStudySubjectPage.Subjects")}
        searchText={t(
          "adminSettings.universityStudySubjectPage.subjectsPage.searchInSubjects"
        )}
      >
        <AddButton
          onClick={() => {
            setShowModal(true);
          }}
        >
          {t(
            "adminSettings.universityStudySubjectPage.subjectsPage.addSubject"
          )}
        </AddButton>
      </Header>
      <SubjectContainer>
        {!loading
          ? subjects.map((subject) => {
              return (
                <ViewComponent
                  onClickDelete={() => {
                    if (deleting) return;
                    setShowDeletePopup(subject);
                  }}
                  imageUrl={
                    subject.logo
                      ? subject.logo
                      : "/assets/defaultuniversityicon.png"
                  }
                  university={false}
                  name={subject.value}
                  onClickEdit={() => {
                    setShowEditForm({
                      subjectId: subject.id,
                      subjectName: subject.value,
                    });
                  }}
                  arrowVisible={false}
                >
                  {deleting === subject.id && (
                    <DeleteOverlay>
                      <LoadingSpinner />
                    </DeleteOverlay>
                  )}
                </ViewComponent>
              );
            })
          : Array.from({ length: 10 }).map(() => <LoadingComponent />)}
      </SubjectContainer>
    </Container>
  );
}

export default Subjects;
