import React, { ReactNode } from "react";
import { FaArrowRight, FaBuildingColumns, FaTrash } from "react-icons/fa6";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUser } from "@/app/redux/publicSelectors/userSelector";
import { UserRole } from "@/app/redux/slices/userSlice";

// styled-components----
const Container = styled.div`
  padding: 1.875rem;
  background: #ffffff;
  margin-top: 1.25rem;
  border-radius: 1.25rem;
  padding-right: 7.0625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;
const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;
const RightContainer = styled.div`
  display: flex;
  align-items: center;
`;
const UniversityImageContainer = styled.div`
  height: 3.625rem;
  width: 3.625rem;
`;

const UniversityImage = styled.img`
  height: 3.625rem;
  width: 3.625rem;
`;
const NameAndIconContainer = styled.div`
  margin-left: 2rem;
  display: flex;
`;
const UniversityIcon = styled(FaBuildingColumns)`
  display: block;
  min-height: 2.25rem;
  height: 2.25rem;
  width: 1.4375rem;
  min-width: 1.4375rem;
  color: ${(props) => props.theme.colors.gray[800]};
  margin-right: 1rem;
`;
const NameContainer = styled.div`
  line-height: 2.25rem;
  font-size: 1.625rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const DataContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 4.3125rem;
  gap: 5.375rem;
`;
const EditButton = styled.button`
  width: 9.125rem;
  height: 2.1875rem;
  border-radius: 10px;
  background: ${(props) => props.theme.colors.gray[500]};
  color: ${(props) => props.theme.colors.gray[800]};
  line-height: 1.4375rem;
  font-size: 1rem;
`;
const ArrowIcon = styled(FaArrowRight)`
  width: 1.375rem;
  height: 1.625rem;
  min-width: 1.375rem;
  min-height: 1.625rem;
  position: absolute;
  top: 50%;
  right: 1.875rem;
  transform-origin: center;
  transform: translate(-50%, -50%);
  color: ${(props) => props.theme.colors.gray[800]};
`;
const DeleteButton = styled.button`
  margin-right: 1rem;
  background: none;
  svg {
    height: 18px;
    min-height: 18px;
    color: ${(props) => props.theme.colors.red[500]};
  }
`;

// ---------------------

const DataTextStyled = styled.div`
  color: ${(props) => props.theme.colors.gray[600]};
  font-size: 1rem;
  line-height: 1.4375rem;
`;
const DataTextBoldStyled = styled.span`
  font-weight: 700;
`;
export function DataText({ number, text }: { number: number; text: string }) {
  return (
    <DataTextStyled>
      <DataTextBoldStyled>{number}</DataTextBoldStyled>&nbsp;
      {text}
    </DataTextStyled>
  );
}

export default function ViewComponent({
  imageUrl,
  university,
  name,
  onClickEdit,
  onClickDelete,
  arrowVisible,
  children,
}: {
  imageUrl: string;
  university?: boolean;
  name: string;
  onClickEdit: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClickDelete: (e: React.MouseEvent<HTMLButtonElement>) => void;
  arrowVisible?: boolean;
  children?: ReactNode;
}) {
  const { t } = useTranslation();
  const userInfo = useSelector(selectUser);

  return (
    <Container>
      <LeftContainer>
        <UniversityImageContainer>
          <UniversityImage src={imageUrl} />
        </UniversityImageContainer>
        <NameAndIconContainer>
          {university && <UniversityIcon />}
          <NameContainer>{name}</NameContainer>
        </NameAndIconContainer>
      </LeftContainer>
      <RightContainer>
        {children && <DataContainer>{children}</DataContainer>}
        {userInfo.role === UserRole.ADMIN && (
          <DeleteButton
            onClick={(e) => {
              e.stopPropagation();
              onClickDelete(e);
            }}
          >
            <FaTrash></FaTrash>
          </DeleteButton>
        )}

        <EditButton
          onClick={(e) => {
            e.stopPropagation();
            onClickEdit(e);
          }}
        >
          {t("adminSettings.universityStudySubjectPage.edit")}
        </EditButton>
      </RightContainer>
      {arrowVisible && <ArrowIcon />}
    </Container>
  );
}
