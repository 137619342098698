import { AdminUniversityView } from "@components/AdminDashboard/SubPages/Settings/SubPages/UniversitiesStudiesAndSubjects/SubPages/Universities/main";
import { AdminUniversity } from "@lib/Types/AdminData/AdminEntitiyList";
export function mapAdminUniversityListToComponent(
  data: AdminUniversity[]
): AdminUniversityView[] {
  return data.map((item) => ({
    id: item.universityId.toString(),
    value: item.name,
    numberOfSubjects: item.numberOfSubjects,
    numberOfStudies: item.numberOfStudies,
    logoUrl: item.logoUrl
      ? `${item.logoUrl}`
      : "/assets/defaultuniversityicon.png",
  }));
}
