import CallToSignIn from "@API/CallToSignIn";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { setUserInfoActionCreator } from "@app/redux/slices/userSlice";
import ConfirmationPopup from "@components/CommonComponents/ConfirmationPopup";
import { device, mixin } from "@styles/theme";
import Cookies from "js-cookie";
import React, { LegacyRef, forwardRef, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
// styled-components ----
const OuterContainer = styled.div`
  width: 100vw;
  background: #00000040;
  position: absolute;
  top: 5.0625rem;
  z-index: 1200;
  right: 0;
  display: flex;
  justify-content: flex-end;
`;

const Container = styled.div`
  width: 100%;
  max-width: 33.75rem;
  height: calc(100vh - 5.0625rem);
  z-index: 1200;
  background: #ffffff;
  right: 0;
  overflow-y: scroll;
  padding: 3.125rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3rem;
  ${mixin.hideScroll()}
  @media ${device.tablet} {
    padding: 1.25rem;
  }
`;
const SideMenuContainer1 = styled.div``;
const SideMenuList1 = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
`;
const SideMenuListItem1 = styled.li`
  display: flex;
  align-items: center;
`;
const SideMenuContainer2 = styled.div``;
const SideMenuList2 = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
`;
const SideMenuListItem2 = styled.li`
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const LogoutButton = styled.button`
  background: none;
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const SideMenuLink2 = styled(NavLink)`
  font-size: 1.125rem;
  line-height: 1.5625rem;
  color: ${(props) => props.theme.colors.gray[800]};
`;
const SideMenuLink1 = styled(NavLink)`
  font-size: 1.5625rem;
  line-height: 2.1875rem;
  color: ${(props) => props.theme.colors.gray[800]};
  @media ${device.tablet} {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
`;
const ComingSoonTag = styled.div`
  padding: 0.1875rem 1.25rem;
  color: ${(props) => props.theme.colors.gray[800]};
  background: ${(props) => props.theme.colors.gray[600]}26;
  border-radius: 20px;
  font-size: 0.625rem;
  line-height: 0.9375rem;
`;
const Divider = styled.div`
  height: 0.125rem;
  background: ${(props) => props.theme.colors.gray[500]};
  margin: 1.875rem 0;
`;
const DividerWithLessMargin = styled(Divider)`
  margin: 1.25rem 0;
`;
const SideMenuFooter = styled.div``;
const AkcNameContaier = styled.div`
  font-size: 1.125rem;
  line-height: 1.5625rem;
  color: ${(props) => props.theme.colors.gray[600]};
  opacity: 0.75;
  font-weight: 700;
  @media ${device.tablet} {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;
const AddressContainer = styled.div`
  margin-top: 0.75rem;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  color: ${(props) => props.theme.colors.gray[600]};
  opacity: 0.75;
  @media ${device.tablet} {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;
const CopyrightInfo = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${(props) => props.theme.colors.gray[600]};
  opacity: 0.75;
`;
const DisclaimerLink = styled(Link)`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${(props) => props.theme.colors.gray[600]};
  opacity: 0.75;
  margin-top: 0.625rem;
`;

const SigninLinksCotainer = styled.div`
  padding: 2.1875rem 1.5625rem;
  background: transparent linear-gradient(90deg, #4883c4 0%, #1b67bc 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 20px;
  margin-top: 3.125rem;
  @media ${device.tablet} {
    padding: 1.875rem 1.625rem;
    margin-top: 1.25rem;
  }
`;
const SignInLinkComponentHeading = styled.div`
  font-size: 1.5625rem;
  line-height: 2.1875rem;
  font-weight: 700;
  color: #ffffff;
  border-radius: 20px;
`;
const ButtonsContainer = styled.div`
  margin-top: 0.8125rem;
  display: grid;
  gap: 1.25rem;
  grid-template-columns: minmax(0, 8fr) minmax(0, 5fr);
  @media ${device.tablet} {
    gap: 1rem;
  }
`;
const SignUpButton = styled(Link)`
  font-size: 1.125rem;
  line-height: 1.5625rem;
  border-radius: 12px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  background: ${(props) => props.theme.colors.green[600]};
  height: 2.5625rem;
  @media ${device.tablet} {
    font-size: 0.875rem;
    line-height: 1.25rem;
    height: 2.0625rem;
  }
`;
const SignInButton = styled(Link)`
  font-size: 1.125rem;
  line-height: 1.5625rem;
  border-radius: 12px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff26;
  height: 2.5625rem;
  @media ${device.tablet} {
    font-size: 0.875rem;
    line-height: 1.25rem;
    height: 2.0625rem;
  }
`;
const SideMenuLinksArray0 = [
  {
    id: 0,
    key: "dashboard",
    name: "My dashboard",
    link: "/dashboard",
    implemented: true,
  },
];
const SideMenuLinksArray1 = [
  {
    id: 0,
    key: "study",
    name: "Study courses",
    link: "/search",
    implemented: true,
  },
  // {
  //   id: 1,
  //   key: "internship",
  //   name: "Internship finder",
  //   link: "#",
  //   implemented: false,
  // },
  // {
  //   id: 2,
  //   key: "sidejob",
  //   name: "Side job finder",
  //   link: "#",
  //   implemented: false,
  // },
  {
    id: 3,
    key: "center",
    name: "Knowledge center",
    link: "#",
    implemented: false,
  },
];
const SideMenuLinksArray2 = [
  {
    id: 0,
    key: "support",
    name: "Support & FAQ",
    link: "/support",
    implemented: true,
  },
  {
    id: 1,
    key: "about",
    name: "About us",
    link: "/about",
    implemented: true,
  },
  {
    id: 2,
    key: "work",
    name: "Work at AKC",
    link: "/workAtAkc",
    implemented: true,
  },
];

//--------------------
const SideMenu = forwardRef(function (_, ref: LegacyRef<HTMLDivElement>) {
  const userInfo = useSelector(selectUser);
  const [showSignoutConfirmation, setShowSignoutConfirmation] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  function logout() {
    Cookies.remove("token");
    dispatch(setUserInfoActionCreator(new Date().getTime(), false));
  }
  return (
    <OuterContainer>
      {showSignoutConfirmation && (
        <ConfirmationPopup
          handleClose={() => {
            setShowSignoutConfirmation(false);
          }}
          onConfirmation={() => {
            logout();
          }}
          text="Are you sure you want to sign out?"
        ></ConfirmationPopup>
      )}
      <Container ref={ref}>
        <SideMenuContainer1>
          {userInfo.loggedIn && (
            <>
              <SideMenuList1>
                {SideMenuLinksArray0.map((item) => {
                  return (
                    <SideMenuListItem1>
                      <SideMenuLink1 to={item.link}>
                        {t(`sidemenu.zerothlinks.${item.key}`)}
                      </SideMenuLink1>
                      &nbsp;&nbsp;
                      {!item.implemented && (
                        <ComingSoonTag>{t("sidemenu.coming")}</ComingSoonTag>
                      )}
                    </SideMenuListItem1>
                  );
                })}
              </SideMenuList1>
              <Divider />
            </>
          )}
          <SideMenuList1>
            {SideMenuLinksArray1.map((item) => {
              return (
                <SideMenuListItem1>
                  <SideMenuLink1 to={item.link}>
                    {t(`sidemenu.firstlinks.${item.key}`)}
                  </SideMenuLink1>
                  &nbsp;&nbsp;
                  {!item.implemented && (
                    <ComingSoonTag>{t("sidemenu.coming")}</ComingSoonTag>
                  )}
                </SideMenuListItem1>
              );
            })}
          </SideMenuList1>
          <Divider />
          <SideMenuList2>
            {SideMenuLinksArray2.map((item) => {
              return (
                <SideMenuListItem2>
                  <SideMenuLink2 to={item.link}>
                    {t(`sidemenu.secondlinks.${item.key}`)}
                  </SideMenuLink2>
                  &nbsp;&nbsp;
                  {!item.implemented && (
                    <ComingSoonTag>{t("sidemenu.coming")}</ComingSoonTag>
                  )}
                </SideMenuListItem2>
              );
            })}
            {userInfo.loggedIn && (
              <SideMenuListItem2>
                <LogoutButton
                  onClick={() => {
                    setShowSignoutConfirmation(true);
                  }}
                >
                  {t("Header.accountmenu.logout")}
                </LogoutButton>
              </SideMenuListItem2>
            )}
          </SideMenuList2>
          {!userInfo.loggedIn && (
            <SigninLinksCotainer>
              <SignInLinkComponentHeading>
                Create a free student account
              </SignInLinkComponentHeading>
              <ButtonsContainer>
                <SignUpButton to="/signup">Create an account</SignUpButton>
                <SignInButton to="/signin">Sign in</SignInButton>
              </ButtonsContainer>
            </SigninLinksCotainer>
          )}
        </SideMenuContainer1>

        <SideMenuFooter>
          <AkcNameContaier>AKC (Academisch Kenniscentrum)</AkcNameContaier>
          <AddressContainer>Keizersgracht 241, Amsterdam</AddressContainer>
          <DividerWithLessMargin />
          <CopyrightInfo>
            © 2023 • {t("sidemenu.copyright")} 4yourbrand.io
          </CopyrightInfo>
          <DisclaimerLink to="#">{t("sidemenu.disclaimer")}</DisclaimerLink>
        </SideMenuFooter>
      </Container>
    </OuterContainer>
  );
});

export default SideMenu;
