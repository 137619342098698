import { CourseStatus } from "@lib/Enums/CourseStatus";
import { TeacherOptionType } from "./Components/Teacher/TeacherSelect";
import {
  QUESTIONS,
  SCHEDULE,
  STUDY_GUIDE_AVAILABLE,
  GUIDE_PRICE,
  SELL_INDIVIDUALLY,
  formSchemaType,
  TEACHER,
  FEATURES,
  HIGHLIGHTS,
  AVAILABLE_FROM,
  AVAILABLE_UPTO,
  STUDY_GUIDE,
  TEACHER_USP_1,
  TEACHER_USP_2,
  NAME,
  SUBJECT,
  UNIVERSITY,
  STUDY,
  EVENT,
  LANGUAGE,
  GRADE_YEAR,
  SPOTS,
  GROUPS,
  ORIGINAL_PRICE,
  SALE_PRICE,
  MANAGER,
} from "./validationSchema";
import { v4 as uuid } from "uuid";
import { optionType } from "@components/LandingPage/FormSelect";
import { Language } from "@lib/Enums/Language";
import { AddCourseManagerListOptionType } from "./Components/GeneralDetails/ManagerSelector";

export interface ScheduleItemType {
  name: string;
  date: Date | undefined;
  startTime: string | undefined;
  endTime: string | undefined;
  id: string;
}
export interface QuestionItemType {
  question: string;
  answer: string;
  id: string;
}
export interface FeaturesItemType {
  id: string;
  content: string;
}
export interface HighlightItemType {
  id: string;
  label: string;
  content: string;
}
export interface TeacherType extends TeacherOptionType {
  usp1: string;
  usp2: string;
}

export interface LanguageOptionType {
  id: Language;
  value: string;
}

export interface formikConfig {
  initialValues: {
    [MANAGER]: AddCourseManagerListOptionType | undefined;
    [NAME]: string;
    [SUBJECT]: optionType | undefined;
    [UNIVERSITY]: optionType | undefined;
    [STUDY]: optionType | undefined;
    [EVENT]: optionType | undefined;
    [LANGUAGE]: LanguageOptionType | undefined;
    [GRADE_YEAR]: optionType | undefined;
    [SPOTS]: "" | number;
    [GROUPS]: "" | number;
    [ORIGINAL_PRICE]: "" | number;
    [SALE_PRICE]: "" | number;
    [QUESTIONS]: QuestionItemType[];
    [SCHEDULE]: ScheduleItemType[];
    [STUDY_GUIDE_AVAILABLE]: boolean;
    [STUDY_GUIDE]: string | undefined;
    [GUIDE_PRICE]: "" | number;
    [AVAILABLE_FROM]: undefined | Date;
    [AVAILABLE_UPTO]: undefined | Date;
    [SELL_INDIVIDUALLY]: boolean;
    [FEATURES]: FeaturesItemType[];
    [HIGHLIGHTS]: HighlightItemType[];
    [TEACHER]: TeacherOptionType | null;
    [TEACHER_USP_1]: string;
    [TEACHER_USP_2]: string;
  };
  onSubmit: Function;
  validationSchema: formSchemaType;
}
export const initialValues: formikConfig["initialValues"] = {
  [MANAGER]: undefined,
  [NAME]: "",
  [SUBJECT]: undefined,
  [UNIVERSITY]: undefined,
  [STUDY]: undefined,
  [EVENT]: undefined,
  [GRADE_YEAR]: undefined,
  [LANGUAGE]: undefined,
  [SPOTS]: "",
  [GROUPS]: "",
  [ORIGINAL_PRICE]: "",
  [SALE_PRICE]: "",
  [QUESTIONS]: [
    {
      question: "What can you expect from this course?",
      answer:
        "In this training you will be prepared for your Psychology Statistics 3 digital trainingsessions of 4 hours each and is given in English by Konstantin. In these 12 training hours you will be extensively supervised and well prepared for your exam.",
      id: uuid(),
    },
  ],
  [FEATURES]: [
    {
      id: uuid(),
      content: "13 Extra hours of training",
    },
    {
      id: uuid(),
      content: "Specifically for RUG students",
    },
    {
      id: uuid(),
      content: "Join easy and efficiently via Zoom",
    },
    {
      id: uuid(),
      content: "Personal help",
    },
    {
      id: uuid(),
      content: "Knowledge guide included",
    },
    {
      id: uuid(),
      content: "Not passed? Next course for free",
    },
  ],
  [SCHEDULE]: [
    {
      name: "",
      date: undefined,
      startTime: undefined,
      endTime: undefined,
      id: uuid(),
    },
  ],
  [HIGHLIGHTS]: [
    {
      id: uuid(),
      label: "Not passed?",
      content: "Next course for free + unlimited use of AKC study materials",
    },
  ],
  [STUDY_GUIDE_AVAILABLE]: true,
  [STUDY_GUIDE]: undefined,
  [GUIDE_PRICE]: "",
  [AVAILABLE_FROM]: undefined,
  [AVAILABLE_UPTO]: undefined,
  [SELL_INDIVIDUALLY]: false,
  [TEACHER]: null,
  [TEACHER_USP_1]: "",
  [TEACHER_USP_2]: "",
};
