import CourseComponent from "@components/CommonComponents/CourseComponent";
import EnrolledCourse from "@components/CommonComponents/CourseComponent/EnrolledCourse";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { device } from "@styles/theme";
import Skeleton from "@components/CommonComponents/CourseComponent/Skeleton";
import EmptyComponent1 from "@components/CommonComponents/EmptyContanetComponents/EmptyComponent1";
import TeacherCourseComponent from "@components/TeachersDashboard/TeacherComponents/TeacherCourseComponent";
import TeachersScheduleEditModal from "@components/TeachersDashboard/TeacherComponents/TeacherScheduleEditModal.tsx/main";
import { getTeacherCoursesList } from "@API/services/Teacher/getTeachersCourses";
import { TeacherCourseType } from "@components/TeachersDashboard/TeacherComponents/TeacherCourseType";
// styled-components----
const Container = styled.div``;
const LoaddingContainer = styled.div`
  width: 100%;
  padding-top: 3rem;
  font-size: 2rem;
  text-align: center;
`;
const Heading = styled.div`
  font-size: 1.375rem;
  line-height: 1.9375rem;
  color: ${(props) => props.theme.colors.gray[800]};
  font-weight: 700;
`;
const EnrolledCoursesContainer = styled.div`
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 1.25rem;
  @media ${device.tablet} {
    margin-top: 0.9375rem;
  }
`;
// ---------------------

function CourseInfo() {
  const [activeCourse, setActiveCourse] = useState<number>();
  const [Course, setCourse] = useState<TeacherCourseType[]>([]);
  const [CourseLoadingErrors, SetCourseLaodingError] = useState<boolean>(false);
  const [CoursesLoading, SetCourseLoading] = useState<boolean>(false);
  const changeActive = (key: number) => {
    setActiveCourse(key);
  };
  async function getCourseData() {
    SetCourseLoading(true);
    const courseResponse = await getTeacherCoursesList();
    if (courseResponse.success) {
      setCourse(courseResponse.data);
    } else {
      SetCourseLoading(false);
      SetCourseLaodingError(true);
    }
    SetCourseLoading(false);
  }
  useEffect(() => {
    getCourseData();
  }, []);
  return (
    <Container>
      {CourseLoadingErrors && <div>Error</div>}
      <Heading>Your courses</Heading>
      {CoursesLoading &&
        Array.from({ length: 5 }).map(() => {
          return (
            <EnrolledCoursesContainer>
              <Skeleton />
            </EnrolledCoursesContainer>
          );
        })}
      {!CourseLoadingErrors && !CoursesLoading && Course && (
        <EnrolledCoursesContainer>
          {Course.length ? (
            Course.map((e, index) => (
              <TeacherCourseComponent
                key={`${e.study}.${e.subject}`}
                Course={Course[index]}
                setActive={changeActive}
                active={activeCourse === index}
                index={index}
              />
            ))
          ) : (
            <EmptyComponent1 to="" buttonHidden={true} />
          )}
        </EnrolledCoursesContainer>
      )}
    </Container>
  );
}

export default CourseInfo;
