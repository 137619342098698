import { createUniversity } from "@API/services/EntitiesCrud";
import { UserRole } from "@app/redux/slices/userSlice";
import {
  Label,
  SettingsInput,
  SettingsSubmitButton,
} from "@components/AdminDashboard/SubPages/Settings/commonComponents/FormComponents";
import Select from "@lib/microComponents/Select";
import { ReactToast } from "@lib/toast";
import { useFormik } from "formik";
import { ReactNode, useState } from "react";
import styled from "styled-components";
import { array, object, string } from "yup";
import SettingsSelect from "../../commonComponents/SettingsSelect";
import { useUniversityOptions } from "@lib/hooks/useUniversityOptions";
import { searchOption } from "@lib/util/main";
import { inviteTeamMember } from "@API/services/Admin/inviteTeamMember";
import { optionType } from "@components/LandingPage/FormSelect";
import { useTranslation } from "react-i18next";
import { device, mixin } from "@styles/theme";
import { useSelector } from "react-redux";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { MultiSelect } from "@lib/microComponents/MultiSelect";
import { TbPointerPause } from "react-icons/tb";

// styled-components ----
const Container = styled.div`
  margin-top: 1.875rem;
  display: flex;
  flex-direction: column;
  padding: 1.875rem;
  padding-top: 0rem;
  @media ${device.tablet} {
    padding-left: 0.2rem;
  }
`;
const Form = styled.form``;
const IconLabel = styled(Label)`
  margin-top: 1.25rem;
`;
const RoleLabel = styled(Label)`
  display: block;
  margin-top: 1.25rem;
`;
const UniversitySubmitButton = styled(SettingsSubmitButton)`
  margin-top: 5rem;
`;
const RoleSelect = styled(SettingsSelect)`
  margin-top: 0.625rem;
`;
const EmailInput = styled(SettingsInput)`
  margin-top: 0.625rem;
`;
const AccessToLabel = styled(Label)`
  margin-top: 1.875rem;
  display: block;
`;
const AccessToSelect = styled(MultiSelect)`
  margin-top: 0.625rem;
`;
const InputContainer = styled.div``;
const Error = styled.div`
  font-size: 0.9rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.red[500]};
  margin-top: 0.5rem;
`;
const EmailLabel = styled(Label)`
  margin-top: 1.25rem;
`;
const SelectedUniversitiesContainer = styled.div`
  display: flex;
  gap: 1.25rem;
  width: 100%;
  overflow-x: scroll;
  ${mixin.hideScroll()}
  margin-top: 1.25rem;
`;
const SelectedUniversityContainer = styled.div`
  padding: 0.5rem 0.8rem;
  background: ${(props) => props.theme.colors.gray[500]};
  border-radius: 12px;
  color: ${(props) => props.theme.colors.gray[800]};
`;
// ----------------------

function SelectedUniversity({ children }: { children: ReactNode }) {
  return <SelectedUniversityContainer>{children}</SelectedUniversityContainer>;
}

const roleOptions = [
  { id: UserRole.ADMIN, value: "Admin" },
  { id: UserRole.MANAGER, value: "Manager" },
];

const FIRST_NAME = "first_name";
const LAST_NAME = "last_name";
const EMAIL = "email";
const ROLE = "role";
const ACCESS = "access";
interface initialvaluesInterface {
  [FIRST_NAME]: string;
  [LAST_NAME]: string;
  [EMAIL]: string;
  [ROLE]: undefined | UserRole;
  [ACCESS]: optionType[];
}
const initialValues: initialvaluesInterface = {
  [FIRST_NAME]: "",
  [LAST_NAME]: "",
  [EMAIL]: "",
  [ROLE]: undefined,
  [ACCESS]: [],
};
function AddMembersForm() {
  const universityOptions = useUniversityOptions();
  const userInfo = useSelector(selectUser);
  const formik = useFormik({
    initialValues,
    validationSchema: object({
      [FIRST_NAME]: string().required("Name field is required"),
      [LAST_NAME]: string().required("Last name is required"),
      [EMAIL]: string()
        .email("must be a valid email")
        .required("email field is required"),
      [ROLE]: string().required("Role is required"),
      // [ACCESS]: string().required("Accesss grant is required"),
      [ACCESS]: array()
        .of(
          object().shape({
            id: string(),
            value: string(),
          })
        )
        .when([ROLE], {
          is: UserRole.MANAGER,
          then: () =>
            array()
              .of(
                object().shape({
                  id: string(),
                  value: string(),
                })
              )
              .min(1, "Select at least one university")
              .required("Required"),
          otherwise: () =>
            array()
              .of(
                object().shape({
                  id: string(),
                  value: string(),
                })
              )
              .notRequired(),
        }),
    }),

    onSubmit: async (values, actions) => {
      if (!values[EMAIL] || !values[ROLE]) {
        ReactToast("There is some error on client side");
        return;
      }
      const res = await inviteTeamMember(
        `${values[FIRST_NAME].trim()} ${values[LAST_NAME].trim()}`,
        values[EMAIL],
        values[ACCESS]
          ? values[ACCESS].map((option) => {
              return Number(option.id);
            })
          : undefined,
        values[ROLE]
      );
      if (res) {
        ReactToast("Invite sent");
        actions.resetForm();
      } else {
        ReactToast("Couldn't send invite");
      }
      actions.setSubmitting(false);
    },
  });
  function getRolesFromId(id: string | undefined) {
    if (!id) return undefined;
    return searchOption(id, roleOptions);
  }
  function getUniversityFromId(id: string | undefined) {
    if (!id) return undefined;
    return searchOption(id, universityOptions);
  }

  const { t } = useTranslation();

  return (
    <Container>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <Label>First name</Label>
        <InputContainer>
          <EmailInput
            placeholder="First name"
            {...formik.getFieldProps(FIRST_NAME)}
          />
          {formik.touched[FIRST_NAME] && formik.errors[FIRST_NAME] && (
            <Error>{formik.errors[FIRST_NAME]}</Error>
          )}
        </InputContainer>
        <EmailLabel>Last name</EmailLabel>
        <InputContainer>
          <EmailInput
            placeholder="Last name"
            {...formik.getFieldProps(LAST_NAME)}
          />
          {formik.touched[LAST_NAME] && formik.errors[LAST_NAME] && (
            <Error>{formik.errors[LAST_NAME]}</Error>
          )}
        </InputContainer>
        <EmailLabel>
          {t("adminSettings.adminsAndManagersPage.email")}
        </EmailLabel>
        <InputContainer>
          <EmailInput
            placeholder={t("adminSettings.adminsAndManagersPage.email")}
            {...formik.getFieldProps(EMAIL)}
          />
          {formik.touched[EMAIL] && formik.errors[EMAIL] && (
            <Error>{formik.errors[EMAIL]}</Error>
          )}
        </InputContainer>
        {}
        <RoleLabel>Roles</RoleLabel>
        <InputContainer>
          <RoleSelect
            text={t("adminSettings.adminsAndManagersPage.selectRole")}
            options={roleOptions}
            name={ROLE}
            formik={formik}
            onChange={(value) => {
              formik.setFieldValue(ROLE, value ? value.id : undefined);
            }}
            value={getRolesFromId(formik.getFieldProps(ROLE).value)}
          />
          {formik.touched[ROLE] && formik.errors[ROLE] && (
            <Error>{formik.errors[ROLE]}</Error>
          )}
        </InputContainer>
        {(!formik.getFieldProps(ROLE).value ||
          formik.getFieldProps(ROLE).value === "MANAGER") && (
          <>
            {!!formik.getFieldProps(ACCESS).value.length && (
              <SelectedUniversitiesContainer>
                {formik.getFieldProps(ACCESS).value.map((item: optionType) => {
                  return <SelectedUniversity>{item.value}</SelectedUniversity>;
                })}
              </SelectedUniversitiesContainer>
            )}

            <AccessToLabel>Access to</AccessToLabel>
            <InputContainer>
              <AccessToSelect
                values={formik.getFieldProps(ACCESS).value}
                onChange={(selectedItems) => {
                  formik.setFieldValue(ACCESS, selectedItems);
                }}
                options={universityOptions}
                text={t(
                  "adminSettings.adminsAndManagersPage.selectUniversities"
                )}
              />
              {formik.touched[ACCESS] &&
                formik.errors[ACCESS] &&
                typeof formik.errors[ACCESS] === "string" && (
                  <Error>{formik.errors[ACCESS]}</Error>
                )}
            </InputContainer>
          </>
        )}

        <UniversitySubmitButton loading={formik.isSubmitting}>
          {t("adminSettings.adminsAndManagersPage.sendInvite")}
        </UniversitySubmitButton>
      </Form>
    </Container>
  );
}

export default AddMembersForm;
