import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";
import { MapCourseToSuggestion } from "@lib/mapperFunctions/mapCoursetoSuggestion";
import { NonEnrolledCourseDataType } from "@components/CommonComponents/CourseComponent/NonEnrolledCourse";
import { SuggestionSearchList } from "@lib/Types/API/APISuggestionResponse";
export const GetSuggestedCourse = async (): Promise<
  NonEnrolledCourseDataType[]
> => {
  const url = urlFunctions.getSuggestionCourse();
  const res = await API.sendGetRequest(url, true);
  if (res.success) {
    const data: SuggestionSearchList = res.data;
    const MappedData: NonEnrolledCourseDataType[] = await MapCourseToSuggestion(
      { item: data }
    );
    return MappedData;
  }
  return [];
};
