import React from "react";
import { Popup } from "./Popup";
import cancelIcon from "@images/cancelIcon.png";
import reloadIcon from "@images/reloadIcon.png";

interface IProps {
  onButtonClick: () => void;
}

const CheckoutErrorPopup: React.FC<IProps> = (props) => {
  const { onButtonClick } = props;
  return (
    <>
      <Popup
        popupName="CheckoutErrorPopup"
        isOpacityContainer={true}
        isPopupImage={true}
        popupImage={cancelIcon}
        isBackgroundImage={false}
        backgroundImage=""
        headingColor="#707070"
        headingWidth="65%"
        buttonColor="#FFFFFF"
        buttonBackgroundColor="#4883C4"
        isPopupButtonImage={true}
        popupButtonImage={reloadIcon}
        onButtonClick={onButtonClick}
      />
    </>
  );
};

export { CheckoutErrorPopup };
