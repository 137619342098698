import React from "react";
import styled from "styled-components";
import Table from "./Table";
import { OverviewDataType } from "./main";
import { OverViewDataReturnType } from "./userOverviewData";
const Container = styled.div`
  margin-top: 1.875rem;
`;
function UniversitiesMetrics({
  overviewState,
}: {
  overviewState: OverViewDataReturnType<true | false>;
}) {
  return (
    <Container>
      <Table overviewState={overviewState} />
    </Container>
  );
}

export default UniversitiesMetrics;
