const baseStrings = {
  "routes.landing": "/",
  "routes.summary": "/summary",
};

export type LanguageStrings = typeof baseStrings;
export const en = baseStrings;
export enum AppRoute {
  Home = "routes.home",
  Summary = "routes.summary",
}
