import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../../commonComponents/Header";
import AddButton from "../../commonComponents/AddButton";
import Table from "./Table";
import Modal from "@components/CommonComponents/Modal";
import AddMembersForm from "./AddTeachersForm";
import { TeacherData } from "./AdminTeachers";
import { getAdminTeachers } from "@API/services/Admin/adminTeacherList";
import { useSelector } from "react-redux";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
// styled-comoponents ----
const Container = styled.div``;
const TableContainer = styled.div`
  margin-top: 1.875rem;
`;
// -----------------------

const SampleData = [
  {
    id: "2",
    firstName: "Mohd",
    LastName: "Huzaifa",
    role: "Teachrs",
    accessTo: "University of munich",
  },
];
export default function Teachers() {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [Teachers, setTeachers] = useState<TeacherData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const userInfo = useSelector(selectUser);
  const getAdminTeacher = async () => {
    setLoading(true);
    const data: TeacherData[] | undefined = await getAdminTeachers(
      userInfo.role
    );
    if (data) setTeachers(data);
    setLoading(false);
  };

  useEffect(() => {
    getAdminTeacher();
  }, []);

  return (
    <Container>
      {showModal && (
        <Modal
          handleClose={() => {
            setShowModal(false);
          }}
          heading="Invite a new team member"
        >
          <AddMembersForm />
        </Modal>
      )}
      <Header text="Teachers" searchText="Teachers">
        <AddButton
          onClick={() => {
            setShowModal(true);
          }}
        >
          Invite new
        </AddButton>
      </Header>
      <TableContainer>
        <Table data={Teachers} loading={loading} />
      </TableContainer>
    </Container>
  );
}
