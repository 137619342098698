import { API } from "@API/API";
import { urlFunctions } from "@API/functions/createUrl";
import { gradeYearList } from "@lib/Types/API/FormData/FormData";

type gradeYearListType =
  | {
      success: true;
      data: gradeYearList;
    }
  | {
      success: false;
      data: string;
    };

export const getGradeYearList = async function (): Promise<gradeYearListType> {
  const url = urlFunctions.getGradeYearList();
  const res = await API.sendGetRequest(url);
  if (res.success) {
    const data: gradeYearList = res.data;
    return {
      success: true,
      data: data,
    };
  } else {
    return {
      success: false,
      data: "There was an error in getting grade year list",
    };
  }
};
