import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { device } from "@styles/theme";

const Container = styled.div``;

export const OpacityContainer = styled.div`
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 0.25;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
`;

export const PopupContainer = styled.div<popupContainerProps>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 410px;
  padding: 30px;

  background: ${({ isBackgroundImage, backgroundImage }) =>
    isBackgroundImage
      ? `url(${backgroundImage}) 0% 0% no-repeat padding-box`
      : "#FFFFFF"};

  background-position: center;
  background-size: cover;
  border-radius: 20px;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media ${device.laptop} {
    width: 410px;
    height: 341px;
  }
  @media ${device.tablet} {
    width: 380px;
    height: 304px;
  }
  @media ${device.tabletS} {
    width: 320px;
    height: 256px;
  }
`;

const PopupTextContainer = styled.div`
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PopupHeading = styled.h3<popupHeadingProps>`
  font-size: 1.75rem;
  font-weight: bold;
  color: ${({ headingColor }) => `${headingColor}`};
  margin-bottom: 30px;
  max-width: ${({ headingWidth }) => `${headingWidth}`};
  text-align: center;
  line-height: 1.5;
  @media ${device.laptop} {
    font-size: 1.45rem;
  }
  @media ${device.tablet} {
    font-size: 1.35rem;
  }
  @media ${device.tabletS} {
    font-size: 1.1rem;
  }
`;

const PopupButton = styled.button<popupButtonProps>`
  background: ${({ buttonBackgroundColor }) =>
    `${buttonBackgroundColor} 0% 0% no-repeat padding-box`};
  border-radius: 10px;
  padding: 0.7rem 3.5rem;
  color: ${({ buttonColor }) => `${buttonColor}`};
  font-size: 0.8rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 10px;
  @media ${device.laptop} {
    font-size: 0.7rem;
    padding: 0.7rem 3rem;
  }
  @media ${device.tablet} {
    padding: 0.6rem 2.7rem;
  }
  @media ${device.tabletS} {
    padding: 0.5rem 2.4rem;
  }
`;

const PopupButtonImage = styled.img`
  width: 15px;
  height: 15px;
  @media ${device.laptop} {
    width: 15px;
    height: 15px;
  }
  @media ${device.tablet} {
    width: 13px;
    height: 13px;
  }
`;

const PopupImage = styled.img`
  margin-bottom: 15px;
  width: 60px;
  height: 60px;
  @media ${device.tabletS} {
    width: 50px;
    height: 50px;
  }
`;

interface popupContainerProps {
  isBackgroundImage: boolean;
  backgroundImage: string;
}

interface popupHeadingProps {
  headingColor: string;
  headingWidth: string;
}

interface popupButtonProps {
  buttonColor: string;
  buttonBackgroundColor: string;
}

interface popupImageProps {
  popupImage: string;
}

interface popupProps
  extends popupContainerProps,
    popupHeadingProps,
    popupButtonProps,
    popupImageProps {
  popupName: string;
  isOpacityContainer: boolean;
  isPopupImage: boolean;
  isPopupButtonImage: boolean;
  popupButtonImage: string;
  onButtonClick: () => void;
}

function Popup(props: popupProps) {
  const {
    popupName,
    isOpacityContainer,
    isPopupImage,
    isBackgroundImage,
    backgroundImage,
    headingColor,
    headingWidth,
    buttonColor,
    buttonBackgroundColor,
    popupImage,
    isPopupButtonImage,
    popupButtonImage,
    onButtonClick,
  } = props;

  const { t } = useTranslation();

  return (
    <Container>
      {isOpacityContainer && <OpacityContainer />}
      <PopupContainer
        isBackgroundImage={isBackgroundImage}
        backgroundImage={backgroundImage}
      >
        {isPopupImage && <PopupImage src={popupImage} />}
        <PopupTextContainer>
          <PopupHeading headingWidth={headingWidth} headingColor={headingColor}>
            {t(`Popups.StudentPopups.${popupName}.popupHeading`)}
          </PopupHeading>
          <PopupButton
            buttonColor={buttonColor}
            buttonBackgroundColor={buttonBackgroundColor}
            onClick={onButtonClick}
          >
            {t(`Popups.StudentPopups.${popupName}.popupButtonText`)}
            {isPopupButtonImage && <PopupButtonImage src={popupButtonImage} />}
          </PopupButton>
        </PopupTextContainer>
      </PopupContainer>
    </Container>
  );
}

export { Popup };
