import { getTeachersList } from "@API/services/CourseCRUD/getTeachersList";
import { selectUser } from "@app/redux/publicSelectors/userSelector";
import { TeacherOptionType } from "@components/AdminDashboard/SubPages/Courses/AddCourse/Components/Teacher/TeacherSelect";
import { mapTeacherToTeachersOptions } from "@lib/mapperFunctions/mapTeacherToTeachersOptions";
import { ReactToast } from "@lib/toast";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useTeacherOptions = function (): TeacherOptionType[] {
  const userInfo = useSelector(selectUser);
  const [teacherOptions, setTeacherOptions] = useState<TeacherOptionType[]>([]);

  useEffect(() => {
    getTeachersOptions();
  }, []);
  async function getTeachersOptions() {
    if (!userInfo.role) {
      throw new Error("Not Authorized");
    }
    const data = await getTeachersList(userInfo.role);
    if (data.success) {
      const mappedList = mapTeacherToTeachersOptions(data.data);
      setTeacherOptions(mappedList);
    } else {
      ReactToast(data.data);
    }
  }
  return teacherOptions;
};
