import { StudentData } from "@components/CommonComponents/CourseApplications/StudentsTable";
import { APITeacherStudents } from "@lib/Types/API/Teacher/APITeacherStudents";

export function mapAPITeacherStudentsToComponent(
  data: APITeacherStudents[]
): StudentData[] {
  const mappedData: StudentData[] = data.map((e) => ({
    firstName: e.name.split(" ")[0],
    lastName: e.name.split(" ")[1],
    university: e.userProfile.university
      ? e.userProfile.university.universityName
      : "No university selected",
    study: e.userProfile.courseStudy
      ? e.userProfile.courseStudy.name
      : "No study selected",
    studentId: e.id,
    grade: e.userProfile.currentGrade
      ? e.userProfile.currentGrade.gradeYear
      : "No grade selected",
    email: e.email,
  }));
  return mappedData;
}
